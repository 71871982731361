import React, { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import lang from "translations";
import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import {
  ActionButton,
  Field,
  Form,
  Icon,
  Image,
  InputCounter,
  PageError,
  Panel,
  Switch,
  Text,
  Toast,
  Tooltip,
  Select,
} from "components/commons";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import initialFormState from "./category-form.state";
import { Path } from "paths";
import { WhiteLock, CheckCircle } from "images";
import { Divider, Skeleton } from "antd";
import { getAvailableCategoryColors } from "apis/category.api";
import { VenueContext } from "contexts";
import { colorListResponse } from "mappers/category.mapper";
import { mixpanel, TrackEvent } from "mixpanel";
import { getMewsOutlet } from "../../../apis/outlet.api";
import { outletMapper } from "mappers/outlet.mapper";
import { useFlags } from "launchdarkly-react-client-sdk";

const CategoryForm = ({
  loading,
  error,
  title,
  initialState = undefined,
  submitting,
  submit,
  deleteCategoryButton,
}) => {
  const { query, history } = useRouter();
  const { id } = query;
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { mewsLinkOutlet } = useFlags();
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const {
    request: outletRequest,
    mappedData: mappedOutlets,
    loading: loadingOutlet,
  } = useApi({
    api: getMewsOutlet,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  const {
    request: getUnAvailableCategoryColorsRequest,
    loading: loadingUnavailableColors,
    error: errorUnavailableColors,
    mappedData: unavailableColors,
  } = useApi({
    api: getAvailableCategoryColors,
    isArray: true,
    handleOwnError: {
      badrequest: true,
    },
    mapper: colorListResponse,
  });

  const outletOptions = useMemo(() => {
    const activeOutlets = mappedOutlets.filter((outlet) => outlet.active);
    return activeOutlets.map((outlet) => {
      const { outletId, outletName } = outlet;
      return { value: outletId, text: outletName };
    });
  }, [mappedOutlets]);

  useMount(() => {
    getUnAvailableCategoryColorsRequest({ categoryId: id || 0, venueId: venue.venueId });
  });

  useMount(async () => {
    await outletRequest();
  });

  const unsaveChangesModal = useModal();

  const goToList = useCallback(() => {
    history.push(Path.CATEGORY);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, goToList, unsaveChangesModal]);

  const handleSubmit = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.Clickedbutton, {
        Button: lang.saveCategoryForm,
        Page: lang.createCategory,
      });
      const res = await submit({ ...getFormValues() });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.CATEGORY);
    } catch ({ code, handleError }) {
      const err = {
        3011: () => {
          applyFieldErrors({
            name: lang.categoryNameAlreadyExist,
          });
        },
        3058: () => {
          applyFieldErrors({
            name: lang.categoryNameReserved,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [applyFieldErrors, history, submit, getFormValues]);

  const submitFormCb = useCallback(() => {
    submitForm(handleSubmit);
  }, [handleSubmit, submitForm]);

  const colorsBox = useMemo(() => {
    const colors = [
      "#FF000A",
      "#FF5F00",
      "#FFCB00",
      "#B8D231",
      "#007FFF",
      "#5F43FF",
      "#840090",
      "#FF008D",
      "#FFA9B8",
      "#FFC6A9",
      "#FFE999",
      "#E8F1A7",
      "#B0DFFF",
      "#CCCCFF",
      "#FF99FF",
      "#FFA6D3",
      "#FF5561",
      "#FF9355",
      "#FFDF68",
      "#D9E76C",
      "#58AFFF",
      "#9688FF",
      "#C24DC8",
      "#FF53B0",
      "#D6000C",
      "#DD4F00",
      "#E6B200",
      "#A9B519",
      "#006FD6",
      "#3622C4",
      "#62006D",
      "#D7007B",
      "#9B0000",
      "#BA3E00",
      "#BC8600",
      "#999900",
      "#005FAD",
      "#0D0089",
      "#400049",
      "#8E035C",
    ];
    let lockedColors = unavailableColors
      ? unavailableColors.map((c) => {
          return c.color;
        })
      : [];

    let colorsList = [];
    colors.forEach((color) => {
      colorsList.push(
        <div
          className="hover:opacity-80 cursor-pointer"
          style={{ width: "12.5%", paddingRight: "10px", paddingBottom: "10px" }}
          onClick={() => {
            if (!lockedColors || !lockedColors.includes(color)) {
              if (fields.color.value === color) {
                modifyField("color", { value: null });
              } else {
                modifyField("color", { value: color });
              }
            }
          }}
          key={color}
        >
          <div
            className="rounded-sm relative"
            style={{ backgroundColor: color, minHeight: "40px" }}
          >
            {fields.color.value && fields.color.value === color ? (
              <Image
                className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={CheckCircle}
                style={{ width: "20px", height: "20px" }}
              />
            ) : lockedColors.includes(color) ? (
              <Image
                className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={WhiteLock}
                style={{ width: "20px", height: "20px" }}
              />
            ) : null}
          </div>
        </div>
      );
    });
    return colorsList;
  }, [unavailableColors, fields.color, modifyField]);

  useEffect(() => {
    if (!fields.isPosAvailable.value) {
      modifyField("color", { value: null, dirty: false });
    }
    // eslint-disable-next-line
  }, [fields.isPosAvailable.value]);

  return (
    <ModuleWrapper>
      <HeaderB title={title} returnText={lang.categories} onClick={leavePage} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormCb}>
        {error ? (
          <PageError />
        ) : (
          <div>
            <FragmentA title={lang.basicInfo}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <Field className="mb-md" {...fields.name} noLabel>
                      <InputCounter {...fields.name} onChange={modifyField} />
                    </Field>
                    <Field>
                      <InputCounter {...fields.description} textarea onChange={modifyField} />
                    </Field>
                    {mewsLinkOutlet && (
                      <Field
                        customLabel={
                          <div className="mb-xs flex items-center justify-between">
                            <Text label>{lang.linkOutlet}</Text>
                            <Tooltip title={lang.linkOutletHoverTooltip}>
                              <div>
                                <Icon name="info" className="text-xs text-pelorous" />
                              </div>
                            </Tooltip>
                          </div>
                        }
                      >
                        <Select
                          {...fields.outletId}
                          placeholder={lang.selectOutlet}
                          options={outletOptions}
                          loading={loadingOutlet}
                          disabled={loadingOutlet}
                          onChange={modifyField}
                        />
                      </Field>
                    )}
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
            <FragmentA title={lang.pointOfSale}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <div className="flex">
                      <Switch {...fields.isPosAvailable} onChange={modifyField} />
                      <div className="ml-md">
                        <Text size="text-md" strong>
                          {lang.activateOnPos}
                        </Text>
                        <Text>{lang.displayCategoryInPos}</Text>
                      </div>
                    </div>
                    {fields.isPosAvailable.value ? (
                      <div>
                        <Divider />
                        <Text size="text-md" strong>
                          {lang.chooseCategoryColor}
                        </Text>
                        {errorUnavailableColors ? (
                          <Text size="text-sm">{lang.errorLoadingColors}</Text>
                        ) : loadingUnavailableColors ? (
                          <div className="flex align-center">
                            <Icon loading className="m-auto" fontSize="30px" />
                          </div>
                        ) : (
                          <div className="flex flex-wrap mt-lg">{colorsBox}</div>
                        )}
                        {}
                      </div>
                    ) : null}
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
          </div>
        )}

        {!error && (
          <ActionButton
            showLine
            loading={submitting}
            disabled={submitting || loadingUnavailableColors}
            primary={{
              onClick: () => {
                submitFormCb();
              },
            }}
            secondary={{
              text: lang.cancel,
              onClick: () => leavePage(),
            }}
            danger={deleteCategoryButton}
          />
        )}
      </Form>
    </ModuleWrapper>
  );
};

export default CategoryForm;
