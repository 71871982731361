import React, { useMemo, useContext } from "react";
import classNames from "classnames";
import { Text, Button, Icon, Image } from "components/commons";
import styles from "../receipt-setting-form/receipt-setting.module.scss";
import { Affix, Skeleton } from "antd";
import { items, transactionInfo, taxList, discountList } from "./data";
import { StyleType } from "enums";
import { StaffContext } from "contexts";
import lang from "translations";
import classnames from "classnames";

const ReceiptSettingPreview = ({
  initialState,
  venue,
  venueId,
  onChangeVisibility,
  loading,
  previewVisible = true,
}) => {
  const { venueName, businessName, businessAddress, businessContactNumber, logoLink } = venue;
  const { staff } = useContext(StaffContext);

  const renderBalanceAndCredit = useMemo(() => {
    const balanceAndCredit = initialState
      ? initialState.salesSettings.filter(
          (setting = {}) =>
            setting.name === "GUESTS_PREVIOUS_BALANCE_TOTAL_PAID_AND_NEW_BALANCE" &&
            setting.isIncluded
        )
      : null;
    if (balanceAndCredit && balanceAndCredit.length > 0) {
      return (
        <tbody>
          <tr>
            <td className="w-2/3">
              <Text receiptContent className="font-bold leading-tight">
                Prev. Bal.
              </Text>
            </td>
            <td className="text-right w-1/3">
              <Text className="font-mono font-bold leading-tight" receiptContent>
                500.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Credits
              </Text>
            </td>
            <td className="text-right leading-tight">
              <Text receiptContent>300.00</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Free Credits
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="leading-tight">
                200.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Pstpaid Crdts Used
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="leading-tight">
                0.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="font-bold leading-tight">
                Total Paid
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="font-bold leading-tight">
                230.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="font-bold leading-tight">
                New Bal.
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="font-bold leading-tight">
                270.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Credits
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="leading-tight">
                270.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Free Credits
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="leading-tight">
                0.00
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text receiptContent className="ml-md leading-tight">
                Pstpaid Crdts Used
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="leading-tight">
                0.00
              </Text>
            </td>
          </tr>
        </tbody>
      );
    }
  }, [initialState]);

  const renderDiscount = () => {
    return (
      <tbody>
        <tr>
          <td>
            <Text receiptContent className="font-bold leading-none">
              {"Discount"}
            </Text>
          </td>
          <td className="text-right">
            <Text receiptContent className="leading-none">
              (1.20)
            </Text>
          </td>
        </tr>
        {discountList.map((discount) => {
          return (
            <tr key={discount.id}>
              <td>
                <Text receiptContent className="ml-md leading-tight">
                  {discount.name}
                </Text>
              </td>
              <td className="text-right">
                <Text receiptContent className="leading-none">
                  ({discount.amount})
                </Text>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const renderTax = useMemo(() => {
    const itemTax = initialState
      ? initialState.salesSettings.filter(
          (setting) => setting.name === "ITEM_TAX" && setting.isIncluded
        )
      : null;

    if (itemTax && itemTax.length > 0) {
      return (
        <tbody>
          <tr>
            <td>
              <Text receiptContent className="font-bold leading-none">
                {"Tax"}
              </Text>
            </td>
            <td className="text-right">
              <Text receiptContent className="font-bold leading-none">
                19.8
              </Text>
            </td>
          </tr>
          {taxList.map((tax) => {
            return (
              <tr key={tax.id}>
                <td>
                  <Text receiptContent className="ml-md leading-tight">
                    {tax.name}
                  </Text>
                </td>
                <td className="text-right">
                  <Text receiptContent className="leading-none">
                    {tax.amount}
                  </Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
  }, [initialState]);

  const renderHeader = useMemo(() => {
    return !initialState ? null : (
      <>
        {loading ? (
          <Skeleton />
        ) : (
          initialState.headerSettings.map((data) => {
            return (
              <div key={data.name}>
                {data.name === "VENUE_LOGO" && data.isIncluded ? (
                  <Image src={logoLink} className="w-20 mx-auto my-lg" />
                ) : null}
                {data.name === "VENUE_NAME" && data.isIncluded ? (
                  <Text receiptContent className="font-bold text-md">
                    {venueName}
                  </Text>
                ) : null}
                {data.name === "BUSINESS_NAME" && data.isIncluded ? (
                  <Text receiptContent className="mb-lg font-bold text-sm">
                    {businessName}
                  </Text>
                ) : null}
                {data.name === "BUSINESS_ADDRESS" && data.isIncluded ? (
                  <Text receiptContent>{businessAddress}</Text>
                ) : null}
                {data.name === "BUSINESS_CONTACT_NUMBER" && data.isIncluded ? (
                  <Text receiptContent>{lang.phoneNo} {businessContactNumber}</Text>
                ) : null}
                {data.name === "TAX_NUMBER" && data.isIncluded ? (
                  <Text receiptContent>{lang.vatReg} {transactionInfo.taxNumber}</Text>
                ) : null}
                {data.name === "DEVICE_ID" && data.isIncluded ? (
                  <Text receiptContent>{lang.deviceId} {transactionInfo.deviceId}</Text>
                ) : null}
                {data.name === "SERIAL_NUMBER" && data.isIncluded ? (
                  <Text receiptContent>{lang.serialNumberAbbv} {transactionInfo.serialNumber}</Text>
                ) : null}
                {data.name === "RECEIPT_NUMBER" && data.isIncluded ? (
                  <Text receiptContent>{lang.orNo} {transactionInfo.receiptNumber}</Text>
                ) : null}
                {data.name === "DATE_AND_TIME_OF_TRANSACTION" && data.isIncluded ? (
                  <Text receiptContent>{transactionInfo.dateAndTime}</Text>
                ) : null}
                {data.name === "VENUE_LOCATION" && data.isIncluded ? (
                  <Text receiptContent>{transactionInfo.venueLocation}</Text>
                ) : null}
                {data.name === "STAFF_NAME_AND_OR_TAG_ID" && data.isIncluded ? (
                  <>
                    <Text receiptContent>{lang.staffWithColonSymbol} {staff.fullName}</Text>
                    <Text receiptContent>{lang.staffTagWithColonSymbol} {transactionInfo.staffTag}</Text>
                  </>
                ) : null}
                {data.name === "TAX_INVOICE_NUMBER" && data.isIncluded ? (
                  <Text receiptContent>{lang.taxInvoiceNo} {transactionInfo.dateIssued}-{venueId}{transactionInfo.venueLocationId}{transactionInfo.transactionId}</Text>
                ) : null}
                {data.name === "CUSTOM_HEADER" && data.isIncluded ? (
                  <Text receiptContent>{data.customValue}</Text>
                ) : null}
              </div>
            );
          })
        )}
      </>
    );
  }, [
    initialState,
    businessAddress,
    businessContactNumber,
    businessName,
    venueName,
    venueId,
    staff.fullName,
    loading,
    logoLink,
  ]);

  const renderFooter = useMemo(() => {
    return !initialState ? null : (
      <>
        {initialState.footerSettings.map((data) => {
          return (
            <div key={data.name}>
              {data.name === "CUSTOM_FOOTER" && data.isIncluded ? (
                <Text receiptContent className="text-center">{data.customValue}</Text>
              ) : null}
              {data.name === "POS_PROVIDER" && data.isIncluded ? (
                <>
                  <Text strong receiptContent>{transactionInfo.companyName}</Text>
                  <Text receiptContent>{transactionInfo.venueStreetAddressBuildingNumber}</Text>
                  <Text receiptContent>{transactionInfo.countryAndPostalCode}</Text>
                  <Text receiptContent>{transactionInfo.customerServiceEmail}</Text>
                  <Text receiptContent>{transactionInfo.companyWebsite}</Text>
                  <Text receiptContent>{lang.companyRegistrationNo} {transactionInfo.companyRegistrationNumber}</Text>
                </>
              ) : null}
              {data.name === "GUEST_NAME_AND_OR_TAG_ID" && data.isIncluded ? (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Text receiptContent className="pt-md">
                            {lang.guestWithColonSymbol}
                          </Text>
                        </td>
                        <td>
                          <Text receiptContent className="pt-md leading-none">
                            {transactionInfo.guestName}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <Text receiptContent className="leading-none">
                            {transactionInfo.guestTagId}
                          </Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}
              {data.name === "TRANSACTION_ID" && data.isIncluded ? (
                <Text receiptContent className="pt-md">
                  {lang.txnId}{transactionInfo.transactionId}
                </Text>
              ) : null}
            </div>
          );
        })}
      </>
    );
  }, [initialState]);

  const renderSubTotal = () => {
    return (
      <thead>
        <tr>
          <td>
            <Text receiptContent className="font-bold leading-none">
              Subtotal
            </Text>
          </td>
          <td>
            <Text receiptContent className="text-right font-bold leading-none">
              210.20
            </Text>
          </td>
        </tr>
      </thead>
    );
  };

  const renderTotal = () => {
    return (
      <thead>
        <tr>
          <td>
            <Text receiptContent className="text-md font-bold leading-none">
              Total
            </Text>
          </td>
          <td>
            <Text receiptContent className="text-md text-right font-bold leading-none">
              230.00
            </Text>
          </td>
        </tr>
      </thead>
    );
  };

  const renderTableItems = () => {
    return !items ? null : (
      <tbody className={styles.dashedLine}>
        {items.map((item) => {
          let amount = item.description.quantity * item.description.price;
          if (item.description.isVoucherPresent === true) {
            amount = 0;
          }
          const amountPerProduct = amount.toFixed(2);
          return (
            <tr key={item.id}>
              <td>
                <Text receiptContent className="leading-none">
                  {item.name}
                </Text>
                <Text receiptContent className="leading-none">
                  {item.description.quantity + " X " + item.description.price}
                </Text>
                {item.description.sugar ? (
                  <Text receiptContent className="leading-none">
                    {"Sugar: " + item.description.sugar}
                  </Text>
                ) : null}
                {item.description.size ? (
                  <Text receiptContent className="leading-none">
                    {"Size: " + item.description.size}
                  </Text>
                ) : null}
                {initialState?.salesSettings[0]?.isIncluded ? (
                  <Text receiptContent className="leading-none">
                    {"Tax: " + item.tax}
                  </Text>
                ) : null}
              </td>
              <td className="text-right align-top">
                <Text receiptContent>{amountPerProduct}</Text>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return previewVisible ? (
    <Affix offsetTop={10}>
      <div className={classNames("w-30", styles.receiptBackground)}>
        <div className="text-right my-sm">
          <Icon
            name="remove"
            className={classnames(styles.icon, "text-white cursor-pointer text-xl")}
            onClick={() => {
              onChangeVisibility(false);
            }}
          />
        </div>
        <div className="bg-white h-auto mx-10 mt-2 px-md py-xl">
          <div className="text-center">{renderHeader}</div>
          <hr className={styles.dashedLine}></hr>
          <table className={classNames("w-full table-fixed", styles.table)}>
            <thead>
              <tr>
                <th className="w-9/12 text-sm">
                  <Text receiptContent>{lang.item}</Text>
                </th>
                <th className="w-3/12 text-right text-xs">
                  <Text receiptContent>{lang.amount}</Text>
                </th>
              </tr>
            </thead>
            {renderTableItems()}
          </table>
          <table className={classNames("w-full table-fixed my-sm", styles.totalTable)}>
            {renderSubTotal()}
            {renderDiscount()}
            {renderTax}
            {renderTotal()}
          </table>
          <hr className={styles.dashedLine}></hr>
          <table className={classNames("w-full table-fixed my-sm", styles.totalTable)}>
            {renderBalanceAndCredit}
          </table>
          <hr className={styles.dashedLine}></hr>
          <Text className="text-center mt-sm" receiptContent>
            {lang.thisServesAsAnOfficialReceipt}
          </Text>
          <hr className={classNames("mb-lg", styles.dashedLine)}></hr>
          {renderFooter}
        </div>

        <div className="flex align-center">
          <Button
            type={StyleType.Plain}
            className="mx-auto mt-lg my-lg"
            onClick={() => {
              onChangeVisibility(false);
            }}
          >
            <Text color="text-white">{lang.closePreview}</Text>
          </Button>
        </div>
      </div>
    </Affix>
  ) : null;
};

export default ReceiptSettingPreview;
