import { Button, Modal } from "components";
import { StyleType } from "enums";
import React from "react";
import lang from "translations";

const ImportErrorModal = (props) => {
  const { onClose, text } = props;

  return (
    <Modal
      {...props}
      width={1337}
      customTitle={
        <p className="text-xl text-pelorous-darker font-semibold pt-3">{lang.impItem}</p>
      }
      noCloseButton
    >
      <div
        style={{ width: 1115, alignItems: "center" }}
        className="bg-white m-auto flex justify-center flex-col pt-24 pb-10"
      >
        <div className="flex flex-col items-center">
          <img src="/error.svg" alt="" className="h-20" />
          <div className="text-20 font-semibold">{lang.dataError}</div>
        </div>
        <div className="my-5">{text}</div>
        {lang.impItemsPlsRecheck}
      </div>
      <div className="flex gap-2 justify-end py-8">
        <Button type={StyleType.Secondary} onClick={onClose}>
          {lang.discard}
        </Button>
        <Button onClick={onClose}>{lang.ok}</Button>
      </div>
    </Modal>
  );
};

export default ImportErrorModal;
