import enumeration from "./enumeration";

const IntegrationType = {
  MEWS: "MEWS",
  MEWS_BOOKINGS: "MEWS_BOOKINGS",
  MEWS_ACCOUNTING: "MEWS_ACCOUNTING",
  CLOUDBEDS: "CLOUDBEDS",
  PURCHASE_PLUS: "PURCHASE_PLUS",
  OTHERS: "OTHERS",
  PROPERTY_MANAGEMENT_SYSTEM: "PROPERTY_MANAGEMENT_SYSTEM",
};

export default enumeration(IntegrationType);
