import { searchStaffWithGuestRegistration } from "apis";
import { searchGuestProfile } from "apis/guest-profile.api";
import {
  Field,
  Filter,
  MultipleSelect,
  RangePicker,
  Select,
  Text,
  TimePicker,
} from "components/commons";
import { useApi, useMount } from "hooks";
import {
  guestProfileFilterRequest,
  guestProfileFilterResponse,
  staffWithGuestFilterRequest,
  staffWithTransactionFilterResponse,
} from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import { statusFilter } from "./filters";
import lang from "translations";

const GuestFilter = ({
  filterState,
  requestState,
  modifyFilter,
  modifyFilters,
  clearFilter,
  fetchGuests,
  applyFilter,
}) => {
  const {
    request: searchStaffRequest,
    loading: loadingStaff,
    mappedData: staffs,
  } = useApi({
    api: searchStaffWithGuestRegistration,
    isArray: true,
    mapper: staffWithTransactionFilterResponse,
  });

  const {
    request: searchGuestProfileRequest,
    loading: loadingGuestProfile,
    mappedData: guestProfiles,
  } = useApi({
    api: searchGuestProfile,
    isArray: true,
    params: {
      includeDeleted: true,
    },
    mapper: guestProfileFilterResponse,
  });

  useMount(() => {
    fetchStaffRequest(requestState);
    fetchGuestProfileRequest(requestState);
  });

  const fetchStaffRequest = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject({ ...requestState }, staffWithGuestFilterRequest));
    },
    [searchStaffRequest]
  );

  const fetchGuestProfileRequest = useCallback(
    (requestState) => {
      searchGuestProfileRequest(mapObject({ ...requestState }, guestProfileFilterRequest));
    },
    [searchGuestProfileRequest]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchStaffRequest(requestState);
    },
    [fetchStaffRequest, modifyFilter]
  );

  const staffOptions = useMemo(() => {
    return mapObjectsToSelect(staffs, { valueKey: "id", textKey: "name" });
  }, [staffs]);

  const guestProfileOptions = useMemo(() => {
    const filteredGuestProfile = [
      ...new Map(guestProfiles.map((guest) => [guest["id"], guest])).values(),
    ];
    return mapObjectsToSelect(filteredGuestProfile, { valueKey: "id", textKey: "name" });
  }, [guestProfiles]);

  return (
    <Filter
      placeholder={lang.searchGuestsByNameOrId}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      filterState={filterState}
      actionsSpan={12}
    >
      <Field className="col-span-4" filterLabel={lang.dateOfRegistration}>
        <RangePicker name="dateRange" onChange={changeDateRangeCb} value={filterState.dateRange} />
      </Field>
      <Field className="col-span-4" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0].set({
                    hour: startTime.get("hour"),
                    minute: startTime.get("minute"),
                  }),
                  filterState.dateRange[1],
                ],
              });
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            to
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0],
                  filterState.dateRange[1].set({
                    hour: endTime.get("hour"),
                    minute: endTime.get("minute"),
                  }),
                ],
              });
            }}
          />
        </div>
      </Field>
      <Field className="col-span-4" filterLabel={lang.guestOrTagStatus}>
        <Select
          name="guestTagStatus"
          options={statusFilter}
          value={filterState.guestTagStatus}
          onChange={modifyFilter}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.staff}>
        <MultipleSelect
          name="staffs"
          selectAllText={lang.allStaff}
          loading={loadingStaff}
          options={staffOptions}
          value={filterState.staffs}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!staffOptions.length ? lang.noAvailableStaff : lang.selectStaff}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.guestProfile}>
        <MultipleSelect
          name="guestProfiles"
          selectAllText={lang.allGuestProfiles}
          loading={loadingGuestProfile}
          options={guestProfileOptions}
          value={filterState.guestProfiles}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={
            !guestProfileOptions.length ? lang.noAvailableGuestProfile : lang.selectGuestProfile
          }
        />
      </Field>
      {/* <Field className="col-span-4" filterLabel="Credit Status">
        <Select
          name="creditStatus"
          options={creditFilter}
          value={filterState.creditStatus}
          onChange={modifyFilter}
        />
      </Field> */}
    </Filter>
  );
};

export default GuestFilter;
