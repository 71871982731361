import React, { useCallback, useMemo } from "react";
import { Field, Filter, MultipleSelect, RangePicker, Text, TimePicker } from "components/commons";
import {
  locationWithTransactionFilterResponse,
  locationWithTransactionFilterRequest,
  staffWithTransactionFilterRequest,
  staffWithTransactionFilterResponse,
  deviceWithTransactionFilterResponse,
  deviceWithTransactionFilterRequest,
} from "mappers";
import { mapObject, mapObjectsToSelect, modifyFromTimeRange, modifyToTimeRange } from "services";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { searchShiftDevices, searchShiftLocations, searchShiftStaffs } from "apis";

const ShiftFilter = ({ filterState, requestState, modifyFilter, applyFilter, clearFilter }) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: searchShiftLocations,
    isArray: true,
    mapper: locationWithTransactionFilterResponse,
  });

  const {
    request: searchStaffRequest,
    loading: loadingStaff,
    mappedData: staffs,
  } = useApi({
    api: searchShiftStaffs,
    isArray: true,
    mapper: staffWithTransactionFilterResponse,
  });

  const {
    request: searchDeviceRequest,
    loading: loadingDevice,
    mappedData: devices,
  } = useApi({
    api: searchShiftDevices,
    isArray: true,
    mapper: deviceWithTransactionFilterResponse,
  });

  useMount(() => {
    fetchLocationRequest(requestState);
    fetchStaffRequest(requestState);
    fetchDeviceRequest(requestState);
  });

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, locationWithTransactionFilterRequest));
    },
    [searchLocationRequest]
  );

  const fetchStaffRequest = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject({ ...requestState }, staffWithTransactionFilterRequest));
    },
    [searchStaffRequest]
  );

  const fetchDeviceRequest = useCallback(
    (requestState) => {
      searchDeviceRequest(mapObject({ ...requestState }, deviceWithTransactionFilterRequest));
    },
    [searchDeviceRequest]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocationRequest(requestState);
      fetchStaffRequest(requestState);
      fetchDeviceRequest(requestState);
    },
    [fetchLocationRequest, fetchStaffRequest, fetchDeviceRequest, modifyFilter]
  );

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const staffOptions = useMemo(() => {
    return mapObjectsToSelect(staffs, { textKey: "name" });
  }, [staffs]);

  const deviceOptions = useMemo(() => {
    return mapObjectsToSelect(devices, { textKey: "name" });
  }, [devices]);

  return (
    <Filter
      placeholder={lang.searchReportsByShiftId}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      filterState={filterState}
    >
      <Field className="col-span-4" filterLabel={lang.dateRange}>
        <RangePicker name="dateRange" onChange={changeDateRangeCb} value={filterState.dateRange} />
      </Field>
      <Field className="col-span-4" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFromTimeRange(startTime, filterState, modifyFilter);
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            to
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyToTimeRange(endTime, filterState, modifyFilter);
            }}
          />
        </div>
      </Field>
      <Field className="col-span-4" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocation}
          options={locationOptions}
          value={filterState.locations}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.staff}>
        <MultipleSelect
          name="staffs"
          selectAllText={lang.allStaff}
          loading={loadingStaff}
          options={staffOptions}
          value={filterState.staffs}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noAvailableStaff : lang.selectStaff}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.device}>
        <MultipleSelect
          name="devices"
          selectAllText={lang.allDevices}
          loading={loadingDevice}
          options={deviceOptions}
          value={filterState.devices}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!deviceOptions.length ? lang.noDeviceAvailable : lang.selectDevice}
        />
      </Field>
    </Filter>
  );
};

export default ShiftFilter;
