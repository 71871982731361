import { Post } from "services";
import { ApiPath } from "paths";

export const searchApplications = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_APPLICATIONS}`, body);
  return res;
};

export const activateApplication = async ({ id, body }) => {
  const res = await Post(`${ApiPath.ACTIVATE_APPLICATION(id)}`, body);
  return res;
};

export const deactivateApplication = async ({ id, body }) => {
  const res = await Post(`${ApiPath.DEACTIVATE_APPLICATION(id)}`, body);
  return res;
};

export const searchApplicationActivations = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_APPLICATION_ACTIVATIONS}`, body);
  return res;
};