import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { CreateVoucher, EditVoucher, VoucherList, VoucherMovement } from "modules";
import { HeaderA, TabBar, ModuleWrapper } from "components";
import lang from "translations";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const VoucherPage = () => {
  const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(true);

  const getVouchers = (result, loading) => {
    checkIfVouchersExceeded(result, loading);
  };

  const checkIfVouchersExceeded = (vouchers, loading) => {
    const { total, voucherTotalLimit } = vouchers;
    if (total >= voucherTotalLimit) {
      setIsCreateBtnDisabled(true);
    } else if (total < voucherTotalLimit) {
      setIsCreateBtnDisabled(false);
    }
    if (total === 0 && voucherTotalLimit === 0 && !loading) {
      setIsCreateBtnDisabled(false);
    }
  };

  useMount(() => {
    setPageTitle("vouchers");
  });

  return (
    <ModuleWrapper>
      <Switch>
        <Route exact path={[Path.VOUCHER, Path.VOUCHER_MOVEMENT]}>
          <div className="mb-lg">
            <HeaderA
              title={lang.vouchers}
              description={lang.createAndManagerVouchers}
              button={{
                path: Path.VOUCHER_CREATE,
                text: lang.createNewVoucher,
                disabled: isCreateBtnDisabled,
              }}
              className="mb-sm"
            />
            <TabBar
              items={[
                { to: Path.VOUCHER, text: lang.allVouchers },
                { to: Path.VOUCHER_MOVEMENT, text: lang.movement },
              ]}
            />
          </div>
          <Route
            exact
            path={Path.VOUCHER}
            render={() => <VoucherList getData={getVouchers}></VoucherList>}
          />
          <Route exact path={Path.VOUCHER_MOVEMENT} component={VoucherMovement} />
        </Route>
        <Route exact path={Path.VOUCHER_CREATE} component={CreateVoucher} />
        <Route exact path={Path.VOUCHER_EDIT} component={EditVoucher} />
      </Switch>
    </ModuleWrapper>
  );
};

export default VoucherPage;
