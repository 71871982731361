import { useEffect } from "react";
import create from "zustand";

// { [page] : { selected item in a page}}
const store = create((set) => ({
  arr: [],
  name: "",
  setArr: (arr) => set({ arr }),
  setName: (arr) => set({ arr }),
}));

const useSelectID = (page = "") => {
  const { arr, setArr, name, setName } = store();

  const onSel = (v) => {
    const array = [...arr];
    const idx = array.findIndex((i) => i === v);
    if (idx > -1) array.splice(idx, 1);
    else array.push(v);

    setArr(array);
  };

  const onAdd = (ids = []) => {
    setArr([...arr, ...ids]);
  };

  const onSub = (ids = []) => {
    const array = [...arr];
    ids.forEach((v) => {
      const idx = array.findIndex((i) => i === v);
      if (idx > -1) array.splice(idx, 1);
    });
    setArr(array);
  };

  const onDel = () => {
    setArr([]);
  };

  useEffect(() => {
    if (page || page !== name) {
      setName(page);
      setArr([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return {
    arr,
    onSel,
    onAdd,
    onSub,
    onDel,
    name,
    setName,
  };
};

export default useSelectID;
