import lang from "translations";

const columns = [
  {
    key: "previousVenueName",
    text: lang.venue,
    width: "w-2/12",
    custom: true,
  },
  {
    key: "previousGuestId",
    text: lang.oldGuestId,
    width: "w-1/12",
    custom: true,
  },
  {
    key: "guestId",
    text: lang.guestId,
    width: "w-1/12",
    custom: true,
  },
  {
    key: "name",
    text: lang.guestName,
    width: "w-2/12",
    custom: true,
  },
  {
    key: "checkInDateTime",
    text: lang.checkedInDate,
    custom: true,
    width: "w-auto",
  },
  {
    key: "checkOutDateTime",
    text: lang.checkedOutDate,
    width: "w-auto",
    custom: true,
  },
  {
    key: "multiPropertyCreditsIn",
    text: lang.creditsReceived,
    width: "w-auto",
    custom: true,
  },
  {
    key: "multiPropertyFreeCreditsIn",
    text: lang.freeCreditsReceived,
    width: "w-auto",
    custom: true,
  },
];

export default columns;
