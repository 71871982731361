const locale = {
  aWholeProductIsFinished:
    "ผลิตภัณฑ์ที่สมบูรณ์เป็นผลิตภัณฑ์สำเร็จรูปที่จำหน่ายในสถานที่ของคุณ ด้วยตัวเลือกผลิตภัณฑ์ คุณอาจเลือกที่จะจำหน่ายผลิตภัณฑ์ในรูปแบบที่ต่างกัน เช่น รสชาติ สี และขนาด ผลิตภัณฑ์ที่สมบูรณ์และตัวเลือกผลิตภัณฑ์จะมีรายการวัตถุดิบเฉพาะ",
  accessToken: "โทเค็นการเข้าถึง",
  accommodation: "ที่พัก",
  account: "บัญชี",
  accountAlreadyExists: "อีเมลนี้ได้เชื่อมโยงกับบัญชีในสถานที่นี้แล้ว",
  accountCantSeemToFind:
    "อุ้ย! ดูเหมือนเราจะไม่พบบัญชีของคุณ โปรดติดต่อผู้จัดการสถานที่เพื่อขอความช่วยเหลือ",
  accountCode: "รหัสบัญชี",
  accountCodeAddedName: "เพิ่มรหัสบัญชี “{0}“ แล้ว",
  accountCodeAlreadyExist: "มีรหัสบัญชีอยู่แล้ว",
  accountEmailAdded: "เพิ่มบัญชี “{0}“ แล้ว",
  accountEmailRemoved: "ลบบัญชี “{0}“ แล้ว",
  accountIsActive: "บัญชีใช้งานอยู่",
  accountMapping: "การทำแผนที่บัญชี",
  accountMappingDescription: "ตั้งค่าและจับคู่บัญชีของคุณกับระบบ PouchVENUE",
  accountName: "ชื่อบัญชี",
  accountNotFound: "ไม่พบบัญชี",
  accountSettings: "การตั้งค่าบัญชี",
  accountType: "ประเภทบัญชี",
  accountingSoftware: "โปรแกรมบัญชี",
  accounts: "บัญชี",
  accrNo: "บัญชี #:",
  actions: "การกระทำ",
  activateInPos: "เปิดใช้งาน ณ จุดขาย",
  activateOnOnlineMenu: "เปิดใช้งานบนเมนูออนไลน์",
  activateOnPOS: "เปิดใช้งาน ณ จุดขาย (POS)",
  activateOnPos: "เปิดใช้งาน ณ จุดขาย",
  activateVariant: "เปิดใช้งานตัวเลือก",
  active: "ใช้งาน",
  activeDateRange: "ช่วงวันที่ใช้งาน",
  actualClosingBalance: "ยอดเงินปิดตามจริง",
  actualQty: "ปริมาณจริง",
  actualStockQty: "ปริมาณสต็อคตามจริง",
  add: "เพิ่ม",
  addANote: "เพิ่มหมายเหตุ...",
  addAProduct: "เพิ่มผลิตภัณฑ์",
  addASupplyItem: "เพิ่มรายการซัพพลาย",
  addAValidAttributeAndOptions: "เพิ่มคุณลักษณะและตัวเลือกที่ถูกต้อง",
  addAWholeProduct: "เพิ่มผลิตภัณฑ์ที่สมบูรณ์",
  addAccount: "เพิ่มบัญชี",
  addAndManageAdditionalCharge: "เพิ่มและจัดการค่าธรรมเนียมเพิ่มเติมสำหรับการขายบน POS",
  addAnotherAccount: "เพิ่มบัญชีอื่น",
  addAnotherAttribute: "เพิ่มคุณลักษณะอื่น",
  addAnotherProductOrSupplyItem: "เพิ่มผลิตภัณฑ์หรือรายการวัตถุดิบอื่น",
  addAnotherSupplyItem: "เพิ่มรายการวัตถุดิบอื่น",
  addCharge: "เพิ่มค่าธรรมเนียม",
  addCustomQuestion: "เพิ่มคำถามที่กำหนดเอง",
  addNewAccount: "เพิ่มบัญชีใหม่",
  addNewAccountCode: "ทำสรุปประจำวัน",
  addNewAccountCodeName: 'เพิ่มรหัสบัญชีใหม่่ "{0}"',
  addNewPaymentMethod: "เพิ่มวิธีการชำระเงินใหม่",
  addNewSupplyItem: "เพิ่มรายการวัตถุดิบใหม่",
  addNewSupplyItemSelect: "เพิ่มรายการวัตถุดิบใหม่ “{0}”",
  addNewTax: "เพิ่มภาษีใหม่",
  addNote: "เพิ่มหมายเหตุ",
  addOption: "เพิ่มตัวเลือก",
  addOptions: "เพิ่มตัวเลือก",
  addPaymentMethod: "เพิ่มวิธีการชำระเงิน",
  addProduct: "เพิ่มผลิตภัณฑ์",
  addProductLimit:
    "หากต้องการเพิ่มผลิตภัณฑ์เพิ่มเติม ให้ลองลบผลิตภัณฑ์บางอย่างเพื่อสร้างผลิตภัณฑ์ใหม่ หรือขอให้เจ้าของสถานที่ของคุณเพิ่มขีดจำกัดผลิตภัณฑ์ของคุณ!",
  addProductLimitOwner:
    "หากต้องการเพิ่มผลิตภัณฑ์เพิ่มเติม ให้ลองลบผลิตภัณฑ์บางอย่างเพื่อสร้างผลิตภัณฑ์ใหม่หรืออัปเกรดแผนของคุณเพื่อเพิ่มขีดจำกัดผลิตภัณฑ์ของคุณ!",
  addProducts: "เพิ่มผลิตภัณฑ์หรือจัดหารายการในชุดผลิตภัณฑ์นี้",
  addQty: "เพิ่มจำนวน",
  addStaffLimit:
    "หากต้องการเพิ่มพนักงาน ให้ลองลบพนักงานบางส่วนออกหรือขอให้เจ้าของสถานที่ของคุณเพิ่มจำนวนพนักงานของคุณ!",
  addStaffLimitOwner:
    "หากต้องการเพิ่มพนักงาน ให้ลองลบพนักงานบางส่วนออกหรืออัพเกรดแผนของคุณเพื่อเพิ่มขีดจำกัดพนักงานของคุณ!",
  addThisProduct: "เพิ่มผลิตภัณฑ์นี้เป็นผลิตภัณฑ์และรายการวัตถุดิบใหม่",
  addSupplyItem: "เพิ่มรายการสินค้า/วัตถุดิบ",
  addVariant: "เพิ่มตัวเลือก",
  addedStock: "สต๊อกที่เพิ่ม",
  additionalChargeReport: "รายงานการเรียกเก็บเงินเพิ่มเติม",
  additionalCharges: "ค่าใช้จ่ายเพิ่มเติม",
  adjustQuantity: "ปรับปริมาณ",
  adjustStockSuccessfully: "ปรับสต๊อกสำเร็จ",
  adjustTheSupplyItemsVariant: "ปรับรายการวัตถุดิบและปริมาณสำหรับตัวเลือกเฉพาะนี้",
  advancedReporting: "การรายงานขั้นสูง",
  all: "ทั้งหมด",
  allAccountTypes: "ประเภทบัญชีทั้งหมด",
  allCategories: "หมวดหมู่ทั้งหมด",
  allDevices: "อุปกรณ์ทั้งหมด",
  allDevicesUsed: "อุปกรณ์ทั้งหมดที่ใช้",
  allDiscounts: "ส่วนลดทั้งหมด",
  allGuestProfiles: "โปรไฟล์แขกทั้งหมด",
  allLocation: "ตำแหน่งทั้งหมด",
  allLocations: "ตำแหน่งทั้งหมด",
  allMeasurements: "ปริมาณทั้งหมด",
  allPaymentMethods: "ช่องทางการชำระเงินทั้งหมด",
  allProducts: "ผลิตภัณฑ์ทั้งหมด",
  allReportsReadyForDownload: "รายงานทั้งหมดพร้อมสำหรับการดาวน์โหลด",
  allRightsReserved: "สงวนลิขสิทธิ์",
  allRoles: "หน้าที่ทั้งหมด",
  allSafeToDeleteLocation: "ไม่มีปัญหา! ไม่มีผลิตภัณฑ์ที่มีตำแหน่งนี้",
  allSelectedAvailablePos: "ผลิตภัณฑ์ที่เลือกทั้งหมดจะพร้อมให้บริการ ณ จุดขาย",
  allSelectedNotPos: "ผลิตภัณฑ์ที่เลือกทั้งหมดจะไม่แสดง ณ จุดขายของคุณ",
  allShiftsClosed: "กะทั้งหมดที่ปิด",
  allSources: "แหล่งที่มาทั้งหมด",
  allStaff: "พนักงานทั้งหมด",
  allStatus: "สถานะทั้งหมด",
  allStockLevels: "ระดับสต๊อกทั้งหมด",
  allTransactionTypes: "ทุกประเภทธุรกรรม",
  allTransactions: "ธุรกรรมทั้งหมด",
  allVenues: "สถานที่ทั้งหมด",
  allVoucherTypes: "ประเภทคูปองทั้งหมด",
  allVouchers: "คูปองทั้งหมด",
  allowAccess: "อนุญาตการเข้าถึง",
  allowAccessAndConnect: "อนุญาตการเข้าถึงและเชื่อมต่อ",
  allowAndSetTip: "อนุญาตให้ทิปและกำหนดเปอร์เซ็นต์",
  allowBargainDesc: "เมื่อตรวจสอบสิ่งนี้ พนักงานจะสามารถเปลี่ยนราคาสินค้าใน POS ได้",
  allowBargainOnThisProduct: "ขออนุญาติต่อรองราคาสินค้าครับ",
  allowCustomTip: "อนุญาตให้แขกเพิ่มทิปที่กำหนดเอง",
  allowGuestWBKeeping: "อนุญาตให้แขกเก็บแท็กไว้เมื่อเช็คเอาท์",
  allowOrRestrictGuestWBKeeping: "อนุญาตหรือห้ามแขกเก็บแท็กเมื่อเช็คเอาท์",
  allowPostpaidAndLimit: "อนุญาตเครดิตชำระเงินที่หลังและกำหนดวงเงิน",
  allowPostpaidInGuestProfile:
    "อนุญาตให้โปรไฟล์แขกรายนี้ทำการซื้อโดยไม่มีเครดิต พวกเขาจะถูกเรียกเก็บเงินเมื่อเช็คเอาท์จากสถานที่",
  allowThisGuestProfileLimit:
    "อนุญาตให้โปรไฟล์แขกรายนี้ทำการซื้อโดยไม่มีเครดิต พวกเขาจะถูกเรียกเก็บเงินเมื่อเช็คเอาท์จากสถานที่",
  allowedPaymentMethod: "วิธีการชำระเงินที่อนุญาต",
  almostThere: "เกือบเสร็จแล้ว!",
  alreadyExists: "มีอยู่แล้ว",
  amount: "จำนวน",
  and: "และ",
  andManyMore: "และอื่น ๆ อีกมากมาย!",
  andWouldBe: "และจะเป็น",
  anHourAgo: "ชั่วโมงที่ผ่านมา",
  anyMoreQuestions: "มีคำถามเพิ่มเติมหรือไม่? {0}",
  appDate: "วันที่ติดตั้ง / อัปเดตแอป",
  appReinstall: "ติดตั้งแอปใหม่",
  appVersion: "เวอร์ชันของแอป",
  applyFilter: "ใช้ตัวกรอง",
  areYouSureYouWantToDeleteCharge: "คุณแน่ใจหรือไม่ว่าต้องการลบรายการเรียกเก็บเงิน {0}",
  areYouSureYouWantToDeleteLocation:
    "คุณแน่ใจหรือว่าต้องการลบ {0}? ปัจจุบันตำแหน่งนี้มีมูลค่าสินค้าคงคลังเป็น {1}",
  areYouSureYouWantToDeleteProduct:
    "คุณแน่ใจหรือว่าต้องการลบ {0}? รายการวัตถุดิบนี้มีอยู่ในสต๊อก {1} {2} รายการ",
  areYouSureYouWantToDeleteProduct2:
    "คุณแน่ใจหรือว่าต้องการลบ {0}? จะไม่มีให้บริการในสถานที่ของคุณอีกต่อไป ธุรกรรมและรายงานก่อนหน้านี้จะไม่ได้รับผลกระทบ",
  areYouSureYouWantToRemoveVariant:
    "คุณแน่ใจหรือไม่ว่าต้องการลบตัวเลือก “{0}” รายการวัตถุดิบจะไม่ถูกลบออก",
  arrivalDate: "วันที่มาถึง",
  arrivals: "มาถึง",
  askDeleteGuestProfile: "ลบโปรไฟล์แขก?",
  askDeleteStaffProfile: "ลบโปรไฟล์พนักงาน?",
  askGuestInfoCheckIn: "สอบถามข้อมูลของแขกขณะเช็คอิน",
  askVenueOwnerToUpgradePlan: "ขอให้เจ้าของสถานที่อัปเกรดแผนของคุณและเริ่มใช้ฟีเจอร์นี้",
  assemble: "การผลิต",
  assembleAProduct: "ผลิตภัณฑ์ที่ผ่านการผลิต",
  asset: "สินทรัพย์",
  assignAProfile: "กำหนดโปรไฟล์",
  assignFreeCreditsStaff: "ให้เครดิตฟรีแก่พนักงานในกลุ่มนี้",
  assignVoucherStaff: "มอบหมายคูปองให้กับพนักงานในกลุ่มนี้",
  assignVoucherToGuest: "กำหนดคูปองให้กับแขกในกลุ่มนี้เมื่อลงทะเบียน",
  assignedRooms: "ห้อง/เตียงที่กำหนด",
  assignedSpace: "พื้นที่ที่กำหนด",
  attribute: "คุณลักษณะ",
  attributeAlreadyExists: "มีชื่อลักษณะอยู่แล้วในผลิตภัณฑ์นี้",
  automaticallyAddThisChargeToTheCart: "เพิ่มการเรียกเก็บเงินนี้ลงในรถเข็นโดยอัตโนมัติหรือไม่",
  automaticallyAddThisChargeToTheCartDesc:
    "เมื่อเพิ่มสินค้าชิ้นแรกลงในรถเข็นแล้ว ค่าธรรมเนียมนี้จะถูกเพิ่มโดยอัตโนมัติ เจ้าหน้าที่สามารถลบค่าธรรมเนียมนี้ได้",
  automaticallyGenerateSku: "สร้าง SKU อัตโนมัติ",
  availability: "การให้บริการ",
  availabilityOnPos: "การให้บริการ ณ จุดขาย",
  available: "มีอยู่",
  availableAnytime: "มีให้บริการตลอดเวลา",
  availableEveryday: "มีให้บริการทุกวัน",
  availableOnPos: "มีให้บริการ ณ จุดขาย",
  averageAmountPerItem: "จำนวนเฉลี่ยต่อรายการ",
  averageDurationOfStay: "ระยะเวลาการเข้าพักโดยเฉลี่ย",
  averageOrderValue: "มูลค่าการสั่งซื้อโดยเฉลี่ย",
  awaitingInviteResponse: "รอการตอบกลับคำเชิญ",
  back: "กลับ",
  backOffice: "แบ็คออฟฟิศ",
  backOfficeRoleDashboardDesc:
    "อนุญาตให้เข้าถึงข้อมูลเชิงลึกของแดชบอร์ดสถานที่จัดงาน การขาย สินค้าคงคลัง สถานที่ตั้ง พนักงาน โปรไฟล์แขก บัตรกำนัล ส่วนลด โปรไฟล์เครื่องพิมพ์ และการตั้งค่าสถานที่",
  backOfficeRoleAppDesc:
    "บทบาทนี้ไม่มีบทบาทที่เกี่ยวข้องกับแอป และจะไม่สามารถเข้าสู่ระบบบนอุปกรณ์ได้",
  balance: "สมดุล",
  balanceMovementsDescription: "ดูว่าสถานที่ใดภายในกลุ่มสถานที่ซึ่งความสมดุลของแขกมาจาก",
  balanceMovementsIn: "การเคลื่อนไหวที่สมดุลใน",
  balanceMovementsInPage: "การเคลื่อนไหวที่สมดุลใน",
  balanceMovementsOut: "สมดุลการเคลื่อนไหวออก",
  balanceMovementsOutPage: "สมดุลการเคลื่อนไหวออก",
  banATag: "ปิดใช้งานแท็ก",
  banDetails: "รายละเอียดการปิด",
  banList: "รายการที่ถูกปิด",
  banTag: "ปิดใช้งานแท็ก",
  banTagListSettings: "ตั้งค่ารายการปิดแท็ก",
  banTags: "ปิดใช้งานแท็ก",
  bannedBy: "ปิดใช้งานโดย",
  bannedTags: "แท็กที่ปิดใช้งาน",
  banningThisTagWillOnlyTakeEffect:
    "การปิดแท็กนี้จะมีผลในครั้งถัดไปที่อุปกรณ์ของคุณซิงค์และอัปเดต (โดยปกติทุกๆ 5 นาทีเมื่อเชื่อมต่ออินเทอร์เน็ต) เพื่อให้การเปลี่ยนแปลงเหล่านี้มีผลใช้งานทันที โปรดซิงค์และอัปเดตอุปกรณ์ของคุณเมื่อคุณปิดใช้งานแท็กนี้",
  bargainAllowed: "อนุญาตให้ต่อรองได้",
  bargainRequiresApproval: "การต่อรองราคาต้องได้รับอนุมัติ",
  bargainRequiresApprovalOnThisProduct:
    "ต้องได้รับอนุญาตจากผู้จัดการเมื่อเปลี่ยนแปลงราคาของรายการนี้บน POS",
  basicInfo: "ข้อมูลพื้นฐาน",
  belowParLevel: "ต่ำกว่าปริมาณขั้นต่ำ",
  billAmount: "จำนวนบิล",
  birthdate: "วันที่เกิด",
  birthday: "วันเกิด",
  bookedBy: "จองโดย",
  bookingAccomodation: "การจองที่พัก",
  bookingAmount: "ยอดจอง",
  bookingDescription: "ติดตามการจองทุกรายการในสถานที่ของคุณ",
  bookingDetails: "รายละเอียดการจอง",
  bookingGuests: "การจองแขก",
  bookingId: "รหัสการจอง",
  bookingList: "รายการจอง",
  bookingNo: "หมายเลขการจอง",
  bookingPrice: "ราคาจอง",
  bookingStatus: "สถานะการจอง",
  bookingSummary: "สรุปการจอง",
  bookings: "การจอง",
  bottle: "ขวด",
  browseProducts: "เรียกดูผลิตภัณฑ์",
  browseVouchers: "เรียกดูคูปอง",
  businessAddress: "ที่อยู่สถานที่ทำงาน",
  businessContactNumber: "เบอร์ติดต่อธุรกิจ",
  businessDay: "วันทำการ",
  businessDetails: "รายละเอียดธุรกิจ",
  businessName: "ชื่อธุรกิจ",
  byAssemblingAProduct:
    "ในการผลิตสินค้า คุณสามารถผลิตสินค้าที่มีรายการวัตถุดิบหลากหลาย รวมถึงรายละเอียดวัตถุดิบแต่ละรายการที่มีหลายตัวเลือก",
  byCreatingPrinterProfiles:
    "ในการสร้างโปรไฟล์เครื่องพิมพ์ คุณสามารถตั้งค่าผลิตภัณฑ์ที่จะสั่งปริ้นในสลิปสำหรับจุดให้บริการต่างๆ ในสถานที่ เช่น ห้องครัว บาร์ และอื่นๆ",
  cacheCleared: "ล้างแคชแล้ว",
  cancel: "ยกเลิก",
  cancelBanTag: "ยกเลิกการปิดใช้งานแท็ก",
  cancelCloseShift: "ยกเลิก ปิดกะ",
  cancelled: "ยกเลิกแล้ว",
  cannotBeDeleted: "ลบไม่ได้",
  cannotDeletePartOfComposite: "ไม่สามารถลบ {0} ได้เนื่องจากเป็นส่วนหนึ่งของ {1} ส่วนประกอบของ {2}",
  cantFindDesc: "ไม่พบสิ่งที่คุณกำลังมองหา? ลองใช้การค้นหาหรือตัวกรองอื่น",
  capacity: "ความจุ",
  card: "การ์ด",
  cardApprovalNumber: "รหัสอนุมัติของบัตร",
  cardNumber: "หมายเลขบัตร",
  cash: "เงินสด",
  cashSales: "ขายเงินสด",
  cashlessTransactionsWithDoor: "ธุรกรรมไร้เงินสดพร้อมการเปิดใช้งานล็อคประตู!",
  categories: "หมวดหมู่",
  categoriesDeleted: "ลบ {0} หมวดหมู่แล้ว",
  categorizeAndManage: "จัดหมวดหมู่และจัดการทุกธุรกรรมด้วยผังบัญชี",
  category: "หมวดหมู่",
  categoryCreated: 'สร้างหมวดหมู่ "{0}" แล้ว',
  categoryDeleted: 'ลบหมวดหมู่ "{0}" แล้ว',
  categoryList: "รายการหมวดหมู่",
  categoryName: "ชื่อหมวดหมู่",
  categoryNameAlreadyExist: "มีชื่อหมวดหมู่นี้แล้ว",
  categoryNameReserved: "ชื่อหมวดหมู่ถูกสงวนไว้ โปรดใช้ชื่ออื่น",
  categoryReport: "รายงานหมวดหมู่",
  categoryUpdated: 'อัปเดตหมวดหมู่ "{0}" แล้ว',
  change: "เปลี่ยน",
  changePassword: "เปลี่ยนรหัสผ่าน",
  changePhoto: "เปลี่ยนรูป",
  changesSaved: "บันทึกการเปลี่ยนแปลงแล้ว",
  changingCurrencyWontAffect:
    "การเปลี่ยนสกุลเงินจะไม่ส่งผลต่อมูลค่าราคาผลิตภัณฑ์ของคุณ สัญลักษณ์สกุลเงินเท่านั้นที่เปลี่ยน",
  charge: "ค่าใช้จ่าย",
  chargeAmount: "จำนวนเงินที่เรียกเก็บ",
  chargeCalculation: "การคำนวณค่าใช้จ่าย",
  chargeDeleted: "การเรียกเก็บเงิน {0} ถูกลบแล้ว",
  chargeItem: "รายการชาร์จ",
  chargeName: "ชื่อค่าธรรมเนียม",
  chargeTaxesOnThisProduct: "เรียกเก็บภาษีสำหรับผลิตภัณฑ์นี้",
  chartOfAccounts: "ผังบัญชี",
  chartOfAccountsDescription: "อัปเดตและจัดการผังบัญชีของคุณ",
  checkInDate: "วันที่เช็คอิน",
  checkInDetails: "รายละเอียดการเช็คอิน",
  checkInQuestions: "คำถามในการเช็คอิน",
  checkInSettings: "การตั้งค่าการเช็คอิน",
  checkIns: "เช็คอิน",
  checkOutDetails: "รายละเอียดการเช็คเอาท์",
  checkOutTheGuide: "ดูคำแนะนำในการเชื่อมต่อการผสานงาน",
  checkOuts: "เช็คเอาท์",
  checkStocks: "ตรวจสอบสต๊อก",
  checkYourEmail: "ตรวจสอบอีเมลของคุณ",
  checkYourEmailAt: "ตรวจสอบอีเมลของคุณที่ {0} เพื่อตั้งค่าบัญชีของคุณให้เสร็จสมบูรณ์",
  checkYourEmailOrAdmin: "ตรวจสอบอีเมลของคุณหรือติดต่อผู้ดูแลระบบของคุณ",
  checkbox: "กล่องเครื่องหมาย",
  checkedIn: "เช็คอิน",
  checkedInBy: "เช็คอินโดย",
  checkedInDate: "วันที่เช็คอิน",
  checkedOut: "เช็คเอาท์",
  checkedOutBy: "เช็คเอาท์โดย",
  checkedOutDate: "วันที่เช็คเอาท์",
  checkedOutOn: "เช็คเอาท์เมื่อ",
  checkinAmount: "จำนวนเงินเช็คอิน",
  checkinId: "รหัสเช็คอิน",
  checkingYourInternet: "กำลังตรวจสอบการเชื่อมต่ออินเทอร์เน็ตหรือข้อมูลของคุณ",
  checkout: "เช็คเอาท์",
  checkoutOurEbook: "ตรวจสอบ eBook ของเรารวมถึงวิธีเพิ่มประสิทธิภาพสถานที่ของคุณ!",
  chooseAPassword: "ใช้รหัสผ่านที่ปลอดภัยซึ่งไม่ได้ใช้ที่อื่น",
  chooseAReason: "เลือกเหตุผล",
  chooseASimpleOrMultipleItem: "เลือกวัตถุดิบรายการเดียวหรือหลายรายการเพื่อผลิตสินค้า",
  chooseAVenue: "เลือกสถานที่",
  chooseAtleast1Location: "เลือกตำแหน่งอย่างน้อย 1 แห่ง",
  chooseCategoryColor: "เลือกสีหมวดหมู่",
  chooseColumns: "เลือกคอลัมน์ที่คุณต้องการดูในรายงานนี้",
  chooseInformation: "เลือกข้อมูลที่จะรวมไว้ในรายละเอียดการขาย",
  chooseLocationForThisStaff: "เลือกการกำหนดตำแหน่งสำหรับพนักงานนี้",
  choosePassword: "เลือกรหัสผ่าน",
  choosePaymentMethod: "เลือกวิธีการชำระเงิน",
  chooseProductsCanBeDiscounted: "เลือกสินค้าจากคลังของคุณที่สามารถลดราคาได้",
  chooseRole: "กำหนดหน้าที่สำหรับพนักงานคนนี้",
  chooseTheLocationWhereThisProduct:
    "เลือกตำแหน่งที่จำหน่ายผลิตภัณฑ์นี้และตำแหน่งที่วัตถุดิบถูกหัก",
  chooseVouchers: "เลือกคูปอง",
  cityStateCountry: "อำเภอ จังหวัด ประเทศ รหัสไปรษณีย์",
  clear: "ล้าง",
  clearAllFilters: "ล้างตัวกรองทั้งหมด",
  clearDownloads: "ล้างการดาวน์โหลด",
  clearedTags: "แท็กที่ล้างแล้ว",
  clearedUnusedAndExpiredCredits: "เครดิตที่หมดอายุ ไม่ได้ใช้ และถูกล้าง",
  clearedUnusedAndExpiredCreditsMessage:
    "สำหรับเครดิตที่เติมเงิน แต่ถูกล้าง ไม่สามารถขอคืนเงินได้ หรือหมดอายุ",
  clearedUnusedAndExpiredCreditsPlaceholder: "[xxxx] - รายได้อื่น (วันหมดอายุ)",
  clearedUnusedExpiredCredit: "รายได้อื่น (วันหมดอายุ)",
  clickHereToRetry: "คลิกที่นี่เพื่อลองอีกครั้ง",
  close: "ปิด",
  closePreview: "ปิดการแสดงตัวอย่าง",
  closeReceiptPreview: "ปิดตัวอย่างใบเสร็จ",
  closeShift: "ปิดกะ",
  closeShiftWarning:
    "ตรวจสอบให้แน่ใจว่าธุรกรรมทั้งหมดได้รับการซิงค์ก่อนที่จะบังคับให้ปิดกะ และใช้สิ่งนี้เฉพาะในกรณีที่คุณไม่สามารถปิดกะในแอปได้",
  cloudbeds: "เตียงเมฆ",
  code: "รหัส",
  coffeeBeans: "เมล็ดกาแฟ",
  columns: "คอลัมน์",
  combineYourProducts: "รวมผลิตภัณฑ์ของคุณเพื่อขายเป็นชุด",
  comingSoon: "เร็วๆ นี้",
  communityGuidelines: "หลักเกณฑ์ของชุมชน",
  companyDetails: "รายละเอียดบริษัท",
  companyRegistrationNo: "ทะเบียนบริษัทเลขที่",
  completeStocktake: "ตรวจนับสต็อคให้เสร็จ",
  completeStocktakeConfirmation: "ทำ{0}ให้เสร็จ?",
  completeSubscription: "สมัครสมาชิกให้สมบูรณ์",
  completeYourSubscription: "สมัครสมาชิกให้สมบูรณ์เพื่อเข้าถึงสถานที่ของคุณ",
  completed: "เสร็จแล้ว",
  completedBy: "เสร็จโดย",
  completedOn: "เสร็จเมื่อ",
  composite: "ส่วนประกอบ",
  composition: "ส่วนประกอบ",
  compositionGuide: "คู่มือการจัดส่วนประกอบ",
  confirm: "ยืนยัน",
  confirmStockAdjustment: "ยืนยันการปรับสต๊อก",
  confirmPassword: "ยืนยันรหัสผ่าน",
  confirmTransfer: "ยืนยันการโอนเงิน",
  confirmationPending: "อยู่ระหว่างการยืนยัน",
  confirmed: "ยืนยันแล้ว",
  connect: "เชื่อมต่อ",
  connectAndInstall: "เชื่อมต่อและติดตั้ง",
  connectPurchasePlus: "เชื่อมต่อ Purchase Plus กับบัญชี PouchNATION ของคุณ",
  connected: "เชื่อมต่อแล้ว",
  contactNumber: "เบอร์ติดต่อ",
  contactUs: "ติดต่อเรา",
  contactUs2: "ติดต่อเรา",
  continue: "ดำเนินการต่อ",
  continueAs: "ดำเนินการต่อในชื่อ {0}",
  continueBanningThisTag: "ปิดการใช้งานแท็กนี้ต่อไปหรือไม่?",
  continuingOnThisPageWillLogout: "การดำเนินการต่อในหน้านี้จะออกจากระบบเซสชันปัจจุบันของคุณ",
  copied: "คัดลอกแล้ว!",
  copyLink: "คัดลอกลิงก์",
  copyrightYear: "ลิขสิทธิ์ © 2024",
  costOfGoodsSold: "ต้นทุนขาย (COGS)",
  costOfGoodsSoldMessage: "บัญชีเริ่มต้นสำหรับต้นทุนของวัตถุดิบเมื่อขายได้",
  costOfGoodsSoldPlaceholder: "[xxxx] - COGS/COGS",
  country: "ประเทศ",
  createALocation: "สร้างตำแหน่ง",
  createAccount: "สร้างบัญชี",
  createAccountCode: "สร้างรหัสบัญชี",
  createAndManageProducts: "สร้างและจัดการผลิตภัณฑ์ที่ขายได้ในตำแหน่งของคุณ",
  createAndManageRawMaterials: "สร้างและจัดการรายการวัตถุดิบและสินค้าคงคลังสำหรับแต่ละตำแหน่ง",
  createAndManagerDiscounts: "สร้างและจัดการส่วนลดของคุณด้วยผลิตภัณฑ์ลดราคา",
  createAndManagerVouchers: "สร้างและจัดการคูปองของคุณเพื่อใช้ในการแลกผลิตภัณฑ์",
  createCategoriesToLink: "สร้างหมวดหมู่เพื่อเชื่อมโยงและจัดหมวดหมู่ผลิตภัณฑ์ของคุณ",
  createCategory: "สร้างหมวดหมู่",
  createDiscountPage: "สร้างหน้าส่วนลด",
  createLocation: "สร้างตำแหน่ง",
  createLocationToStoreYourProducts: "สร้างตำแหน่งสำหรับจัดเก็บผลิตภัณฑ์ของคุณ",
  createNewCategory: "สร้างหมวดหมู่ใหม่",
  createNewCharge: "สร้างค่าธรรมเนียมใหม่",
  createNewDiscount: "สร้างส่วนลดใหม่",
  createNewGuestProfile: "สร้างโปรไฟล์แขกใหม่",
  createNewLocation: "สร้างตำแหน่งใหม่",
  createNewProduct: "สร้างผลิตภัณฑ์ใหม่",
  createNewProductSet: "สร้างชุดผลิตภัณฑ์ใหม่",
  createNewProfile: "สร้างโปรไฟล์ใหม่",
  createNewStaffProfile: "สร้างโปรไฟล์พนักงานใหม่",
  createNewStocktake: "สร้างการเช็คสต๊อกใหม่",
  createNewSupplyItem: "สร้างรายการวัตถุดิบ",
  createNewVoucher: "สร้างคูปองใหม่",
  createNote: "สร้างหมายเหตุ",
  createPrinterProfile: "สร้างโปรไฟล์เครื่องพิมพ์",
  createProduct: "สร้างผลิตภัณฑ์",
  createProductSet: "สร้างชุดผลิตภัณฑ์",
  createStaffProfile: "สร้างโปรไฟล์พนักงาน",
  createStaffWhoCan: "สร้างพนักงานที่สามารถเติมเครดิต ลงทะเบียนแขก และขายในสถานที่ของคุณ",
  createTheProduct: "สร้างผลิตภัณฑ์เพื่อขายในสถานที่ของคุณ",
  createVenue: "สร้างสถานที่",
  createVenuePage: "สร้างหน้าสถานที่",
  createVoucherPage: "สร้างหน้าคูปอง",
  createVouchersForGuest: "สร้างคูปองเพื่อให้แขกและพนักงานของคุณใช้!",
  createYourOwnIntegration: "สร้างการผสานการทำงานของคุณเองด้วย PouchCONNECT",
  createYourVenue: "สร้างสถานที่ของคุณ",
  createdSupplyItem: "สร้างรายการวัตถุดิบ",
  credentialsAreInvalid:
    "อ๊ะ ข้อมูลรับรองบางอย่างไม่ถูกต้อง โปรดป้อนข้อมูลที่ถูกต้องแล้วลองอีกครั้ง",
  credit: "เครดิต",
  creditAutomaticallyExpires: "เครดิตจะหมดอายุโดยอัตโนมัติหลังจากเช็คเอาท์",
  creditBalance: "ยอดเครดิตคงเหลือ",
  creditDebit: "เครดิต/เดบิต",
  creditDebitSales: "การขายสินเชื่อ/เดบิต",
  creditLimits: "วงเงินเครดิต",
  creditSettings: "การตั้งค่าเครดิต",
  credits: "เครดิต",
  creditsDescription: "กำหนดเครดิตฟรีให้แก่แขกในกลุ่มนี้และกำหนดวงเงินเครดิตแบบชำระภายหลัง",
  creditsExpiration: "วันหมดอายุเครดิต",
  creditsExpirationDescription: "ตั้งค่าเครดิตให้หมดอายุเมื่อแขกเช็คเอาท์",
  creditsExpirationOnCheckout: "เครดิตหมดอายุเมื่อเช็คเอาท์",
  creditsKept: "เครดิตที่เก็บไว้",
  creditsKeptByGuests: "เครดิตที่แขกเก็บไว้",
  creditsLiabilityAccount: "บัญชีความรับผิดชอบเครดิต",
  creditsLiabilityAccountMessage: "บัญชีสำหรับเครดิตทั้งหมดที่เติมในแท็กก่อนการขาย",
  creditsLiabilityAccountPlaceholder: "[xxxx] - ความรับผิดชอบ/เครดิต",
  creditsNeverExpires: "เครดิตไม่มีวันหมดอายุหลังจากเช็คเอาท์",
  creditsReceived: "เครดิตที่ได้รับ",
  creditsReceivedFromVenues: "เครดิตที่ได้รับจากสถานที่อื่น",
  creditsWouldNeverExpire: "เครดิตจะไม่มีวันหมดอายุเมื่อผู้เข้าพักอยู่ในระหว่างการเช็คอิน",
  currency: "สกุลเงิน",
  currencyAndFormat: "สกุลเงินและรูปแบบ",
  currentGuestProfiles: "โปรไฟล์แขกปัจจุบัน",
  currentPassword: "รหัสผ่านปัจจุบัน",
  currentPasswordAndNewPasswordMatched: "รหัสผ่านปัจจุบันและรหัสผ่านใหม่ตรงกัน",
  currentQty: "ปริมาณปัจจุบัน",
  custom: "กำหนดเอง",
  customDateTime: "กำหนดช่วงวันที่และเวลาเอง",
  customFields: "ช่องว่างที่กำหนดเอง",
  customFooter: "ป้ายกำกับที่กำหนดเอง",
  customHeader: "ป้ายกำกับที่กำหนดเอง",
  customName: "ชื่อที่กำหนดเอง",
  customNameIsAlreadyExisting: "ชื่อที่กำหนดเองมีอยู่แล้ว",
  customQuestion: "คำถามที่กำหนดเอง",
  customQuestions: "คำถามที่กำหนดเอง",
  customTax: "กำหนดภาษีเอง",
  customerBehaviourOverTime: "พฤติกรรมลูกค้าล่วงเวลา",
  customizeReceipt: "ปรับแต่งแต่ละส่วนของใบเสร็จของคุณ",
  customizeSectionReceipt: "ปรับแต่งส่วนต่างๆ ของใบเสร็จของคุณ",
  dataError: "ข้อมูลผิดพลาด",
  dataIncorrectly: "ข้อมูลไม่ถูกต้อง",
  dashboard: "แผงควบคุม",
  date: "วันที่",
  dateAndTime: "วันและเวลา",
  dateAndTimeOfTransaction: "วันที่และเวลาที่ทำรายการ",
  dateBanned: "วันที่ปิดใช้งาน",
  dateCreated: "วันที่สร้าง",
  dateIssuedWithColonSymbol: "วันที่ออก:",
  dateModified: "วันที่แก้ไข",
  dateOfCheckin: "วันที่เช็คอิน",
  dateOfCheckout: "วันที่เช็คเอาท์",
  dateOfRegistration: "วันที่ลงทะเบียน",
  dateRange: "ช่วงวันที่",
  day: "วัน",
  dayEnd: "สิ้นสุดวัน",
  dayStart: "วันเริ่มต้น",
  daySummary: "สรุปวัน",
  days: "วัน",
  daysAfterCheckout: "วันหลังจากเช็คเอาท์",
  debitCredit: "บัตรเดบิต/เครดิต",
  default: "ค่าเริ่มต้น",
  defaultAccountForTaxes: "บัญชีเริ่มต้นสำหรับภาษีที่เรียกเก็บสำหรับแต่ละผลิตภัณฑ์",
  defaultFields: "ช่องว่างเริ่มต้น",
  defaultQuestions: "คำถามเริ่มต้น",
  defaultRevenueAccount: "บัญชีรายได้เริ่มต้น",
  defaultRevenueAccountMessage: "บัญชีเริ่มต้นสำหรับรายได้จากการขายทั้งหมด",
  defaultTableName: "ชื่อตารางเริ่มต้น",
  defaultTax: "ภาษีเริ่มต้น",
  defectiveTag: "แท็กชำรุด",
  defectiveTags: "แท็กชำรุด",
  delete: "ลบ",
  deleteAProduct: "ลบผลิตภัณฑ์",
  deleteAProduct2: "ลบสินค้า?",
  deleteAProductSet: "ลบชุดผลิตภัณฑ์",
  deleteCategories: "ลบ {0} หมวดหมู่",
  deleteCategory: "ลบหมวดหมู่",
  deleteCharge: "ลบค่าธรรมเนียม",
  deleteChargeFailed: "ลบการเรียกเก็บเงินไม่ได้ โปรดลองอีกครั้งในภายหลัง",
  deleteCurrentlyBeingUsed:
    "กำลังใช้{0}ในชุดผลิตภัณฑ์บางชุด หากต้องการดำเนินการต่อ โปรดลบผลิตภัณฑ์นี้ออกจากชุดผลิตภัณฑ์ต่อไปนี้",
  deleteDiscount: "ลบส่วนลด",
  deleteGuestProfile: "ลบโปรไฟล์แขก",
  deleteLocation: "ลบตำแหน่ง",
  deleteLocationNote: "หมายเหตุ: ธุรกรรมก่อนหน้าของคุณกับตำแหน่งนี้จะถูกเก็บไว้",
  deleteName: 'ลบ "{0}" หรือไม่',
  deleteNote: "ลบหมายเหตุ",
  deletePaymentMethod: "ลบวิธีการชำระเงิน",
  deletePrinterProfile: "ลบโปรไฟล์เครื่องพิมพ์",
  deleteProduct: "ลบ{0}หรือไม่?",
  deleteProductSet: "ลบชุดผลิตภัณฑ์หรือไม่?",
  deleteProducts: "ลบผลิตภัณฑ์ {0} รายการหรือไม่?",
  deleteProducts2: "ลบผลิตภัณฑ์ {0} รายการ",
  deleteQuesiton: "ลบคำถาม",
  deleteSelectedCategories: "ลบหมวดหมู่ที่เลือก",
  deleteSelectedCategory: "ลบหมวดหมู่ที่เลือก",
  deleteSelectedProduct: "ลบผลิตภัณฑ์ที่เลือก",
  deleteSelectedProducts: "ลบผลิตภัณฑ์ที่เลือก",
  deleteStaff: "ลบพนักงาน",
  deleteStaffProfile: "ลบโปรไฟล์พนักงาน",
  deleteSupplyItem: "ลบรายการวัตถุดิบ",
  deleteTax: "ลบภาษี",
  deleteThis: "ลบ {0} นี้",
  deleteThisMultiple: "ลบ {0} {1} หรือไม่?",
  deleteThisQuestion: "ลบคำถามนี้หรือไม่?",
  deleteVoucher: "ลบคูปอง",
  deleted: "ลบแล้ว",
  deletedPaymentMethod: 'ลบวิธีการชำระเงิน "{0}" แล้ว',
  deletedTax: 'ลบภาษี "{0}" แล้ว',
  deletingLoading: "กำลังลบ...",
  deletingName: "กำลังลบ {0}",
  deletingThisLocation:
    "การลบตำแหน่งนี้จะลบยอดคงเหลือในสต็อคของรายการวัตถุดิบทั้งหมดด้วย การดำเนินการนี้ไม่สามารถยกเลิกได้",
  denominations: "นิกาย",
  departure: "การออกเดินทาง",
  departureDate: "วันออกเดินทาง",
  describeThisProduct: "อธิบายผลิตภัณฑ์ชิ้นนี้...",
  describeThisProductSet: "อธิบายชุดผลิตภัณฑ์นี้",
  description: "คำอธิบาย",
  destinationLocation: "สถานที่ปลายทาง",
  destinationLocationNewQty: "ตำแหน่งปลายทาง จำนวนใหม่",
  device: "อุปกรณ์",
  deviceId: "รหัสอุปกรณ์",
  deviceImei: "อุปกรณ์ IMEI",
  deviceImeiSerial: "อุปกรณ์ IMEI / Serial",
  deviceInfo: "ข้อมูลอุปกรณ์",
  deviceLimitExceeded:
    "คุณกำลังใช้อุปกรณ์ {0} เครื่องซึ่งเกินขีดจำกัดแผนของคุณที่อุปกรณ์ {1} เครื่อง โปรดติดต่อเจ้าของสถานที่เพื่ออัปเกรดแผนของคุณ",
  deviceLimitExceededManager:
    "คุณกำลังใช้อุปกรณ์ {0} เครื่องซึ่งเกินขีดจำกัดแผนของคุณที่อุปกรณ์ {1} เครื่อง กรุณาอัพเกรดแผนของคุณ",
  deviceList: "รายการอุปกรณ์",
  deviceMac: "ที่อยู่ MAC ของอุปกรณ์",
  deviceMacAddress: "ที่อยู่ MAC ของอุปกรณ์",
  deviceName: "ชื่ออุปกรณ์",
  deviceNameAlreadyExists: "มีชื่ออุปกรณ์นี้แล้ว",
  deviceNameUpdated: "อัปเดตอุปกรณ์ “{0}” แล้ว",
  deviceSerialNumber: "หมายเลขซีเรียลชอุปกรณ์",
  deviceUid: "UID ของอุปกรณ์",
  devices: "อุปกรณ์",
  difference: "แตกต่าง",
  directory: "ไดเรกทอรี",
  disableSellingOnPos: "ปิดการใช้งาน POS",
  disabled: "พิการ",
  discard: "ยกเลิก",
  discardChanges: "ยกเลิกการเปลี่ยนแปลง",
  discardWristbandKeepingSettings: "ยกเลิกการตั้งค่าการรักษาสายรัดข้อมือ",
  disclaimerCategoryReport:
    "การปฏิเสธความรับผิด: ผลิตภัณฑ์บางรายการอาจถูกกำหนดหมวดหมู่ไว้หลายหมวดหมู่ซึ่งจะส่งผลให้มีการนับซ้ำในแต่ละรายการ",
  disconnect: "ตัดการเชื่อมต่อ",
  discount: "การลดราคา",
  discountApplied: "ใช้ส่วนลดแล้ว",
  discountCanBeApplied: "ส่วนลดนี้สามารถใช้ได้ในสถานที่ของคุณ",
  discountCreated: 'สร้างส่วนลด "{0}" แล้ว',
  discountDeleted: 'ลบส่วนลด "{0}" แล้ว',
  discountName: "ชื่อส่วนลด",
  discountNameAlreadyExist: "ชื่อส่วนลดนี้มีอยู่แล้ว",
  discountRateOrValue: "อัตราส่วนลดหรือมูลค่า",
  discountReport: "รายงานส่วนลด",
  discountType: "ประเภทส่วนลด",
  discountUpdated: 'อัปเดตส่วนลด "{0}" แล้ว',
  discountableProducts: "ผลิตภัณฑ์ลดราคา",
  discounts: "ส่วนลด",
  discountsPage: "หน้าส่วนลด",
  discrepancy: "ความคลาดเคลื่อน",
  displayCategoryInPos: "แสดงหมวดหมู่นี้บนอุปกรณ์ POS",
  displayedIn: "แสดงใน",
  distribution: "การจัดจำหน่าย",
  done: "เสร็จแล้ว",
  dontSellOnPos: "ห้ามขายที่ POS",
  dontWorry: "ไม่ต้องกังวล!",
  download: "ดาวน์โหลด",
  downloadAndSync: "ดาวน์โหลดแอป PouchVENUE และซิงค์ธุรกรรมแรกของคุณ",
  downloading: "กำลังดาวน์โหลด...",
  dropdown: "เลื่อนลง",
  duplicateOption: "ตัวเลือกซ้ำ",
  easierGuestReg: "การลงทะเบียนและเช็คอินแขกที่ง่ายขึ้น",
  edit: "แก้ไข",
  editAccessToken: "แก้ไขโทเค็นการเข้าถึง",
  editAccount: "แก้ไขบัญชี",
  editCategory: "แก้ไขหมวดหมู่",
  editCharge: "แก้ไขค่าธรรมเนียม",
  editDetails: "แก้ไขรายละเอียด",
  editDevices: "แก้ไขอุปกรณ์",
  editDiscountsPage: "แก้ไขหน้าส่วนลด",
  editGuestProfile: "แก้ไขโปรไฟล์แขก",
  editLocation: "แก้ไขตำแหน่ง",
  editLocations: "แก้ไขตำแหน่ง",
  editNewCategory: "แก้ไขหมวดหมู่ใหม่",
  editNote: "แก้ไขหมายเหตุ",
  editPaymentMethod: "แก้ไขวิธีการชำระเงิน",
  editPaymentMethods: "แก้ไขวิธีการชำระเงิน",
  editPrinter: "แก้ไขโปรไฟล์เครื่องพิมพ์",
  editProduct: "แก้ไขผลิตภัณฑ์",
  editProductSet: "แก้ไขชุดผลิตภัณฑ์",
  editQuestions: "แก้ไขคำถาม",
  editSFTPDetails: "แก้ไขรายละเอียด SFTP",
  editStaff: "แก้ไขพนักงาน",
  editStaffPage: "แก้ไขหน้าพนักงาน",
  editStaffProfilePage: "แก้ไขหน้าโปรไฟล์พนักงาน",
  editSupplyItem: "แก้ไขรายการวัตถุดิบ",
  editTax: "แก้ไขภาษี",
  editVariants: "แก้ไขตัวเลือก",
  editVoucherPage: "แก้ไขหน้าคูปอง",
  editYourAccountPreferences: "แก้ไขการตั้งค่าบัญชี ที่อยู่อีเมล และรหัสผ่านของคุณ",
  egAppliesToGuests: "เช่น ใช้กับผู้เข้าพักที่มีอายุ 60 ปีขึ้นไป",
  egSeniorDiscount: "เช่น ส่วนลดอาวุโส",
  egSizeFlavor: "เช่น ขนาด รสชาติ",
  eightCharactersMinimum: "อย่างน้อย 8 ตัวอักษร",
  eitherYouHavePoorConnection:
    "อาจเกิดจากการเชื่อมต่อเครือข่ายของคุณไม่ดี การเข้าถึงของคุณถูกปฏิเสธ หรือมีบางอย่างผิดพลาดจากฝั่งเรา",
  email: "อีเมล",
  emailAddress: "ที่อยู่อีเมล",
  emailAlreadyInVenue: "อีเมลนี้เชื่อมโยงกับบัญชีในสถานที่นี้แล้ว",
  emailDoesntHaveRecord: "อีเมลที่คุณป้อนไม่ตรงกับบันทึกของเรา โปรดตรวจสอบและลองอีกครั้ง",
  enableSellingOnPos: "เปิดใช้งานการขายบน POS",
  enableTip: "เปิดใช้งานเคล็ดลับ?",
  end: "จบ",
  endDay: "จบวัน",
  endOfDay: "ประจำวัน",
  endOfDayChecklist: "รายการตรวจสอบประจำวัน",
  endOfDayCompleted: "สิ้นสุดวันเรียบร้อยแล้ว",
  endOfDayFailed: "ไม่สามารถดำเนินการสิ้นสุดวันได้",
  endOfDayReports: "รายงานประจำวัน",
  endOfDayReportsListPage: "หน้ารายการรายงานสิ้นสุดวัน",
  endOfDayRunning:
    "จุดสิ้นสุดของวันกำลังทำงานอยู่ โปรดรีเฟรชหน้าเพื่ออัปเดตสถานะสิ้นสุดเวลาของ EOD",
  endedBy: "สิ้นสุดโดย",
  enterEachOption: "ป้อนแต่ละตัวเลือกโดยคั่นด้วยเครื่องหมายจุลภาค",
  enterEmailToLogin: "ป้อนที่อยู่อีเมลของคุณเพื่อลงทะเบียนหรือเข้าสู่ระบบ",
  enterYourEmailAddress: "ป้อนที่อยู่อีเมลของคุณ",
  enterYourPassword: "ป้อนรหัสผ่านของคุณ",
  enteredPasswordIncorrect: "รหัสผ่านที่ป้อนไม่ถูกต้อง",
  equity: "ทุน",
  errorLoadingColors: "เกิดข้อผิดพลาดในการโหลดสี โปรดลองอีกครั้งในภายหลัง",
  example1: "ตัวอย่างที่ 1",
  example1AssembleProduct:
    "{0}: Cafe Latte ต้องการมี {1}, {2} และ {3} สินค้าคงคลังจะถูกหักออกจากเมล็ดกาแฟ นม และน้ำตาลเมื่อซื้อ Cafe Latte",
  example2: "ตัวอย่าง 2",
  example2AssembleProduct:
    '{0}: ไวน์สามารถขายเป็น {1} หรือ {2} เลือกไวน์เป็นรายการวัตถุดิบ จากนั้นสร้างตัวเลือกเป็น "ขวด" และ "แก้ว" และปรับปริมาณของตัวเลือกแต่ละอัน ดังนั้นปริมาณจะถูกหักออกจากรายวัตถุดิบตามตัวเลือก',
  expStockQty: "จำนวนสต็อคที่หมดอายุ ",
  expectedEndingBalance: "ยอดคงเหลือที่คาดการณ์",
  expectedQty: "จำนวนที่คาดการณ์",
  expense: "ค่าใช้จ่าย",
  expired: "หมดอายุ",
  export: "ส่งออก",
  exportCheckinDetails: "ส่งออกรายละเอียดการเช็คอิน",
  exportGuestReport: "ส่งออกรายงานแขก",
  exportList: "รายการส่งออก",
  exportRegisterReport: "ส่งออกรายงานการลงทะเบียน",
  exportTaxReport: "รายงานภาษีส่งออก",
  exportTransactions: "ส่งออกรายการธุรกรรม",
  exportXlsx: "ส่งออกเป็น XLSX",
  extendThePower: "เพิ่มประสิทธภาพการทำงานของ PouchVENUE ด้วยส่วนเสริมที่ดีที่สุด",
  eWallet: "E-wallet",
  failed: "ล้มเหลว",
  failedToLoad: "โหลดไม่สำเร็จ",
  featureLocked: "คุณสมบัติถูกล็อค",
  feedback: "ข้อเสนอแนะ",
  feelFreeToContactPouchnation: "สามารถติดต่อเราได้ที่ PouchNATION",
  filters: "ตัวกรอง",
  firstName: "ชื่อจริง",
  fixedAmount: "จำนวนเงินคงที่",
  fixedAmountRange: "จำนวนเงินคงที่ควรอยู่ระหว่าง 1-9,999,999",
  footerSection: "ส่วนท้าย",
  forApproval: "เพื่อการอนุมัติ",
  forProperReportingAndTo:
    "เพื่อการรายงานที่เหมาะสมและเพื่อให้แดชบอร์ดของคุณอัปเดตอยู่เสมอ โปรดเรียกใช้ช่วงสิ้นสุดวันทุกวันเพื่อหลีกเลี่ยงไม่ให้ข้อมูลสูญหาย",
  forProperReportingWithYourMews:
    "เพื่อการรายงานที่ถูกต้องด้วยบัญชี MEWS ของคุณ โปรดตรวจสอบให้แน่ใจว่าหน้าต่างประวัติที่แก้ไขได้ของบัญชีของคุณถูกตั้งค่าเป็น 7 วัน เรียกใช้จุดสิ้นสุดของวันทุกวันเพื่อหลีกเลี่ยงการสูญหายของข้อมูล",
  forceBanned: "บังคับเช็คเอาท์และรายละเอียดการสั่งห้าม",
  forceCheckOutBy: "บังคับให้เช็คเอาท์โดย",
  forceCheckout: "บังคับเช็คเอาท์",
  forceCheckoutDetails: "รายละเอียดการบังคับเช็คเอาท์",
  forceCheckoutName: "บังคับเช็คเอาท์ {0}?",
  forcedCheckedOutBy: "บังคับให้เช็คเอาท์โดย",
  formatting: "การจัดรูปแบบ",
  freeCredits: "เครดิตฟรี",
  freeCreditsAreCreditsThatAreFreeOfCharge:
    "เครดิตฟรีคือเครดิตที่ไม่มีค่าใช้จ่าย เครดิตเหล่านี้คือ",
  freeCreditsHoverTooltip:
    "เครดิตฟรีไม่สามารถขอคืนได้ และจะจัดลำดับเป็นอันดับแรกและถูกใช้ก่อนเมื่อชำระเงิน",
  freeCreditsIssued: "ออกเครดิตฟรี",
  freeCreditsKept: "เครดิตฟรีที่เก็บไว้",
  freeCreditsLiabilityAccount: "บัญชีความรับผิดเครดิตฟรี",
  freeCreditsLiabilityAccountMessage: "ค่าใช้จ่ายของเครดิตฟรีที่ออกให้แก่แขกเมื่อใช้แล้ว",
  freeCreditsLiabilityAccountPlaceholder: "[xxxx] - ความรับผิด/เครดิตฟรี",
  freeCreditsReceived: "เครดิตฟรีที่ได้รับ",
  freeCreditsRemoved: "เครดิตฟรีลบแล้ว",
  freeCreditsReport: "รายงานเครดิตฟรี",
  freeTrial: "ทดลองใช้งานฟรี 30 วัน!",
  frontOffice: "หน้าสำนักงาน",
  frontOfficeRoleDashboardDesc:
    "อนุญาตให้เข้าถึงข้อมูลเชิงลึก การจอง แขก ธุรกรรม รายงาน อุปกรณ์ และแท็กห้ามของแดชบอร์ดสถานที่จัดงาน",
  frontOfficeRoleAppDesc:
    "บทบาทนี้ไม่มีบทบาทที่เกี่ยวข้องกับแอป และจะไม่สามารถเข้าสู่ระบบบนอุปกรณ์ได้",
  future: "อนาคต",
  gender: "เพศ",
  general: "ทั่วไป",
  generalSettings: "การตั้งค่าทั่วไป",
  generateAndDownload: "สร้างและดาวน์โหลด",
  generateQRCode: "สร้างรหัส QR",
  generateQRDescription:
    "สร้างและพิมพ์รหัส QR เพื่อให้ลูกค้าสแกนและสั่งซื้อจากเมนูออนไลน์ของสถานที่นี้",
  generatingReport: "กำลังสร้างรายงาน...",
  getNotifications: "รับการแจ้งเตือน",
  getPouchVENUEeBook: "รับ eBook ของ PouchVENUE",
  getStarted: "เริ่ม",
  glass: "แก้ว",
  glossary: "อภิธานศัพท์",
  goToXDashboard: "ไปที่แดชบอร์ด {0}",
  grossProfit: "กำไรขั้นต้น",
  guest: "แขก",
  guestCheckedOutAndBanned: "แขกเช็คเอาท์และปิดการใช้งานแท็ก",
  guestDetails: "รายละเอียดแขก",
  guestId: "รหัสผู้เยี่ยมชม",
  guestInfoNotYetSync: "ยังไม่ได้ซิงค์ข้อมูลแขก",
  guestInformationYouCollect: "ข้อมูลแขกที่คุณรวบรวมไว้เมื่อเช็คอิน",
  guestList: "รายชื่อแขก",
  guestName: "ชื่อแขก",
  guestNameAndOrTagId: "ชื่อแขกและ/หรือรหัสแท็ก",
  guestOrTagStatus: "สถานะแขกหรือแท็ก",
  guestProfile: "โปรไฟล์แขก",
  guestProfileAmountPlaceholder: "0.00",
  guestProfileDeleted: "โปรไฟล์แขกลบแล้ว",
  guestProfileDescriptionPlaceholder: "เช่น “แขกที่เช็คอินในศูนย์อาหาร”",
  guestProfileList: "รายการโปรไฟล์แขก",
  guestProfileName: "ชื่อโปรไฟล์แขก",
  guestProfileNameCreate: "สร้างโปรไฟล์แขก “{0}” แล้ว",
  guestProfileNamePlaceholder: "เช่น “แขกของศูนย์อาหาร”",
  guestProfiles: "โปรไฟล์แขก",
  guestQuestions: "คำถามสำหรับแขก",
  guestSince: "แขกตั้งแต่ {0}",
  guestSpecialRequest: "คำขอพิเศษของแขก",
  guestTap: "แถบสำหรับแขก",
  guestWithColonSymbol: "แขก:",
  guests: "แขก",
  guestsCheckedIn: "แขกเช็คอิน",
  guestsNewBalance: "ยอดคงเหลือใหม่ของแขก",
  guestsPreviousBalance: "ยอดคงเหลือก่อนหน้าของแขก",
  guestsPreviousBalanceTotalPaidAndNewBalance:
    "ยอดคงเหลือก่อนหน้า ยอดชำระทั้งหมด และยอดคงเหลือใหม่",
  guestsStatus: "สถานะแขก",
  headOfBooking: "หัวหน้าฝ่ายจอง",
  headerSection: "ส่วนหัว",
  hello: "สวัสดี",
  helpCenter: "ศูนย์ช่วยเหลือ",
  highDenominations: "นิกายสูง",
  hiHeresWhatsHappening: "สวัสดี {0} นี่คือสิ่งที่เกิดขึ้นกับ {1}",
  hidden: "ที่ซ่อนอยู่",
  hide: "ซ่อน",
  hideDetails: "ซ่อนรายละเอียด",
  hideInPos: "ซ่อนที่ POS",
  hideOnlineMenu: "ซ่อนในเมนูออนไลน์",
  hideVariant: "ซ่อนตัวเลือก",
  holdingAccoutForToppedUpItems: "บัญชีเงินฝากสำหรับเครดิตทั้งหมดที่เติมในแท็กก่อนการขาย",
  hostname: "ชื่อโฮสต์",
  hour: "ชั่วโมง",
  hours: "ชั่วโมง",
  id: "ID",
  ifYouLeaveThisPage:
    "หากคุณออกจากหน้านี้ การเปลี่ยนแปลงที่ไม่ได้บันทึกทั้งหมดจะสูญหายไป คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเปลี่ยนแปลง",
  ifYoudLikeToJustStopSellingProduct:
    "หากคุณต้องการหยุดขายผลิตภัณฑ์บน POS ของคุณ เพียงแค่ปิดการใช้งานโดยปิด “ขายบน POS”",
  ignore: "ไม่สนใจ",
  ignoreAllOpenShifts: "ละเว้นกะที่เปิดอยู่ทั้งหมด",
  ignoreAllShifts: "ละเว้นการเปลี่ยนแปลงทั้งหมด",
  ignoredShiftFromPrevious: "ละเว้นการเปลี่ยนแปลงจาก EOD ก่อนหน้า",
  imageTypeSize: "JPG GIF หรือ PNG ขนาดสูงสุดของ1MB",
  impItem: "รายการนำเข้า",
  impItemModalNote1:
    "สำหรับรายการอุปทาน/ผลิตภัณฑ์ที่มีอยู่แล้วในสินค้าคงคลัง การนำเข้าจะต้องเพิ่มปริมาณของรายการอุปทานนั้นในสินค้าคงคลัง",
  impItemModalNote2:
    "สำหรับรายการอุปทาน/ผลิตภัณฑ์ที่ไม่มีอยู่ในสินค้าคงคลัง คลิกยืนยันเพื่อสร้าง SKU ใหม่ในสินค้าคงคลัง",
  impItemMaxSize: "ขนาดสูงสุด 25MB",
  import: "นำเข้า",
  importSupplyItems: "นำเข้ารายการซัพพลาย",
  importSupplyItemsCreateWholeProducts: "นำเข้ารายการจัดหาและสร้างผลิตภัณฑ์ทั้งหมด",
  importSupplyItemsErr: "ชื่อสถานที่ไม่ตรงกับสถานที่ที่มีอยู่ในสถานที่ กรุณาตรวจสอบอีกครั้ง",
  impItemsErr1: "ไม่พบตำแหน่ง {0}",
  impItemsErr2: "ไม่พบการวัด {0}",
  impItemsErr3: "รูปแบบข้อมูลชีต {0} {1} ไม่ถูกต้อง",
  impItemsErr4: "มีความแตกต่างในราคาสำหรับรายการสินค้า/วัตถุดิบ {0} + การวัด {1}",
  impItemsErr5: "แผ่นงาน {0} ไม่ควรเกิน 200 แถว",
  impItemsErr6: "ไม่พบหมวดหมู่",
  impItemsErr7: "มีความแตกต่างในราคาสำหรับผลิตภัณฑ์ {0} + การวัด {1}",
  impItemsPlsRecheck: "โปรดตรวจสอบข้อมูลในการนำเข้าไฟล์อีกครั้งแล้วอัปโหลดอีกครั้ง",
  in: "ใน",
  inHouse: "ภายในองค์กร",
  inProgress: "กำลังดำเนินการ",
  inStock: "มีสินค้า",
  inactive: "ไม่ใช้งาน",
  include: "รวม",
  indeterminate: "ไม่แน่นอน",
  information: "ข้อมูล",
  informationToCollect: "ข้อมูลที่จะรวบรวมเมื่อลงทะเบียนแขก",
  integrateDesc: "ผสานรวมกับระบบการจัดการทรัพย์สินอันทรงพลังและอื่น ๆ",
  integration: "บูรณาการ",
  integrationSettingDesc:
    "เชื่อมต่อกับธุรกิจและแอปเพิ่มประสิทธิภาพอื่นๆ เพื่อช่วยให้คุณเติบโตและปรับปรุงธุรกิจของคุณ",
  integrationSettings: "การตั้งค่าการผสานรวม",
  integrations: "ผสานรวม",
  invalidFileType: "ประเภทไฟล์ไม่ถูกต้อง ประเภทไฟล์ที่อนุญาตคือ JPG หรือ PNG",
  invalidOptions: "ตัวเลือกที่ไม่ถูกต้อง",
  invalidTimeRange: "ช่วงเวลาที่ไม่ถูกต้อง",
  invalidToken: "โทเค็นไม่ถูกต้อง",
  inventory: "สินค้าคงคลัง",
  inventoryAccount: "บัญชีสินค้าคงคลัง",
  inventoryAccountMessage: "สำหรับรายการอุปทานที่ซื้อและสินค้าคงคลังก่อนขาย",
  inventoryAccountPlaceholder: "[xxxx] - สินทรัพย์/สินค้าคงคลัง",
  inventoryGainLossAccount: "บัญชีกำไร/ขาดทุนของสินค้าคงคลัง",
  inventoryGainLossAccountMessage: "สำหรับความคลาดเคลื่อนในการตรวจนับสต็อค การปรับสต็อค ฯลฯ",
  inventoryGainLossAccountPlaceholder: "[xxxx] - กำไร/ขาดทุนจากการขายสินค้าคงคลัง",
  inventoryManagement: "การจัดการสินค้าคงคลัง",
  inventoryMovement: "การเคลื่อนไหวของสินค้าคงคลัง",
  inventoryMovementPage: "หน้าการเคลื่อนไหวของสินค้าคงคลัง",
  inventoryReport: "รายงานสินค้าคงคลัง",
  inventoryValue: "มูลค่าสินค้าคงคลัง",
  invitationHasBeenSentTo: "ส่งคำเชิญไปที่ {0} แล้ว",
  inviteNewStaff: "เชิญพนักงานใหม่",
  inviteYourStaff: "เชิญพนักงานของคุณ",
  inviteYourVenueStaff: "เชิญพนักงานของสถานที่ของคุณ จัดการบทบาทและการเข้าถึง POS ของคุณ",
  invoiceNumber: "หมายเลขใบเสร็จ",
  isAreserveWord: "เป็นคําสํารอง.",
  issuanceLimit: "วงเงินการออก",
  issue: "ปัญหา",
  issueFreeCredits: "ออกเครดิตฟรี",
  issuedBy: "ออกโดย",
  issuedOn: "ออกเมื่อ",
  item: "รายการ",
  itemBargain: "ราคาของรายการนี้เปลี่ยนจาก {0} เป็น {1}",
  itemName: "ชื่อสินค้า",
  itemTax: "ภาษีสินค้า",
  items: "รายการ",
  itemsSold: "สินค้าที่ขาย",
  justNow: "แค่ตอนนี้",
  lastIssuedTo: "ออกล่าสุดแก่",
  lastModifiedBy: "แก้ไขล่าสุดโดย",
  lastModifiedOn: "แก้ไขล่าสุดเมื่อ",
  lastName: "นามสกุล",
  lastPurchasedProduct: "ผลิตภัณฑ์ที่ซื้อล่าสุด",
  lastSync: "ซิงค์ล่าสุด",
  lastSynced: "ซิงค์ล่าสุด {0}",
  lastVisitedLocation: "ตำแหน่งเยี่ยมชมล่าสุด",
  learnHowToRenewSubscription: "เรียนรู้วิธีต่ออายุการสมัครของคุณ",
  learnMore: "เรียนรู้เพิ่มเติม",
  legalBusinessName: "ชื่อธุรกิจตามกฎหมาย",
  letsGetStarted: "มาเริ่มกันเลย",
  liability: "ความรับผิด",
  limit: "จำกัด",
  limitVoucherPerPerson: "จำกัดการออกคูปองต่อท่าน",
  linkAccountingItem: "เชื่อมโยงรายการบัญชี",
  linkOutlet: "ลิงค์เอาท์เล็ต",
  linkOutletHoverTooltip: "ลิงก์ไปยังร้าน MEWS",
  linkWithMewsAccountingItems: "เชื่อมโยงกับรายการบัญชี MEWS",
  litePlanMaxProductReached: "คุณมีผลิตภัณฑ์ {0} ถึงจำนวนสูงสุดแล้วด้วยแผน {1} ของคุณ",
  litePlanMaxStaffReached: "คุณมีพนักงานถึงจำนวนสูงสุด {0} คนตามแผน {1} ของคุณแล้ว",
  loading: "กำลังโหลด...",
  location: "ที่ตั้ง",
  locationDesignation: "การกำหนดตำแหน่ง",
  locationName: "ชื่อตำแหน่ง",
  locationNameAlreadyExist: "มีตำแหน่งนี้อยู่แล้ว เลือกชื่ออื่น",
  locationNameCreated: 'สร้างตำแหน่ง "{0}" แล้ว',
  locationNameDeleted: 'ลบตำแหน่ง "{0}" แล้ว',
  locationNameUpdated: 'อัปเดตตำแหน่ง "{0}" แล้ว',
  locationQRCode: "รหัส QR ที่ตั้ง (หนึ่งอันสำหรับทั้งสถานที่)",
  locationVoucherRedeemed: "เลือกตำแหน่งที่จะแลกคูปองนี้",
  locations: "ตำแหน่ง",
  locationsList: "รายการตำแหน่ง",
  logIn: "เข้าสู่ระบบ",
  loginHasEnded: "เซสชั่นการเข้าสู่ระบบของคุณสิ้นสุดแล้ว กรุณาเข้าสู่ระบบอีกครั้ง",
  loginSessionEnded: "สิ้นสุดเซสชันการเข้าสู่ระบบ",
  logout: "ออกจากระบบ",
  looksLikeAlreadyInSystem: "ดูเหมือนว่าคุณจะอยู่ในระบบของเราแล้ว!",
  looksLikeYouEncounteredSomething:
    "ดูเหมือนว่าคุณพบข้อผิดพลาด เราจะติดตามข้อผิดพลาดเหล่านี้โดยอัตโนมัติ ในระหว่างนี้ โปรดลองรีเฟรช",
  looksLikeYouHaveNoLocationYet: "ดูเหมือนว่าคุณยังไม่มีที่ตั้ง",
  lostTag: "แท็กที่หาย",
  lostTags: "แท็กที่หาย",
  lowDenominations: "นิกายต่ำ",
  lowOrOutOfStock: "สต๊อกต่ำหรือหมด",
  lowParLevel: "ระดับ PAR ต่ำ",
  lowerCaseNoneRefundable: "ไม่สามารถคืนเงินได้",
  lowerCaseTopUpCredits: "เครดิตเติมเงิน",
  mailingList: "รายชื่อผู้รับจดหมาย",
  manageAccountNotificationStockLevel: "จัดการบัญชีเพื่อแจ้งเตือนเมื่อรายการลดถึงระดับการแจ้งเตือน",
  manageAndAssignQuestions:
    "จัดการและกำหนดคำถามในการเช็คอิน เครดิตฟรี และคูปองให้กับโปรไฟล์แขกต่างๆ",
  manageBusinessInfo: "จัดการข้อมูลทางธุรกิจ",
  manageEmailNotif: "จัดการอีเมลแจ้งเตือนที่ส่งถึงคุณและพนักงานของคุณ",
  manageHowVenueTax:
    "จัดการการเรียกเก็บภาษีของสถานที่ของคุณโดยขึ้นอยู่กับภูมิภาค ปรึกษากับผู้เชี่ยวชาญด้านภาษีเพื่อทำความเข้าใจการชำระภาษีของคุณ",
  manageImportantDetailsSettings:
    "จัดการรายละเอียดและกฎเกณฑ์ที่สำคัญของสถานที่ของคุณ เช่น การตั้งค่าสถานที่ การตั้งค่าการชำระเงินและเครดิต การตั้งค่าการเช็คอิน และการตั้งค่าบัญชีของคุณ",
  manageInventory: "จัดการสินค้าคงคลัง",
  manageInventoryAndProducts: "จัดการสินค้าคงคลังและผลิตภัณฑ์ของชุดนี้",
  manageInventoryAndSupply: "จัดการสินค้าคงคลังและรายการวัตถุดิบของผลิตภัณฑ์นี้",
  manageLocationTable: "จัดการโต๊ะและการจัดที่นั่งของสถานที่นี้",
  manageNotifcations: "จัดการอีเมลแจ้งเตือนและการแจ้งเตือนที่ส่งถึงพนักงานของคุณ",
  managePlacesYouStock: "จัดการสถานที่ที่คุณสต๊อกสินค้าคงคลังและขายผลิตภัณฑ์",
  manageReceipts: "จัดการลักษณะและข้อมูลที่แสดงบนใบเสร็จของคุณ",
  manageSupplyItems: "จัดการรายการวัตถุดิบ",
  manageThePosDevicesVenue: "จัดการอุปกรณ์ POS ที่เข้าสู่ระบบและกำลังใช้งานในสถานที่ของคุณ",
  manageTheStocksPerLocation: "จัดการสต็อคตามตำแหน่งของรายการวัตถุดิบนี้",
  manageTipDesc: "เปิดใช้งานทิปและตั้งค่าเปอร์เซ็นต์ทิปที่คุณต้องการแสดงบน POS",
  manageToppedUpCredits: "จัดการเครดิตที่เติม เครดิตฟรี เครดิตแบบรายเดือน และเครดิตที่หมดอายุ",
  manageVariants: "จัดการตัวเลือก",
  manageVenues: "จัดการสถานที่",
  manageYourAccountsSales:
    "จัดการบัญชีของคุณเกี่ยวกับการขาย ต้นทุนผลิตภัณฑ์ ภาษีผลิตภัณฑ์ หรือส่วนลด",
  manageYourStaffProfileDescription:
    "จัดการพนักงาน หน้าที่ การมอบหมายหน้าที่ และโปรไฟล์พนักงานที่รับผิดชอบ",
  manageYourVenueSetting: "จัดการการตั้งค่าสถานที่ของคุณ เช่น ภาษีและสกุลเงิน",
  manageYourVenueSubscription:
    "จัดการการสมัครรับข้อมูลสถานที่ของคุณ ข้อมูลการเรียกเก็บเงิน และใบแจ้งหนี้",
  manageYourVenueTax: "เพิ่มและจัดการวิธีที่สถานที่ของคุณเรียกเก็บภาษี",
  manager: "ผู้จัดการ",
  managerAuthorization: "การอนุญาตจากผู้จัดการ",
  managerRoleDesc: "เข้าถึงทั้ง Venue Dashboard ออนไลน์และแอป Venue ได้อย่างสมบูรณ์",
  mapAndManageAccounts:
    "วางแผนและจัดการบัญชีเริ่มต้นของคุณสำหรับแต่ละกระบวนการทางธุรกิจของ PouchVENUE",
  mapAndManageAccountsLink: "วางแผนและจัดการบัญชีของคุณใน {0} สำหรับการบัญชีที่เหมาะสม",
  mapYourAccounts: "วางแผนการบัญชีของคุณสำหรับสินทรัพย์สินค้าคงคลัง ค่าใช้จ่ายที่ไม่ตรงกัน ฯลฯ",
  margin: "มาร์จิ้น",
  markUp: "มาร์กอัป",
  maxProductReached: "เข้าถึงผลิตภัณฑ์สูงสุดแล้ว",
  maxStaffReached: "ถึงจำนวนพนักงานสูงสุดแล้ว",
  maximumLengthCharacters: "ระดับสูงสุด {0} ตัวอักษร",
  maximumValueAllowed: "ค่าสูงสุดที่อนุญาตคือ 20,000,000",
  maximumValueIs: "ค่าสูงสุดคือ",

  measurement: "การวัด",
  measurements: "ปริมาณ",
  mediumDenominations: "นิกายขนาดกลาง",
  menuEngineering: "เมนูวิศวกรรม",
  mews: "โรงรถ",
  mewsAccounting: "MEWS_การบัญชี",
  mewsAccounting1: "การบัญชีมิวส์",
  mewsBookings: "MEWS_การจอง",
  mewsBookings1: "การจองมิวส์",
  mewsSystems: "ระบบมิวส์",
  mewsTaxCode: "รหัสภาษีมาจาก MEWS หรือไม่",
  mewsTaxCodeEditNote:
    "หมายเหตุ: เมื่อซิงค์ธุรกรรม ผลิตภัณฑ์ที่เชื่อมโยงกับภาษีนี้จะมีจำนวน VAT ใน MEWS",
  milk: "นม",
  minOfNCharacters: "ขั้นต่ำ {0} ตัวอักษร",
  minimum: "ขั้นต่ํา",
  minimumCharactersAllowed: "อย่างน้อย {0} อักขระที่ใช้ได้",
  minimumOf: "ขั้นต่ำ 2 รายการที่จำเป็น",
  minimumValueIs: "ค่าต่ําสุดคือ",
  minute: "นาที",
  minutes: "นาที",
  missedCheckIn: "พลาดการเช็คอิน",
  missedCheckOut: "พลาดการเช็คเอาท์",
  mixed: "ผสม",
  mobileNumber: "เบอร์มือถือ",
  month: "เดือน",
  months: "เดือน",
  monthsAfterCheckout: "เดือนหลังจากเช็คเอาท์",
  mostPurchasedProduct: "ผลิตภัณฑ์ที่ซื้อมากที่สุด",
  mostVisitedLocation: "ตำแหน่งที่มากที่สุด",
  movement: "ความเคลื่อนไหว",
  multiProperty: "คุณสมบัติหลายอย่าง",
  nOptions: "{0} ตัวเลือก",
  nTon: "{0} ถึง {1}",
  name: "ชื่อ",
  nameAdded: "เพิ่ม “{0}” แล้ว",
  nameYourProduct: "ตั้งชื่อผลิตภัณฑ์...",
  nameYourProductSet: "ตั้งชื่อชุดผลิตภัณฑ์ของคุณ...",
  nameYourSupplyItem: "ตั้งชื่อรายการวัตถุดิบของคุณ",
  nationality: "สัญชาติ",
  needHelp: "ต้องการความช่วยเหลือ?",
  netWeightN: "น้ำหนักสุทธิ {0}",
  new: "ใหม่",
  newBalance: "ยอดคงเหลือใหม่",
  newGuests: "แขกใหม่",
  newInviteLink: 'ส่งลิงก์คำเชิญใหม่ไปยัง "{0}" แล้ว',
  newPassWord: "รหัสผ่านใหม่",
  newProduct: "ผลิตภัณฑ์ใหม่",
  newProductSet: "ชุดผลิตภัณฑ์ใหม่",
  newQuantity: "ปริมาณใหม่",
  newQty: "จำนวนใหม่",
  newToPouchNation: "ยังไม่เคยสมัครสมาชิกกับ PouchNATION ใช่หรือไม่?",
  nfcTags: "แท็กเอ็นเอฟซี",
  night: "กลางคืน",
  no: "ไม่",
  noAccountsAvailable: "ไม่มีบัญชีให้บริการ",
  noAccountsYet: "ยังไม่มีบัญชี",
  noAvailableCategories: "ไม่มีหมวดหมู่",
  noAvailableGuestProfile: "ไม่มีโปรไฟล์แขก",
  noAvailableLocations: "ไม่มีตำแหน่งให้บริการ",
  noAvailableLocationsYet: "ยังไม่มีตำแหน่งให้บริการ",
  noAvailablePaymentMethodYet: "ยังไม่มีวิธีการชำระเงินที่ใช้ได้",
  noAvailableStaff: "ไม่มีพนักงานให้บริการ",
  noBannedTagsYet: "ยังไม่มีแท็กที่ถูกปิดใช้งาน",
  noBookingsYet: "ยังไม่มีการจอง",
  noCheckInsInDateRange: "ไม่มีการเช็คอินในช่วงวันที่นี้",
  noData: "ไม่มีข้อมูล",
  noDataAvailable: "ไม่มีข้อมูลที่สามารถใช้ได้",
  noDeviceAvailable: "ไม่มีอุปกรณ์ให้บริการ",
  noDevicesFound: "ไม่พบอุปกรณ์",
  noEndDate: "ไม่มีวันสิ้นสุด",
  noLocation: "ไม่มีที่ตั้ง",
  noLocationAvailable: "ไม่มีที่ตั้งให้บริการ",
  noNotesFound: "ไม่พบโน้ต",
  noOfCheckedIn: "{0} เช็คอินแล้ว",
  noOfCheckedOut: "เช็คเอาท์แล้ว {0}",
  noOfGuests: "จำนวนแขก",
  noOfNights: "จำนวนคืน",
  noOfTables: "#ตาราง",
  noProductsAvailable: "ไม่มีผลิตภัณฑ์ให้บริการ",
  noProductsSelected: "ยังไม่ได้เลือกผลิตภัณฑ์",
  noRecords: "ไม่มีบันทึก",
  noRecordsOf: "ไม่มีบันทึกของ",
  noRemainingBalance: "ไม่มียอดเงินคงเหลือ",
  noReportsFound: "ไม่พบรายงาน",
  noResultInSearch: "เราไม่พบผลลัพธ์ใด ๆ สำหรับการค้นหาของคุณ",
  noResults: "ไม่มีผลลัพธ์",
  noResultsFound: "ไม่พบผลลัพธ์",
  noResultsMatching: "ไม่มีผลลัพธ์ที่ตรงกัน",
  noResultsMatchingX: "ไม่มีผลลัพธ์ที่ตรงกับ “{0}”",
  noReturnCreditsInDateRange: "ไม่มีเครดิตคืนในช่วงวันที่นี้",
  noSalesInDateRange: "ไม่มีการขายในช่วงวันที่นี้",
  noSearchFound: "ไม่พบการค้นหา",
  noSearchResultsForName: "ไม่มีผลการค้นหาสำหรับ {0}",
  noShow: "ไม่แสดง",
  noStocks: "ไม่มีสต๊อก",
  noTagUid: "ไม่มีแท็ก UID",
  noTagYet: "ยังไม่มีแท็ก",
  noTagsFound: "ไม่พบแท็ก",
  noVoucherAvailable: "ไม่มีคูปองให้บริการ",
  noVoucherMovement: "ไม่มีการใช้คูปอง",
  noVouchersSelectedYet: "ยังไม่ได้เลือกคูปอง",
  nonRefundable: "ไม่สามารถคืนเงินได้",
  none: "ไม่มี",
  notCheckedIn: "ไม่เช็คอิน",
  notSellingOnPos: "ไม่ได้ขายที่ POS",
  note: "หมายเหตุ",
  notePreviousTransaction: "หมายเหตุ: ธุรกรรมก่อนหน้าที่ทำกับผลิตภัณฑ์เหล่านี้จะถูกเก็บไว้",
  noteTransactionStaff: "หมายเหตุ: ธุรกรรมก่อนหน้าที่ทำกับพนักงานคนนี้จะถูกเก็บไว้",
  notedByOn: "หมายเหตุโดย {0} เมื่อ {1}",
  notes: "หมายเหตุ",
  notificationSettings: "การตั้งค่าการแจ้งเตือน",
  notifications: "การแจ้งเตือน",
  nowToDo: "ตอนนี้ต้องทำ",
  nthGuestWhoUseThisTag: "แขก {0} คนที่ใช้แท็กนี้",
  nthStaffWhoUsedThisTag: "พนักงาน {0} คนที่ใช้แท็กนี้",
  nthUse: "ใช้ {0} คน",
  numberOfGuests: "จำนวนแขก",
  numberOfNights: "จำนวนคืน",
  offline: "ออฟไลน์",
  ok: "ตกลง",
  okay: "ตกลง",
  oldGuestId: "รหัสผู้เยี่ยมชมเก่า",
  onPos: "ที่ POS",
  onboardingDescriptionCompleted:
    "สุดยอด! ตอนนี้คุณสามารถเริ่มขายบนอุปกรณ์ PouchVENUE POS ของคุณได้แล้ว! สั่งซื้อแท็ก NFC ของคุณตอนนี้เพื่อทำธุรกรรมไร้เงินสดพร้อมเปิดใช้งานการล็อคประตู! ตรวจสอบ eBook ของเรารวมถึงวิธีเพิ่มประสิทธิภาพสถานที่ของคุณ!",
  onboardingTitle: "ยินดีต้อนรับ! เริ่มจัดสถานที่ของคุณ",
  onboardingTitleCompleted: "สถานที่ของคุณพร้อมแล้ว!",
  onceYouBanATag:
    "เมื่อคุณปิดการใช้งานแท็กแล้ว แท็กนั้นจะไม่สามารถใช้เพื่อทำธุรกรรมภายในสถานที่ของคุณได้",
  onceYouCompleteStocktake:
    "เมื่อคุณตรวจนับสต็อคเรียบร้อยแล้ว ปริมาณของรายการวัตถุดิบทั้งหมดจะถูกปรับตามนั้น การดำเนินการนี้ไม่สามารถยกเลิกได้",
  onlineMenuOnly: "เมนูออนไลน์เท่านั้น",
  open: "เปิด",
  openReceiptPreview: "ดูตัวอย่างใบเสร็จ",
  openWindow: "เปิดหน้าต่างสู่ตำแหน่ง",
  opened: "เปิดแล้ว",
  openingBalance: "ยอดยกมา",
  optionNameAlreadyExists: "มีชื่อตัวเลือกนี้แล้ว",
  options: "ตัวเลือก",
  orNo: "หรือ#",
  orderQty: "จำนวนสั่งซื้อ",
  orderQuantityRequired: "ต้องระบุปริมาณการสั่งซื้อ",
  orderYour: "สั่งซื้อของคุณ",
  orderYourNFCTags: "สั่งซื้อแท็ก NFC ของคุณ",
  original: "ต้นฉบับ",
  other: "อื่นๆ",
  others: "อื่นๆ",
  outlet: "ทางออก",
  overview: "ภาพรวม",
  pairedTagOnCheckin: "แท็กคู่เมื่อเช็คอิน",
  pairedTags: "แท็กที่จับคู่",
  parLevel: "ระดับ Par",
  password: "รหัสผ่าน",
  passwordDoesNotMatch: "รหัสผ่านที่คุณป้อนไม่ตรงกัน",
  passwordIncorrect: "รหัสผ่านที่คุณป้อนไม่ถูกต้อง",
  passwordsDoNotMatch: "รหัสผ่านไม่ตรงกัน",
  paymentAndCreditSettings: '"การตั้งค่าการชำระเงินและเครดิต',
  paymentMethod: "วิธีการชำระเงิน",
  paymentMethodAddedName: 'เพิ่มวิธีการชำระเงิน "{0}" แล้ว',
  paymentMethodAlreadyExist: "มีวิธีการชำระเงินอยู่แล้ว",
  paymentMethodName: "ชื่อวิธีการชำระเงิน",
  paymentMethods: "วิธีการชำระเงิน",
  paymentMethodsDescription: "สร้างและจัดการวิธีการชำระเงินที่คุณยอมรับในสถานที่ของคุณ",
  paymentMethodsSettings: "การตั้งค่าวิธีการชำระเงิน",
  paymentMethodsYouAccept: "วิธีการชำระเงินที่คุณยอมรับในสถานที่ของคุณ",
  paymentStatus: "สถานะการชำระเงิน",
  pending: "รอดำเนินการ",
  perPage: "{0} ต่อหน้า",
  percentage: "เปอร์เซ็นต์",
  percentageBetween: "เปอร์เซ็นต์ควรอยู่ระหว่าง 1-100",
  permitNumberWithColonSymbol: "หมายเลขใบอนุญาต:",
  phone: "โทรศัพท์",
  phoneNo: "เบอร์ติดต่อ.",
  pleaseCheckYourNetwork: "โปรดตรวจสอบเครือข่ายของคุณเพื่ออัปเดตข้อมูลสถานที่ของคุณ",
  pleaseChooseLocation: "กรุณาเลือกสถานที่",
  pleaseConnectToAnAccounting: "โปรดเชื่อมต่อกับผสานบัญชีเพื่อจัดการผังบัญชีของคุณ",
  pleaseContactOwnerToReactiveVenue:
    "โปรดติดต่อเจ้าของสถานที่เพื่อต่ออายุการสมัครและเปิดใช้งานสถานที่นี้อีกครั้ง",
  pleaseContactSupportRenewSubscription:
    "โปรดติดต่อฝ่ายสนับสนุนเพื่อต่ออายุการสมัครและเปิดใช้งานสถานที่ของคุณอีกครั้ง",
  pleaseCreateAccounts: "โปรดสร้างรหัสบัญชีจากแพลตฟอร์มบัญชีรวมของคุณ",
  pleaseCreateLocation: "โปรดสร้างตำแหน่งอย่างน้อย 1 ตำแหน่งเพื่อจัดสรรสต็อคของรายการวัตถุดิบนี้",
  pleaseCreateYourChartOfAccounts:
    "โปรดสร้างผังบัญชีในการตั้งค่าหมวดหมู่บัญชีในแดชบอร์ด MEWS ของคุณ",
  pleaseEmailUs: "กรุณาส่งอีเมลถึงเราที่ {0} {1}",
  pleaseEnsureLocation:
    "โปรดตรวจสอบให้แน่ใจว่าสถานที่นั้นไม่มีธุรกรรมที่กำลังดำเนินอยู่ก่อนที่จะเริ่มการตรวจนับสินค้า",
  pleaseEnterYourSFTP: "โปรดป้อนรายละเอียดและข้อมูลรับรองเซิร์ฟเวอร์ SFTP ของคุณ",
  pleaseRenewSubscription: "โปรดต่ออายุการสมัครของคุณเพื่อเปิดใช้งานสถานที่ของคุณอีกครั้ง",
  pleaseSelectLocation: "กรุณาเลือกตำแหน่ง",
  pleaseSelectStockUnit: "กรุณาเลือกหน่วยสต๊อก",
  pleaseUpgradeToUseLockedFeature: "โปรดอัปเกรดแผนของคุณเพื่อใช้คุณสมบัติที่ถูกล็อคนี้",
  pointOfSale: "จุดขาย",
  posAndOnlineMenu: "POS + เมนูออนไลน์",
  posDevices: "อุปกรณ์ ณ จุดขาย",
  posOnly: "POS เท่านั้น",
  posProvider: "ผู้ให้บริการ POS",
  postPaidCreditsIsTheAmount:
    "เครดิตแบบชำระภายหลังคือจำนวนเงินที่แขกสามารถใช้ได้เมื่อยอดเงินคงเหลือเป็นศูนย์ เมื่อแขกมียอดเครดิตคงค้างชำระภายหลัง จำนวนเงินควรจะชำระและชำระผ่านการเติมเงินหรือเมื่อเช็คเอ้าท์",
  postPaidCreditsUsed: "เครดิตแบบชำระภายหลังที่ใช้",
  postpaidCreditLimit: "วงเงินเครดิตแบบชำระภายหลัง",
  postpaidCredits: "เครดิตแบบชำระภายหลัง",
  postpaidCreditsLiabilityAccount: "บัญชีรับผิดเครดิตแบบชำระภายหลัง",
  postpaidCreditsLiabilityAccountMessage:
    "เครดิตที่ใช้เมื่อแขกมียอดคงเหลือเป็นศูนย์ ต้องชำระเงินด้วยการเติมเงินเมื่อเช็คเอาท์",
  postpaidCreditsLiabilityAccountPlaceholder: "[xxxx] - สินทรัพย์/เครดิตแบบชำระภายหลัง",
  postpaidCreditsUsed: "เครดิตแบบชำระภายหลังที่ใช้",
  postpaidCreditsUsedCanOnlyBeReplenished:
    '"เครดิตแบบชำระภายหลังที่ใช้สามารถเติมและชำระเงินผ่านการเติมเงินหรือเมื่อเช็คเอาท์เท่านั้น',
  pouchConnect: "PouchCONNECT",
  pouchLogo: "โลโก้ PouchNATION",
  pouchnation: "PouchNATION",
  powerYourVenue: "เพิ่มประสิทธิภาพให้แก่สถานที่ของคุณด้วยการผสานรวม",
  preloadedVouchers: "คูปองที่โหลดไว้ล่วงหน้า",
  preparingReport: "จัดทำรายงาน",
  previousBalance: "ยอดคงเหลือก่อนหน้า",
  price: "ราคา",
  pricePerOrderQty: "ราคาต่อจำนวนการสั่งซื้อ",
  pricing: "ราคา",
  pricingAndTaxes: "ราคาและภาษี",
  printableItems: "รายการที่พิมพ์ได้",
  printableItemsSelect: "เลือกผลิตภัณฑ์ที่จะพิมพ์บนโปรไฟล์เครื่องพิมพ์นี้",
  printerLocationsDesc: "เลือกตำแหน่งที่สามารถใช้โปรไฟล์เครื่องพิมพ์นี้ได้",
  printerProfile: "โปรไฟล์เครื่องพิมพ์",
  printerProfileCreated: "สร้างโปรไฟล์เครื่องพิมพ์ “{0}” แล้ว",
  printerProfileDeleted: "โปรไฟล์เครื่องพิมพ์ถูกลบ",
  printerProfileName: "ชื่อโปรไฟล์เครื่องพิมพ์",
  printerProfileNameAlreadyExists: "มีชื่อโปรไฟล์เครื่องพิมพ์นี้แล้ว",
  printerProfileUpdated: "อัปเดตโปรไฟล์เครื่องพิมพ์ “{0}” แล้ว",
  printerProfiles: "โปรไฟล์เครื่องพิมพ์",
  printers: "เครื่องพิมพ์",
  prioritizedAndUsedFirst: "จัดลำดับความสำคัญและใช้ก่อนเมื่อชำระเงิน",
  privacy: "ความเป็นส่วนตัว",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  privacyPolicy2: "นโยบายความเป็นส่วนตัว",
  proceed: "ดำเนินการ",
  proceedToCheckout: "ดำเนินการชำระเงิน",
  processingTransactions: "กำลังดำเนินการธุรกรรม...",
  product: "ผลิตภัณฑ์",
  productCategories: "หมวดหมู่ผลิตภัณฑ์",
  productCategory: "ประเภทผลิตภัณฑ์",
  productCount: "จำนวนผลิตภัณฑ์",
  productDeleted: 'ลบผลิตภัณฑ์ "{0}" แล้ว',
  productList: "รายการผลิตภัณฑ์",
  productName: "ชื่อผลิตภัณฑ์",
  productRedeemed: "แลกรับผลิตภัณฑ์",
  productRemovedFromInventory: "{0} ผลิตภัณฑ์ถูกลบออกจากสินค้าคงคลัง",
  productSet: "ชุดผลิตภัณฑ์",
  productSetDeleted: 'ลบชุดผลิตภัณฑ์ "{0}" แล้ว',
  productSetName: "ชื่อชุดผลิตภัณฑ์",
  productUpdated: "อัปเดตผลิตภัณฑ์ {0} รายการ",
  products: "ผลิตภัณฑ์",
  productsArePartOfComposite:
    "ผลิตภัณฑ์เหล่านี้เป็นส่วนประกอบของผลิตภัณฑ์อื่นๆ หากต้องการลบ ให้ลบออกจากผลิตภัณฑ์ส่วนประกอบก่อน หรือลบออก {0} ด้วยผลิตภัณฑ์คอมโพสิตที่มีอยู่",
  productsAreSupplyItemsAre:
    "{0} คือผลิตภัณฑ์ที่คุณขายในสถานที่ของคุณ {1} คือรายการที่คุณใช้เพื่อประกอบผลิตภัณฑ์ของคุณ รายการซัพพลายได้รับการจัดการใน {2} ของคุณ",
  productsCanBeRedeemed: "เลือกผลิตภัณฑ์จากสินค้าคงคลังของคุณที่สามารถแลกได้จากคูปองนี้",
  productsDeleted: "ลบผลิตภัณฑ์ {0} รายการ",
  productsDoesNotExist: "ผลิตภัณฑ์ที่คุณต้องการเพิ่มต้องมีอยู่ในทุกตำแหน่ง",
  productsForDeletion:
    "ผลิตภัณฑ์ {0} รายการที่มีตำแหน่งนี้พร้อมสำหรับการลบเช่นกัน การดำเนินการนี้ไม่สามารถยกเลิกได้",
  productsRemovedFromInventory: "{0} ผลิตภัณฑ์ถูกลบออกจากผลิตภัณฑ์คงคลัง",
  productsToBeDeleted: "ผลิตภัณฑ์ที่จะลบ",
  productsUpdated: "อัปเดตผลิตภัณฑ์ {0} รายการ",
  productsVSupplyItems: "ผลิตภัณฑ์เทียบกับรายการวัตถุดิบ",
  productsVariants: "ผลิตภัณฑ์ / ตัวเลือก",
  productsWithNoCategory: "เหล่านี้เป็นผลิตภัณฑ์ที่ยังไม่มีหมวดหมู่",
  profile: "โปรไฟล์",
  profileName: "ชื่อโปรไฟล์",
  profileNameAlreadyExists: "ชื่อโปรไฟล์มีอยู่แล้ว โปรดป้อนชื่ออื่น",
  propertyManagementSystem: "ระบบบริหารจัดการทรัพย์สิน",
  propertyManagementSystemExample: "ระบบเช่น MEWS และ Cloudbeds",
  purchasePlus: "ซื้อพลัส",
  purchaseplus: "ซื้อพลัส",
  purchasingAndInventory: "การจัดซื้อและสินค้าคงคลัง",
  qrCodeForOnlineMenu: "QR Code สำหรับเมนูออนไลน์",
  qty: "จำนวน",
  quantity: "ปริมาณ",
  quantitySupplyItems: "ปริมาณของรายการวัตถุดิบที่ใช้สำหรับผลิตภัณฑ์นี้",
  question: "คำถาม",
  questionAddedName: "เพิ่มคำถาม “{0}“ แล้ว",
  questionAlreadyExist: "มีคำถามอยู่แล้ว",
  questionDeleted: "ลบคำถาม “{0}“ แล้ว",
  questionDescription:
    "ข้อมูลที่จะรวบรวมเมื่อแขกลงทะเบียน ใช้คำถามเหล่านี้โดยกำหนดให้กับ {0} ของคุณ",
  questionSettings: "การตั้งค่าคำถาม",
  questions: "คำถาม",
  radioButton: "ปุ่มตัวเลือก",
  reachedReorderPoint: "ถึงจุดสั่งซื้อใหม่",
  readyForDownload: "พร้อมสำหรับการดาวน์โหลด",
  reallyUnbanTag: "เลิกปิดใช้งานแท็กนี้จริงหรอ?",
  reallyUnbanThisTagDescription:
    "การเลิกปิดใช้งานแท็กนี้จะมีผลในครั้งถัดไปที่อุปกรณ์ของคุณได้รับการซิงค์และอัปเดต (โดยปกติทุกๆ 5 นาทีเมื่อเชื่อมต่ออินเทอร์เน็ต) เพื่อให้สอดคล้องกับการเปลี่ยนแปลงเหล่านี้ทันที โปรดซิงค์และอัปเดตอุปกรณ์ของคุณเมื่อคุณเลิกปิดใช้งานแท็กนี้",
  reason: "เหตุผล",
  reasonsForStay: "เหตุผลในการเข้าพัก",
  receiptFormatting: "การจัดรูปแบบใบเสร็จ",
  receiptNumber: "เลขที่ใบเสร็จ",
  receiptSettings: "การตั้งค่าใบเสร็จรับเงิน",
  receipts: "ใบเสร็จ",
  recentTransactions: "ธุรกรรมล่าสุด",
  reconnect: "เชื่อมต่อใหม่",
  redeem: "แลก",
  redeemableProducts: "ผลิตภัณฑ์แลกซื้อ",
  redeemed: "แลกแล้ว",
  referenceNo: "อ้างอิง เลขที่",
  refreshingThisPage: "กำลังรีเฟรชหน้านี้",
  refundedCredits: "เครดิตเงินคืน",
  registerGuests: "ลงทะเบียนแขก",
  registerGuestsRoleDashboardDesc: "อนุญาตให้เข้าถึงการเช็คอินและเช็คเอาท์แขกของแอป Venue",
  registerGuestsRoleAppDesc: "บทบาทนี้ไม่มีสิทธิ์เข้าถึง Venue Dashboard ออนไลน์",
  registerInfo: "ลงทะเบียนข้อมูล",
  registerReports: "ลงทะเบียนรายงาน",
  registerReportsPage: "หน้ารายงานการลงทะเบียน",
  registerStaff: "ลงทะเบียนเจ้าหน้าที่",
  registeredBusinessInformation: "ข้อมูลธุรกิจที่ลงทะเบียน",
  registeredBusinessTaxNumber: "หมายเลขภาษีธุรกิจจดทะเบียน",
  registeredOfficialBusinessName: "ชื่อธุรกิจที่จดทะเบียน/เป็นทางการ",
  remove: "ลบ",
  removeFreeCredits: "ลบเครดิตฟรี",
  removeFromList: "ลบออกจากรายการ",
  removeNameAsYourStaff: "ลบ {0} ในฐานะพนักงานของคุณหรือไม่?",
  removeStocks: "ลบสต๊อก?",
  removeStocksConfirm: "ลบสต๊อก",
  removeStocksDetails: "คุณแน่ใจหรือไม่ว่าต้องการลบ {0} {1} ในสต๊อกของ {2} ใน {3}?",
  removeThisAccount: "นำบัญชีนี้ออกไหม?",
  removeThisSupplyItem: "ลบรายการวัตถุดิบนี้ออกจากผลิตภัณฑ์ก่อนเพื่อลบรายการนี้",
  removeVariant: "ลบตัวเลือก",
  removeVariantFirstFromProduct: "ลบตัวเลือกผลิตภัณฑ์นี้ออกจากผลิตภัณฑ์ก่อน",
  removedStock: "ลบสต๊อก",
  renewSubscription: "ต่ออายุการสมัครสมาชิก",
  reorderPoint: "จุดสั่งซื้อใหม่",
  reorderPointReached: "ถึงจุดสั่งซื้อใหม่แล้ว",
  reorderStocks: "สั่งสต๊อกใหม่",
  reportAProblem: "รายงานปัญหา",
  reportingDay: "วันรายงานตัว",
  report: "รายงาน",
  reports: "รายงาน",
  reportsFailedToLoad: "ไม่สามารถโหลดรายงาน",
  reportsInfoPage: "หน้าข้อมูลรายงาน",
  requireManagerAuthorization: "จำเป็นต้องมีการยืนยันจากผู้จัดการเมื่ออ้างสิทธิ์ส่วนลดนี้",
  requireManagerAuthorizationVoucher: "ต้องได้รับอนุญาตจากผู้จัดการเมื่อออกบัตรกำนัลนี้",
  required: "ที่จำเป็น",
  resendInvite: "ส่งคำเชิญอีกครั้ง?",
  resetExpiration: "รีเซ็ตวันหมดอายุ",
  resetTheExpirationPeriod: "รีเซ็ตระยะเวลาหมดอายุเมื่อแขกทำธุรกรรมเสร็จสิ้นโดยไม่ต้องเช็คอิน",
  restrictUseOfTags: "จำกัดการใช้แท็กบางอันในสถานที่ของคุณ",
  retailPrice: "ราคาขายปลีก",
  retailPriceMustNotBeNegative: "ราคาขายปลีกต้องไม่ติดลบ",
  retryReloadingChart: "ลองโหลดแผนภูมิอีกครั้ง",
  returnCredits: "คืนเครดิต",
  returnCreditsRoleDashboardDesc: "อนุญาตให้เข้าถึงเครดิตคืนของแอป Venue",
  returnCreditsRoleAppDesc: "บทบาทนี้ไม่มีสิทธิ์เข้าถึง Venue Dashboard ออนไลน์",
  returnedTags: "แท็กที่ส่งคืน",
  returnedVoucher: "คูปองที่ส่งคืน",
  returningGuests: "แขกที่กลับมา",
  revenue: "รายรับ",
  revenueAccount: "บัญชีรายรับ",
  revenueAccountMessage: "บัญชีเริ่มต้นสำหรับรายได้จากการขายทั้งหมด",
  revenueAccountPlaceholder: "[xxxx] - รายได้/การขาย FOC",
  reviewYourDailyVenueActivity: "ตรวจสอบกิจกรรมสถานที่ประจำวันของคุณ",
  revokeInvite: "เพิกถอนคำเชิญ",
  revokeThisInvite: "เพิกถอนคำเชิญนี้หรือไม่",
  roadMap: "แผนงาน",
  role: "หน้าที่่",
  roleAndDesignation: "หน้าที่และการกำหนด",
  roles: "หน้าที่",
  rooms: "ห้อง",
  runEndOfDay: "ดำเนินการประจำวัน",
  running: "วิ่ง",
  runningTheEodWithUnsynced: "การเรียกใช้ EOD ด้วยธุรกรรมที่ไม่ซิงค์จะส่งผลให้รายงานไม่สมบูรณ์",
  sale: "ขาย",
  saleDiscounts: "รายได้/ส่วนลดการขาย",
  saleFoc: "รายได้/การขาย FOC",
  saleReturn: "ผลตอบแทนจากการขาย",
  sales: "ฝ่ายขาย",
  salesDiscounts: "ส่วนลดการขาย",
  salesDiscountsMessage: "ค่าใช้จ่ายสำหรับส่วนลดที่ใช้ทั้งหมด",
  salesDiscountsPlaceholder: "[xxxx] - ส่วนลดการขาย",
  salesFOC: "ฝ่ายขาย",
  salesFOCMessage: "ค่าใช้จ่ายสำหรับเครดิตฟรีที่ใช้ทั้งหมด",
  salesFOCPlaceholder: "[xxxx] - รายได้/การขาย FOC",
  salesInformation: "ข้อมูลการขาย",
  salesOverTime: "การขายเมื่อเวลาผ่านไป",
  salesReport: "รายงานการขาย",
  salesSummary: "สรุปการขาย",
  save: "บันทึก",
  saveAccountSetting: "บันทึกการตั้งค่าบัญชี",
  saveCategoryForm: "บันทึกแบบฟอร์มหมวดหมู่",
  saveCreditSetting: "บันทึกการตั้งค่าเครดิต",
  saveDeviceForm: "บันทึกแบบฟอร์มอุปกรณ์",
  saveDiscountForm: "บันทึกแบบฟอร์มส่วนลด",
  saveGeneralSettings: "บันทึกการตั้งค่าทั่วไป",
  saveLocationForm: "บันทึกแบบฟอร์มตำแหน่ง",
  saveNewSupplyItemForm: "บันทึกแบบฟอร์มรายการวัตถุดิบใหม่",
  savePaymentMethod: "บันทึกวิธีการชำระเงิน",
  savePrinterProfileForm: "บันทึกแบบฟอร์มโปรไฟล์เครื่องพิมพ์",
  saveProduct: "บันทึกผลิตภัณฑ์",
  saveProductSet: "บันทึกชุดผลิตภัณฑ์",
  saveQuestion: "บันทึกคำถาม",
  saveReceiptSettings: "บันทึกการตั้งค่าใบเสร็จ",
  saveStaffForm: "บันทึกแบบฟอร์มพนักงาน",
  saveStaffProfileForm: "บันทึกแบบฟอร์มประวัติพนักงาน",
  saveTaxSetting: "บันทึกการตั้งค่าภาษี",
  saveVenueForm: "บันทึกแบบฟอร์มสถานที่",
  saveVoucherForm: "บันทึกแบบฟอร์มคูปอง",
  saveWristbandKeepingSettings: "บันทึกการตั้งค่าการเก็บสายรัดข้อมือ",
  savedChanges: "การเปลี่ยนแปลงที่บันทึกไว้",
  scheduleACall: "กำหนดเวลาการโทร",
  search: "ค้นหา",
  search2: "ค้นหา...",
  searchBanTags: "ค้นหาแท็กที่ถูกปิดใช้งานโดยแท็ก UID",
  searchCategories: "ค้นหาและเลือกหมวดหมู่สำหรับบัตรกำนัลนี้",
  searchForProducts: "ค้นหาผลิตภัณฑ์หรือรายการวัตถุดิบ",
  searchForStockId: "ค้นหารหัสสต๊อก...",
  searchForStocktakeId: "ค้นหารหัสสินค้าคงคลัง...",
  searchGuestsBalanceMovements: "ค้นหาแขกด้วยชื่อหรือรหัสแท็ก",
  searchGuestsByNameOrId: "ค้นหาแขกด้วยชื่อหรือแท็ก ID",
  searchMovementPlaceholder: "ค้นหาการใช้งานด้วยชื่อคูปอง ขื่ออุปกรณ์ ชื่อแขก",
  searchProductByKeyOrSku: "ค้นหาผลิตภัณฑ์ด้วยคีย์เวิร์ดหรือ SKU",
  searchProductsByKeyword: "ค้นหาผลิตภัณฑ์ตามคีย์เวิร์ด",
  searchProductsIn: "ค้นหาผลิตภัณฑ์ใน {0}",
  searchReportsByShiftId: "ค้นหารายงานตามรหัสกะ",
  searchResult: "ผลการค้นหา",
  searchResults: "ผลการค้นหา",
  searchSupplyItemBy: "ค้นหารายการซัพพลายตามชื่อหรือ SKU",
  searchTag: "ค้นหาแท็ก",
  searchTransactionPlaceholder: "ค้นหาธุรกรรมตามชื่อผู้เข้าพัก รหัสแท็ก ตำแหน่ง รหัสเช็คอิน",
  searchVouchers: "ค้นหาคูปอง",
  second: "วินาที",
  seconds: "วินาที",
  selectAType: "เลือกประเภท",
  selectAccount: "เลือกบัญชี",
  selectAccountPlaceholder: "เลือกบัญชี...",
  selectAccountType: "เลือกประเภทบัญชี",
  selectAndMakeComposition: "เลือกยี่ห้อและองค์ประกอบของผลิตภัณฑ์นี้",
  selectCategoriesOrProducts: "เลือกหมวดหมู่หรือผลิตภัณฑ์จากตำแหน่งที่คุณเลือก",
  selectCategory: "เลือกหมวดหมู่",
  selectCharge: "เลือกชาร์จ",
  selectCountry: "เลือกประเทศ",
  selectDevice: "เลือกอุปกรณ์",
  selectGuestProfile: "เลือกโปรไฟล์แขก",
  selectItemsThisProductMadeOf:
    '"เลือกรายการที่ผลิตภัณฑ์นี้ทำจาก กำหนดปริมาณของการจัดหาผลิตภัณฑ์แต่ละรายการที่จะหักตามใบสั่งขายของผลิตภัณฑ์นี้',
  selectLocation: "เลือกตำแหน่ง",
  selectNumberOfTables: "เลือกจำนวนตาราง",
  selectOutlet: "เลือก เอาท์เลท",
  selectProducts: "เลือกผลิตภัณฑ์",
  selectRedeemableProducts: "เลือกผลิตภัณฑ์ที่แลกได้",
  selectSource: "เลือกแหล่งที่มา",
  selectStaff: "เลือกพนักงาน",
  selectStatus: "เลือกสถานะ",
  selectStockLevel: "เลือกระดับสต๊อก",
  selectStockUnit: "เลือกหน่วยสต๊อก",
  selectSupplyItem: "เลือกรายการวัตถุดิบ",
  selectTax: "เลือกภาษี",
  selectToIncludeCategory: "เลือกรวมผลิตภัณฑ์นี้ในหมวดหมู่..",
  selectToIncludeProductSetInCategory: "เลือกรวมผลิตภัณฑ์ชุดนี้เข้าหมวดหมู่..",
  selectVenue: "เลือกสถานที่",
  selectVouchers: "เลือกคูปอง",
  sellOnPos: "ขายที่ POS",
  sellOnPosRoleDashboardDesc: "อนุญาตให้เข้าถึงการขายและการสั่งซื้อออนไลน์ของ Venue App",
  sellOnPosRoleAppDesc: "บทบาทนี้ไม่มีสิทธิ์เข้าถึง Venue Dashboard ออนไลน์",
  selling: "ขาย",
  sellingOnPos: "ขายที่ POS",
  sellingQty: "จำนวนขาย",
  sellingQtyMustBeGreaterThanZero: "ปริมาณการขายต้องมากกว่าศูนย์",
  serialNumber: "หมายเลขซีเรียล",
  serialNumberAbbv: "S/N",
  set: "ชุด",
  setAnAmountToLimitPostPaid: "กำหนดจำนวนเงินเพื่อจำกัดเครดิตแบบชำระภายหลังของผู้เข้าพัก",
  setAsDefaultTax: "กำหนดให้เป็นภาษีเริ่มต้น",
  setAsNonRefundable: "กำหนดให้ไม่สามารถคืนเงินได้",
  setCode: "ตั้งรหัส",
  setExpirationPeriod: "กำหนดระยะเวลาหมดอายุ",
  setUpYourAccount: "ตั้งค่าบัญชีของคุณ!",
  sets: "ชุด",
  settings: "การตั้งค่า",
  share: "แบ่งปัน",
  shareThisLink: "แชร์ลิงก์นี้เพื่อเปิด {0} บนอุปกรณ์ใดก็ได้! คัดลอกลิงก์และแชร์กับพนักงานของคุณ",
  shift: "กะ",
  shiftAlreadyClosed: "กะนี้ปิดแล้ว",
  shiftClosed: "กะปิดแล้ว",
  shiftEnd: "กะสิ้นสุด",
  shiftId: "รหัสกะ",
  shiftStart: "กะเริ่ม",
  shifts: "กะ",
  shiftsAndTransactions: "การเปลี่ยนแปลงและการทำธุรกรรม",
  shouldBeANumber: "ต้องเป็นตัวเลข",
  show: "แสดง",
  showHideColumns: "แสดง / ซ่อนคอลัมน์",
  showLess: "แสดงน้อยลง",
  showMore: "แสดงมากขึ้น",
  showOnlineMenu: "แสดงในเมนูออนไลน์",
  signIn: "เข้าสู่ระบบ",
  signInButton: "ปุ่มลงชื่อเข้าใช้",
  signUp: "ลงชื่อ",
  sku: "SKU",
  skuAlreadyExists: "SKU มีอยู่แล้ว",
  skuStockKeepingUnit: "SKU (หน่วยในการจัดเก็บสินค้า)",
  someArePartOfCompositeNote:
    "{0} {1} ด้านล่างไม่สามารถลบได้เนื่องจากเป็นส่วนหนึ่งของผลิตภัณฑ์อื่นๆ หากต้องการลบรายการเหล่านี้ ให้ลบออกจากรายการผลิตภัณฑ์หรือลบ {2} ด้วยกรายการผลิตภัณฑ์ที่มีอยู่",
  someShiftsHaveUnsynced:
    "การเปลี่ยนแปลงบางอย่างมีธุรกรรมที่ไม่ซิงค์กัน โปรดตรวจสอบอุปกรณ์กะเพื่อส่งข้อมูลสถานที่",
  somethingWentWrong: "อะไรบางอย่างผิดปกติ",
  somethingWentWrongPlsTryAgain: "มีบางอย่างผิดพลาด โปรดลองอีกครั้ง",
  sorryVenueSuspended: "ขออภัย สถานที่นี้ถูกระงับ",
  source: "แหล่งที่มา",
  sourceLocation: "ที่ตั้งต้นทาง",
  sourceLocationOriginalQty: "ตำแหน่งต้นทาง จำนวนเดิม",
  sourceLocationTransferQty: "จำนวนการโอนตำแหน่งต้นทาง",
  sourceLocationRemainQty: "ตำแหน่งต้นทางยังคงมีจำนวนอยู่",
  specificCategories: "หมวดหมู่เฉพาะ",
  specificProducts: "ผลิตภัณฑ์เฉพาะ",
  spend: "ใช้จ่าย",
  staff: "พนักงาน",
  staffCanAccess: "พนักงานสามารถเข้าถึงแอป POS และดำเนินการได้",
  staffList: "รายชื่อพนักงาน",
  staffName: "ชื่อพนักงาน",
  staffNameAndOrTagId: "ชื่อพนักงานและ/หรือรหัสแท็ก",
  staffProfile: "โปรไฟล์พนักงาน",
  staffProfileDeleted: "โปรไฟล์พนักงานถูกลบ",
  staffProfileList: "รายชื่อโปรไฟล์พนักงาน",
  staffProfileName: "ชื่อโปรไฟล์พนักงาน",
  staffProfileNameCreated: "สร้างโปรไฟล์พนักงาน “{0}” แล้ว",
  staffProfileNamePlaceholder: "พิมพ์ชื่อโปรไฟล์พนักงานนี้...",
  staffProfiles: "โปรไฟล์พนักงาน",
  staffRemoved: "พนักงานถูกลบออก",
  staffTagWithColonSymbol: "แท็กพนักงาน:",
  staffWithColonSymbol: "พนักงาน:",
  standard: "มาตรฐาน",
  start: "เริ่ม",
  startStocktake: "เริ่มนับสต๊อก",
  startedOn: "เริ่มเมื่อ",
  status: "สถานะ",
  staying: "พักอยู่",
  stockAdjustment: "การปรับสต๊อกสินค้า",
  stockAdjSupplyItemsTxtConfirm: "คุณแน่ใจหรือไม่ว่าต้องการปรับสต็อกรายการสินค้า/วัตถุดิบใน {0} ",
  stockBelowParLevel: "สต๊อกอยู่ต่ำกว่าระดับพาร์",
  stockLevel: "ระดับสต็อก",
  stockLevelAlerts: "การแจ้งเตือนระดับสต๊อก",
  stockLevelNotificationSettings: "การตั้งค่าการแจ้งเตือนระดับสต๊อก",
  stockLevels: "ระดับสต๊อก",
  stockManager: "ผู้จัดการสต๊อก",
  stockManagerRoleDashboardDesc:
    "อนุญาตให้เข้าถึง Venue Dashboard ที่จำกัดเฉพาะการตรวจนับสินค้าคงคลังเท่านั้น",
  stockManagerRoleAppDesc:
    "บทบาทนี้ไม่มีบทบาทที่เกี่ยวข้องกับแอป และจะไม่สามารถเข้าสู่ระบบบนอุปกรณ์ได้",
  stockReachedReorderPoint: "สต๊อกมาถึงจุดสั่งซื้อใหม่แล้ว",
  stockUnit: "หน่วยสต๊อก",
  stockVariance: "ตัวเลือกสต๊อก",
  stockWeightPerN: "น้ำหนักสต๊อกต่อ {0}",
  stocks: "สต๊อก",
  stocktake: "ตรวจนับสต๊อก",
  stocktakeAlreadyExists: "มีสต๊อกอยู่แล้ว",
  stocktakeSaved: "บันทึกสต๊อกแล้ว",
  stocktakeWithId: "ตรวจนับสต๊อก #ST-{0}",
  streetVillage: "ที่อยู่ ตำบล / หมู่บ้าน",
  subTotal: "ยอดรวม",
  subscribeAndRegister: "สมัครสมาชิกและลงทะเบียนตอนนี้เพื่อรับ",
  subscription: "สมัครสมาชิก",
  subscriptionCanceledReason: "คุณได้ยกเลิกการสมัครสมาชิกของคุณแล้ว",
  subscriptionExpiredReason: "คุณได้ยกเลิกการสมัครสมาชิกของคุณแล้ว",
  subscriptionSuspended:
    "การสมัครสมาชิกสิ้นสุดลงแล้ว โปรดติดต่อเจ้าของสถานที่เพื่อต่ออายุการสมัครก่อน {2} เพื่อรักษาสิทธิ์ในการเข้าถึง {1}",
  subscriptionSuspendedIn5:
    "สถานที่นี้กำลังจะระงับในอีก 5 วัน โปรดติดต่อเจ้าของสถานที่เพื่อต่ออายุการสมัครก่อน {0} เพื่อรักษาสิทธิ์ในการเข้าถึง {1}",
  subscriptionSuspendedIn5Manager:
    "สถานที่ของคุณจะถูกระงับในอีกไม่ถึง 5 วัน โปรดต่ออายุการสมัครของคุณก่อน {0} เพื่อรักษาสิทธิ์ในการเข้าถึง {1}",
  subscriptionSuspendedManager:
    "{0} โปรดต่ออายุการสมัครของคุณก่อน {1} เพื่อรักษาสิทธิ์ในการเข้าถึง {2}",
  subscriptionSuspendedToday:
    "สถานที่ของคุณจะถูกระงับในวันนี้ โปรดติดต่อเจ้าของสถานที่เพื่อต่ออายุการสมัครเพื่อรักษาสิทธิ์ในการเข้าถึง {0}",
  subscriptionSuspendedTodayManager:
    "สถานที่ของคุณจะถูกระงับในวันนี้ โปรดต่ออายุการสมัครของคุณวันนี้เพื่อรักษาการเข้าถึง {0}",
  subscriptionUnpaidReason: "ดูเหมือนว่าจะมีปัญหากับรายละเอียดการชำระเงินของคุณ",
  subscription_only_owner_access:
    "เฉพาะเจ้าของสถานที่ซึ่งสมัครรับแผนนี้เท่านั้นจึงจะสามารถเข้าถึงการสมัครสมาชิกนี้ได้",
  success: "ความสำเร็จ",
  sugar: "น้ำตาล",
  suggested: "แนะนำ",
  summary: "สรุป",
  supplyCost: "ต้นทุนการจัดหา",
  supplyItem: "รายการวัตถุดิบ",
  supplyItemAlreadyExists: "มีชื่อรายการวัตถุดิบแล้ว",
  supplyItemDeleted: "ลบรายการวัตถุดิบแล้ว",
  supplyItemNameCreated: "สร้างรายการวัตถุดิบ “{0}” แล้ว",
  supplyItemSummaryPage: "หน้าสรุปรายการวัตถุดิบ",
  supplyItems: "รายการวัตถุดิบ",
  supplyTaxes: "ภาษีอุปทาน",
  supplyUnit: "หน่วยวัตถุดิบ",
  supplyValue: "มูลค่าการจัดหา",
  supportPouchnation: "support@pouchnation.com",
  suspended: "ถูกระงับ",
  syncToMews: "ซิงค์กับ MEWS",
  tableManagement: "การจัดการตาราง",
  tableQRCode: "QR Code ของตาราง (ต่อโต๊ะ)",
  tables: "ตาราง",
  tagBanned: "แท็กที่ปิดใช้งาน",
  tagId: "หมายเลขแท็ก",
  tagIds: "หมายเลขแท็ก",
  tagInfo: "ข้อมูลแท็ก",
  tagUID: "แท็ก UID",
  tagUnbanned: "เลิกปิดการใช้งานแท็กแล้ว",
  tags: "แท็ก",
  tagsIssued: "แท็กออก",
  tapCount: "แตะนับ",
  tapStatus: "แตะสถานะ",
  tax: "ภาษี",
  taxAddedName: 'เพิ่มภาษี "{0}" แล้ว',
  taxAlreadyExists: "มีภาษีอยู่แล้ว",
  taxCode: "รหัสภาษี",
  taxEditNote:
    "หมายเหตุ: ผลิตภัณฑ์ที่เชื่อมโยงกับภาษีนี้จะได้รับการอัปเดตโดยอัตโนมัติ ซึ่งจะไม่ส่งผลกระทบต่อยอดขายที่ผ่านมา",
  taxEg: "เช่น “ภาษีมูลค่าเพิ่ม”",
  taxIncluded: "รวมภาษี",
  taxInvoiceNo: "ใบกำกับภาษี#:",
  taxInvoiceNumber: "หมายเลขใบกำกับภาษี",
  taxName: "ชื่อผู้เสียภาษี",
  taxNumber: "หมายเลขภาษี",
  taxOnPurchasesAccount: "ภาษีในบัญชีซื้อ",
  taxOnPurchasesAccountMessage: "ภาษีมูลค่าเพิ่มและภาษีอื่นๆ ในใบสั่งซื้อ",
  taxOnPurchasesAccountPlaceholder: "[xxxx] - ความรับผิด/ภาษีในการซื้อ",
  taxOnSalesLiabilityAccount: "ภาษีในบัญชีความรับผิดในการขาย",
  taxOnSalesLiabilityAccountMessage: "บัญชีเริ่มต้นสำหรับภาษีที่เรียกเก็บสำหรับแต่ละผลิตภัณฑ์",
  taxOnSalesLiabilityAccountPlaceholder: "[xxxx] - ความรับผิด/ภาษีจากการขาย",
  taxPercentageAndValue: "ภาษี ({0}%): {1}",
  taxRate: "อัตราภาษี",
  taxSettings: "การตั้งค่าภาษี",
  taxes: "ภาษี",
  termsAndConditions: "ข้อกำหนดและเงื่อนไข",
  termsInAllowingAccess:
    "การคลิกที่อนุญาตการเข้าถึง แสดงว่าคุณอนุญาตให้ {0} ใช้ รับ และจัดเก็บข้อมูลของคุณ โปรดตรวจสอบ {2} และ {3} ของ {1} คุณสามารถหยุดการตั้งค่าการผสานรวมของบัญชี PouchNATION ได้ทุกเมื่อ",
  termsInAllowingAccess2:
    "การคลิกที่อนุญาตการเข้าถึง แสดงว่าคุณอนุญาตให้ {0} ใช้ รับ และจัดเก็บข้อมูลของคุณ คุณสามารถหยุดได้ตลอดเวลาในการตั้งค่าการรวมระบบของบัญชี PouchNATION ของคุณ",
  termsInAllowingAccess3:
    "การคลิกที่อนุญาตการเข้าถึง แสดงว่าคุณอนุญาตให้ {0} จัดเก็บข้อมูล {1} ของคุณ โปรดตรวจสอบ {2} และ {3} ของเรา",
  termsInSignUp: "การลงทะเบียนแสดงว่า ฉันยอมรับ {0} {1} และ {2} ของ PouchNATION",
  termsOfService: "เงื่อนไขการให้บริการ",
  termsOfUse: "เงื่อนไขการใช้บริการ",
  textbox: "กล่องข้อความ",
  theActualNumberOfGuests: "จำนวนผู้เข้าพักจริงที่เช็คอิน/เช็คเอาท์",
  theAmountTheGuestWillPay: "นี่คือจำนวนเงินที่ผู้เข้าพักจะจ่ายสำหรับการจอง",
  theBookingStatus: "สถานะการจองจากแหล่งที่มา",
  theLinkYouAreTryingToAccess: "ลิงก์ที่คุณพยายามเข้าถึงไม่ได้มาจากสถานที่ของคุณ",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation:
    "ลิงก์ที่คุณพยายามเข้าถึงไม่ได้มาจากตำแหน่งที่คุณกำหนด",
  theShiftAndTransactionDetails:
    "รายละเอียดกะและธุรกรรมจะแสดงที่นี่เมื่อกะถูกปิดในเครื่องบันทึกเงินสด",
  thereIsSomeError:
    "ไม่นะ! มีข้อผิดพลาดบางอย่างที่เกิดจากเรา โปรดรอสักครู่และตรวจสอบอีกครั้งในภายหลัง",
  thereNthOtherProducts:
    "{0} {1} ผลิตภัณฑ์อื่นๆ ที่มีตำแหน่งนี้และตำแหน่งอื่นๆ จะยังคงอยู่ในสินค้าคงคลังของคุณ",
  theseProductsCannotBeDeleted: "ผลิตภัณฑ์เหล่านี้ไม่สามารถลบได้",
  thisAppRequests: "แอปนี้ขอข้อมูลต่อไปนี้:",
  thisAppWouldRead: "แอปนี้จะอ่านและ/หรือเขียนข้อมูลต่อไปนี้:",
  thisEmailIsAlreadyAdded: "เพิ่มอีเมลนี้แล้ว",
  thisEmailIsInvalid: "อีเมลนี้ไม่ถูกต้อง",
  thisFieldIsRequired: "จำเป็นต้องระบุ",
  thisLocationHasTables: "สถานที่นี้มีโต๊ะและการจัดเรียงที่นั่ง",
  thisMonth: "เดือนนี้",
  thisProductNameAlreadyExists: "มีชื่อผลิตภัณฑ์นี้แล้ว",
  thisProductsHasMultipleVariant: "ผลิตภัณฑ์มีหลายแบบให้เลือก เช่น ขนาด สี รส ฯลฯ",
  thisQuestionIsLinkedToNProfiles: "คำถามนี้เชื่อมโยงกับโปรไฟล์แขก {0} รายการ",
  thisServesAsAnOfficialReceipt: "- ใช้เป็นใบเสร็จรับเงินอย่างเป็นทางการ -",
  thisShiftHasUnsyncedTransactions: "เที่ยงวันนี้มีการทำธุรกรรมที่ยังไม่ได้ซิงค์",
  thisSkuExists: "มี SKU นี้แล้ว",
  thisStaffHasNpLongerAccess:
    "พนักงานนี้จะไม่สามารถเข้าถึง {0} ได้อีกต่อไป การดำเนินการนี้ไม่สามารถยกเลิกได้",
  thisTagIdIsAlreadyBanned: "ID แท็กนี้ถูกปิดใช้งานแล้ว",
  thisVenueFreeTrial: "สถานที่นี้จะเปิดให้ทดลองใช้งานฟรีใน 30 วันแรก",
  thisWeek: "ในสัปดาห์นี้",
  time: "เวลา",
  timeEnded: "ช่วงเวลา",
  timeRange: "เวลาสิ้นสุด",
  timeStarted: "เวลาเริ่มต้น",
  to: "ถึง",
  toAddMoreCreditsToATag: "หากต้องการเพิ่มเครดิตให้กับแท็ก แขกอาจสามารถ",
  toBeCheckedIn: "ที่จะเช็คอิน",
  toBeCheckedOut: "ที่จะเช็คเอาท์",
  toContinueCreatingDiscount: "หากต้องการสร้างส่วนลดนี้ต่อ คุณต้องสร้างตำแหน่งก่อน",
  toContinueCreatingProducts: "หากต้องการสร้างผลิตภัณฑ์นี้ต่อ คุณต้องสร้างตำแหน่งก่อน",
  toContinueCreatingStaff: "หากต้องการสร้างพนักงานคนนี้ต่อ คุณต้องสร้างตำแหน่งก่อน",
  toContinueCreatingStocktake: "หากต้องการสร้างสต๊อกนี้ต่อ คุณต้องสร้างตำแหน่งก่อน",
  toDeleteItem: "เพื่อลบรายการนี้",
  toScheduleACall: "เพื่อกำหนดการสนทนากับฝ่ายบริการลูกค้าของเรา",
  today: "วันนี้",
  together: "ด้วยกัน",
  topCategoriesBySales: "หมวดหมู่ยอดนิยมตามฝ่ายขาย",
  topLocations: "ตำแหน่งยอดนิยม",
  topProducts: "ผลิตภัณฑ์ยอดนิยม",
  topSpendingGuests: "แขกผู้มียอดใช้จ่ายสูงสุด",
  topupAndReturnCredits: "เติมและคืนเครดิต",
  topupCredits: "เติมเงินเครดิต",
  total: "รวม",
  totalAmount: "จำนวนเงินทั้งหมด",
  totalBookings: "ยอดจอง",
  totalCreditsKept: "ยอดเครดิตที่ถือครองรวม (หลังจากเช็คเอาท์)",
  totalCreditsKeptByGuests: "ยอดเครดิตที่ลูกค้าถือครองทั้งหมด",
  totalCreditsReceived: "ยอดเครดิตที่ได้รับทั้งหมด",
  totalFreeCreditsIssued: "เครดิตฟรีทั้งหมดที่ออก",
  totalFreeCreditsRemoved: "ลบเครดิตฟรีทั้งหมด",
  totalInStock: "รวมในสต๊อก",
  totalOpeningBalance: "ยอดยกมาทั้งหมด",
  totalPaid: "ยอดชำระทั้งหมด",
  totalProductSales: "ยอดขายผลิตภัณฑ์ทั้งหมด",
  totalRefunded: "ยอดคืนเงินทั้งหมด",
  totalRefundedCredits: "เครดิตเงินคืนทั้งหมด",
  totalRemoved: "ลบทั้งหมด",
  totalReturnCredits: "เครดิตคืนทั้งหมด",
  totalReturnedCredits: "เครดิตเงินคืนทั้งหมด",
  totalSales: "ยอดขายทั้งหมด",
  totalSpent: "ยอดใช้จ่ายทั้งหมด",
  totalSpentToDate: "ยอดใช้จ่ายทั้งหมดจนถึงปัจจุบัน",
  totalStocksPerLocation: "จำนวนสต็อคทั้งหมดต่อตำแหน่ง",
  totalTagsUsed: "แท็กที่ออกทั้งหมด",
  totalTax: "ภาษีทั้งหมด",
  totalTopUps: "เติมเงินทั้งหมด",
  totalTopupCredits: "เครดิตเติมเงินทั้งหมด",
  totalTransactions: "รวมธุรกรรม",
  totalUnspentCredits: "ยอดเครดิตที่ไม่ได้ใช้ทั้งหมด",
  totalUse: "การใช้งานทั้งหมด",
  totalVisits: "การเข้าชมทั้งหมด {0}",
  totals: "ยอดรวม",
  trackAndManage: "ติดตามและจัดการการนับสินค้าคงคลังด้วยคุณ",
  trackEveryGuestCheckedIn:
    "ติดตามแขกทุกคนที่เช็คอินในตำแหน่งของคุณ พร้อมดูผลิตภัณฑ์และตำแหน่งโปรดของพวกเขา",
  trackWhichItemsLow: "ติดตามดูว่ามีสินค้าใดในสต๊อกที่ต่ำและจำเป็นต้องจัดหาใหม่",
  trackedInventory: "ติดตามสินค้าคงคลัง",
  tradeTypeTransactionNumber: "หมายเลขการทำรายการของ {0}",
  transaction: "ธุรกรรม",
  transactionId: "รหัสธุรกรรม",
  transactionInfoPage: "หน้าข้อมูลการทำธุรกรรม",
  transactionStatus: "สถานะการทำธุรกรรม",
  transactionType: "ประเภทรายการ",
  transactions: "ธุรกรรม",
  transactionsFailedToProcess: "ธุรกรรมล้มเหลวในการประมวลผล",
  transactionsPage: "หน้าธุรกรรม",
  transfer: "โอนย้าย",
  transferSupplyItems: "โอนย้ายรายการสินค้า/วัตถุดิบ",
  transferSupplyItemsSuccessfully: "โอนย้ายรายการสินค้า/วัตถุดิบสำเร็จแล้ว",
  transferSupplyItemsTxtConfirm:
    "คุณแน่ใจหรือไม่ว่าต้องการถ่ายโอนรายการสินค้า/วัตถุดิบจาก {0} ไปยัง {1} ",
  try: "ลอง:",
  turnOffPos: "ปิดการขาย {0} {1}?",
  turnOnPos: "เปิดการขาย {0} {1}?",
  txnId: "รหัส Txn: #",
  type: "พิมพ์",
  typeAQuestion: "พิมพ์คำถาม",
  typeAReason: "พิมพ์เหตุผล...",
  typeAnOption: "พิมพ์ตัวเลือก",
  typeDisconnect: "พิมพ์ DISCONNECT เป็นตัวพิมพ์ใหญ่เพื่อยืนยัน",
  typeHere: "พิมพ์ที่นี่...",
  typeToSearch: "พิมพ์เพื่อค้นหารายการ!",
  typeToSetAccountCode: "พิมพ์เพื่อตั้งค่า/เลือกรหัสบัญชี",
  unableToDelete: "ลบไม่ได้",
  unbaleToUploadMoreThanMb: "ไม่สามารถอัปโหลดเกิน 1mb",
  unban: "เลิกการปิดใช้งาน",
  unbanTag: "เลิกการปิดใช้งานแท็ก",
  unbanningWillMakeTagAccessible: "การเลิกปิดใช้งานแท็กจะทำให้แท็กนี้เข้าถึงได้ในสถานที่ของคุณ",
  uncategorized: "เลิกการจัดหมวดหมู่",
  undefinedError: "ข้อผิดพลาดที่ี่ไม่มีในระบบ",
  undefinedErrorPlsTryAgain: "ข้อผิดพลาดที่ี่ไม่มีในระบบ โปรดรีเฟรชหน้าเว็บแล้วลองใหม่อีกครั้ง",
  undo: "เลิกทำ",
  unignore: "ยกเลิกการเพิกเฉย",
  unignoreAllOpenShifts: "ยกเลิกการเพิกเฉยทุกเวรที่เปิดอยู่",
  unignoreAllShifts: "ยกเลิกการเพิกเฉยทุกเวร",
  unitSold: "หน่วยขาย",
  unitsSold: "หน่วยขาย",
  unknown: "ไม่รู้จัก",
  upToDate: "ปัจจุบัน",
  updatedOn: "อัปเดตเมื่อ {0}",
  upgradeNow: "อัปเกรดตอนนี้",
  upgradePlanHeader:
    "เพิ่มประสิทธิภาพสถานที่ของคุณและเข้าถึงคุณสมบัติพรีเมียมเพิ่มเติมเมื่อคุณอัปเกรดแผนของคุณตอนนี้!",
  upgradeYourPlan: "อัปเกรดแผนของคุณ! ✨",
  uploadPhoto: "อัพโหลดรูปภาพ",
  uponRegistration: "เมื่อลงทะเบียนหรือกับเจ้าหน้าที่เติมเงิน / ตู้คีออสภายในสถานที่",
  usage: "การใช้งาน",
  usageDatePeriod: "ระยะเวลาวันที่ใช้งาน",
  useThisVenueDetails: "ใช้รายละเอียดสถานที่นี้",
  useVenueAppAndLogin:
    "ใช้แอปสถานที่และเข้าสู่ระบบด้วยแท็ก NFC ที่เปิดใช้งานหรือด้วยชื่อผู้ใช้หรือรหัสผ่านปัจจุบันของคุณ ยังไม่มีแอป? ถามผู้จัดการสถานที่ของคุณหรือ {0}",
  used: "ใช้แล้ว",
  usesLastIssuedTo: "ใช้, ออกล่าสุดไปยัง",
  username: "ชื่อผู้ใช้",
  validUntilWithColonSymbol: "ใช้ได้ถึงวันที่;",
  value: "ค่า",
  valueBetween: "ค่าควรอยู่ระหว่าง",
  valueRedeemed: "มูลค่าที่แลก",
  variant: "ตัวเลือก",
  variantAlreadyExist: "มีตัวเลือกอยู่แล้ว",
  variantIsCurrentlyBeingUsed: "ปัจจุบันมีการใช้ตัวเลือกในผลิตภัณฑ์ต่อไปนี้",
  variants: "ตัวเลือก",
  venue: "สถานที่",
  vatReg: "ทะเบียนภาษีมูลค่าเพิ่ม",
  venueCurrency: "สกุลเงินของสถานที่",
  venueDashboard: "แดชบอร์ดสถานที่",
  venueDetails: "รายละเอียดสถานที่",
  venueInformation: "ข้อมูลสถานที่",
  venueLocation: "ที่ตั้งตำแหน่ง",
  venueLogo: "โลโก้สถานที่",
  venueName: "ชื่อสถานที่",
  venueNameAlreadyExist: "มีชื่อสถานที่อยู่แล้ว กรุณาเลือกชื่ออื่น",
  venueSettings: "การตั้งค่าสถานที่",
  venueSuspended: "สถานที่ถูกระงับ",
  veryHighDenominations: "มูลค่าสูงมาก",
  view: "ดู",
  viewAllTransactions: "ดูธุรกรรมทั้งหมด",
  viewAndExportDiscountReport:
    "ดูและส่งออกรายการส่วนลดของคุณและยอดรวมที่หักสำหรับส่วนลดแต่ละรายการ",
  viewAndExportEachClosedRegister:
    "ดูและส่งออกรายงานการลงทะเบียนปิดแต่ละรายการที่ทำโดยพนักงานของคุณ",
  viewAndExportFreeCreditsReport: "ดูและส่งออกรายงานเครดิตฟรีที่ออกและถูกนำออก",
  viewAndExportSalesReportByCategories: "ดูและส่งออกรายงานการขายของคุณตามหมวดหมู่",
  viewAndExportVoucherReport:
    "ดูและส่งออกคูปองทั้งหมดที่ใช้กับผลิตภัณฑ์ที่แลกและจำนวนรวมของการขายปลีกและวัตถุดิบที่แลกโดยคูปองแต่ละใบ",
  viewBillingStatement: "ดูใบแจ้งยอดการเรียกเก็บเงิน",
  viewBookingInfo: "ดูข้อมูลการจองจากแพลตฟอร์มต่างๆ เช่น MEWS, PouchNATION Ticketing และอีกมากมาย!",
  viewCategoryDetails: "ดูรายละเอียดหมวดหมู่",
  viewDetails: "ดูรายละเอียด",
  viewDevice: "ดูอุปกรณ์",

  viewDiscountDetails: "ดูรายละเอียดส่วนลด",
  viewEveryTransactions: "ดูทุกธุรกรรมที่ทำในสถานที่ของคุณภายในระยะเวลาหนึ่ง",
  viewInfo: "ดูข้อมูล",
  viewIntegrations: "ดูการผสานรวม",
  viewNTransactions: "ดูธุรกรรม {0}",
  viewNOfNTransactions: "ดูธุรกรรม {0} / {1}",
  viewPlansAndPricing: "ดูแผนและราคา",
  viewPreview: "ดูตัวอย่าง",
  viewPrinter: "ดูเครื่องพิมพ์",
  viewTaxSettings: "ดูการตั้งค่าภาษี",
  viewTheSalesSummaryOfYourVenue:
    "ดูข้อมูลสรุปการขายของสถานที่และตำแหน่ง ผลิตภัณฑ์ และอื่นๆ ที่ขายดี",
  viewVoucherDetails: "ดูรายละเอียดคูปอง",
  void: "ยกเลิก",
  voidAuthorization: "การอนุญาตยกเลิก",
  voidConfirmation: "คุณแน่ใจหรือไม่ว่าต้องการยกเลิก {0} การดำเนินการนี้ไม่สามารถยกเลิกได้",
  voidId: "ยกเลิก {0}?",
  voidInfo: "ยกเลิกข้อมูล",
  voidReason: "สาเหตุการยกเลิก",
  voidStocktake: "ยกเลิกการนับสต๊อก",
  voided: "ยกเลิกแล้ว",
  voidedBy: "ยกเลิกโดย",
  voidedItems: "รายการที่ยกเลิก",
  voidedOn: "ยกเลิกเมื่อ",
  voidedValue: "มูลค่าที่ยกเลิก",
  voucher: "คูปอง",
  voucherApplied: "ใช้คูปอง",
  voucherAvailable: "คูปองนี้สามารถออกในสถานที่ของคุณได้",
  voucherCreated: "{0} เพิ่ม",
  voucherDeleted: "ลบคูปอง {0} แล้ว",
  voucherIssue: "การออกบัตรส่วนลด",
  voucherMovementPage: "หน้าการใช้งานคูปอง",
  voucherName: "ชื่อคูปอง",
  voucherNameAlreadyExist: "ชื่อคูปองนี้มีอยู่แล้ว",
  voucherNotApplied: "ยังไม่ได้ใช้บัตรส่วนลด",
  voucherRedeemed: "ใช้บัตรส่วนลดแล้ว",
  voucherReport: "รายงานคูปอง",
  voucherReports: "รายงานบัตรส่วนลด",
  voucherRetailValue: "คูปองมูลค่าขายปลีก",
  vouchers: "คูปอง",
  voucherSupplyValue: "มูลค่าการจัดหาคูปอง",
  voucherType: "ประเภทคูปอง",
  vouchersCreationLimitInfo:
    "คุณสามารถสร้างคูปองได้สูงสุด 8 ใบต่อสถานที่ คุณสามารถลบคูปองที่หมดอายุหรือใช้งานไม่ได้เพื่อให้มีที่ว่างสำหรับคูปองเพิ่มเติม",
  vouchersIssued: "คูปองที่ออก",
  vouchersListPage: "หน้ารายการคูปอง",
  vouchersMaximumNumberReached: "คูปองถึงจำนวนสูงสุดแล้ว",
  vouchersRedeemed: "แลกคูปองแล้ว",
  vouchersRemoved: "คูปองถูกลบออก",
  vouchersUsage: "การใช้คูปอง",
  voucherTransactions: "การทำธุรกรรมบัตรส่วนลด",
  voucherUpdated: "อัพเดทคูปอง",
  wait: "รอ...",
  waiters: "พนักงานเสิร์ฟ",
  warning: "คำเตือน",
  warningMessage: "คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ? การกระทำนี้ไม่สามารถย้อนกลับได้",
  watchTutorial: "ดูวิธีการใช้งาน",
  weCouldNotFindAnyEmails: "เราไม่พบอีเมลใดที่เกี่ยวข้องกับบัญชีนี้",
  weCouldNotFindAnyGuestsWithThisCriteria: "เราไม่พบข้อมูลของแขกที่ตรงกับเกณฑ์ดังกล่าว",
  weDidNotFoundReports: "เราไม่พบรายงานใดๆ สำหรับตัวกรองของคุณ",
  weeksAfterCheckout: "สัปดาห์หลังเช็คเอาท์",
  weHaveSentEmail: "เราได้ส่งอีเมลยืนยันการกู้คืนรหัสผ่านไปยัง {0}",
  weHaveSentEmailTo: "เราได้ส่งอีเมลไปยัง {0}",
  weightInGrams: "น้ำหนักเป็นกรัม",
  weNeedAtLeastOneNumber: "เราต้องการตัวเลขอย่างน้อยหนึ่งตัว",
  weNeedYourDetailsToContinue: "เราต้องการข้อมูลของคุณเพื่อดำเนินการต่อ",
  weRegretToInform:
    "เราเสียใจที่จะแจ้งให้ทราบว่าเราไม่สามารถประมวลผลคำขอนี้ได้ กรุณาลองอีกครั้งในภายหลัง",
  weSuggestYouTo: "เราขอแนะนำให้คุณ {0}",
  weWillSendYouAnEmail: "เราจะส่งอีเมลยืนยันไปยัง {0}",
  weWillUpdateYouViaEmail: "เราจะอัปเดตคุณทางอีเมลเมื่อการประมวลผลสำเร็จ",
  webhooks: "Webhooks",
  webhooksFor: "Webhooks สำหรับ {0}",
  webhooksInformation: "ข้อมูล Webhooks",
  webhooksIntegration: "การรวม Webhooks",
  welcome: "ยินดีต้อนรับ!",
  welcomeBack: "ยินดีต้อนรับกลับ!",
  welcomeTo: "ยินดีต้อนรับสู่ {0}",
  whatIsStocktake: "ตรวจสอบสต็อกคืออะไร",
  whatsThis: "นี่คืออะไร?",
  whenAnItemAlertLevel: "เมื่อรายการต่ำถึงระดับการแจ้งเตือน",
  whenAnOrderIsPaid: "เมื่อคำสั่งซื้อได้รับการชำระเงิน สถานะของคำสั่งซื้อจะเปลี่ยน",
  whenIGetANewBooking: "เมื่อฉันได้รับการจองใหม่",
  whenIGetANewSale: "เมื่อฉันได้รับการขายใหม่",
  whenYouCreateABooking: "เมื่อคุณสร้างการจอง",
  whenYouHaveSettledATransaction: "เมื่อคุณได้ดำเนินการชำระเงินสำเร็จแล้ว",
  whereDoYouWantToTakeAction: "คุณต้องการดำเนินการที่ไหน?",
  whereToFind: "สถานที่ในการค้นหาข้อมูลการตั้งค่านี้",
  whole: "ทั้งหมด",
  wholeProduct: "ผลิตภัณฑ์ทั้งหมด",
  whoops: "อุ๊ปส์!",
  whoopsSomethingWentWrong: "อุ๊ปส์! บางอย่างผิดพลาด",
  withPendingPayments: "ด้วยการชำระเงินที่รอดำเนินการ",
  withRemainingBalance: "ด้วยยอดเงินคงเหลือ",
  withSufficientPermissions: "กับสิทธิ์ที่เพียงพอ",
  withThisPaymentMethod: "ด้วยวิธีการชำระเงินนี้:",
  withThisPhone: "ด้วยโทรศัพท์นี้:",
  workShift: "กะงาน",
  wristbandKeeping: "การรักษาสายรัดข้อมือ",
  wristbandTag: "แท็กสายรัดข้อมือ",
  wristbandTagKeeping: "การเก็บรักษาแท็กสายรัดข้อมือ",
  wristbandTagSettings: "การตั้งค่าแท็กสายรัดข้อมือ",
  writeADescription: "เขียนคำอธิบาย",
  writeADescriptionOptional: "เขียนคำอธิบาย (ไม่บังคับ)",
  writeAName: "เขียนชื่อ",
  writeANameOptional: "เขียนชื่อ (ไม่บังคับ)",
  writeAnEmail: "เขียนอีเมล",
  writeMessage: "เขียนข้อความ",
  xConnectedFailed:
    "ดูเหมือนเราจะไม่รู้จักโทเค็นการเข้าถึงนี้ โปรดดูคำแนะนำด้านล่างเพื่อดึงโทเค็นการเข้าถึงที่เหมาะสมของคุณ",
  xConnectedSuccessfully: "{0} เชื่อมต่อสำเร็จแล้ว",
  year: "ปี",
  years: "ปีที่",
  yes: "ใช่",
  yesContinue: "ใช่ ดำเนินการต่อ",
  you: "คุณ",
  youAreAboutToDelete: 'คุณกำลังจะลบ {0} "{1}" การดำเนินการนี้ไม่สามารถยกเลิกได้',
  youAreAboutToDeleteAQuestionThatIsLinked:
    "คุณกำลังจะลบคำถามที่เชื่อมโยงกับโปรไฟล์ผู้เยี่ยมชม การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeleteGuestProfile:
    "คุณกำลังจะลบโปรไฟล์ผู้เยี่ยมชมนี้ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeleteMultiple: "คุณกำลังจะลบ {0} {1} การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeletePaymentMethod: 'คุณกำลังจะลบวิธีการชำระเงิน "{0}"',
  youAreAboutToDeleteQuestion: "คุณกำลังจะลบคำถามนี้ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeleteStaffName:
    "คุณกำลังจะลบ {0} ในฐานะพนักงานของคุณ พนักงานนี้จะไม่สามารถเข้าถึง {1} ได้อีกต่อไป การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeleteStaffProfile:
    "คุณกำลังจะลบโปรไฟล์พนักงานนี้ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToDeleteTax: 'คุณกำลังจะลบภาษี "{0}"',
  youAreAboutToDisconnect: "คุณกำลังจะยกเลิกการเชื่อมต่อและนำข้อมูลทั้งหมดออกจากแอปนี้อย่างถาวร",
  youAreAboutToRemoveFromMailingList:
    "คุณกำลังจะลบ {0} ออกจากรายชื่อส่งเมลนี้ บัญชีนี้จะไม่สามารถรับการแจ้งเตือนระดับสต๊อกได้",
  youAreAboutToRemoveMultipleProduct:
    "คุณกำลังจะลบผลิตภัณฑ์ {0} รายการและตัวเลือกออกจากสินค้าคงคลังและตำแหน่งตั้งทั้งหมดของคุณ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToRemoveProduct:
    "คุณกำลังจะลบผลิตภัณฑ์นี้ออกจากสินค้าคงคลังและที่ตั้งทั้งหมดของคุณ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAboutToRemoveVariantProduct:
    "คุณกำลังจะลบผลิตภัณฑ์นี้และตัวเลือก {0} รายการออกจากสินค้าคงคลังและตำแหน่งตั้งทั้งหมดของคุณ การดำเนินการนี้ไม่สามารถยกเลิกได้",
  youAreAssigned: "คุณได้รับมอบหมายหน้าที่ดังต่อไปนี้:",
  youAreAuthorizedToAccess: "คุณได้รับสิทธิ์ในการเข้าถึง",
  youAreLoggedInAs: "คุณเข้าสู่ระบบในฐานะ {0}",
  youAreNotAuthorized: "คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้",
  youCanAlso: "คุณยังสามารถ",
  youCanChangeThisLater: "คุณสามารถเปลี่ยนได้ในภายหลัง!",
  youCanNowLogin: "ตอนนี้คุณสามารถเข้าสู่ระบบโดยใช้อีเมลและรหัสผ่านของคุณ",
  youCanStillAccess: "คุณยังสามารถเข้าถึงสถานที่นี้ได้ด้วย",
  youCannotAddPastTime: "คุณไม่สามารถเพิ่มเวลาในอดีตได้",
  youCannotRemovePastTime: "คุณไม่สามารถลบเวลาในอดีตได้",
  youDontHavePermission: "คุณไม่ได้รับอนุญาตให้เข้าถึงลิงก์นี้",
  youDontHaveSufficientPermissions: "คุณไม่มีสิทธิ์เพียงพอในการดำเนินการนี้",
  youHaveAMessage: "คุณมีข้อความ!",
  youHaveNoBooking: "คุณไม่มีการจองใด ๆ",
  youHaveNoClosedRegisters: "คุณไม่มีเวรปิด",
  youHaveNoData: "คุณไม่มีข้อมูล",
  youHaveNoOpenShifts: "คุณไม่มีเวรที่เปิดอยู่",
  youHaveNoTransactions: "คุณไม่มีรายการทำธุรกรรม",
  youHaveNotAddedAnyCategory: "คุณยังไม่ได้เพิ่มหมวดหมู่ใด ๆ",
  youHaveNotSelectedAnyProducts: "คุณยังไม่ได้เลือกผลิตภัณฑ์ใด ๆ",
  youHaveNotYetCreatedAnyVoucher: "คุณยังไม่ได้สร้างบัตรส่วนลดใด ๆ",
  youHaveNoVoucher: "คุณไม่มีบัตรส่วนลด",
  youHaveNoVoucherCredits: "คุณไม่มีเครดิตบัตรส่วนลด",
  youHaveSelected: "คุณได้เลือก {0}",
  youHaveUnsavedChanges: "คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึกในหน้านี้",
  youllFindYourBookingsHere: "คุณจะพบการจองของคุณที่นี่",
  yourGeneralSettings: "การตั้งค่าทั่วไปของคุณ เช่น ชื่อสถานที่และสกุลเงิน",
  yourMailingIsEmpty: "รายชื่อผู้รับจดหมายของคุณยังว่างอยู่",
  youMustAddAtLeastOneTag: "คุณต้องเพิ่มแท็กอย่างน้อยหนึ่งในการเพิ่มข้อความนี้",
  youMustAddAtLeastOneTicketType: "คุณต้องเพิ่มประเภทตั๋วอย่างน้อยหนึ่งในการสร้างภาพรวมของงานนี้",
  youMustAssignUser: "คุณต้องกำหนดผู้ใช้ที่ต้องการเปลี่ยนสถานะ",
  youMustFillInAllFields: "คุณต้องกรอกข้อมูลในช่องทั้งหมด",
  youMustSetAtLeastOneAccessRule: "คุณต้องตั้งค่ากฏการเข้าถึงอย่างน้อยหนึ่งรายการ",
  youMustSpecifyAValue: "คุณต้องระบุค่า",
  youSure: "แน่ใจหรือไม่?",
  yourBookings: "การจองของคุณ",
  yourBookingWasSuccessfullyCanceled: "การจองของคุณถูกยกเลิกเรียบร้อยแล้ว",
  yourBookingWasSuccessfullyCreated: "การจองของคุณสร้างเรียบร้อยแล้ว",
  yourBookingWasSuccessfullyUpdated: "การจองของคุณได้รับการอัปเดตเรียบร้อยแล้ว",
  yourPasswordHasBeenReset: "รหัสผ่านของคุณได้รับการรีเซ็ตเรียบร้อยแล้ว",
  yourPasswordHasBeenSuccessfullyChanged: "รหัสผ่านของคุณได้รับการเปลี่ยนแปลงเรียบร้อยแล้ว",
  yourQuestion: "คำถามของคุณ",
  yourSessionIsInvalid: "เซสชันของคุณไม่ถูกต้อง",
  yourTagIsNotAllowed: "แท็กของคุณไม่ได้รับอนุญาตให้ใช้งาน",
  yourVenues: "สถานที่ของคุณ",
  yourVoucherHasExpired: "บัตรส่วนลดของคุณหมดอายุแล้ว",
  youveReachedTheEndOfTheList: "คุณมาถึงจุดสิ้นสุดของรายการแล้ว",
  zeroInventory: "สินค้าคงคลังเป็นศูนย์",
  zeroQuantity: "ปริมาณศูนย์",
  zipCode: "รหัสไปรษณีย์",
};
export default Object.freeze(locale);
