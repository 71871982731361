import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React from "react";
import lang from "translations";
import { FragmentB } from "components/fragments";
import EodSummary from "./eod-summary";
import { Title, Pill, Panel, Text, Skeleton, ButtonLink } from "components/commons";
import { PillType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";
import { getEndOfDayReport } from "apis";
import { viewEndOfDayReport } from "mappers";
import styles from "./view-end-of-day.module.scss";
import { formatNumberToMoneyWithCurrencySymbol } from "services";

const ViewEndOfDay = () => {
  const { query } = useRouter();
  const { id } = query || {};
  const transactionTitle = `#${id}`;
  const { request, loading, mappedData, error } = useApi({
    api: getEndOfDayReport,
    params: {
      eodId: id,
    },
    mapper: viewEndOfDayReport,
  });

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.transactionInfoPage,
    });
  });

  const { shiftDate, isOpen, shifts = { ignored: [], shift: [] }, devices = [] } = mappedData;

  const renderShift = (shift) => {
    return shift.map(({ items = [], date }, key) => {
      return (
        <div key={key}>
          <Text className="my-lg" description>
            {date}
          </Text>
          <div>
            {items.map((item, itemIndex) => {
              const { shiftId, name, location, shift, id, closeBalance, expectedCloseBalance } = item || {};
              return (
                <div>
                  <div className="flex justify-between">
                    <div>
                      <ButtonLink newTabPath={Path.REGISTER_REPORT_ID(id)}>{shiftId}</ButtonLink>
                    </div>
                    <div>
                      <Text>{name}</Text>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <Text>{location}</Text>
                    </div>
                    <div>
                      <Text>{shift}</Text>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <Text>{lang.discrepancy}</Text>
                    </div>
                    <div>
                      <Text danger={closeBalance - expectedCloseBalance < 0}>
                        {formatNumberToMoneyWithCurrencySymbol(closeBalance - expectedCloseBalance)}
                      </Text>
                    </div>
                  </div>
                  {itemIndex !== items.length - 1 && (
                    <div className="mt-md mb-md border border-bottom border-gray-lightest"></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          loading={loading}
          title={
            <div className="flex items-center">
              <Title xl>{transactionTitle} </Title>
              {!loading && isOpen && (
                <Pill size="text-xs" className="ml-sm" type={PillType.Green}>
                  {lang.open}
                </Pill>
              )}
            </div>
          }
          description={shiftDate}
          returnPath={Path.END_OF_DAY_REPORT}
          returnText={lang.endOfDayReports}
        ></HeaderB>
      }
    >
      <FragmentB
        sideContent={
          <div>
            <Panel>
              <Title className="mb-md">{lang.shifts}</Title>
              {loading ? (
                <Skeleton />
              ) : (
                <div className={styles.sidePanelContainer}>
                  <div>{renderShift(shifts.shift)}</div>
                  {shifts.ignored.length ? (
                    <div className="mt-lg">
                      <Text strong>{lang.ignoredShiftFromPrevious}</Text>
                      {renderShift(shifts.ignored)}
                    </div>
                  ) : null}
                  {!shifts.ignored.length && !shifts.shift.length ? (
                    <Text description>{lang.noRecords}</Text>
                  ) : null}
                </div>
              )}
            </Panel>

            <Panel>
              <Title className="mb-md">{lang.devices}</Title>
              {loading ? (
                <Skeleton />
              ) : (
                <div className={styles.sidePanelContainer}>
                  {devices.map(({ deviceName, deviceImei }, key) => {
                    return (
                      <div key={key}>
                        <div>
                          <div>
                            <Text>{deviceName}</Text>
                          </div>
                          <div>
                            <Text fontMono>{deviceImei}</Text>
                          </div>
                        </div>
                        {key !== devices.length - 1 && (
                          <div className="mt-md mb-md border border-bottom border-gray-lightest"></div>
                        )}
                      </div>
                    );
                  })}
                  {!devices.length ? <Text description>{lang.noRecords}</Text> : null}
                </div>
              )}
            </Panel>
          </div>
        }
      >
        <EodSummary data={mappedData} loading={loading} />
      </FragmentB>
    </ModuleWrapper>
  );
};

export default ViewEndOfDay;
