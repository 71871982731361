export const STACK_BAR_ACCESS_COLOR = [
  "#ff9999",
  "#66b3ff",
  "#99ff99",
  "#ffcc99",
  "#c2c2f0",
  "#aaccff",
  "#eebbcc",
  "#dcedc1",
  "#f0e68c",
  "#32cd32",
  "#ff4500",
  "#ffd700",
  "#9acd32",
  "#436eee",
  "#e06666",
  "#cc0000",
  "#00b3e6",
  "#990099",
  "#808080",
  "#000000",
];

export const STACK_BAR_GUEST_COLOR = [
  "#d9d9d9",
  "#737373",
  "#4d4d4d",
  "#ccccff",
  "#9999ff",
  "#6666ff",
  "#e0e0e0",
  "#cccccc",
  "#b3b3b3",
  "#c2e0f0",
  "#87ceeb",
  "#4fbae6",
  "#ffe0cc",
  "#ffc799",
  "#ffb366",
  "#ffe5e5",
  "#fcc7c7",
  "#faa3a3",
  "#ffffcc",
  "#ffff99",
];
