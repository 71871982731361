import { Post } from "services";
import { ApiPath } from "paths";

export const searchFreeCreditsReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_FREE_CREDITS_REPORT}`, body);
  return res;
};

export const searchFreeCreditsReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_FREE_CREDITS_REPORT_EXPORT}`, body);
  return res;
};
