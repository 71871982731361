import { DeleteModal } from "components/modals";
import React from "react";
import lang from "translations";
import { deletePaymentMethod } from "apis/payment-method.api";
import { useApi } from "hooks";
import { Text, Title, Toast } from "components/commons";

const DeletePaymentMethodModal = ({ name, active, close, id, goToList }) => {
  const { request, loading } = useApi({
    api: deletePaymentMethod,
    params: {
      paymentMethodId: id,
    },
  });
  return (
    <DeleteModal
      deleting={loading}
      customHeader={
        <Title lg className="mb-md pt-md">
          {lang.populate(lang.deleteName, [name])}
        </Title>
      }
      active={active}
      customContent={<Text>{lang.populate(lang.youAreAboutToDeletePaymentMethod, [name])}</Text>}
      close={close}
      primaryText={lang.deletePaymentMethod}
      ok={async () => {
        await request();
        Toast({
          content: lang.populate(lang.deletedPaymentMethod, [name]),
          success: true,
          icon: "check",
        }).open();
        close();
        goToList();
      }}
    />
  );
};

export default DeletePaymentMethodModal;
