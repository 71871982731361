import { ApiPath } from "paths";
import { Post, Put, Delete } from "services";

export const searchAccessPoint = async (data) => {
  const res = await Post(ApiPath.ACCESS_POINT_SEARCH, data);
  return res;
};

export const createAccessPoint = async (data) => {
  const res = await Post(ApiPath.ACCESS_POINT_BATCH, data);
  return res;
};

export const updateAccessPoint = async (id, data) => {
  const res = await Put(`${ApiPath.ACCESS_POINT}/${id}`, data);
  return res;
};

export const deleteAccessPoint = async (id) => {
  const res = await Delete(`${ApiPath.ACCESS_POINT}/${id}/delete`);
  return res;
};

export const searchAccessControlReport = async (data) => {
  const res = await Post(ApiPath.ACCESS_POINT_SEARCH_REPORT, data);
  return res;
};

export const accessControlReportExport = async (data) => {
  const res = await Post(`${ApiPath.ACCESS_POINT_EXPORT_REPORT}`, data);
  return res;
};
