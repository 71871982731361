import moment from "moment";

export const balanceMovementListFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    venueList: [],
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    startDateTime: null,
    endDateTime: null,
    statusList: [],
  };
};
