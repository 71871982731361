import React, { useCallback, useMemo } from "react";
import {
  Field,
  Filter,
  Icon,
  MultipleSelect,
  RangePicker,
  Select,
  Text,
  TimePicker,
  Tooltip,
} from "components/commons";
import {
  locationWithTransactionFilterResponse,
  locationWithTransactionFilterRequest,
  staffWithTransactionFilterRequest,
  staffWithTransactionFilterResponse,
} from "mappers";
import { searchLocationWithTransaction, searchStaffWithTransaction } from "apis";
import { ExternalPaymentStatus, TapStatus, TransactionStatus, TransactionType } from "enums";
import { prettifyTapStatus, prettifyTransactionStatus, prettifyTransactionType } from "services";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { prettifyExPaymentStatus } from "services/pretty.service";

const TransactionFilter = ({
  filterState,
  requestState,
  modifyFilter,
  applyFilter,
  clearFilter,
}) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: searchLocationWithTransaction,
    isArray: true,
    mapper: locationWithTransactionFilterResponse,
  });

  const {
    request: searchStaffRequest,
    loading: loadingStaff,
    mappedData: staffs,
  } = useApi({
    api: searchStaffWithTransaction,
    isArray: true,
    mapper: staffWithTransactionFilterResponse,
  });

  useMount(() => {
    fetchLocationRequest(requestState);
    fetchStaffRequest(requestState);
  });

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, locationWithTransactionFilterRequest));
    },
    [searchLocationRequest]
  );

  const fetchStaffRequest = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject({ ...requestState }, staffWithTransactionFilterRequest));
    },
    [searchStaffRequest]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocationRequest(requestState);
      fetchStaffRequest(requestState);
    },
    [fetchLocationRequest, fetchStaffRequest, modifyFilter]
  );

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const staffOptions = useMemo(() => {
    return mapObjectsToSelect(staffs, { textKey: "name" });
  }, [staffs]);

  const transactionTypeOptions = useMemo(() => {
    const transactionType = [
      TransactionType.SALE,
      TransactionType.TOPUP,
      TransactionType.RETURN,
      TransactionType.REDEEM,
      TransactionType.MIXED,
      TransactionType.ISSUE_FOC,
      TransactionType.REMOVE_FOC,
      TransactionType.VOUCHER_ISSUE,
      TransactionType.VOUCHER_REMOVE,
    ];
    const t = transactionType.map((type) => {
      return { text: prettifyTransactionType(type), value: type };
    });
    return t;
  }, []);

  const transactionStatusOptions = useMemo(() => {
    const transactionStatus = [TransactionStatus.SUCCESS, TransactionStatus.VOID];
    const prettyTransactionStatus = transactionStatus.map((type) => {
      return { text: prettifyTransactionStatus(type), value: type };
    });
    return [{ text: lang.allStatus, value: null }, ...prettyTransactionStatus];
  }, []);

  const tapStatusOptions = useMemo(() => {
    const tapStatus = [TapStatus.Success, TapStatus.Failed];
    const prettyTapStatus = tapStatus.map((status) => {
      return { text: prettifyTapStatus(status), value: status };
    });
    return [{ text: lang.allStatus, value: null }, ...prettyTapStatus];
  }, []);

  const exPaymentStatusOptions = useMemo(() => {
    const statusArr = [
      ExternalPaymentStatus.Success,
      ExternalPaymentStatus.Failed,
      ExternalPaymentStatus.Pending,
      ExternalPaymentStatus.Timeout,
    ];
    const prettyStatus = statusArr.map((status) => ({
      text: prettifyExPaymentStatus(status),
      value: status,
    }));
    return [...prettyStatus, { text: lang.na, value: "NONE" }];
  }, []);

  return (
    <Filter
      placeholder={lang.searchTransactionPlaceholder}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      filterState={filterState}
      actionsSpan={12}
    >
      <Field className="col-span-4" filterLabel={lang.dateRange}>
        <RangePicker name="dateRange" onChange={changeDateRangeCb} value={filterState.dateRange} />
      </Field>
      <Field className="col-span-4" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            disabled={!filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0].set({
                    hour: startTime.get("hour"),
                    minute: startTime.get("minute"),
                  }),
                  filterState.dateRange[1],
                ],
              });
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            {lang.to}
          </Text>
          <TimePicker
            disabled={!filterState.dateRange[0]}
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0],
                  filterState.dateRange[1].set({
                    hour: endTime.get("hour"),
                    minute: endTime.get("minute"),
                  }),
                ],
              });
            }}
          />
        </div>
      </Field>
      <Field className="col-span-4" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocation}
          options={locationOptions}
          value={filterState.locations}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          emptyPlaceHolder={lang.allLocations}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.staff}>
        <MultipleSelect
          name="staffs"
          selectAllText={lang.allStaff}
          loading={loadingStaff}
          options={staffOptions}
          value={filterState.staffs}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          emptyPlaceHolder={lang.allStaff}
          placeholder={!locationOptions.length ? lang.noAvailableStaff : lang.selectStaff}
        />
      </Field>
      <Field className="col-span-2" filterLabel={lang.transactionType}>
        <MultipleSelect
          name="transactionTypes"
          selectAllText={lang.allTransactions}
          options={transactionTypeOptions}
          value={filterState.transactionTypes}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          emptyPlaceHolder={lang.allTransactions}
        />
      </Field>
      <Field className="col-span-2" filterLabel={lang.transactionStatus}>
        <Select
          name="transactionStatus"
          options={transactionStatusOptions}
          value={filterState.transactionStatus}
          onChange={modifyFilter}
        />
      </Field>
      <Field className="col-span-2" filterLabel={lang.nfcStatus}>
        <Select
          name="tapStatus"
          options={tapStatusOptions}
          value={filterState.tapStatus}
          onChange={modifyFilter}
        />
      </Field>
      <Field
        className="col-span-2"
        filterLabel={lang.externalPaymentStatus}
        labelSuffix={
          <div className="ml-1 flex-1">
            <Tooltip
              title={
                <Text className="text-center" color="text-white">
                  {lang.thisIsForDirectIntegrationWithPaymentTerminalPartner}
                </Text>
              }
              placement="top"
            >
              <div className="w-min">
                <Icon name="info" fontSize="14px" color="text-gray-light" />
              </div>
            </Tooltip>
          </div>
        }
      >
        <MultipleSelect
          name="externalPaymentStatus"
          options={exPaymentStatusOptions}
          value={filterState.externalPaymentStatus}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          selectAllText={lang.allStatus}
          emptyPlaceHolder={lang.allStatus}
        />
      </Field>
    </Filter>
  );
};

export default TransactionFilter;
