import React, { useCallback, useContext, useMemo } from "react";
import { searchVoucher } from "apis";
import { Pill, Text, DataTable, ButtonLink } from "components/commons";
import { VenueContext } from "contexts";
import { PillType, VoucherStatus } from "enums";
import {
  useApi,
  useFilter,
  useMount,
  // useRouter
} from "hooks";
import { voucherListResponse, voucherListFilterRequest } from "mappers";
import { mapObject, prettifyVoucherStatus } from "services";
import columns from "./columns";
import { Path } from "paths";
import { ArtOctopus } from "images";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const VoucherList = (props) => {
  const { venue } = useContext(VenueContext);
  // const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchVoucherRequest,
    loading: loadingVoucher = true,
    result: searchVoucherResult = { data: [], metadata: { total: 0, voucherTotalLimit: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchVoucher,
    isArray: true,
    mapper: voucherListResponse,
    handleOwnError: true,
  });

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
    sort: { key: "dateCreated", value: "desc" },
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.voucher === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.voucher !== getState().name)
      setFilterState({
        name: filterName.voucher,
        filter: {},
      });
    fetchVouchers(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.vouchersListPage,
    });
  });

  const fetchVouchers = useCallback(
    async (requestState) => {
      await searchVoucherRequest(mapObject(requestState, voucherListFilterRequest));
    },
    [searchVoucherRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState) => {
      setFilterState({
        name: filterName.voucher,
        filter: requestState,
      });
      fetchVouchers(requestState);
    },
    [fetchVouchers, setFilterState]
  );

  const renderVoucher = useCallback(
    (name, id) => (
      <ButtonLink
        className="text-left"
        // onClick={() => history.push(Path.VOUCHER_ID(id))}
        path={Path.VOUCHER_ID(id)}
      >
        {name}
      </ButtonLink>
    ),
    []
  );

  const renderStatus = useCallback((status) => {
    if (status === VoucherStatus.Active) {
      return (
        <Pill type={PillType.Green} size="text-xs">
          {prettifyVoucherStatus(status)}
        </Pill>
      );
    }
    if (status === VoucherStatus.Inactive) {
      return (
        <Pill type={PillType.Opaque} size="text-xs">
          {prettifyVoucherStatus(status)}
        </Pill>
      );
    }
    return (
      <Pill type={PillType.Gray} size="text-xs">
        {prettifyVoucherStatus(status)}
      </Pill>
    );
  }, []);

  const renderDateCreated = useCallback((dateCreated) => {
    return (
      <div>
        <Text>{dateCreated?.date}</Text>
        {dateCreated?.time && <Text color="text-gray">{dateCreated.time}</Text>}
      </div>
    );
  }, []);

  const prepareVoucherList = useCallback(() => {
    return mappedData.map((voucher) => {
      const { id, name, status, dateCreated } = voucher;

      return {
        voucher: renderVoucher(name, id),
        status: renderStatus(status),
        dateCreated: renderDateCreated(dateCreated),
      };
    });
  }, [mappedData, renderVoucher, renderStatus, renderDateCreated]);

  const vouchers = useMemo(() => {
    return prepareVoucherList();
  }, [prepareVoucherList]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  return (
    <DataTable
      onLoad={props.getData(searchVoucherResult.metadata, loadingVoucher)}
      page={filterState.page}
      pageSize={filterState.pageSize}
      onChangePage={modifyFilters}
      fetchList={fetchUpdateStore}
      total={searchVoucherResult.metadata.total}
      loading={loadingVoucher}
      columns={columns}
      data={vouchers}
      error={error}
      sort={filterState.sort}
      setSort={sortCb}
      minWidth="900px"
      renderEmpty={{
        image: ArtOctopus,
        title: lang.createVouchersForGuest,
      }}
    />
  );
};

export default VoucherList;
