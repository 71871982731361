// import { prettifyTimeUnit } from "services";
import { AccountType } from "enums";
import { Text } from "components";
import lang from "translations";

const accountTypes = [
  { text: lang.asset, value: AccountType.Asset },
  { text: lang.liability, value: AccountType.Liability },
  { text: lang.equity, value: AccountType.Equity },
  { text: lang.revenue, value: AccountType.Revenue },
  { text: lang.expense, value: AccountType.Expense },
  { text: lang.unknown, value: AccountType.Unknown },
];

export const getAccountingType = (accountCode) => {
  switch (String(accountCode).charAt(0)) {
    case "1":
      return AccountType.Asset;
    case "2":
      return AccountType.Liability;
    case "3":
      return AccountType.Equity;
    case "4":
      return AccountType.Revenue;
    case "5":
      return AccountType.Expense;
    default:
      return AccountType.Unknown;
  }
};

export const mapAccountOptions = (accountListMappedData) => {
  return accountListMappedData?.map((account) => {
    return {
      text: `${account.code ?? "[xxxx]"} - ${account.accountName}`,
      value: account.accountCodeId,
      group: getAccountingType(account.code),
    };
  });
};

export const mapMewsTaxRatesOptions = (mappedMewsTaxRates) => {
  return mappedMewsTaxRates?.map((taxRate) => {
    return {
      text: `${taxRate.name} - ${taxRate.value.toFixed(2)}%`,
      value: taxRate.code,
      percentage: taxRate.value.toFixed(2),
    };
  });
};

export const groupAccountOptions = (accountOptions, suggested, showSuggestedLabel = true) => {
  let groupedAccountOptions = [];

  accountTypes.forEach((accountType) => {
    let newArray = [];
    // add options in the group
    accountOptions?.forEach((option) => {
      if (option && accountType.value === option.group) {
        newArray.push(option);
      }
    });
    // add group in the Select Option
    if (newArray.length !== 0) {
      if (accountType.value === suggested) {
        groupedAccountOptions.push({
          label: (
            <Text className="ml-sm text-gray-lighter">
              {`${accountType.text} ${lang.accounts} `}
              {showSuggestedLabel && (
                <span className={"text-sm text-pelorous"}>{`(${lang.suggested})`}</span>
              )}
            </Text>
          ),
          options: newArray,
          suggested: true,
        });
      } else {
        groupedAccountOptions.push({
          label: (
            <Text className="ml-sm text-gray-lighter">{`${accountType.text} ${lang.accounts} `}</Text>
          ),
          options: newArray,
        });
      }
    }
  });

  let suggestedObj = [];

  // set the suggested accounts to the first group of options
  groupedAccountOptions.forEach((groupedAccount, index) => {
    if (groupedAccount.suggested) {
      suggestedObj = groupedAccount;
      groupedAccountOptions.splice(index, 1);
      groupedAccountOptions.unshift(suggestedObj);
    }
  });

  return groupedAccountOptions;
};
