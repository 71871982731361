import React from "react";
import ChartContainer from "../chart-container";
import { MultiBarStackChart } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import lang from "translations";

const TotalSalesLineChart = ({ ...props }) => {
  const data = props.mappedData;
  const chartColors = ["#FDC02F", "#FED45B", "#2A8BE6", "#4CA7FC", "#0F0", "#DEEEFC"];
  console.log("TotalSalesLineChart", data);

  return (
    <ChartContainer
      {...props}
      empty={!data.creditSales}
      emptyText={lang.noSalesInDateRange}
      title={lang.salesOverTime}
    >
      <div className="pb-4" />
      <MultiBarStackChart
        showLegend={false}
        isLegendPointStyle={true}
        checkBeginAtZero={true}
        chartColors={chartColors}
        height={330}
        {...data.data}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value);
        }}
      />
    </ChartContainer>
  );
};

export default TotalSalesLineChart;
