import { searchAccessPoint } from "apis/accessPoint";
import { VenueContext } from "contexts";
import { useApi, useMount } from "hooks";
import { useContext } from "react";

export const useList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { request, loading, error, mappedData } = useApi({
    api: searchAccessPoint,
    isArray: true,
    mapper: { _keys: ["id", "guestProfiles"], name: { key: "accessPointName" } },
    handleOwnError: true,
    params: { venueId },
  });

  useMount(() => {
    request();
  });

  return {
    request,
    loading,
    error,
    mappedData,
  };
};
