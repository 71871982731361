import lang from "translations";

export const freeCreditsReportColumns = [
  {
    key: "name",
    text: lang.guest,
  },
  {
    key: "type",
    text: lang.type,
  },
  {
    key: "creditsAmount",
    text: lang.amount,
  },
  {
    key: "staff",
    text: lang.staff,
  },
  {
    key: "transactionDate",
    text: lang.date,
  },
  {
    key: "locationName",
    text: lang.location,
  },
];
