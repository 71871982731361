import {
  ActionButton,
  AlertMessage,
  ButtonLink,
  Field,
  Input,
  Modal,
  Text,
  Toast,
  Select,
  Image,
  Form
} from "components/commons";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import { AlertType, Field as FieldType, IntegrationType } from "enums";
import { useApi, useForm, useModal } from "hooks";
import { activateMews, editTokenMews } from "apis/integration.api";
import { VenueContext } from "contexts";
import { openInNewTab } from "services/url.service";
import classNames from "classnames";
import styles from "./connect-modal.module.scss";
import { activateCloudBeds, syncCloudBeds, activatePurchasePlus, deactivateMews, setVenue, getMewsOutlet } from "apis";
import { PurchasePlusLogo } from "images";
import Validation from "services/validation.service";
import { venueRequest } from "mappers/venue.mapper";
import { outletMapper } from "mappers/outlet.mapper";
import { useEffect } from "react";
import { getMewsService } from "apis/outlet.api";
import { activateApplication } from "apis/application.api";

const ConnectModal = ({
  title = "",
  instruction,
  guideLink,
  modifyField: modifyConnection,
  onEdit = false,
  accessToken,
  appId,
  hostname,
  username,
  password,
  directory,
  request,
  integrationId,
  integrationType,
  disableAccessToken,
  mappedOutlets,
  mappedServices,
  onOk,
  outletRequest: outletRequest2,
  serviceRequest: serviceRequest2,
  ...props
}) => {
  const { venue, fetchVenue } = useContext(VenueContext);
  const { venueId } = venue || {};

  const [isInvalid, setIsInvalid] = useState(false);
  const [sftpAuthFail, setSftpAuthFail] = useState(false);
  const [sftpNoFile, setSftpNoFile] = useState(false);
  const [showOutletFields, setShowOutletFields] = useState(false);

  const unsaveChangesModal = useModal();

  const initialState = useMemo(() => {

    const { defaultMewsOutletId, mewsCashBackOutletId, mewsTopUpOutletId, mewsCreditsReceivedOutletId, mewsCreditsKeptOutletId, defaultMewsServiceId } = venue;

    return {
      accessToken: {
        name: "accessToken",
        value: accessToken || null,
        type: FieldType.INPUT,
        label: lang.accessToken,
        // validations: [Validation.required()],
        placeholder: "667CCCE510874DB5A74EAD810067A286-E06E376C2277323CDEC2AC1533C58F6",
      },
      defaultMewsOutletId: {
        name: "defaultMewsOutletId",
        value: defaultMewsOutletId,
        type: FieldType.DROPDOWN,
        label: "Sales Outlet",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      mewsTopUpOutletId: {
        name: "mewsTopUpOutletId",
        value: mewsTopUpOutletId,
        type: FieldType.DROPDOWN,
        label: "Top-up Outlet",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      mewsCashBackOutletId: {
        name: "mewsCashBackOutletId",
        value: mewsCashBackOutletId,
        type: FieldType.DROPDOWN,
        label: "Return Credits Outlet",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      mewsCreditsReceivedOutletId: {
        name: "mewsCreditsReceivedOutletId",
        value: mewsCreditsReceivedOutletId,
        type: FieldType.DROPDOWN,
        label: "Credits Received Outlet",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      mewsCreditsKeptOutletId: {
        name: "mewsCreditsKeptOutletId",
        value: mewsCreditsKeptOutletId,
        type: FieldType.DROPDOWN,
        label: "Credits Kept Outlet",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      defaultMewsServiceId: {
        name: "defaultMewsServiceId",
        value: defaultMewsServiceId,
        type: FieldType.DROPDOWN,
        label: "Linked Service",
        required: true,
        validations:
          showOutletFields || (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ? [
            Validation.required(),
          ] : []
      },
      hostname: {
        name: "hostname",
        value: hostname || "",
        type: FieldType.INPUT,
        label: lang.hostname,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      username: {
        name: "username",
        value: username || "",
        type: FieldType.INPUT,
        label: lang.username,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      password: {
        name: "password",
        value: password || "",
        type: FieldType.INPUT,
        inputType: "password",
        label: lang.password,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      directory: {
        name: "directory",
        value: directory || "",
        type: FieldType.INPUT,
        label: lang.directory,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      }
    };



  }, [accessToken, directory, hostname, password, username, title, venue, integrationType, onEdit, showOutletFields]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState,
  });

  const activateMewsRequest = useApi({
    api: activateMews,
    params: {
      businessId: venueId,
      integrationType: integrationType,
    },
  });

  const activateCloudBedsRequest = useApi({
    api: activateCloudBeds,
    params: {
      venueId,
    },
  });

  const activatePurchasePlusRequest = useApi({
    api: activatePurchasePlus,
    params: {
      venueId,
      directory,
      hostname,
      username,
      password,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const syncCloudBedsRequest = useApi({
    api: syncCloudBeds,
    params: {
      businessId: venueId,
      enterpriseId: 19423,
    },
  });

  const editTokenMewsRequest = useApi({
    api: editTokenMews,
    params: {
      businessId: venueId,
      integrationType: integrationType,
    },
  });

  const requestDeactivateMews = useApi({
    api: deactivateMews,
    params: { id: integrationId, integrationType, venueId },
  });

  const {
    request: outletRequest,
    mappedData: getMappedOutlets,
  } = useApi({
    api: getMewsOutlet,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  const {
    request: serviceRequest,
    mappedData: getMappedServices,
  } = useApi({
    api: getMewsService,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  const activateApp = useApi({
    api: activateApplication,
  });

  const submitFormValue = useCallback(
    async (params) => {
      try {
        let res = {};
        if (
          integrationType && integrationType.indexOf('MEWS') > -1
        ) {
          const r = onEdit ? editTokenMewsRequest : activateMewsRequest;
          res = await r.request({
            token: params.accessToken.value,
          });
        } else if (integrationType === IntegrationType.CLOUDBEDS) {
          res = await activateCloudBedsRequest.request({
            token: params.accessToken.value,
          });
          await syncCloudBedsRequest.request({
            enterpriseId: 19423,
            venueId: venueId,
          });
        } else if (integrationType === IntegrationType.PURCHASE_PLUS) {
          try {
            setSftpAuthFail(false);
            setSftpNoFile(false);
            if (onEdit) {
              res = await activatePurchasePlusRequest.request({
                directory: params.directory.value,
                hostname: params.hostname.value,
                username: params.username.value,
                password: params.password.value,
              });
              if (
                params.hostname.value ||
                params.username.value ||
                params.password.value ||
                params.directory.value
              ) {
                res = await requestDeactivateMews.request({
                  id: integrationId,
                  integrationType: IntegrationType.PURCHASE_PLUS,
                  venueId: venueId,
                });
                res = await activatePurchasePlusRequest.request({
                  directory: params.directory.value,
                  hostname: params.hostname.value,
                  username: params.username.value,
                  password: params.password.value,
                });
              } else {
                res = await activatePurchasePlusRequest.request({
                  directory: params.directory.value,
                  hostname: params.hostname.value,
                  username: params.username.value,
                  password: params.password.value,
                });
              }
            } else {
              res = await activatePurchasePlusRequest.request({
                directory: params.directory.value,
                hostname: params.hostname.value,
                username: params.username.value,
                password: params.password.value,
              });
            }
          } catch ({ code, handleError }) {
            setIsInvalid(true);
            const err = {
              3109: () => {
                setSftpAuthFail(true);
              },
              3110: () => {
                setSftpNoFile(true);
              },
            };
            if (err[code]) {
              err[code]();
            } else {
              handleError();
            }
          }
        } else if (integrationType === IntegrationType.PROPERTY_MANAGEMENT_SYSTEM) {
          const r = onEdit ? activateApp : activateApp;
          res = await r.request({
            id: appId,
            body: {
              businessId: venueId,
              businessType: "VENUE",
              accessToken: params.accessToken.value,
            },
          });
        }

        if (res.data) {
          modifyConnection("isConnected", { value: true });
          if (integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1) {
            outletRequest();
            serviceRequest();
            setShowOutletFields(true);
          } else {
            props.close();
            Toast({
              content: lang.populate(lang.xConnectedSuccessfully, [title]),
              success: true,
              icon: "check",
            }).open();
            if (request) {
              request.request();
            }
          }

        } else {
          applyFieldErrors({
            accessToken: lang.xConnectedFailed,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [
      modifyConnection,
      props,
      title,
      activateMewsRequest,
      activateApp,
      venueId,
      appId,
      applyFieldErrors,
      request,
      onEdit,
      editTokenMewsRequest,
      activateCloudBedsRequest,
      syncCloudBedsRequest,
      activatePurchasePlusRequest,
      integrationType,
      integrationId,
      requestDeactivateMews,
      outletRequest,
      serviceRequest
    ]
  );

  const headerWithLogo = useMemo(() => {
    return onEdit ? (
      <Text className="font-semibold text-lg ml-2">{lang.editSFTPDetails}</Text>
    ) : (
      <div>
        <div className="flex items-center justify-start">
          <div className="pr-2">
            <Image className="w-20" src={PurchasePlusLogo} />
          </div>
          <div>
            <Text className="font-semibold text-lg">{lang.purchaseplus}</Text>
            <Text color="text-gray" className="text-xs leading-4">
              {lang.inventoryManagement}
            </Text>
          </div>
        </div>
      </div>
    );
  }, [onEdit]);

  const header = useMemo(() => {
    if (onEdit) {
      if (showOutletFields || (integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit)) {
        return <Text className="font-semibold text-lg ml-sm">MEWS Accounting Integration Requirements</Text>
      } else {
        return <Text className="font-semibold text-lg ml-sm">{lang.editAccessToken}</Text>
      }
    } else {
      return <Text className="font-semibold text-lg ml-sm">{`${title} Integration`}</Text>
    }
  }, [onEdit, title, showOutletFields, integrationType]);

  useEffect(() => {
    if (integrationType && integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) {
      outletRequest();
      serviceRequest();
      setShowOutletFields(true);
    }
    // eslint-disable-next-line
  }, [onEdit, integrationType])

  const outletOptions = useMemo(() => {
    const outlets = getMappedOutlets || mappedOutlets;
    const activeOutlets = outlets.filter((outlet) => outlet.active);
    return activeOutlets?.map((outlet) => {
      const { outletId, outletName } = outlet;
      return { value: outletId, text: outletName };
    });
  }, [mappedOutlets, getMappedOutlets]);

  const serviceOptions = useMemo(() => {
    const outlets = getMappedServices || mappedServices;
    const activeOutlets = outlets.filter((outlet) => outlet.active);
    return activeOutlets?.map((outlet) => {
      const { outletId, outletName } = outlet;
      return { value: outletId, text: outletName };
    });
  }, [mappedServices, getMappedServices]);

  const { request: updateVenueRequest, loading: submittingVenueRequest } = useApi({
    api: setVenue,
    paramsMapper: venueRequest,
    params: {
      venueId: venue.venueId,
    },
    handleOwnError: {
      badrequest: false,
    },
  });

  const submitOutletFields = useCallback(async () => {
    try {
      const values = getFormValues();
      await updateVenueRequest({ ...venue, ...values });
      await fetchVenue();
      await outletRequest2();
      await serviceRequest2();
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      onOk();
      props.close();
    } catch (error) {
      console.log(error)
    }
  }, [getFormValues, updateVenueRequest, props, venue, onOk, outletRequest2, serviceRequest2, fetchVenue]);

  return (
    <Modal
      width={600}
      style={{ top: 20 }}
      {...props}
      close={() => {
        setIsInvalid(false);
        setSftpAuthFail(false);
        setSftpNoFile(false);
        props.close();
      }}
      customTitle={integrationType === IntegrationType.PURCHASE_PLUS ? headerWithLogo : header}
    >
      <div className="p-sm pt-0">
        {integrationType === IntegrationType.PURCHASE_PLUS ? (
          <>
            {!onEdit && (
              <div>
                <div className="mb-2">
                  <Text className="font-semibold text-md leading-6">
                    {lang.connectPurchasePlus}
                  </Text>
                </div>
                <div className="pb-4">
                  <Text color="text-gray" className="text-base leading-6 font-semibold">
                    {lang.pleaseEnterYourSFTP}
                  </Text>
                </div>
              </div>
            )}

            {isInvalid && (
              <AlertMessage
                type={AlertType.Error}
                message={lang.credentialsAreInvalid}
                showIcon={true}
                icon="info"
                className="mb-lg"
              />
            )}

            <Field {...fields.hostname}>
              <Input
                {...fields.hostname}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.username}>
              <Input
                {...fields.username}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.password}>
              <Input
                {...fields.password}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.directory}>
              <Input
                {...fields.directory}
                onChange={modifyField}
                className={sftpNoFile ? "border-red" : "border-black"}
              />
            </Field>
          </>
        ) : (
          !showOutletFields && integrationType && !(integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ?
            <Field {...fields.accessToken}>
              <Input
                {...fields.accessToken}
                disabled={activateMewsRequest.loading || disableAccessToken}
                onChange={modifyField}
              />
            </Field> : null
        )}
        {integrationType === IntegrationType.PURCHASE_PLUS ? null : (
          !showOutletFields && integrationType && !(integrationType.indexOf(IntegrationType.MEWS_ACCOUNTING) > -1 && onEdit) ?
            <>
              {instruction}
              {guideLink && (
                <ButtonLink
                  className={classNames("mt-sm underline text-pelorous", styles.button)}
                  onClick={() => {
                    openInNewTab(guideLink);
                  }}
                >
                  {lang.checkOutTheGuide}
                </ButtonLink>
              )}
            </> :

            <Form unsaveChangesModal={unsaveChangesModal} >
              <p className="text-sm mb-xs text-gray">Edit Outlets and Service</p>
              <p className="text-sm mb-sm">All transactions are synced to specific outlets in MEWS depending on the transaction type.
                We recommend you to create fresh new outlets specific to transactions made through the app. To find out how to create outlets in MEWS, follow "Create an outlet" step <a href="https://help.mews.com/s/article/set-up-outlets?language=en_US#link1" target="_blank" rel="noreferrer" className="text-pelorous text-sm">from this article</a>.</p>

              <Field {...fields.defaultMewsOutletId}>
                <Select
                  {...fields.defaultMewsOutletId}
                  options={outletOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Sales Outlet"
                  onClick={() => { outletRequest() }}
                  disabled={!outletOptions || outletOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>

              <Field {...fields.mewsTopUpOutletId}>
                <Select
                  {...fields.mewsTopUpOutletId}
                  options={outletOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Top-up Outlet"
                  onClick={() => { outletRequest() }}
                  disabled={!outletOptions || outletOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>

              <Field {...fields.mewsCashBackOutletId}>
                <Select
                  {...fields.mewsCashBackOutletId}
                  options={outletOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Return Credits Outlet"
                  onClick={() => { outletRequest() }}
                  disabled={!outletOptions || outletOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>

              <Field {...fields.mewsCreditsReceivedOutletId}>
                <Select
                  {...fields.mewsCreditsReceivedOutletId}
                  options={outletOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Credits Received Outlet"
                  onClick={() => { outletRequest() }}
                  disabled={!outletOptions || outletOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>

              <Field {...fields.mewsCreditsKeptOutletId}>
                <Select
                  {...fields.mewsCreditsKeptOutletId}
                  options={outletOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Credits Kept Outlet"
                  onClick={() => { outletRequest() }}
                  disabled={!outletOptions || outletOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>

              <p className="text-sm mb-sm mt-lg">All transactions that are synced needs to be linked to an "Additional" Service created in MEWS. We recommend you to
                create a fresh new service to associate transactions made through the app. To find out how to create an additional service in MEWS, follow "Create an additional service" step <a href="https://help.mews.com/s/article/create-or-delete-an-additional-service?language=en_US#link1" target="_blank" rel="noreferrer" className="text-pelorous text-sm">from this article</a>.</p>

              <Field {...fields.defaultMewsServiceId}>
                <Select
                  {...fields.defaultMewsServiceId}
                  options={serviceOptions}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder="Select Service"
                  onFocus={() => { serviceRequest() }}
                  disabled={!serviceOptions || serviceOptions.length === 0}
                // loading={loadingOutlets}
                />
              </Field>
            </Form>
        )}

        <ActionButton
          className="py-md"
          loading={
            activateMewsRequest.loading ||
            editTokenMewsRequest.loading ||
            activatePurchasePlusRequest.loading
          }
          secondary={
            {
              text: lang.cancel,
              onClick: () => {
                setIsInvalid(false);
                setSftpAuthFail(false);
                setSftpNoFile(false);

                if (showOutletFields && dirty) {
                  unsaveChangesModal.show({
                    ok: async () => {
                      await unsaveChangesModal.close();
                      props.close();
                    },
                  });
                } else {
                  props.close();
                }
              },
            }
          }
          primary={!showOutletFields ? {
            text: onEdit ? lang.reconnect : lang.connectAndInstall,
            onClick: () => {
              submitForm(submitFormValue);
            },
            disabled: title !== lang.purchaseplus ? !fields.accessToken.value : false,
          } : {
            text: onEdit ? "Update Integration" : "Finish Integration",
            onClick: () => {
              submitForm(submitOutletFields);
            },
            disabled: submittingVenueRequest || (onEdit && !dirty),
            loading: submittingVenueRequest
          }
          }
        />
      </div>
    </Modal >
  );
};

export default ConnectModal;
