const shiftPaths = {
  SHIFT: "",
  SEARCH_SHIFT: "search",
  SEARCH_SHIFT_EXPORT: "search/export",
  SHIFT_ID: (id) => id,
  EXPORT_EOD_SHIFT: (id) => `${id}/eod/export`,
  SEARCH_SHIFT_LOCATION: "location/search",
  SEARCH_SHIFT_STAFF: "staff/search",
  SEARCH_SHIFT_DEVICE: "device/search",
  CLOSE_SHIFT: (id) => `close/${id}`,
};

export default shiftPaths;
