import enumeration from "./enumeration";

const TransactionType = {
  TOPUP: "TOPUP",
  SALE: "SALE",
  RETURN: "RETURN",
  MIXED: "MIXED",
  REDEEM: "REDEEM",
  REMOVE: "VOUCHER_REMOVE",
  ISSUE: "VOUCHER_ISSUE",
  ISSUE_FOC: "ISSUE_FOC",
  REMOVE_FOC: "REMOVE_FOC",
  VOUCHER_ISSUE: "VOUCHER_ISSUE",
  VOUCHER_REMOVE: "VOUCHER_REMOVE",
};

export default enumeration(TransactionType);
