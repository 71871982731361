import reportStatus from "enums/report-status";
import React from "react";
import styles from "./status-icon.module.scss";
import { useMemo } from "react";

const StatusIcon = ({ type = reportStatus.done, onClear = undefined, disableCanceling }) => {
  const hasClear = useMemo(() => {
    let ret = type !== reportStatus.done;
    if (type === reportStatus.processing) {
      ret = !disableCanceling;
    }
    return ret;
  }, [disableCanceling, type]);

  return (
    <div className={styles.wrapper}>
      {type === reportStatus.done ? (
        <svg
          className={`status-icon done ${styles.doneIcon}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.33333 0 0 5.33333 0 12C0 18.6667 5.33333 24 12 24C18.6667 24 24 18.6667 24 12C24 5.33333 18.6667 0 12 0ZM18.2667 8.93333L10.9333 16.9333C10.6667 17.2 10.2667 17.3333 10 17.3333C9.73333 17.3333 9.33333 17.2 9.06667 16.9333L5.73333 13.3333C5.2 12.8 5.33333 12 5.86667 11.4667C6.4 10.9333 7.2 11.0667 7.73333 11.6L10 14.1333L16.4 7.2C16.9333 6.66667 17.7333 6.66667 18.2667 7.06667C18.8 7.46667 18.8 8.4 18.2667 8.93333Z"
            fill="#78CC44"
          />
        </svg>
      ) : type === reportStatus.failed ? (
        <svg
          className={`status-icon failed ${styles.failedIcon}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 13.2C11.34 13.2 10.8 12.66 10.8 12V7.19999C10.8 6.53999 11.34 6 12 6C12.66 6 13.2 6.53999 13.2 7.19999V12C13.2 12.66 12.66 13.2 12 13.2ZM13.2 16.8C13.2 17.4628 12.6627 18 12 18C11.3373 18 10.8 17.4628 10.8 16.8C10.8 16.1373 11.3373 15.6 12 15.6C12.6627 15.6 13.2 16.1373 13.2 16.8Z"
            fill="#E02D32"
          />
        </svg>
      ) : (
        <svg
          className={`status-icon processing animate-spin ${styles.processingIcon}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10.5" stroke="#7DD9A3" strokeWidth="3" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.1767 24 23.2635 19.3333 23.9268 13.3333H20.9019C20.2577 17.6716 16.5175 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3V0Z"
            fill="#BDEACF"
          />
        </svg>
      )}

      {hasClear ? (
        <svg
          onClick={onClear}
          className={`status-icon cursor-pointer ${styles.clearIcon}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
          <path
            d="M17.25 7.8075L16.1925 6.75L12 10.9425L7.8075 6.75L6.75 7.8075L10.9425 12L6.75 16.1925L7.8075 17.25L12 13.0575L16.1925 17.25L17.25 16.1925L13.0575 12L17.25 7.8075Z"
            fill="#ffffff"
          />
        </svg>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default StatusIcon;
