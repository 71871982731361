import React, { useCallback, useContext, useEffect, useState } from "react";
import lang from "translations";
import AccessControlReportFilter from "./filter";
import { HeaderA, Icon, ModuleWrapper, Panel } from "components";
import { useApi, useFilter, useMount } from "hooks";
import {
  accessControlReportExport,
  searchAccessControlReport,
  searchAccessPoint,
} from "apis/accessPoint";
import { AppContext, VenueContext } from "contexts";
import moment from "moment";
import { TimeUnit, ViewReportAccessControl } from "enums";
import StackBarGraph from "components/commons/stack-bar-graph";
import { toStackBarChartAccessPointData } from "services/chart.service";
import { searchGuestProfile } from "apis/guest-profile.api";
import { toApiDateTimeFormat } from "services";
import timeUnit from "enums/time-unit";

const AccessControlReport = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { setGlobalLoading } = useContext(AppContext);
  const [data, setData] = useState({});
  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter(
    defaultFilterState(venue.venueId)
  );

  const { request: exportRequest, loading: exportLoading } = useApi({
    api: accessControlReportExport,
  });

  const {
    loading: accessPointLoading,
    mappedData: accessPointData,
    request: accessPointRequest,
  } = useApi({
    api: searchAccessPoint,
    isArray: true,
    params: { venueId },
    mapper: { text: { key: "accessPointName" }, value: { key: "id" } },
  });

  const {
    loading: guestProfileLoading,
    mappedData: guestProfileData,
    request: guestProfileRequest,
  } = useApi({
    api: searchGuestProfile,
    isArray: true,
    params: { venueId },
    mapper: { text: { key: "guestProfileName" }, value: { key: "guestProfileId" } },
  });

  const state2Params = useCallback(
    (requestState) => {
      let intervalType = timeUnit.Hour;
      const interval = requestState.intervalType;
      if (interval === timeUnit.Day || interval === timeUnit.Week || interval === timeUnit.WeekS) {
        intervalType = timeUnit.Day;
      } else if (interval === timeUnit.Month || interval === timeUnit.Quarter) {
        intervalType = timeUnit.Month;
      }
      const params = {
        ...requestState,
        startDateTime: toApiDateTimeFormat(requestState.dateRange[0]),
        endDateTime: toApiDateTimeFormat(requestState.dateRange[1], true),
        intervalType,
      };
      if (params.viewBy === ViewReportAccessControl.Access) {
        if (!params.accessPointIds.length)
          params.accessPointIds = accessPointData.map(({ value }) => value);
        else params.accessPointIds = params.accessPointIds.map(({ value }) => value);
      } else {
        if (!params.guestProfileIds.length)
          params.guestProfileIds = guestProfileData.map(({ value }) => value);
        else params.guestProfileIds = params.guestProfileIds.map(({ value }) => value);
      }

      // delete params.viewBy;
      delete params.dateRange;
      delete params.locationState;

      return params;
    },
    [accessPointData, guestProfileData]
  );

  const exportXlsx = useCallback(() => {
    exportRequest(state2Params(requestState));
  }, [exportRequest, requestState, state2Params]);

  const fetchReport = useCallback(
    async (requestState) => {
      try {
        setGlobalLoading(true);
        const params = state2Params(requestState);
        const res = await searchAccessControlReport(params);
        setData(toStackBarChartAccessPointData(res, requestState.intervalType));
      } catch (error) {
        console.log("fetchReport error", error);
      } finally {
        setGlobalLoading(false);
      }
    },
    [setGlobalLoading, state2Params]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({ page: 1, searchKey });
      await fetchReport(requestState, filterState);
    },
    [fetchReport, modifyFilters]
  );

  const clearFilterCb = useCallback(async () => {
    clearFilter();
    const { requestState, filterState } = clearFilter();
    await fetchReport(requestState, filterState);
  }, [clearFilter, fetchReport]);

  useMount(() => {
    fetchReport(requestState);
    accessPointRequest();
    guestProfileRequest();
  });

  useEffect(() => {
    modifyFilters({
      accessPointIds: [],
      guestProfileIds: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.viewBy]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.accessControlReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: exportLoading,
          disabled: exportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <AccessControlReportFilter
        filterState={filterState}
        modifyFilter={modifyFilter}
        applyFilterCb={applyFilterCb}
        clearFilterCb={clearFilterCb}
        accessPointData={accessPointData}
        accessPointLoading={accessPointLoading}
        guestProfileData={guestProfileData}
        guestProfileLoading={guestProfileLoading}
      />

      <Panel>
        <StackBarGraph {...data} />
      </Panel>
    </ModuleWrapper>
  );
};

const defaultFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    intervalType: TimeUnit.Hour,
    viewBy: ViewReportAccessControl.Access,
    accessPointIds: [],
    guestProfileIds: [],
  };
};

export default AccessControlReport;
