import React from "react";

import { Tooltip } from "components";

const NoticeEditTooltip = ({ children }) => {
  const content = (
    <div className="text-xs text-gray-disabled">
      This product has variants, please go to product detail to edit price
    </div>
  );

  return (
    <Tooltip title={content} arrowPointAtCenter placement="top" color="#46B0C6" trigger="click">
      {children}
    </Tooltip>
  );
};

export default NoticeEditTooltip;
