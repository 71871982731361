import field from "enums/field";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { closeType = null, closeReason = "" } = initialState;
  return {
    closeType: {
      name: "closeType",
      value: closeType,
      type: field.DROPDOWN,
    },
    closeReason: {
      name: "closeReason",
      value: closeReason,
      type: field.INPUT,
      maxLength: 255,
      label: lang.typeAReason,
    },
  };
};

export default initialFormState;
