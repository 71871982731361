export const guestPaths = {
  GUEST: "",
  GUESTS: "guests",
  GUEST_PROFILE: "profile",
  GUEST_PROFILE_CREATE: "profile/create",
  GUEST_PROFILE_EDIT: "profile/:id",
  GUEST_PROFILE_ID: (id) => `profile/${id}`,
  GUEST_DETAILS: "guests/:id",
  GUEST_DETAILS_ID: (id) => `guests/${id}`,
  GUEST_DETAILS_PROFILE: "guests/:id/profile",
  GUEST_DETAILS_PROFILE_ID: (id) => `guests/${id}/profile`,
};
