const staffPaths = {
  STAFF: "/v2",
  CHECK_STAFF: "check",
  SEARCH_STAFF: "search/v2",
  INVITE_STAFF: "invite",
  STAFF_ID: (id) => id,
  GET_STAFF_ID: (id) => `${id}/v2`,
  STAFF_RESEND_INVITE: "resend-invite",
  STAFF_VALIDATE_TOKEN: "validate-token",
};

export default staffPaths;
