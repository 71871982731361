import { Get, Post, Delete } from "services";
import { ApiPath } from "paths";

export const getTaxes = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_TAX_LIST}`, body);
  return res;
};

export const createTax = async (body) => {
  const res = await Post(`${ApiPath.TAX}`, body);
  return res;
};

export const deleteTax = async ({ taxId }) => {
  const res = await Delete(`${ApiPath.TAX_ID(taxId)}`);
  return res;
};

export const editTax = async (body) => {
  const res = await Post(`${ApiPath.TAX_ID(body.taxId)}`, body);
  return res;
};

export const getMewsTaxRates = async (body) => {
  const res = await Get(`${ApiPath.MEWS_TAX_RATES}`, body);
  return res;
};
