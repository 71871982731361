import { Button, Field, Icon, Modal, Text, Title } from "components/commons";
import { VenueContext } from "contexts";
import StyleType from "enums/style-type";
import { useModal } from "hooks";
import React, { useContext, useMemo } from "react";
import { openInNewTab } from "services/url.service";
import lang from "translations";
import ConnectModal from "./connect-modal";
import DisconnectModal from "./disconnect-modal";
const IntegrationInfoModal = ({ modifyField, data, active, refreshList, mappedOutlets, loadingOutlets, outletRequest, mappedServices, serviceRequest, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { defaultMewsOutletId, mewsCashBackOutletId, mewsTopUpOutletId, mewsCreditsReceivedOutletId, mewsCreditsKeptOutletId, defaultMewsServiceId } = venue || {};

  const {
    accessToken,
    name,
    instruction,
    request,
    disconnectionMessage,
    dashboardLink,
    guideLink,
    isApp = false,
    id,
    appId,
    integrationType = "",
    // connectedIntegrationsList,
    SFTPDetails,
  } = data;

  const connectModal = useModal();
  const disconnectModal = useModal();

  const cloudbedsPropertyName = useMemo(() => {
    const d = data.request?.result?.data;
    if (d) {
      const cloudbedsIntegration = d.filter(integration => integration.integrationType === 'CLOUDBEDS');
      if (cloudbedsIntegration && cloudbedsIntegration[0]) {
        const integration = cloudbedsIntegration[0]
        return integration.enterpriseName;
      }
    }
    return null;
  }, [data.request])

  const mewsPropertyName = useMemo(() => {
    const d = data.request?.result?.data;
    if (d) {
      const mewsIntegration = d.filter(integration => integration.integrationType === 'MEWS'
        || integration.integrationType === 'MEWS,MEWS_ACCOUNTING'
        || integration.integrationType === 'MEWS_ACCOUNTING');
      if (mewsIntegration && mewsIntegration[0]) {
        const integration = mewsIntegration[0]
        return integration.enterpriseName;
      }
    }
    return null;
  }, [data.request])

  const cashbackOutletLabel = useMemo(() => {
    let label = "-";
    mappedOutlets?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === mewsCashBackOutletId) {
        label = outletName;
      }
    });
    return label;
  }, [mewsCashBackOutletId, mappedOutlets])

  const saleOutletLabel = useMemo(() => {
    let label = "-";
    mappedOutlets?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === defaultMewsOutletId) {
        label = outletName;
      }
    });
    return label;
  }, [defaultMewsOutletId, mappedOutlets])

  const topUpOutletLabel = useMemo(() => {
    let label = "-";
    mappedOutlets?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === mewsTopUpOutletId) {
        label = outletName;
      }
    });
    return label;
  }, [mewsTopUpOutletId, mappedOutlets])

  const creditsReceivedOutletLabel = useMemo(() => {
    let label = "-";
    mappedOutlets?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === mewsCreditsReceivedOutletId) {
        label = outletName;
      }
    });
    return label;
  }, [mewsCreditsReceivedOutletId, mappedOutlets])

  const creditsKeptOutletLabel = useMemo(() => {
    let label = "-";
    mappedOutlets?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === mewsCreditsKeptOutletId) {
        label = outletName;
      }
    });
    return label;
  }, [mewsCreditsKeptOutletId, mappedOutlets])

  const serviceLabel = useMemo(() => {
    let label = "-";
    mappedServices?.forEach(outlet => {
      const { outletId, outletName } = outlet;
      if (outletId === defaultMewsServiceId) {
        label = outletName;
      }
    });
    return label;
  }, [defaultMewsServiceId, mappedServices])

  const statusLabel = useMemo(() => {
    if (integrationType?.indexOf('MEWS_ACCOUNTING') > -1 && (!defaultMewsServiceId || !mewsTopUpOutletId || !defaultMewsOutletId || !mewsCashBackOutletId)) {
      return <div className="flex flex-col items-left">
        <div className="flex items-center">
          <Icon name="warning" color="text-orange" size="text-xs" />
          <Text className="ml-xs" fontWeight="font-semibold">
            Partially Connected
          </Text>
        </div>
        <p className="text-xs text-gray">Complete this integration by assigning outlets/services on your configurations below.</p>
      </div>
    } else {
      return <div className="flex items-center">
        <Icon name="check" color="text-green-darker" size="text-xs" />
        <Text className="ml-xs" fontWeight="font-semibold">
          {lang.connected}
        </Text>
      </div>
    }
  }, [defaultMewsOutletId, defaultMewsServiceId, integrationType, mewsCashBackOutletId, mewsTopUpOutletId]);

  return (
    <>
      <Modal
        {...props}
        active={connectModal.active ? false : active}
        title={
          <div className="flex justify-between ml-2">
            {integrationType === "PURCHASE_PLUS" ? (
              <Title>{`${name}`}</Title>
            ) : (
              <Title>{`${name} ${lang.integration}`}</Title>
            )}
            {/* <Icon name="remove" onClick={() => props.close()} /> */}
          </div>
        }
      >
        <div className="p-sm">
          <div className="grid grid-cols-1 gap-4">
            <Field
              customLabel={
                <Text fontWeight="font-semibold" color="text-gray">
                  {lang.status}
                </Text>
              }
            >
              {statusLabel}
            </Field>
            {!isApp && integrationType === "MEWS_Accounting || MEWS" ? (
              <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    {lang.accessToken}
                  </Text>
                }
              >
                <div className="flex items-center">
                  <Text className="my-sm" fontMono breakAll>
                    {accessToken}
                  </Text>
                  <div
                    className="ml-xs mt-xxs text-blue text-sm cursor-pointer"
                    onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}
                  >
                    {lang.edit}
                  </div>
                </div>
              </Field>
            ) : integrationType === "PURCHASE_PLUS" ? (
              <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    {lang.hostname}
                  </Text>
                }
              >
                <div className="flex items-center">
                  <Text className="my-sm">{SFTPDetails.hostname}</Text>
                </div>
              </Field>
            ) : null}
            {!isApp && integrationType === "CLOUDBEDS" && cloudbedsPropertyName
              && <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    Cloudbeds Property Name
                  </Text>
                }
                className="mb-sm"
              >
                <div className="flex items-center">
                  <Text fontWeight="font-semibold">
                    {cloudbedsPropertyName}
                  </Text>
                </div>
              </Field>
            }
            {!isApp && integrationType.indexOf('MEWS') > -1 && mewsPropertyName
              && <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    MEWS Property Name
                  </Text>
                }
                className="mb-sm"
              >
                <div className="flex items-center">
                  <Text fontWeight="font-semibold">
                    {mewsPropertyName}
                  </Text>
                </div>
              </Field>
            }
            {!isApp && integrationType.indexOf('MEWS_ACCOUNTING') > -1
              && <>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Sale Outlet
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {saleOutletLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Top-Up Outlet
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {topUpOutletLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Return Credits Outlet
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {cashbackOutletLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Credits Received Outlet
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {creditsReceivedOutletLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Credits Kept Outlet
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {creditsKeptOutletLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
                <Field
                  customLabel={
                    <Text fontWeight="font-semibold" color="text-gray">
                      Service
                    </Text>
                  }
                  className="mb-sm"
                >
                  <div className="flex items-center">
                    <Text fontWeight="font-semibold">
                      {serviceLabel}
                    </Text>
                    <p className="text-sm ml-sm text-pelorous cursor-pointer" onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}>Edit</p>
                  </div>
                </Field>
              </>
            }
          </div>
          <div className="flex my-md justify-end gap-2">
            <Button
              type={StyleType.Danger}
              onClick={() => {
                props.close();
                disconnectModal.show({
                  disconnectionMessage,
                  modifyField,
                  title: name,
                  guideLink,
                  isApp,
                  id,
                  appId
                });
              }}
            >
              {lang.disconnect}
            </Button>

            {integrationType === "PURCHASE_PLUS" ? (
              <Button
                type={StyleType.Primary}
                onClick={() => {
                  connectModal.show({
                    onEdit: true,
                    hostname: SFTPDetails.hostname,
                    username: SFTPDetails.username,
                    password: SFTPDetails.password,
                    directory: SFTPDetails.directory,
                    modifyField,
                    title: name,
                    request,
                    integrationType,
                    integrationId: data.id,
                  });
                }}
              >
                {lang.editDetails}
              </Button>
            ) : null}

            {!isApp && dashboardLink ? (
              <Button
                type={StyleType.Primary}
                onClick={() => {
                  if (dashboardLink) {
                    openInNewTab(dashboardLink);
                  }
                }}
              >
                {lang.populate(lang.goToXDashboard, [name])}
              </Button>
            ) : null}
          </div>
        </div>


      </Modal>
      <ConnectModal {...connectModal}
        mappedOutlets={mappedOutlets}
        loadingOutlets={loadingOutlets}
        mappedServices={mappedServices}
        close={() => {
          connectModal.close();
        }}
        outletRequest={outletRequest}
        serviceRequest={serviceRequest}
      />
      <DisconnectModal
        {...disconnectModal}
        id={id}
        integrationType={integrationType}
        request={request}
      />
    </>
  );
};

export default IntegrationInfoModal;
