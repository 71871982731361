import { Post, Delete, Put, Get } from "services";
import { ApiPath } from "paths";

export const searchAdditionalCharge = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_ADDITIONAL_CHARGE}`, body);
  return res;
};

export const searchAdditionalChargeReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_ADDITIONAL_CHARGE_REPORT}`, body);
  return res;
};

export const searchAdditionalChargeReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_ADDITIONAL_CHARGE_REPORT_EXPORT}`, body);
  return res;
};

export const queryAdditionalCharge = async ({ id }) => {
  const res = await Get(`${ApiPath.ADDITIONAL_CHARGE_ID(id)}`);
  return res;
};

export const createAdditionalCharge = async (body) => {
  const res = await Post(`${ApiPath.ADDITIONAL_CHARGE}`, body);
  return res;
};

export const deleteAdditionalCharge = async ({ id }) => {
  const res = await Delete(`${ApiPath.ADDITIONAL_CHARGE_ID(id)}`);
  return res;
};

export const editAdditionalCharge = async (body) => {
  const res = await Put(`${ApiPath.ADDITIONAL_CHARGE_ID(body.id)}`, body);
  return res;
};
