import { searchDiscountReport, getDiscountDetailReport, searchDiscountReportExport } from "apis";
import { Button, DataTable, Text, Icon, ButtonLink } from "components/commons";
import { VenueContext, StaffContext } from "contexts";
import { useApi, useFilter, useModal, useMount } from "hooks";
import {
  discountDetailReportListResponse,
  discountReportListRequest,
  discountReportListResponse,
} from "mappers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { mapObject, pluralize } from "services";
import { discountReportFilterState } from "./filters";
import { discountDetailReportColumn } from "./columns";
import DiscountReportFilter from "./discount-report-filter";
import lang from "translations";
import { StyleType } from "enums";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { Path } from "paths";
import { ProductNamePopover } from "components/popovers";
import { mixpanel, TrackEvent } from "mixpanel";

const DiscountReport = () => {
  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const [showDiscountDetailResults, setShowDiscountDetailResults] = useState();

  const columnModal = useModal();

  const {
    request: searchDiscountReportRequest,
    loading: loadingDiscountReports,
    result: searchDiscountReportResult = { data: [], metadata: { total: 0 } },
    mappedData: discountReportsData,
    error: errorDiscountReports,
  } = useApi({
    api: searchDiscountReport,
    isArray: true,
    mapper: discountReportListResponse,
  });

  const {
    request: getDiscountDetailReportRequest,
    loading: loadingDiscountDetailReports,
    result: getDiscountDetailReportResult = {
      data: [],
      metadata: { total: 0, totalTransaction: 0 },
    },
    mappedData: discountDetailReportsData,
    error: errorDiscountDetailReports,
  } = useApi({
    api: getDiscountDetailReport,
    isArray: true,
    mapper: discountDetailReportListResponse,
  });

  const { request: searchDiscountReportExportRequest, loading: searchDiscountReportExportLoading } =
    useApi({
      api: searchDiscountReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    discountReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchDiscountReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.discountReport,
    });
  });

  const showDiscountDetailResultsCb = useCallback((filterState) => {
    if (filterState.discount || filterState.discount === 0) {
      setShowDiscountDetailResults(true);
    } else {
      setShowDiscountDetailResults(false);
    }
  }, []);

  const fetchDiscountReports = useCallback(
    async (filterState, requestState) => {
      await searchDiscountReportRequest(mapObject({ ...requestState }, discountReportListRequest));
      showDiscountDetailResultsCb(filterState);
    },
    [searchDiscountReportRequest, showDiscountDetailResultsCb]
  );

  const fetchDiscountDetailReports = useCallback(
    async (filterState, requestState) => {
      await getDiscountDetailReportRequest(
        mapObject({ ...requestState }, discountReportListRequest)
      );
      showDiscountDetailResultsCb(filterState);
    },
    [getDiscountDetailReportRequest, showDiscountDetailResultsCb]
  );

  const renderName = useCallback(
    (name, discountId, isDiscountDeleted, index) => {
      const linkDiscount = !(staff?.roles?.includes("FRONT_OFFICE") && staff?.roles?.length === 1);
      return index === 0 || !discountId ? (
        <Text>{name}</Text>
      ) : isDiscountDeleted ? (
        <Text>{name}</Text>
      ) : (
        <ButtonLink path={linkDiscount ? Path.DISCOUNT_ID(discountId) : null}>{name}</ButtonLink>
      );
    },
    [staff]
  );

  const prepareDiscountReports = useCallback(() => {
    return discountReportsData.map((discountReport, index) => {
      const { discountId, name, isDiscountDeleted, sales, discountAmount, used } = discountReport;

      return {
        discountId,
        name: renderName(name, discountId, isDiscountDeleted, index),
        sales,
        discountAmount,
        used,
      };
    });
  }, [discountReportsData, renderName]);

  const discountReports = useMemo(() => {
    return prepareDiscountReports();
  }, [prepareDiscountReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchDiscountReports(filterState, requestState);
    },
    [fetchDiscountReports]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      if (filterState.discount || filterState.discount === 0) {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "deviceDateAndTime", value: "desc" },
        });
        await fetchDiscountDetailReports(filterState, requestState);
      } else {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "sales", value: "desc" },
        });
        await fetchDiscountReports(filterState, requestState);
      }
    },
    [fetchDiscountReports, fetchDiscountDetailReports, modifyFilters, filterState.discount]
  );

  const renderDateAndTime = useCallback((dateAndTime) => {
    if (typeof dateAndTime === "string") {
      return (
        <div className="whitespace-nowrap">
          <Text>{dateAndTime}</Text>
        </div>
      );
    }
    return (
      <div>
        <Text className="whitespace-nowrap">{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray whitespace-nowrap">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderTransaction = useCallback((transactionId) => {
    if (transactionId) {
      return (
        <Text color="text-pelorous">
          <a
            rel="noreferrer"
            className="text-sm"
            href={`/transaction/${transactionId}`}
            target="_blank"
          >{`#${transactionId}`}</a>
        </Text>
      );
    }
    return null;
  }, []);

  const renderLocation = useCallback((productSku) => {
    return (
      productSku && (
        <div>
          {productSku.locationStocks
            ? productSku.locationStocks.map((locationStock) => {
                return <Text>{locationStock.locationName}</Text>;
              })
            : null}
        </div>
      )
    );
  }, []);

  const renderProduct = useCallback((productId, productName) => {
    return (
      <ProductNamePopover productId={productId}>
        <a href={"/product/edit/" + productId} target="_blank" rel="noreferrer">
          <Text color="text-blue" className="whitespace-nowrap">
            {productName}
          </Text>
        </a>{" "}
      </ProductNamePopover>
    );
  }, []);

  const prepareDiscountDetailReports = useCallback(() => {
    return discountDetailReportsData.map((discountDetailReport) => {
      const {
        dateAndTime,
        transactionId,
        productId,
        product,
        grossProfit,
        sales,
        supplyValue,
        margin,
        discountAmount,
        discountValue,
        guestName,
        staffName,
        productSku,
        device,
        locationName,
        isGuestTagUid,
      } = discountDetailReport;

      return {
        dateAndTime: renderDateAndTime(dateAndTime),
        transaction: renderTransaction(transactionId),
        product: renderProduct(productId, product),
        grossProfit,
        sales,
        supplyValue,
        margin,
        discountAmount,
        discountValue,
        guestName: isGuestTagUid ? <Text tagUid>{guestName}</Text> : guestName,
        staffName,
        location: locationName || renderLocation(productSku),
        device,
      };
    });
  }, [
    discountDetailReportsData,
    renderDateAndTime,
    renderTransaction,
    renderLocation,
    renderProduct,
  ]);

  const discountDetailReports = useMemo(() => {
    return prepareDiscountDetailReports();
  }, [prepareDiscountDetailReports]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      if (showDiscountDetailResults) {
        fetchDiscountDetailReports(filterState, requestState);
      } else {
        fetchDiscountReports(filterState, requestState);
      }
    },
    [fetchDiscountReports, fetchDiscountDetailReports, showDiscountDetailResults, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchDiscountReportExportRequest(mapObject({ ...requestState }, discountReportListRequest));
  }, [searchDiscountReportExportRequest, requestState]);

  const discountDetailTableRightContent = useMemo(() => {
    if (showDiscountDetailResults) {
      return (
        <div>
          <Button
            type={StyleType.Secondary}
            onClick={() => {
              columnModal.show();
            }}
          >
            {lang.showHideColumns}
          </Button>
        </div>
      );
    }
  }, [showDiscountDetailResults, columnModal]);

  const discountDetailResultContent = useMemo(() => {
    if (showDiscountDetailResults) {
      return `${getDiscountDetailReportResult.metadata.totalTransaction} ${pluralize(
        getDiscountDetailReportResult.metadata.totalTransaction,
        lang.transaction,
        lang.transactions
      )}`;
    }
  }, [getDiscountDetailReportResult.metadata.totalTransaction, showDiscountDetailResults]);

  const prepareColumns = useCallback(() => {
    if (showDiscountDetailResults) {
      return discountDetailReportColumn();
    }

    return [
      {
        key: "name",
        text: lang.discountName,
      },
      {
        key: "sales",
        text: lang.sales,
        align: "right",
        sort: true,
      },
      {
        key: "discountAmount",
        text: lang.discountAmount,
        align: "right",
      },
      {
        key: "used",
        text: lang.used,
        align: "right",
      },
      {
        key: "action",
        actions: true,
        align: "right",
        actionOptions: [
          {
            text: lang.viewDetails,
            onClick: (column) => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.viewDiscountDetails,
                Page: lang.discountReport,
              });

              filterState.discount = column.discountId;
              modifyFilter("discount", {
                value: column.discountId,
              });
              applyFilterCb();
            },
          },
        ],
        exclude: [0],
      },
    ];
  }, [showDiscountDetailResults, applyFilterCb, filterState, modifyFilter]);

  const columns = useMemo(() => {
    return prepareColumns();
  }, [prepareColumns]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.discountReport}
        description={lang.viewAndExportDiscountReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchDiscountReportExportLoading,
          disabled: searchDiscountReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />
      <DiscountReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />
      {discountReports.length > 0 ? (
        <DataTable
          page={filterState.page}
          resultContent={discountDetailResultContent}
          renderRightContent={discountDetailTableRightContent}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={showDiscountDetailResults ? fetchDiscountDetailReports : fetchDiscountReports}
          total={
            showDiscountDetailResults
              ? getDiscountDetailReportResult.metadata.total
              : searchDiscountReportResult.metadata.total
          }
          loading={loadingDiscountReports || loadingDiscountDetailReports}
          columns={columns}
          data={showDiscountDetailResults ? discountDetailReports : discountReports}
          error={errorDiscountReports || errorDiscountDetailReports}
          sort={filterState.sort}
          setSort={sortCb}
          minWidth={showDiscountDetailResults ? "2220px" : null}
        />
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default DiscountReport;
