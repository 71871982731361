import React, { useMemo, useContext } from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { SimpleTable, Text } from "components/commons";
import { Path } from "paths";
import { ProductNamePopover } from "components/popovers";
import { StaffContext } from "contexts";

const TopProductsChart = ({ ...props }) => {
  const { staff } = useContext(StaffContext);
  const linkLocation = !(staff?.roles?.includes("FRONT_OFFICE") && staff?.roles?.length === 1);
  const data = useMemo(() => {
    return props.mappedData.map((p) => {
      return {
        ...p,
        name: (
          <div className="px-md py-sm">
            {p.deleted ? (
              <Text color="text-black" className="whitespace-nowrap">
                {p.name + " (Deleted)"}
              </Text>
            ) : (
              <ProductNamePopover productId={p.id}>
                <a href={"/product/edit/" + p.id} target="_blank" rel="noreferrer">
                  <Text color="text-black" className="whitespace-nowrap">
                    {p.name}
                  </Text>
                </a>{" "}
              </ProductNamePopover>
            )}
            
          </div>
        ),
        unitSold: (
          <div className="px-md py-sm">
            <Text>{p.unitSold}</Text>
          </div>
        ),
      };
    });
  }, [props.mappedData]);

  return (
    <ChartContainer
      title={ linkLocation ? { to: Path.PRODUCT, name: lang.topProducts } : { name: lang.topProducts }}
      {...props}
      empty={!props.mappedData.length}
      paddingless
    >
      <SimpleTable
        className="mt-md"
        columns={[
          {
            text: lang.product,
            key: "name",
            className: "py-sm",
            headerClassName: "pl-md text-left capitalize font-bold",
          },
          {
            text: lang.unitsSold,
            key: "unitSold",
            className: "text-right py-sm",
            headerClassName: "text-right pr-md capitalize font-bold",
          },
        ]}
        data={data.slice(0, 5)}
      />
    </ChartContainer>
  );
};

export default TopProductsChart;
