import { getMewsOutlet } from "apis";
import { getMewsService } from "apis/outlet.api";
import { VenueContext } from "contexts";
import { useApi, useMount } from "hooks";
import { outletMapper } from "mappers/outlet.mapper";
import React, { useContext } from "react";
import data from "./data";
import IntegrationCardList from "./integration-card-list/integration-card-list";

const IntegrationList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};

  const {
    request: outletRequest,
    mappedData: mappedOutlets,
    loading: loadingOutlet,
  } = useApi({
    api: getMewsOutlet,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  const {
    request: serviceRequest,
    mappedData: mappedServices,
  } = useApi({
    api: getMewsService,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  useMount(() => {
    outletRequest();
    serviceRequest();
  }, [outletRequest, serviceRequest])

  return <IntegrationCardList
    data={data}
    mappedOutlets={mappedOutlets}
    loadingOutlet={loadingOutlet}
    mappedServices={mappedServices}
    outletRequest={outletRequest}
    serviceRequest={serviceRequest}
  />;
};

export default IntegrationList;
