import { Field, Icon, Input, Text, CheckboxField } from "components/commons";
import { VenueContext } from "contexts";
import lang from "translations";
import React, { useContext, useMemo } from "react";
import { formatNumberWithComma } from "services";
import {
  formatNumberToMoney,
  formatNumberToMoneyCurrency,
  parseMoneyToNumber,
} from "services/money.service";
import {
  isDecimalLastCharacter,
  isNumberValid,
  isValidDecimalPlaces,
  isZeroLastCharacter,
  parseAmountToNumber,
  toAmount,
} from "services/number.service";
import { computeMarkUp, computeRetailPrice, computeTax } from "services/product-price.service";
import { Tooltip } from "components";

const PriceField = ({
  supplyCost,
  markUp,
  retailPrice,
  onChange,
  hasTax,
  tax,
  taxOptions = [],
  taxLoading,
  bargainAllowed,
  bargainRequiresApproval
}) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;
  const taxValue = useMemo(() => {
    const taxPercentage = taxOptions
      ?.filter((t) => t.value === tax.value)
      ?.map((t) => t.taxPercentage);

    const taxValue = taxPercentage.length ? computeTax(retailPrice.value, taxPercentage) : 0;
    if (taxLoading) {
      return <Icon loading />;
    }
    return lang.populate(lang.taxPercentageAndValue, [
      taxPercentage,
      formatNumberToMoneyCurrency(taxValue),
    ]);
  }, [taxOptions, tax, retailPrice, taxLoading]);
  return (
    <div>
      <div className="grid grid-cols-3 mt-md">
        <div className="col-span-1">
          <Field {...supplyCost}>
            <Input
              right
              {...supplyCost}
              iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value)) {
                  onChange({
                    supplyCost: {
                      ...supplyCost,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value)) {
                  return false;
                }
                const v = parseMoneyToNumber(value).value;
                onChange({
                  supplyCost: {
                    ...supplyCost,
                    value: v,
                  },
                  retailPrice,
                  markUp,
                });
              }}
              onBlur={() => {
                const v = parseMoneyToNumber(supplyCost.value).value;
                const rp = parseMoneyToNumber(retailPrice.value).value;
                const mu = computeMarkUp(v, rp);
                onChange({
                  supplyCost: {
                    ...supplyCost,
                    // value: formatNumberToMoney(supplyCost.value),
                    value: supplyCost.value
                  },
                  retailPrice,
                  markUp: {
                    ...markUp,
                    value: formatNumberWithComma(mu),
                  },
                });
              }}
              onFocus={() => {
                onChange({
                  supplyCost: {
                    ...supplyCost,
                    value: parseMoneyToNumber(supplyCost.value).value || "",
                  },
                  retailPrice,
                  markUp,
                });
              }}
            />
          </Field>
        </div>
        <div className="col-span-1 px-md">
          <Field {...markUp}>
            <Input
              {...markUp}
              iconSuffix={<Text color="text-gray">%</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || value.trim() === "-") {
                  onChange({
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value) || !isValidDecimalPlaces(value, 2)) {
                  return false;
                }
                const max = 99999999.99;
                if (parseAmountToNumber(value) <= max) {
                  onChange({
                    supplyCost,
                    retailPrice,
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                }

                // }
              }}
              onFocus={() => {
                onChange({
                  supplyCost,
                  retailPrice,
                  markUp: {
                    ...markUp,
                    value: parseAmountToNumber(markUp.value) || "",
                  },
                });
              }}
              onBlur={() => {
                const v = parseAmountToNumber(markUp.value);
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const rp = computeRetailPrice(sc, v);
                onChange({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    value: formatNumberToMoney(rp),
                  },
                  markUp: {
                    ...markUp,
                    // value: toAmount(markUp.value || 0, "0,0.00"),
                    value: markUp.value || 0
                  },
                });
              }}
            />
          </Field>
        </div>
        <div className="col-span-1">
          <Field
            {...retailPrice}
            noLabel
            customLabel={
              <div>
                <Text label>
                  {lang.retailPrice} {hasTax?.value && `(${lang.taxIncluded})`}
                </Text>
              </div>
            }
          >
            <Input
              right
              {...retailPrice}
              iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || isZeroLastCharacter(value)) {
                  onChange({
                    retailPrice: {
                      ...retailPrice,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value)) {
                  return false;
                }
                const v = parseMoneyToNumber(value).value;
                const max = 99999999.99;
                if (v <= max) {
                  onChange({
                    supplyCost,
                    retailPrice: {
                      ...retailPrice,
                      value: v,
                    },
                    markUp,
                  });
                }
              }}
              onBlur={() => {
                const rp = parseMoneyToNumber(retailPrice.value).value;
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const mu = computeMarkUp(sc, rp);
                onChange({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    // value: formatNumberToMoney(rp),
                    value: retailPrice.value
                  },
                  markUp: {
                    ...markUp,
                    value: toAmount(mu),
                  },
                });
              }}
              onFocus={() => {
                onChange({
                  retailPrice: {
                    ...retailPrice,
                    value: parseMoneyToNumber(retailPrice.value).value || "",
                  },
                  supplyCost,
                  markUp,
                });
              }}
            />
          </Field>
          {hasTax?.value && tax?.value && (
            <div className="mt-sm">
              <Text description>{taxValue}</Text>
            </div>
          )}
        </div>
      </div>
      <div className="flex mt-sm pt-6">
        <div>
          <CheckboxField
            {...bargainAllowed}
            onChange={async (name, { value }) => {
              onChange({
                [name]: {
                  value,
                },
              });
              if (!value) {
                onChange({
                  [name]: {
                    value,
                  },
                  [bargainRequiresApproval.name]: {
                    value,
                  },
                });
              }
            }}
          >
            {lang.allowBargainOnThisProduct}
          </CheckboxField>
        </div>
        <div>
          <Tooltip
            className="cursor-pointer"
            title={lang.allowBargainDesc}
          >
            <span className="icon-warning text-gray-400 text-xs ml-xs"></span>
          </Tooltip>
        </div>
      </div>
      {bargainAllowed.value && (
        <CheckboxField
          className="pt-2 pl-6"
          {...bargainRequiresApproval}
          onChange={async (name, { value }) => {
            onChange({
              [name]: {
                value,
              },
            });
          }}
        >
          {lang.bargainRequiresApprovalOnThisProduct}
        </CheckboxField>
      )}
    </div>
  );
};

export default PriceField;
