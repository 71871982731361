import { Panel, Title, CheckboxField, ButtonLink, Select, Field } from "components/commons";
import { FragmentA } from "components/fragments";
import { Path } from "paths";
import React from "react";
import lang from "translations";
import PriceField from "./price-field";

const PricingAndTaxesForm = ({ form, modifyForm, requestTax }) => {
  const { hasTax, tax, supplyCost, markUp, retailPrice, hasVariants, bargainAllowed, bargainRequiresApproval } = form;
  const taxDefault = requestTax.mappedData.filter((option) => {
    return option.taxDefault;
  })?.[0];
  return (
    <FragmentA title={lang.pricingAndTaxes}>
      <Panel>
        <div className="flex justify-between">
          <div>
            <Title>{lang.taxes}</Title>
          </div>
          <div>
            <ButtonLink icon="arrow-diagonal-right" suffix newTabPath={Path.SETTING_TAX}>
              {lang.viewTaxSettings}
            </ButtonLink>
          </div>
        </div>
        <CheckboxField
          disabled={!requestTax.mappedData.length}
          className="mt-sm"
          {...hasTax}
          onChange={async (name, { value }) => {
            modifyForm({
              [name]: {
                value,
              },
            });
            if (value) {
              await requestTax.request();
            }
            modifyForm({
              [name]: {
                value,
              },
              [tax.name]: {
                value: taxDefault?.value || null,
                label: taxDefault?.text || null,
                required: value,
              },
            });
          }}
        >
          {lang.chargeTaxesOnThisProduct}
        </CheckboxField>
        {hasTax.value && (
          <Field>
            <Select {...tax} options={requestTax.mappedData} loading={requestTax.loading} />
          </Field>
        )}
      </Panel>
      {!hasVariants.value && (
        <Panel>
          <Title>{lang.price}</Title>
          <PriceField
            supplyCost={supplyCost}
            markUp={markUp}
            retailPrice={retailPrice}
            onChange={modifyForm}
            hasTax={hasTax}
            tax={tax}
            taxOptions={requestTax.mappedData}
            bargainAllowed={bargainAllowed}
            bargainRequiresApproval={bargainRequiresApproval}
          />
        </Panel>
      )}
    </FragmentA>
  );
};

export default PricingAndTaxesForm;
