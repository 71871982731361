import enumeration from "./enumeration";

const StaffRole = {
  SellOnPos: "SELL_ON_POS",
  TopupCredits: "TOPUP_CREDITS",
  ReturnCredits: "RETURN_CREDITS",
  RegisterGuests: "REGISTER_GUESTS",
  StockManager: "STOCK_MANAGER",
  Manager: "MANAGER",
  FrontOffice: "FRONT_OFFICE",
  BackOffice: "BACK_OFFICE",
  AccessControl: "ACCESS_CONTROL",
};

export default enumeration(StaffRole);
