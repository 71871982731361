import lang from "translations";
const columns = [
  {
    key: "name",
    text: lang.name,
    align: "left",
  },
  {
    key: "email",
    text: lang.email,
    align: "left",
  },
  {
    key: "mobileNumber",
    text: lang.mobileNumber,
    align: "left",
  },
  {
    key: "gender",
    text: lang.gender,
    align: "left",
  },
  {
    key: "birthdate",
    text: lang.birthdate,
    align: "left",
  },
  {
    key: "nationality",
    text: lang.nationality,
    align: "left",
  },
  {
    key: "guestProfile",
    text: lang.guestProfile,
    align: "left",
  },
  {
    key: "ticketNo",
    text: lang.ticketNo,
    align: "left",
  },
  {
    key: "preloadedCredit",
    text: lang.preloadedCredit,
    align: "left",
  },
  {
    key: "checkinDate",
    text: lang.checkInDate,
    align: "right",
  },
];

export default columns;
