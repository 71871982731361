import { StaffRole } from "enums";
import { Path } from "paths";

export const roleAccess = {
  [StaffRole.StockManager]: [
    Path.INVENTORY,
    Path.SETTING,
    Path.INVENTORY_STOCKTAKE,
    Path.STOCKTAKE_PAGE,
    Path.ACCOUNT_SETTING,
  ],
  [StaffRole.FrontOffice]: [
    Path.SLASH,
    Path.BOOKING,
    Path.GUEST,
    Path.GUESTS,
    Path.GUEST_DETAILS,
    Path.GUEST_DETAILS_PROFILE,
    Path.TRANSACTIONS,
    Path.REGISTER_REPORT,
    Path.END_OF_DAY_REPORT,
    Path.REPORT,
    Path.REPORT_INVENTORY,
    Path.REPORT_CATEGORY,
    Path.REPORT_DISCOUNT,
    Path.REPORT_VOUCHER,
    Path.REPORT_SALES,
    Path.DEVICE,
    Path.DEVICES,
    Path.SETTING,
    Path.BAN_TAG,
    Path.ACCOUNT_SETTING,
    Path.MULTI_PROPERTY,
    Path.MULTI_PROPERTY_BALANCE_MOVEMENT_IN,
    Path.MULTI_PROPERTY_BALANCE_MOVEMENT_OUT,
  ],
  [StaffRole.BackOffice]: [
    Path.SLASH,
    Path.PRODUCT,
    Path.CATEGORY,
    Path.INVENTORY,
    Path.INVENTORY_SUPPLY_ITEM,
    Path.IMPORT_SUPPLY_ITEM,
    Path.CREATE_SUPPLY_ITEM,
    Path.INVENTORY_STOCKTAKE,
    Path.CREATE_STOCKTAKE,
    Path.STOCKTAKE_PAGE,
    Path.LOCATION,
    Path.STAFF,
    Path.GUEST,
    Path.GUEST_PROFILE,
    Path.VOUCHER,
    Path.DISCOUNT,
    Path.DEVICE,
    Path.PRINTER,
    Path.SETTING,
    Path.SETTING_GENERAL_SETTING,
    Path.WRISTBAND_KEEPING,
    Path.SETTING_NOTIFICATION,
    Path.SETTING_NOTIFICATION_STOCK_LEVEL,
    Path.SETTING_TAX,
    Path.PAYMENT_METHOD_SETTING,
    Path.CREDIT_LIMIT_SETTING,
    Path.QUESTION_SETTING,
    Path.ACCOUNT_SETTING,
    Path.RECEIPT_SETTING,
    Path.INTEGRATION_SETTING,
    Path.ACCOUNT_MAPPING,
    Path.CHART_OF_ACCOUNTS,
    Path.SETTING_ACCESS,
  ],
};
