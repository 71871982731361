import React, { useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ButtonsGroup = ({
  label,
  options,
  disabled: primaryDisabled = false,
  loading: primaryLoading = false,
  handleClick,
  defaultSelect = 0,
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#46b0c6",
        contrastText: "#fff",
      },
    },
  });
  const anchorRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelect);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onClick = () => {
    handleClick(selectedIndex);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          disabled={primaryDisabled || primaryLoading}
        >
          <LoadingButton
            variant="contained"
            loading={primaryLoading}
            disabled={primaryDisabled || primaryLoading}
            onClick={onClick}
          >
            {options[selectedIndex]}
          </LoadingButton>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label={label}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </ThemeProvider>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default ButtonsGroup;
