import { environment } from "environments/environment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import downloaderState from "pages/venue/downloader.state";
import moment from "moment";
import reportStatus from "enums/report-status";
import { StatusIcon, Toast } from "components/commons";
import { downloadReport, deleteReport, searchReport, getStaff } from "apis";
import useMount from "./useMount";
import { useApi } from "hooks";
import { reportListResponse } from "mappers/report.mapper";
import { mixpanel, TrackEvent } from "mixpanel";
import lang from "translations";
import { StaffRole } from "enums";

/* eslint-disable import/no-webpack-loader-syntax */
import Worker from "worker-loader!./download.worker.js";
import classNames from "classnames";
import { deleteImport, searchImport } from "apis/report.api";

const key = "downloaderNotification";

const useDownloader = (venueId) => {
  const [downloader, setDownloader] = useState(downloaderState);
  const [response, setResponse] = useState();
  const [resImport, setResImport] = useState();

  const [isManager, setIsManager] = useState(false);
  const [isFrontOffice, setIsFrontOffice] = useState(false);
  const [isBackOffice, setIsBackOffice] = useState(false);

  const { request: getReportRequest } = useApi({
    api: searchReport,
    isArray: true,
    mapper: reportListResponse,
    handleOwnError: true,
  });

  const { request: getStaffRequest } = useApi({
    api: getStaff,
    pageError: true,
    handleOwnError: true,
  });

  const processItems = useCallback(
    async (items) => {
      let processing = 0;
      let success = 0;
      let failed = 0;

      const json = items
        .filter((item) => {
          return !downloader.excludedIds.includes(item.reportId);
        })
        .sort(function (a, b) {
          return b.reportId - a.reportId;
        })
        .map((item) => {
          const expired = moment(item.expiresAt).diff(moment()) < 0;
          let actions = [];

          if (item.reportStatus === reportStatus.done) {
            item.status = reportStatus.done;
            item.label = `${expired ? "Expired" : "Expires"} ${moment(item.expiresAt).fromNow()}`;
            if (!expired) {
              actions.push({
                label: lang.download,
                action: "download",
              });
            }
            actions.push({
              label: lang.clear,
              action: "clear",
            });

            success++;
          } else if (item.reportStatus === reportStatus.failed) {
            item.status = reportStatus.failed;
            item.label = lang.failed;
            failed++;
          } else {
            item.status = reportStatus.processing;
            item.label = lang.generatingReport;
            processing++;
          }
          item.title = item.reportName;
          item.actions = actions;
          return item;
        });
      var downloaderTitle = lang.downloading;
      if (json.length === success) {
        downloaderTitle = lang.allReportsReadyForDownload;
      } else if (json.length === processing) {
        downloaderTitle = lang.preparingReport;
      } else {
        downloaderTitle = `${success} of ${json.length} ${
          json.length > 1 ? lang.reports : lang.report
        } ${lang.readyForDownload}`;
      }

      setDownloader({
        ...downloader,
        title: downloaderTitle,
        successCount: success,
        processingCount: processing,
        failedCount: failed,
      });

      setResponse(json);
    },
    [downloader]
  );

  const fetchReports = useCallback(
    async (requestState) => {
      try {
        const res = await getReportRequest(requestState);
        const reports = res.data;
        processItems(reports);
      } catch (e) {
        Toast({
          content: lang.reportsFailedToLoad,
          error: true,
          icon: "exclamation-fill",
        }).open();
      }
    },
    [getReportRequest, processItems]
  );

  const fetchImport = useCallback(async () => {
    try {
      const res = await searchImport(venueId);
      setResImport(res.data);
    } catch (e) {}
  }, [venueId]);

  const fetchStaffRole = useCallback(async () => {
    const s = await getStaffRequest({ venueId });
    const staffRole = s.roles.map((r) => {
      return r.role;
    });
    setIsManager(staffRole.includes(StaffRole.Manager));
    setIsFrontOffice(staffRole.includes(StaffRole.FrontOffice));
    setIsBackOffice(staffRole.includes(StaffRole.BackOffice));
  }, [getStaffRequest, venueId]);

  const getFileType = (fileType) => {
    if (fileType === ".xlsx" || fileType === ".xls") {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else {
      return "application/octet-stream";
    }
  };

  const handleClickReport = useCallback(async (report) => {
    try {
      const res = await downloadReport(report.reportId);
      const fileType = getFileType(report.fileType);
      downloadFile(res, fileType, report.reportName, report.fileType);
      return {
        response: res,
      };
    } catch (e) {
      throw e;
    }
  }, []);

  const handleClearReport = useCallback(
    async (report) => {
      try {
        setDownloader({
          ...downloader,
          excludedIds: downloader.excludedIds.push(report.reportId),
        });
        const res = await deleteReport(report.reportId);
        return {
          response: res,
        };
      } catch (e) {
        throw e;
      }
    },
    [downloader]
  );

  const handleClearImport = useCallback(
    async (item) => {
      try {
        const id = item.importProcessId;
        await deleteImport(id, venueId);
      } catch (e) {
        throw e;
      }
    },
    [venueId]
  );

  const downloadFile = (data, type, name, format) => {
    const anchor = document.createElement("a");
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    anchor.download = name + format;
    anchor.href = url;
    anchor.click();
  };

  const createdDownloadedContent = useCallback(
    (downloadList, importingList) => {
      return (
        <div className="flex gap-4 items-end overflow-hidden" style={{ maxHeight: 300 }}>
          <ExportList
            handleClearReport={handleClearReport}
            handleClickReport={handleClickReport}
            title={downloader.title}
            list={downloadList}
          />
          <ImportList handleClear={handleClearImport} list={importingList} />
        </div>
      );
    },
    [handleClearReport, handleClickReport, downloader.title, handleClearImport]
  );

  const openDownloaderBox = useCallback(async (content) => {
    notification.open({
      bottom: 0,
      key: key,
      icon: null,
      className: "downloader open",
      description: content,
      placement: "bottomRight",
      duration: 0,
      closeIcon: <p></p>,
      btn: null,
    });
  }, []);

  useEffect(() => {
    if (!venueId) return;
    const worker = new Worker();
    worker.postMessage({
      url: environment.ACTIVE_MQ_HOST,
      login: environment.ACTIVE_MQ_USER,
      passcode: environment.ACTIVE_MQ_PW,
      destination: `/topic/reports/${venueId}`,
      destination2: `/topic/import/${venueId}`,
    });

    worker.addEventListener("message", (event) => {
      const { action, data } = event.data;
      if (action === "downloader" && data) {
        processItems(JSON.parse(data));
      } else if (action === "import" && data) {
        setResImport(JSON.parse(data));
      }
    });

    return () => {
      worker.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isManager || isBackOffice) {
      openDownloaderBox(createdDownloadedContent(response, resImport));
    } else if (isFrontOffice) {
      openDownloaderBox(createdDownloadedContent(response, []));
    }
  }, [
    response,
    isManager,
    isFrontOffice,
    openDownloaderBox,
    createdDownloadedContent,
    resImport,
    isBackOffice,
  ]);

  useMount(() => {
    fetchStaffRole();
    fetchImport();
    fetchReports({
      page: 1,
      reportStatuses: ["PROCESSING", "UPLOADING", "DONE", "FAILED"],
      searchKey: "",
      types: [],
      venueId: venueId,
    });
  });

  return {
    downloader,
    setDownloader,
  };
};

export default useDownloader;

const ExportList = ({ list = [], title, handleClearReport, handleClickReport }) => {
  const [expand, setExpand] = useState(true);

  const toggleExpanded = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    setExpand(true);
  }, [list]);

  if (!list || !list.length) return null;
  return (
    <div className="w-80 bg-white flex-col max-h-71">
      <div className="px-5 py-3.5 bg-pelorous rounded-sm">
        <div onClick={toggleExpanded} className="flex items-center text-white h-6">
          <p className="flex-1">{title}</p>
          <i className={"icon-caret-down text-sm"}></i>
        </div>
      </div>
      <div className={classNames("flex-1 max-h-64 overflow-auto", !expand && "hidden")}>
        {list.map((item, i) => {
          return (
            <div className="flex download-item" key={i}>
              <div className="p-md">
                {item.icon === ".zip" ? (
                  <i className="icon-zip process-icon text-pelorous text-3xl"></i>
                ) : item.fileType === ".pdf" ? (
                  <i className="icon-pdf process-icon text-pelorous text-3xl"></i>
                ) : item.fileType === ".zip" ? (
                  <i className="icon-zip process-icon text-pelorous text-3xl"></i>
                ) : item.fileType === ".xlsx" ? (
                  <i className="icon-xlsx2 process-icon text-pelorous text-3xl"></i>
                ) : item.fileType === ".csv" ? (
                  <i className="icon-csv process-icon text-pelorous text-3xl"></i>
                ) : (
                  <i className="icon-png process-icon text-pelorous text-3xl"></i>
                )}
              </div>
              <div className="flex-1 pt-md pb-md">
                <p className="download-item-title text-xs font-normal">{item.title}</p>
                {item.reportStatus === reportStatus.done ? (
                  <div className="flex items-center">
                    <div className="flex-1 flex text-xs">
                      <div
                        className="text-pelorous-dark text-xs cursor-pointer"
                        onClick={() => {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            button: lang.download,
                          });
                          handleClickReport(item);
                        }}
                      >
                        {lang.download}
                      </div>
                      &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                      <div
                        className="text-pelorous-dark text-xs cursor-pointer"
                        onClick={() => {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            button: lang.clearDownloads,
                          });
                          handleClearReport(item);
                        }}
                      >
                        {lang.clear}
                      </div>
                    </div>
                    <p className="download-item-label text-xxs pl-md text-gray-400">{item.label}</p>
                  </div>
                ) : item.reportStatus === reportStatus.failed ? (
                  <div className="flex">
                    <p className="download-item-label text-xs text-gray-400">Export failed</p>
                  </div>
                ) : (
                  <div className="flex">
                    <p className="download-item-label text-xs text-gray-400">{item.label}</p>
                  </div>
                )}
              </div>
              <div className="p-md">
                <StatusIcon
                  type={item.reportStatus}
                  onClear={() => {
                    handleClearReport(item);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ImportList = ({ list = [], handleClear }) => {
  const [expand, setExpand] = useState(true);

  const title = useMemo(() => {
    let processing = 0;
    list.forEach((item) => {
      if (item.processStatus === reportStatus.processing) {
        processing++;
      }
    });
    if (processing) return lang.prepareImport;
    else return lang.fileImported;
  }, [list]);

  const toggleExpanded = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    setExpand(true);
  }, [list]);

  if (!list || !list.length) return null;
  return (
    <div className="w-80 bg-white flex-col">
      <div className="px-5 py-3.5 bg-pelorous rounded-sm">
        <div onClick={toggleExpanded} className="flex items-center text-white h-6">
          <p className="flex-1">{title}</p>
          <i className={"icon-caret-down text-sm"}></i>
        </div>
      </div>
      <div className={classNames("flex-1 max-h-64 overflow-auto", !expand && "hidden")}>
        {list.map((item, i) => {
          return (
            <div className={"flex download-item"} key={i}>
              <div className="p-md">
                <i className="icon-xlsx2 process-icon text-pelorous text-3xl"></i>
              </div>
              <div className="flex-1 pt-md pb-md">
                <p className="download-item-title text-xs font-normal">{item.name}</p>
                {item.processStatus === reportStatus.done ? (
                  <div
                    className="text-pelorous-dark text-xs cursor-pointer"
                    onClick={() => {
                      handleClear(item);
                    }}
                  >
                    {lang.clear}
                  </div>
                ) : item.processStatus === reportStatus.failed ? (
                  <div className="flex">
                    <p className="download-item-label text-xs text-gray-400">Import failed</p>
                  </div>
                ) : (
                  <div className="flex">
                    <p className="download-item-label text-xs text-gray-400">Importing</p>
                  </div>
                )}
              </div>
              <div className="p-md">
                <StatusIcon
                  type={item.processStatus}
                  onClear={() => {
                    handleClear(item);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
