import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";

const MultiBarStackChart = ({
  labels,
  data = [],
  formatToolTipValue,
  height,
  position,
  align,
  chartColors = [],
  isLegendPointStyle,
  legendBoxWidth = 20,
  showLegend = true,
}) => {
  const datasets = useMemo(() => {
    if (data.length) {
      return data.map((d, index) => {
        return {
          data: d.data,
          backgroundColor: chartColors[index],
          borderColor: chartColors[index],
          fill: false,
          label: d.label,
        };
      });
    }
    return [];
  }, [data, chartColors]);

  return (
    <div>
      <Bar
        height={height}
        data={{
          labels,
          datasets,
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            title: {
              display: false,
              fontSize: 20,
            },
            legend: {
              display: showLegend,
              position: position,
              align: align,
              labels: {
                usePointStyle: isLegendPointStyle ? true : false,
                boxWidth: legendBoxWidth,
              },
            },
            tooltip: {
              displayColors: false,
              bodyAlign: "center",
              titleAlign: "center",
              bodySpacing: 3,
              bodyFontSize: 15,
              titleFontSize: 12,
              xPadding: 12,
              yPadding: 12,
              mode: "index",
              intersect: false,
              callbacks: {
                label: (l) => {
                  if (formatToolTipValue) {
                    return `${datasets[l.datasetIndex].label} - ${formatToolTipValue(l.raw)}`;
                  }
                  return l.formattedValue;
                },
              },
            },
          },
          scales: {
            xAxes: {
              stacked: true,
            },
            yAxes: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                // grouped: true,
                // stepSize: 500,
              },
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },

          hover: {
            mode: "nearest",
            intersect: true,
          },
        }}
      />
    </div>
  );
};

export default MultiBarStackChart;
