import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useForm, useMount, useApi } from "hooks";
import {
  Form,
  Panel,
  Field,
  Input,
  Title,
  Text,
  Skeleton,
  ActionButton,
  Select,
  Toast,
  UploadImage,
  RadioGroup,
} from "components/commons";
import { EMode, Field as FieldType } from "enums";
import Validation from "services/validation.service";
import { VenueContext } from "contexts";
import { getCurrencies } from "apis/utils.api";
import { setVenue } from "apis/venue.api";
import { venueRequest } from "mappers/venue.mapper";
import { mixpanel, TrackEvent } from "mixpanel";
import classNames from "classnames";
import styles from "./general-settings.module.scss";
import { countriesOptions } from "services/country.service";
import { prettifyMode } from "services/pretty.service";

const GeneralSetting = () => {
  const { venue, fetchVenue, loading, error } = useContext(VenueContext);

  const { history } = useRouter();
  const unsaveChangesModal = useModal();

  const requestCurrencies = useApi({
    api: getCurrencies,
  });

  const { request, loading: submitting } = useApi({
    api: setVenue,
    paramsMapper: venueRequest,
    params: {
      venueId: venue.venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const denominationOptions = useMemo(() => {
    return [
      {
        text: lang.lowDenominations,
        alternateText: "[10] [50] [100] [200] [500] [1,000]",
        value: "10,50,100,200,500,1000",
      },
      {
        text: lang.mediumDenominations,
        alternateText: "[1,000] [2,000] [5,000] [10,000] [20,000] [50,000] [10,0000]",
        value: "1000,2000,5000,10000,20000,50000,100000",
      },
      {
        text: lang.highDenominations,
        alternateText:
          "[500] [1,000] [2,000] [5,000] [10,000] [20,000] [50,000] [100,000] [200,000] [500,000]",
        value: "500,1000,2000,5000,10000,20000,50000,100000,200000,500000",
      },
      {
        text: lang.veryHighDenominations,
        alternateText: "[100,000] [200,000] [300,000] [400,000] [500,000] [1,000,000]",
        value: "100000,200000,300000,400000,500000,1000000",
      },
      {
        text: lang.extremelyHighDenominations,
        alternateText:
          "[500,000] [1,000,000] [1,500,000] [2,000,000] [3,000,000] [4,000,000] [5,000,000] [10,000,000]",
        value: "500000,1000000,1500000,2000000,3000000,4000000,5000000,10000000",
      },
    ];
  }, []);

  const initialState = useMemo(() => {
    const {
      venueName,
      currencyCode,
      businessName,
      businessAddress,
      businessContactNumber,
      businessTaxNumber,
      countryName,
      logoLink,
      demo,
      denominations,
      defaultMode,
    } = venue || {};

    return {
      name: {
        name: "name",
        value: venueName,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        disabled: demo,
      },
      currency: {
        name: "currency",
        value: currencyCode,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
      },
      businessName: {
        name: "businessName",
        value: businessName || "",
        type: FieldType.INPUT,
        maxLength: 75,
        placeholder: lang.registeredOfficialBusinessName,
      },
      businessAddress: {
        name: "businessAddress",
        value: businessAddress || "",
        type: FieldType.INPUT,
        maxLength: 255,
        placeholder: lang.businessAddress,
      },
      businessContactNumber: {
        name: "businessContactNumber",
        value: businessContactNumber || "",
        type: FieldType.INPUT,
        maxLength: 75,
      },
      businessTaxNumber: {
        name: "businessTaxNumber",
        value: businessTaxNumber || "",
        type: FieldType.INPUT,
        maxLength: 75,
        placeholder: lang.registeredBusinessTaxNumber,
      },
      countryName: {
        name: "countryName",
        value: countryName || "",
        type: FieldType.DROPDOWN,
      },
      logoLink: {
        name: "logoLink",
        value: logoLink || "",
        type: FieldType.ANY,
      },
      denominations: {
        name: "denominations",
        value: denominations || denominationOptions[0].value,
        type: FieldType.RADIO,
      },
      defaultMode: {
        name: "defaultMode",
        type: FieldType.DROPDOWN,
        value: defaultMode || EMode.Venue,
      },
    };
  }, [venue, denominationOptions]);

  const { fields, modifyField, dirty, submitForm, getFormValues, applyFieldErrors } = useForm({
    initialState,
  });

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const submitFormValue = useCallback(async () => {
    try {
      await request(getFormValues());
      await fetchVenue();
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch ({ code, handleError }) {
      const err = {
        3028: () => {
          applyFieldErrors({
            name: lang.venueNameAlreadyExist,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [getFormValues, request, goToList, fetchVenue, applyFieldErrors]);

  const currencyOptions = useMemo(() => {
    if (requestCurrencies.result) {
      const filter = {};
      requestCurrencies.result.forEach((element) => {
        filter[element.currency] = element;
      });
      return Object.keys(filter).map((key) => {
        const { currency, code, symbol } = filter[key];
        return {
          value: code,
          text: `${code} ${symbol} ${currency}`,
          custom: (
            <div className="flex">
              <Text className="w-14">{symbol}</Text>
              <Text>{currency}</Text>
            </div>
          ),
        };
      });
    }
    return [];
  }, [requestCurrencies.result]);

  const modeOptions = useMemo(
    () => [EMode.Venue, EMode.Event].map((i) => ({ value: i, text: prettifyMode(i) })),
    []
  );

  useMount(() => {
    fetchVenue();
    requestCurrencies.request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.generalSetting,
    });
  });

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.general}
          returnPath={Path.SETTING}
          onClick={() => {
            leavePage();
          }}
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={() => {}}>
        {loading ? (
          <FragmentA title={lang.venueDetails}>
            <Skeleton />
          </FragmentA>
        ) : (
          <>
            {/* Venue Details */}
            <FragmentA title={lang.venueDetails}>
              <Panel>
                <Title md className="mb-md">
                  {lang.venueInformation}
                </Title>
                <div className="flex justify-start items-start">
                  <div className={classNames("pr-md pb-sm", styles.image)}>
                    <UploadImage {...fields.logoLink} onChange={modifyField} loader={true} />
                  </div>

                  <div className={"w-10/12 pl-4 ml-auto"}>
                    <Title sm color="text-gray">
                      {lang.venueName}
                    </Title>
                    <Field {...fields.name} width="w-full">
                      <Input required {...fields.name} onChange={modifyField} />
                    </Field>
                  </div>
                </div>
              </Panel>
            </FragmentA>

            {/* Business Details */}

            <FragmentA title={lang.businessDetails}>
              <Panel>
                <Title md className="mb-md">
                  {lang.registeredBusinessInformation}
                </Title>
                <Title sm color="text-gray">
                  {lang.legalBusinessName}
                </Title>
                <Field {...fields.businessName}>
                  <Input {...fields.businessName} onChange={modifyField} />
                </Field>

                <Title sm color="text-gray" className="mt-lg">
                  {lang.businessAddress}
                </Title>
                <Field {...fields.businessAddress}>
                  <Input {...fields.businessAddress} onChange={modifyField} />
                </Field>

                <Field {...fields.countryName}>
                  <Select
                    {...fields.countryName}
                    options={countriesOptions()}
                    onChange={modifyField}
                    searchable
                    customNotFoundContent={lang.noSearchFound}
                    placeholder={lang.selectCountry}
                  />
                </Field>
                <Title sm color="text-gray" className="mt-lg">
                  {lang.contactNumber}
                </Title>
                <Field {...fields.businessContactNumber}>
                  <Input
                    {...fields.businessContactNumber}
                    onChange={(name, { value }) => {
                      if (
                        !value ||
                        (Number(value) && Number(value) >= "0") ||
                        Number(value) === "0"
                      ) {
                        modifyField(name, {
                          value,
                        });
                      }
                    }}
                  />
                </Field>
                <Title sm color="text-gray" className="mt-lg">
                  {lang.taxNumber}
                </Title>
                <Field {...fields.businessTaxNumber}>
                  <Input
                    {...fields.businessTaxNumber}
                    onChange={(name, { value }) => {
                      if (
                        !value ||
                        (Number(value) && Number(value) >= "0") ||
                        Number(value) === "0"
                      ) {
                        modifyField(name, {
                          value,
                        });
                      }
                    }}
                  />
                </Field>
              </Panel>
            </FragmentA>

            {/* Venue Currency */}
            <FragmentA title={lang.venueCurrency}>
              <Panel>
                <Title md className="mb-md">
                  {lang.currencyAndFormat}
                </Title>
                <Text className="mb-sm">{lang.changingCurrencyWontAffect}</Text>
                <Field {...fields.currency}>
                  <Select
                    {...fields.currency}
                    searchable={true}
                    onChange={modifyField}
                    required
                    options={currencyOptions}
                  />
                </Field>

                <Title md className="mb-md mt-xl">
                  {lang.denominations}
                </Title>
                <Text className="mb-sm">
                  These group of denominations will be the available options in your Venue
                  Application's Top-Up Screen.
                </Text>

                <Field {...fields.denominations}>
                  <RadioGroup
                    {...fields.denominations}
                    options={denominationOptions}
                    onChange={(value) => {
                      modifyField("denominations", { value: value });
                    }}
                  />
                </Field>
              </Panel>
            </FragmentA>

            <FragmentA title={lang.mode}>
              <Panel>
                <Title md className="mb-md">
                  {lang.defaultMode}
                </Title>
                <Text className="mb-sm">{lang.defaultModeSettingDesc}</Text>

                <Field {...fields.defaultMode}>
                  <Select
                    {...fields.defaultMode}
                    searchable={true}
                    onChange={modifyField}
                    required
                    options={modeOptions}
                    sortOptions={false}
                  />
                </Field>
              </Panel>
            </FragmentA>
          </>
        )}
      </Form>
      <ActionButton
        showLine
        loading={submitting}
        primary={{
          onClick: () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.saveGeneralSettings,
              Page: lang.generalSetting,
            });
            submitForm(submitFormValue);
          },
          disabled: !dirty,
        }}
        secondary={{
          onClick: () => leavePage(),
        }}
      />
    </ModuleWrapper>
  );
};

export default GeneralSetting;
