import { Button, Form, Input, Skeleton, Text, Toast } from "components/commons";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useApi, useForm } from "hooks";
import { InventoryType, ProductType, StyleType } from "enums";
import lang from "translations";
import { Popover } from "antd";
import {
  computeMarkUp,
  computeRetailPrice,
  formatNumberToMoney,
  isNumberValid,
  isValidDecimalPlaces,
  parseAmountToNumber,
  parseMoneyToNumber,
  sortByKeyName,
} from "services";
import {
  add,
  formatNumberWithComma,
  isDecimalLastCharacter,
  isZeroLastCharacter,
  multiply,
  toAmount,
} from "services/number.service";
import { VenueContext } from "contexts";
import initialFormState from "modules/product/product-form/product-form.state";
import { getProduct } from "apis";
import { product } from "mappers";
import Validation from "services/validation.service";
import { updateProduct } from "apis/product.api";
import { isEmpty } from "lodash";
import {
  CreateSupplyItemField,
  CreateVariantForm,
  validateSkus,
} from "modules/product/product-form/product-form.state";

const EditPriceProductPopover = ({
  id,
  supplyPriceRange,
  trigger = "click",
  placement = "top",
  onRefresh,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const { venue } = useContext(VenueContext);
  const { currencySymbol, venueId } = venue;

  const {
    request: getProductRequest,
    loading,
    mappedData,
  } = useApi({
    api: getProduct,
    params: {
      productId: id,
      venueId,
    },
    mapper: {
      ...product,
      _keys: [
        "revenueAccountCodeId",
        "cogsAccountCodeId",
        "categories",
        "description",
        "imageLink",
        "sellableInPos",
        "isActiveMobileOrdering",
        "creationType",
        "productAttributes",
        "productSkus",
        "tax",
        "locations",
        "isActiveAllLocations",
        "isActiveAllCategories",
      ],
    },
    handleOwnError: true,
  });

  const { request: saveProduct, loading: submitting } = useApi({
    api: updateProduct,
    params: {
      productId: id,
      venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const initialState = useMemo(() => {
    const {
      name,
      imageLink,
      description,
      categories = [],
      creationType,
      sellableInPos,
      isActiveMobileOrdering = false,
      productAttributes = [],
      productSkus = [],
      hasTax,
      tax = {},
      locations = [],
      isActiveAllLocations,
      isActiveAllCategories,
      revenueAccountCodeId,
      cogsAccountCodeId,
    } = mappedData;

    const hasVariants = productAttributes.length;

    let obj = {
      productName: {
        value: name,
      },
      productLogo: {
        value: imageLink,
      },
      description: {
        value: description || "",
      },
      category: {
        isAll: isActiveAllCategories,
        value: categories.map(({ categoryId, categoryName }) => {
          return {
            value: categoryId,
            text: categoryName,
          };
        }),
      },
      activePos: {
        value: sellableInPos,
      },
      activeOnlineMenu: {
        value: isActiveMobileOrdering,
      },
      inventoryType: {
        value: creationType || InventoryType.WholeProduct,
      },
      hasTax: {
        value: hasTax,
      },
      tax: {
        value: tax.taxId,
      },
      generatedSku: {
        value: false,
      },
      supplyUnit: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      sellingQuantity: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      supplyQuantity: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      supplyItems: {
        disabled: hasVariants,
      },
      location: {
        isAll: isActiveAllLocations,
        value: locations.map(({ locationId, locationName }) => {
          return {
            value: locationId,
            text: locationName,
          };
        }),
      },
      revenueAccountCodeId: {
        value: revenueAccountCodeId,
      },
      cogsAccountCodeId: { value: cogsAccountCodeId },
    };

    if (hasVariants) {
      const productSkusSorted = productSkus
        .map((ps, index) => {
          const normalizeValue = {};
          const attributes = [];
          ps.productVariants.forEach((attr) => {
            normalizeValue[attr.attribute] = attr.value;
            attributes.push(attr.attribute);
          });
          return {
            text: attributes
              .sort()
              .map((attr) => normalizeValue[attr])
              .join(", "),
            index,
          };
        })
        .sort(sortByKeyName("text"));
      obj = {
        ...obj,

        hasVariants: {
          value: hasVariants,
          hidden: true,
        },
        type: {
          value: ProductType.Variant,
        },
        attributeAndOptions: {
          value: [],
        },
        variants: {
          value: productSkusSorted.map(({ text, index: productSkuIndex }) => {
            const variant = productSkus[productSkuIndex];
            const quantity =
              creationType === InventoryType.WholeProduct ? variant.compositions?.[0].quantity : 0;
            // const keys = variant.productVariants.map((attr) => attr.value);
            let supplyPriceValue = 0;
            const supplyItemsValue = {
              value: variant?.compositions?.map((composition) => {
                const costValue = multiply(composition.supplyPrice, composition.quantity);
                supplyPriceValue = add(costValue, supplyPriceValue);
                return CreateSupplyItemField({
                  item: {
                    value: composition.productSkuId,
                    symbol: composition.measurement?.unit || "",
                    error: false,
                    pristine: true,
                    validations: [],
                  },
                  quantity: {
                    value: toAmount(
                      composition.quantity,
                      composition.measurement?.unit === "pc" ? "0,0" : "0,0.000"
                    ),
                  },
                  cost: {
                    value: formatNumberToMoney(costValue),
                    amount: composition.supplyPrice,
                  },
                });
              }),
            };
            const supplyCostValue = supplyPriceValue;
            const compositions = {
              value: variant?.partOfCompositions?.map((composition) => {
                const { product } = composition;
                const { productName, productId } = product;
                return {
                  productName,
                  productId,
                };
              }),
            };
            return CreateVariantForm({
              productSkuId: {
                value: variant.productSkuId,
              },
              status: {
                value: variant.status,
              },
              logo: {
                value: variant.imageLink,
                image: variant.imageLink,
              },
              sku: {
                value: variant.sku,
                required: true,
                validations: [
                  Validation.required(),
                  Validation.minlength(4),
                  Validation.maxlength(16),
                  validateSkus,
                ],
              },
              attribute: {
                value: variant.productVariants,
              },
              key: {
                value: text,
              },
              supplyUnit: {
                value: variant.measurement?.measurementId,
                unit: variant.measurement?.unit,
                disabled: creationType === InventoryType.AssembleProduct,
              },
              sellingQuantity: {
                value: toAmount(quantity, variant.measurement?.unit === "pc" ? "0,0" : "0,0.000"),
                disabled: creationType === InventoryType.AssembleProduct,
              },
              supplyQuantity: {
                value: toAmount(variant.stockWeightPerPiece, "0,0.000"),
                disabled: creationType === InventoryType.AssembleProduct,
              },
              supplyItems: supplyItemsValue,
              supplyCost: {
                value: formatNumberToMoney(supplyCostValue),
              },
              retailPrice: {
                value: formatNumberToMoney(variant.retailPrice),
              },
              markUp: {
                value: toAmount(computeMarkUp(supplyCostValue, variant.retailPrice)),
              },
              bargainAllowed: {
                value: variant.bargainAllowed,
              },
              bargainRequiresApproval: {
                value: variant.bargainRequiresApproval,
              },
              compositions,
            });
          }),
        },
      };
      const attributes = [];
      productAttributes.forEach((attr) => {
        if (!attributes.includes(attr.attribute)) {
          attributes.push(attr.attribute);
        }
      });
      obj.attributes = {
        value: attributes,
      };
    } else {
      const productSku = productSkus?.[0] || {};
      if (creationType === InventoryType.AssembleProduct) {
        let supplyPriceValue = 0;
        obj = {
          ...obj,
          supplyItems: {
            value: productSku?.compositions?.map((composition) => {
              const costValue = multiply(composition.supplyPrice, composition.quantity);
              supplyPriceValue = add(costValue, supplyPriceValue);

              return CreateSupplyItemField({
                item: {
                  value: composition.productSkuId,
                  unit: composition.measurement?.unit,
                  symbol: composition.measurement?.unit,
                  pristine: true,
                  validations: [],
                  error: false,
                },
                quantity: {
                  value: toAmount(
                    composition.quantity,
                    composition.measurement?.unit === "pc" ? "0,0" : "0,0.000"
                  ),
                },
                cost: {
                  value: formatNumberToMoney(costValue),
                  amount: composition.supplyPrice,
                },
              });
            }),
          },
          supplyCost: {
            value: formatNumberToMoney(supplyPriceValue),
          },
          markUp: {
            value: toAmount(computeMarkUp(supplyPriceValue, productSku.retailPrice)),
            disabled: false,
          },
        };
      } else {
        const supplyCost =
          productSku.compositions?.[0].quantity * productSku.compositions?.[0].supplyPrice;
        obj = {
          ...obj,
          supplyItem: {
            value: productSku.compositions?.[0].productSkuId,
          },
          supplyUnit: {
            value: productSku.measurementId,
            unit: productSku.measurement?.unit,
            symbol: productSku.measurement?.unit,
          },
          sellingQuantity: {
            value: toAmount(
              productSku.compositions?.[0].quantity,
              productSku.measurement?.unit ? "0,0" : "0,0.000"
            ),
          },
          supplyQuantity: {
            value: formatNumberWithComma(productSku.stockWeightPerPiece),
          },
          supplyCost: {
            value: formatNumberToMoney(supplyCost),
          },
          markUp: {
            value: toAmount(computeMarkUp(supplyCost, productSku.retailPrice)),
            disabled: false,
          },
        };
      }
      obj = {
        ...obj,
        sku: {
          value: productSku.sku,
          required: true,
          validations: [Validation.required(), Validation.minlength(4), Validation.maxlength(16)],
        },
        retailPrice: {
          value: formatNumberToMoney(productSku.retailPrice),
        },
        bargainAllowed: {
          value: productSku.bargainAllowed,
        },
        bargainRequiresApproval: {
          value: productSku.bargainRequiresApproval,
        },
      };
    }
    return obj;
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const form = useForm({
    initialState: formState,
  });

  const { fields, modifyField, validateField, modifyForm, getFormValues, clearForm } = form;

  const formField = useMemo(() => {
    const formItems = {};
    [
      "productLogo",
      "productName",
      "description",
      "category",
      "revenueAccountCodeId",
      "cogsAccountCodeId",
      "activePos",
      "activeOnlineMenu",
      "location",
      "inventoryType",
      "sku",
      "generatedSku",
      "supplyUnit",
      "supplyQuantity",
      "sellingQuantity",
      "supplyItem",
      "hasVariants",
      "hasTax",
      "supplyCost",
      "markUp",
      "retailPrice",
      "variants",
      "attributeAndOptions",
      "supplyItems",
      "tax",
      "attributes",
      "bargainAllowed",
      "bargainRequiresApproval",
    ].forEach((key) => {
      formItems[key] = {
        ...fields[key],
        onChange: modifyField,
        validateField: () => {
          validateField(key, fields[key]);
        },
      };
    });
    return formItems;
  }, [fields, modifyField, validateField]);
  const { supplyCost, markUp, retailPrice } = formField;

  const submitForm = useCallback(async () => {
    const formValues = getFormValues();

    let params = {
      imageLink: formValues.productLogo,
      productName: formValues.productName,
      description: formValues.description,
      revenueAccountCodeId: formValues.revenueAccountCodeId,
      cogsAccountCodeId: formValues.cogsAccountCodeId,
      inventoryTracked: false,
      creationType: formValues.inventoryType,
      categories: formValues.category?.length
        ? formValues.category.map(({ text, value }) => {
            return { categoryName: text, categoryId: value };
          })
        : [],
      taxId: formValues.hasTax ? Number(formValues.tax) : null,
      sellableInPos: formValues.activePos,
      locationIds: formValues.location?.length
        ? formValues.location.map(({ value }) => {
            return value;
          })
        : [],
      isActiveAllLocations: fields.location.isAll,
      isActiveAllCategories: fields.category.isAll,
      isActiveMobileOrdering: formValues.activeOnlineMenu,
    };

    if (!formValues.hasVariants) {
      params = {
        ...params,
        sku: formValues.sku,
        retailPrice: parseMoneyToNumber(formValues.retailPrice).value,
        supplyPrice: parseMoneyToNumber(formValues.supplyCost).value,
        bargainAllowed: formValues.bargainAllowed,
        bargainRequiresApproval: formValues.bargainRequiresApproval,
      };
      if (params.creationType === InventoryType.AssembleProduct) {
        params.compositions = formValues.supplyItems?.map((supplyItem) => {
          return {
            productSkuId: supplyItem.item,
            quantity: parseAmountToNumber(supplyItem.quantity),
          };
        });
      } else {
        params = {
          ...params,
          measurementId: Number(formValues.supplyUnit),
          sellingQuantity: parseAmountToNumber(formValues.sellingQuantity),
          stockWeightPerPiece: parseAmountToNumber(formValues.supplyQuantity),
          supplyItemProductSkuId: Number(formValues.supplyItem),
        };
      }
    } else {
      params.productSkus = formValues.variants?.map((variant) => {
        let productSkuObj = {};
        if (params.creationType === InventoryType.WholeProduct && !true) {
          productSkuObj = {
            ...productSkuObj,
            measurementId: Number(variant.supplyUnit),
            sellingQuantity: parseAmountToNumber(variant.sellingQuantity),
            stockWeightPerPiece: parseAmountToNumber(variant.supplyQuantity),
          };
        } else {
          productSkuObj.compositions = variant.supplyItems?.map((supplyItem) => {
            return {
              productSkuId: supplyItem.item,
              quantity: parseAmountToNumber(supplyItem.quantity),
            };
          });
          if (params.creationType === InventoryType.WholeProduct) {
            productSkuObj = {
              ...productSkuObj,
              measurementId: Number(variant.supplyUnit),
              sellingQuantity: parseAmountToNumber(variant.sellingQuantity),
              quantity: parseAmountToNumber(variant.sellingQuantity),
              stockWeightPerPiece: parseAmountToNumber(variant.supplyQuantity),
            };
          }
        }
        if (variant.productSkuId) {
          productSkuObj.productSkuId = variant.productSkuId;
        }
        return {
          ...productSkuObj,
          imageLink: variant.logo,
          sku: variant.sku,
          supplyPrice: parseMoneyToNumber(variant.supplyCost).value,
          retailPrice: parseMoneyToNumber(variant.retailPrice).value,
          variants: variant.attribute,
          status: variant.status,
          bargainAllowed: variant.bargainAllowed,
          bargainRequiresApproval: variant.bargainRequiresApproval,
        };
      });
    }

    try {
      await saveProduct({
        ...params,
        venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      setVisible(false);
      onRefresh();
    } catch (e) {
      console.log("error", e);
      Toast({
        content: lang.somethingWentWrong,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [
    fields.category.isAll,
    fields.location.isAll,
    getFormValues,
    onRefresh,
    saveProduct,
    venueId,
  ]);

  useEffect(() => {
    if (visible) {
      if (isEmpty(mappedData)) getProductRequest();
    } else {
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, mappedData]);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="p-1 w-56">
          <Skeleton />
        </div>
      );
    }
    return (
      <Form className="p-1 w-56">
        <div className="grid grid-cols-2 gap-y-5">
          <div className="text-base font-semibold">{lang.supplyCost}</div>
          <div className="text-base text-disabled text-right mr-7">{supplyPriceRange}</div>
          <div className="text-base font-semibold my-2">{lang.markUp}</div>
          <div>
            <Input
              {...markUp}
              iconSuffix={<Text color="text-gray">%</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || value.trim() === "-") {
                  modifyForm({
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value) || !isValidDecimalPlaces(value, 2)) {
                  return false;
                }
                const max = 99999999.99;
                if (parseAmountToNumber(value) <= max) {
                  modifyForm({
                    supplyCost,
                    retailPrice,
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                }
              }}
              onFocus={() => {
                modifyForm({
                  supplyCost,
                  retailPrice,
                  markUp: {
                    ...markUp,
                    value: parseAmountToNumber(markUp.value) || "",
                  },
                });
              }}
              onBlur={() => {
                const v = parseAmountToNumber(markUp.value);
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const rp = computeRetailPrice(sc, v);
                modifyForm({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    value: formatNumberToMoney(rp),
                  },
                  markUp: {
                    ...markUp,
                    value: markUp.value || 0,
                  },
                });
              }}
            />
          </div>
          <div className="text-base font-semibold my-2">{lang.retailPrice}</div>
          <div>
            <Input
              right
              {...retailPrice}
              iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || isZeroLastCharacter(value)) {
                  modifyForm({
                    retailPrice: {
                      ...retailPrice,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value)) {
                  return false;
                }
                const v = parseMoneyToNumber(value).value;
                const max = 99999999.99;
                if (v <= max) {
                  modifyForm({
                    supplyCost,
                    retailPrice: {
                      ...retailPrice,
                      value: v,
                    },
                    markUp,
                  });
                }
              }}
              onBlur={() => {
                const rp = parseMoneyToNumber(retailPrice.value).value;
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const mu = computeMarkUp(sc, rp);
                modifyForm({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    value: retailPrice.value,
                  },
                  markUp: {
                    ...markUp,
                    value: toAmount(mu),
                  },
                });
              }}
              onFocus={() => {
                modifyForm({
                  retailPrice: {
                    ...retailPrice,
                    value: parseMoneyToNumber(retailPrice.value).value || "",
                  },
                  supplyCost,
                  markUp,
                });
              }}
            />
          </div>
        </div>

        <div className="flex gap-5 mx-5 mt-5">
          <Button
            type={StyleType.Secondary}
            onClick={() => setVisible(false)}
            className="flex-1"
            disabled={submitting}
          >
            {lang.cancel}
          </Button>
          <Button onClick={submitForm} className="flex-1" disabled={submitting}>
            {lang.save}
          </Button>
        </div>
      </Form>
    );
  }, [
    currencySymbol,
    loading,
    markUp,
    modifyForm,
    retailPrice,
    submitForm,
    submitting,
    supplyCost,
    supplyPriceRange,
  ]);

  return (
    <Popover
      content={content}
      trigger={trigger}
      visible={visible}
      placement={placement}
      onVisibleChange={setVisible}
    >
      {children}
    </Popover>
  );
};

export default EditPriceProductPopover;
