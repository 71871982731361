import React, { useCallback, useContext, useMemo, useState } from "react";
import VoucherForm from "../voucher-form/voucher-form";
import lang from "translations";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import { deleteVoucher, editVoucher, getVoucher, searchProduct } from "apis";
import { voucherFormRequest, voucherFormResponse } from "mappers";
import { Toast } from "components/commons";
import { Path } from "paths";
import { DeleteModal } from "components/modals";
import { mapObjects } from "services";
import { mixpanel, TrackEvent } from "mixpanel";

const EditVoucher = () => {
  const [linkedProductsMap, setLinkedProductsMap] = useState();
  const [linkedProductsLoaded, setLinkedProductsLoaded] = useState(false);

  const { query, history } = useRouter();
  const { id } = query;

  const { venue } = useContext(VenueContext);

  const deleteModal = useModal();

  const {
    request: getVoucherRequest,
    loading = true,
    error,
    mappedData,
  } = useApi({
    api: getVoucher,
    mapper: voucherFormResponse,
  });

  const { request: editVoucherRequest, loading: submitting } = useApi({
    api: editVoucher,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: voucherFormRequest,
  });

  const { request: searchProductRequest, loading: loadingProduct } = useApi({
    api: searchProduct,
    isArray: true,
  });

  const { request: deleteVoucherRequest, loading: deleting } = useApi({
    api: deleteVoucher,
  });

  useMount(() => {
    fetchVouchers();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editVoucherPage,
    });
  });

  const fetchVouchers = useCallback(async () => {
    const { data } = await getVoucherRequest({ voucherId: id });
    const { data: products } = await searchProductRequest({
      venueId: venue.venueId,
      locationIds: data.locations.map((l) => l.locationId),
    });

    const productCategories = groupProductsByCategory(
      mapObjects(products, {
        id: { key: "productId" },
        name: { key: "productName" },
        categories: {
          transform: ({ self }) =>
            mapObjects(self, { id: { key: "categoryId" }, name: { key: "categoryName" } }),
        },
      })
    );

    const map = new Map();

    productCategories.forEach((pc) => {
      const productMap = new Map();

      data.voucherProductSkus.forEach((vps) => {
        const { productId, productName, productSku } = vps;

        if (checkIfProductBelongsToCategory(productId, pc.id, products)) {
          if (productMap.has(productName)) {
            productMap.set(productName, [...productMap.get(productName), productSku.productSkuId]);
          } else {
            productMap.set(productName, [productSku.productSkuId]);
          }
        }
      });

      map.set(pc.name, productMap);
    });

    setLinkedProductsMap(map);
    setLinkedProductsLoaded(true);
  }, [getVoucherRequest, id, searchProductRequest, venue]);

  const initialState = useMemo(() => {
    const { name, description, posStatus, dateRange, issuanceLimit, productSkuIds, locations, managerAuthorization } =
      mappedData;
    return {
      name,
      description,
      posStatus,
      dateRange,
      issuanceLimit,
      productSkuIds,
      locations,
      managerAuthorization,
    };
  }, [mappedData]);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await editVoucherRequest({
          ...params,
          venueId: venue.venueId,
          id: mappedData.id,
        });
        return {
          response: res,
          message: lang.populate(lang.voucherUpdated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [editVoucherRequest, mappedData, venue]
  );

  const deleteVoucherCb = useCallback(async () => {
    await deleteVoucherRequest({ voucherId: mappedData.id });
    Toast({
      content: lang.populate(lang.voucherDeleted, [mappedData.name]),
      success: true,
      icon: "check",
    }).open();
    history.push(Path.VOUCHER);
    deleteModal.close();
  }, [deleteModal, deleteVoucherRequest, history, mappedData]);

  return (
    <div>
      <VoucherForm
        loading={loading || loadingProduct}
        initialState={initialState}
        submit={submitForm}
        title={mappedData.name}
        submitting={submitting}
        error={error}
        deleteButton={{
          text: lang.deleteVoucher,
          onClick: () => {
            deleteModal.show({ ok: deleteVoucherCb });
          },
        }}
        linkedProductsMap={linkedProductsMap}
        linkedProductsLoaded={linkedProductsLoaded}
      />
      <DeleteModal
        {...deleteModal}
        deleting={deleting}
        subject={lang.voucher.toLowerCase()}
        subjectName={mappedData.name}
      />
    </div>
  );
};

const groupProductsByCategory = (products) => {
  const categoryMap = new Map();

  products.forEach((p) => p.categories.forEach((c) => categoryMap.set(c.id, c.name)));

  categoryMap.set(0, lang.uncategorized);

  return Array.from(categoryMap).map(([key, value]) => ({ id: key, name: value }));
};

const checkIfProductBelongsToCategory = (productId, categoryId, products) => {
  const product = products.find((p) => p.productId === productId) || {};

  if (categoryId === 0) {
    return !product.categories || product.categories.length === 0;
  } else {
    return product.categories?.some((c) => c.categoryId === categoryId);
  }
};

export default EditVoucher;
