import { searchLocation } from "apis";
import { Field, Filter, MultipleSelect } from "components";
import { useApi, useMount } from "hooks";
import { allLocationRequest, location } from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect, sortByKeyName } from "services";
import lang from "translations";
import { roleOptions } from "../staff-form/staff-form-state";
import { StaffStatus } from "enums";

const inviteOptions = [
  {
    text: lang.awaiting,
    value: StaffStatus.Awaiting,
  },
  {
    text: lang.accepted,
    value: StaffStatus.Active,
  },
];

const StaffFilter = ({ filterState, requestState, modifyFilter, clearFilter, applyFilter }) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });

  useMount(() => {
    fetchLocationRequest(requestState);
  });

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, allLocationRequest));
    },
    [searchLocationRequest]
  );

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return mapObjectsToSelect(sorted, { textKey: "name" });
  }, [locations]);
  return (
    <Filter
      placeholder={lang.searchStaffEmailOrTagID}
      className="mb-lg"
      onClear={clearFilter}
      onApply={applyFilter}
      filterState={filterState}
      actionsSpan={3}
    >
      <Field className="col-span-3" filterLabel={lang.role}>
        <MultipleSelect
          name="roles"
          selectAllText={lang.allRoles}
          options={roleOptions(true).sort(sortByKeyName("text"))}
          value={filterState.roles}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.allRoles}
          emptyPlaceHolder={lang.allRoles}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocations}
          options={locationOptions}
          value={filterState.locations}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
          emptyPlaceHolder={lang.noLocationAvailable}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.inviteResponse}>
        <MultipleSelect
          name="invites"
          selectAllText={lang.allResponse}
          options={inviteOptions}
          value={filterState.invites}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.allResponse}
          emptyPlaceHolder={lang.allResponse}
        />
      </Field>
    </Filter>
  );
};

export default StaffFilter;
