import lang from "translations";

const columns = [
  {
    key: "name",
    text: lang.chargeName,
    width: "w-40",
  },
  {
    key: "type",
    text: lang.type,
    width: "w-20",
  },
  {
    key: "value",
    text: lang.charge,
    align: "right",
    width: "w-20",
  },
  {
    key: "locationNames",
    text: lang.location,
    align: "left",
    width: "w-40",
  },
  {
    key: "showInPos",
    text: lang.onPos,
    align: "right",
    width: "w-20",
  },
];

export default columns;
