import lang from "translations";

const columns = [
  {
    key: "guestId",
    text: lang.guestId,
    width: "w-auto",
    custom: true,
  },
  {
    key: "name",
    text: lang.guestName,
    width: "w-auto",
    custom: true,
  },
  {
    key: "checkOutDateTime",
    text: lang.checkedOutDate,
    width: "w-auto",
    custom: true,
  },
  {
    key: "freeCreditsRemoved",
    text: lang.freeCreditsRemoved,
    width: "w-auto",
    custom: true,
  },
  {
    key: "multiPropertyCreditsOut",
    text: lang.creditsKept,
    width: "w-auto",
    custom: true,
  },
  {
    key: "multiPropertyFreeCreditsOut",
    text: lang.freeCreditsKept,
    width: "w-auto",
    custom: true,
  },
];

export default columns;
