import { AccountName, AccountType, Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { name = "", accountCodeId, id = 0, refundable = false } = initialState;
  return {
    name: {
      name: "name",
      label: lang.name,
      value: name,
      type: Field.INPUT,
      validations: [
        Validation.minlength(3),
        Validation.required(),
        Validation.reserveWord([
          "others",
          "credit / debit card",
          "cash",
          "credit",
          "debit",
          "pouchpay",
        ]),
      ],
      disabled:
        name === "PouchPAY" ||
        name === lang.cash ||
        name === lang.others ||
        name === "Credit / Debit Card" ||
        name === "E-wallet"
          ? true
          : false,
      required: true,
      maxLength: 75,
      placeholder: lang.paymentMethodName,
    },
    accountCodeId: {
      name: "accountCodeId",
      value: accountCodeId,
      label: lang.creditsLiabilityAccount,
      type: Field.ANY,
      placeholder: lang.selectAccount,
      emptyPlaceHolder: lang.noAccountsAvailable,
      accountType: AccountName.CreditsLiabilityAccount,
      suggested: AccountType.Liability,
    },
    isEditable: {
      type: Field.CHECKBOX,
      value: true,
    },
    refundable: {
      name: "refundable",
      value: refundable,
      type: Field.CHECKBOX,
    },
    icon: {
      type: Field.INPUT,
      value: "assets/images/payment-method-others.svg",
    },
    id: {
      type: Field.INPUT,
      value: id,
    },
  };
};

export default initialFormState;
