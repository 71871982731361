import { Button, ButtonLink, Input, Select, Text, Icon, Tooltip, Field } from "components/commons";
import { VenueContext } from "contexts";
import lang from "translations";
import React, { useContext, useMemo, useState, useEffect } from "react";
import { StyleType } from "enums";
import { SupplyItemField } from "./product-form.state";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber, toAmount } from "services";
import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";
import { add } from "services/number.service";
import { computeRetailPrice } from "services/product-price.service";
import { useModal } from "hooks";
import AddNewSupplyItemModal from "../add-new-supply-item-modal/add-new-supply-item-modal";
import { mixpanel, TrackEvent } from "mixpanel";

const SupplyItemsField = ({
  options,
  form,
  modifyForm,
  loadingOptions,
  requestSupplyItems,
  removeAddButton,
}) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;

  const { supplyItems } = form;
  const addNewSupplyItemModal = useModal();

  const [addSupplyButtonState, setAddSupplyButtonState] = useState({ value: "", state: true });
  const [originalOptions, setOriginalOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const supplyItemsOptions = options.filter((option) => {
    return option.value;
  });

  var selectedSupplyItems = useMemo(() => {
    return supplyItems?.value?.map((field) => {
      return field.value.item.value;
    });
  }, [supplyItems.value]);

  useEffect(() => {
    if (originalOptions.length !== 0 && originalOptions.length !== supplyItemsOptions.length) {
      const newSupply = supplyItemsOptions[supplyItemsOptions.length - 1];
      supplyItems?.value?.forEach((field, index) => {
        const { value: supplyItem } = field;
        if (index === currentIndex) {
          const indexValue = supplyItems.value[currentIndex];
          supplyItems.value[currentIndex] = {
            ...indexValue,
            value: {
              ...indexValue.value,
              [supplyItem.item.name]: {
                ...indexValue.value[supplyItem.item.name],
                value: newSupply.value,
                unit: newSupply.measurement.unit,
                symbol: newSupply.measurement.unit,
              },
              [supplyItem.quantity.name]: {
                ...indexValue.value[supplyItem.quantity.name],
                value: toAmount(0, newSupply.measurement.unit === "pc" ? "0,0" : "0,0.000"),
              },
              [supplyItem.cost.name]: {
                ...indexValue.value[supplyItem.cost.name],
                value: formatNumberToMoney(0),
              },
            },
          };
        }
        selectedSupplyItems[currentIndex] = newSupply.value;
      });

      setOriginalOptions([]);
    }
    //eslint-disable-next-line
  }, [originalOptions]);

  const submitSupplyItem = async () => {
    await requestSupplyItems.request();
    setOriginalOptions(supplyItemsOptions);
  };

  const addNewSupplyButton = () => {
    return addSupplyButtonState.state ? (
      <ButtonLink
        type={StyleType.Secondary}
        className="flex flex-grow p-md w-auto"
        onClick={() => addNewSupplyItemModal.show({ itemName: addSupplyButtonState.value })}
      >
        {addSupplyButtonState.value ? (
          <lang.Translate
            className="text-sm truncate"
            text={lang.addNewSupplyItemSelect}
            items={[<b>{addSupplyButtonState.value}</b>]}
          />
        ) : (
          <lang.Translate className="text-sm" text={lang.addNewSupplyItem} items={[]} />
        )}
      </ButtonLink>
    ) : (
      <></>
    );
  };

  const supplyRenderContent = (menu) => {
    return (
      <div>
        <div className="flex">{addNewSupplyButton()}</div>
        {menu}
      </div>
    );
  };

  return (
    <div>
      <div className="grid grid-cols-9 mb-sm">
        <div className="col-span-4">
          <Text label>{lang.supplyItem}</Text>
        </div>
        <div className="col-span-2 px-sm">
          <div className="flex items-center">
            <Text label>{lang.qty}</Text>
            <Tooltip title={<Text color="text-white">{lang.quantitySupplyItems}</Text>}>
              <span className="h-min mt-0 flex items-center">
                <Icon name="info" color="text-gray" className="text-xxs" />
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="col-span-3">
          <Text label>{lang.supplyCost}</Text>
        </div>
      </div>
      {supplyItems?.value?.map((field, index) => {
        const { value: supplyItem } = field;
        const onChange = (changes) => {
          const v = supplyItems.value;
          const indexValue = v[index];
          v[index] = {
            ...indexValue,
            value: {
              ...indexValue.value,
              ...changes,
            },
          };
          let supplyCost = 0;
          v.forEach((field) => {
            supplyCost = add(supplyCost, parseMoneyToNumber(field.value.cost.value).value);
          });
          modifyForm({
            [supplyItems.name]: {
              value: v,
            },
            supplyCost: {
              value: formatNumberToMoney(supplyCost),
            },
            retailPrice: {
              value: formatNumberToMoney(computeRetailPrice(supplyCost, form.markUp.value)),
            },
          });
        };
        const supplyItemsOptionsFiltered = supplyItemsOptions.filter((option) => {
          return (
            !selectedSupplyItems.includes(option.value) || supplyItem.item.value === option.value
          );
        });
        return (
          <div className="mt-md" key={`supplyitemfields=${index}`}>
            <div className="grid grid-cols-9">
              <div className="col-span-4">
                <Select
                  searchable
                  loading={loadingOptions}
                  {...supplyItem.item}
                  customRenderContent={(menu) => supplyRenderContent(menu)}
                  onClick={() => {
                    setCurrentIndex(index);
                  }}
                  onSearch={(input) => {
                    setAddSupplyButtonState({ value: input, state: true });
                    if (
                      supplyItemsOptions.some(
                        (supplyItem) => supplyItem.text.toLowerCase() === input.toLowerCase()
                      )
                    ) {
                      setAddSupplyButtonState({ state: false });
                    }
                  }}
                  options={supplyItemsOptionsFiltered}
                  onChange={(name, { value, option }) => {
                    setAddSupplyButtonState({ value: "", state: true });
                    const item = supplyItemsOptionsFiltered[option.key];
                    const indexValue = supplyItems.value[index].value;
                    const supplyQuantity = parseAmountToNumber(supplyItem.quantity.value) || 1;
                    onChange({
                      [name]: {
                        ...indexValue[name],
                        value: value,
                        symbol: item.measurement.unit,
                      },
                      [supplyItem.quantity.name]: {
                        ...indexValue[supplyItem.quantity.name],
                        value: toAmount(
                          supplyQuantity,
                          item.measurement.unit === "pc" ? "0,0" : "0,0.000"
                        ),
                      },
                      [supplyItem.cost.name]: {
                        ...indexValue[supplyItem.cost.name],
                        value: formatNumberToMoney(supplyQuantity * item.supplyCost),
                        amount: item.supplyCost,
                        disabled: true,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-span-2 px-sm">
                <Field {...supplyItem.quantity}>
                  <Input
                    {...supplyItem.quantity}
                    iconSuffix={<Text color="text-gray">{supplyItem.item.symbol}</Text>}
                    disabled={!supplyItem.item.value}
                    onChange={(name, { value }) => {
                      const isProductPerPc = supplyItem.item.symbol === "pc";
                      if (
                        !isNumberValid(value) ||
                        !isValidDecimalPlaces(value, isProductPerPc ? 0 : 3)
                      ) {
                        return;
                      }

                      const max = isProductPerPc ? 99999999 : 9999999.999;
                      if (parseAmountToNumber(value) <= max) {
                        onChange({
                          [name]: {
                            ...supplyItems.value[index].value[name],
                            value,
                          },
                          [supplyItem.cost.name]: {
                            ...supplyItems.value[index].value[supplyItem.cost.name],
                            value: formatNumberToMoney(value * supplyItem.cost.amount),
                          },
                        });
                      }
                    }}
                    onFocus={() => {
                      const value = parseAmountToNumber(supplyItem.quantity.value);
                      onChange({
                        [supplyItem.quantity.name]: {
                          ...supplyItem.quantity,
                          value: !value ? "" : value,
                        },
                      });
                    }}
                    onBlur={() => {
                      // const isProductPerPc = supplyItem.item.symbol === "pc";
                      onChange({
                        [supplyItem.quantity.name]: {
                          ...supplyItem.quantity,
                          // value: toAmount(
                          //   parseAmountToNumber(supplyItem.quantity.value),
                          //   isProductPerPc ? "0,0" : "0,0.000"
                          // ),
                          value: supplyItem.quantity.value,
                        },
                      });
                    }}
                  />
                </Field>
              </div>
              <div className="col-span-3 flex items-start">
                <Input
                  {...supplyItem.cost}
                  iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
                />
                {supplyItems.value.length > 1 && (
                  <Icon
                    name="remove"
                    color="text-gray"
                    onClick={() => {
                      let supplyCost = 0;

                      const v = supplyItems.value.filter((item, itemIndex) => {
                        return index !== itemIndex;
                      });

                      v.forEach((field) => {
                        supplyCost = add(
                          supplyCost,
                          parseMoneyToNumber(field.value.cost.value).value
                        );
                      });

                      modifyForm({
                        [supplyItems.name]: {
                          value: v,
                        },
                        supplyCost: {
                          value: formatNumberToMoney(supplyCost),
                        },
                        retailPrice: {
                          value: formatNumberToMoney(
                            computeRetailPrice(supplyCost, form.markUp.value)
                          ),
                        },
                      });
                    }}
                  />
                )}
              </div>
            </div>
            {index + 1 !== supplyItems.value.length && (
              <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
            )}
          </div>
        );
      })}
      {!removeAddButton && (
        <div className="mt-md">
          <Button
            type={StyleType.Secondary}
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.addAnotherSupplyItem,
              });
              supplyItems.onChange(supplyItems.name, {
                value: [...supplyItems.value, SupplyItemField],
              });
            }}
          >
            {lang.addAnotherSupplyItem}
          </Button>
        </div>
      )}

      <AddNewSupplyItemModal onSubmit={submitSupplyItem} {...addNewSupplyItemModal} />
    </div>
  );
};

export default SupplyItemsField;
