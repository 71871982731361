import React from "react";
import { Path } from "paths";
import { CreateDiscount, DiscountList, EditDiscount } from "modules";
import { RouteRender } from "components/commons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const DiscountPage = () => {
  const flags = useFlags();
  const { discounts } = flags;
  
  useMount(() => {
    setPageTitle("discounts");
  });

  return (
    <RouteRender
      defaultRedirect={Path.DISCOUNT}
      renders={{
        [Path.DISCOUNT_CREATE]: {
          exact: true,
          component: CreateDiscount,
          featureFlag: discounts,
        },
        [Path.DISCOUNT_EDIT]: {
          exact: true,
          component: EditDiscount,
          featureFlag: discounts,
        },
        [Path.DISCOUNT]: {
          exact: true,
          component: DiscountList,
          featureFlag: discounts,
        },
      }}
    />
  );
};

export default DiscountPage;
