import { VenueContext } from "contexts";
import { useRouter, useMount, useApi } from "hooks";
import React, { useContext, useState, useEffect, useCallback } from "react";
import lang from "translations";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";
import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import { Skeleton, Panel, Title, Text, CheckboxField, ActionButton, Toast } from "components/commons";
import { Switch } from "components";
import Input from "components/commons/input/input";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber } from "services";
import { tipResponseMapper } from "mappers";
import { getTips, editTips } from "apis/tip.api";
import classnames from "classnames";

const TipSetting = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();

  const { request, loading, mappedData, error } = useApi({
    api: getTips,
    params: {
      venueId,
    },
    mapper: tipResponseMapper,
  });

  const { request: editTipsRequest, loading: submitting } = useApi({
    api: editTips,
    handleOwnError: {
      badrequest: true,
    },
  });

  const [mappedDataState, setMappedDataState] = useState(mappedData);

  useMount(async () => {
    await request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.taxSettings,
    });
  });

  useEffect(() => {
    setMappedDataState(mappedData)
  }, [mappedData]);

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    goToList();
  }, [goToList]);

  const submitUpdate = useCallback(async () => {
    try {
      await editTipsRequest({
        ...mappedDataState,
        venueId: venueId
      });
      history.push(Path.SETTING);
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      throw error;
    }
  }, [mappedDataState, editTipsRequest, venueId, history]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.tip}
          returnPath={Path.SETTING}
          onClick={() => history.push(Path.SETTING)}
        />
      }
    >
      {loading ? (
        <FragmentA title={lang.tip}>
          <Skeleton />
        </FragmentA>
      ) : (
        <>
          <FragmentA title={""} description={lang.manageTipDesc}>
            <Panel>
              <Title md className="mb-sm">
                <div className="inline-flex">
                  {lang.enableTip}
                  <Switch
                    className="ml-2"
                    value={mappedDataState.allowTip}
                    onChange={() => {
                      const newMappedDataState = {...mappedDataState};
                      newMappedDataState.allowTip = !newMappedDataState.allowTip;
                      setMappedDataState(newMappedDataState);
                    }}
                  />
                </div>
              </Title>
              <div className="flex items-center mt-6">
                <Text className={classnames(
                      "uppercase text-xs font-medium text-gray align-bottom py-sm",
                      "w-50"
                    )}>{lang.tipOptions}</Text>
                <Text className={classnames(
                      "uppercase text-xs font-medium text-gray align-bottom py-sm",
                      "w-50",
                      "text-left px-6"
                    )}>{lang.percentage}</Text>
              </div>
              {mappedData.tips && mappedData.tips.map((item, index) => (
                <div className="flex items-center mb-2">
                  <Text>{item.name}</Text>
                  <Input
                    className="ml-6"
                    key={index}
                    width="w-1/6"
                    value={item.value}
                    onChange={(name, obj) => {
                      const { value } = obj;
                      if (!isNumberValid(value) || !isValidDecimalPlaces(value, 3)) {
                        return;
                      }

                      const max = 9999999.999;
                      if (parseAmountToNumber(value) <= max) {
                        const newMappedData = {...mappedDataState};
                        newMappedData.tips = newMappedData.tips.map((i) => {
                          if (i.name === item.name) {
                            i.value = value;
                          }
                          return i;
                        });
                        setMappedDataState(newMappedData);
                      }
                    }}
                    iconSuffix={<span className="text-sm text-gray-400 pl-sm">%</span>}
                  />
                </div>
              ))}

              <CheckboxField
                className="mt-6"
                value={mappedDataState.allowGuestsCustomTip}
                onChange={() => {
                  const newMappedDataState = {...mappedDataState};
                  newMappedDataState.allowGuestsCustomTip = !newMappedDataState.allowGuestsCustomTip;
                  setMappedDataState(newMappedDataState);
                }}
                textSize="text-sm"
              >
                {lang.allowCustomTip}
              </CheckboxField>
            </Panel>
          </FragmentA>
          <ActionButton
            showLine
            loading={submitting}
            primary={{
              onClick: () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.saveCreditSetting,
                  Page: lang.creditSettings,
                });
                submitUpdate();
              },
              disabled: false,//!dirty,
            }}
            secondary={{
              text: lang.cancel,
              onClick: () => leavePage(),
            }}
          />
        </>
      )}
    </ModuleWrapper>
  );
};

export default TipSetting;
