import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    name = "",
    type,
    value = 0,
    calculateMethod,
    addToCart,
    locationIds,
    isActiveAllLocations,
    showInPos,
    venueId,
  } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    type: {
      name: "type",
      value: type,
      type: Field.RADIO,
      validations: [Validation.required()],
      required: true,
    },
    value: {
      name: "value",
      value: value,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
    },
    calculateMethod: {
      name: "calculateMethod",
      value: calculateMethod,
      type: Field.RADIO,
      // label: lang.selectNumberOfTables,
      validations: [Validation.required()],
      required: true,
    },
    addToCart: {
      type: Field.CHECKBOX,
      value: addToCart,
      name: "addToCart",
      required: true,
    },
    showInPos: {
      type: Field.CHECKBOX,
      value: showInPos,
      name: "showInPos",
      required: true,
    },
    locationIds: {
      name: "locationIds",
      value: locationIds,
      type: Field.DROPDOWN,
      placeholder: lang.chooseAtleast1Location,
      selectAllText: lang.allLocations,
      isAll: isActiveAllLocations,
      validations: [Validation.requiredMultiDropdown(lang.pleaseSelectLocation)],
      required: true,
    },
    venueId: {
      name: "venueId",
      value: venueId,
      type: Field.INPUT,
      required: true,
    },
  };
};

export default initialFormState;
