import React from "react";
import { Path } from "paths";
import { RouteRender } from "components/commons";
import { MpBalanceMovementInList, MpBalanceMovementOutList } from "modules";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const MultiPropertyPage = () => {
  const flags = useFlags();
  const { multiProperty } = flags;

  useMount(() => {
    setPageTitle("multiProperty");
  });

  return (
    <RouteRender
      defaultRedirect={Path.MULTI_PROPERTY_BALANCE_MOVEMENT_IN}
      renders={{
        [Path.MULTI_PROPERTY_BALANCE_MOVEMENT_IN]: {
          exact: true,
          component: MpBalanceMovementInList,
          featureFlag: multiProperty,
        },
        [Path.MULTI_PROPERTY_BALANCE_MOVEMENT_OUT]: {
          exact: true,
          component: MpBalanceMovementOutList,
          featureFlag: multiProperty,
        },
      }}
    />
  );
};

export default MultiPropertyPage;
