import { setVenue } from "apis";
import { Toast } from "components";
import { VenueContext } from "contexts";
import { useApi, useMount } from "hooks";
import { useCallback, useContext } from "react";
import lang from "translations";

const useToggle = () => {
  const { venue, fetchVenue, loading } = useContext(VenueContext);
  const checked = venue.isEnableExitAccessPoint;

  const { request, loading: submitting } = useApi({
    api: setVenue,
    params: {
      venueId: venue.venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const onToggle = useCallback(async () => {
    try {
      await request({ isEnableExitAccessPoint: !checked });
      await fetchVenue();
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      Toast({
        content: lang.somethingWentWrong,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [checked, fetchVenue, request]);

  useMount(async () => {
    fetchVenue();
  });

  return {
    checked,
    loading: loading || submitting,
    onToggle,
  };
};

export default useToggle;
