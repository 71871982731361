import { searchCategoryReport, getCategoryDetailReport, searchCategoryReportExport } from "apis";
import { Button, DataTable, Text, Icon } from "components/commons";
import { ColumnModal } from "components/modals";
import { VenueContext, StaffContext } from "contexts";
import { useApi, useFilter, useModal, useMount } from "hooks";
import {
  categoryReportListResponse,
  categoryReportListRequest,
  categoryDetailReportListResponse,
} from "mappers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { mapObject, pluralize } from "services";
import { categoryReportFilterState } from "./filters";
import { categoryDetailReportColumn } from "./columns";
import CategoryReportFilter from "./category-report-filter";
import lang from "translations";
import { StyleType } from "enums";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { ProductNamePopover } from "components/popovers";
import { mixpanel, TrackEvent } from "mixpanel";

const CategoryReport = () => {
  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const [showCategoryDetailResults, setShowCategoryDetailResults] = useState();
  const [columnValues, setColumnValues] = useState({
    grossProfit: true,
    sale: true,
    supplyValue: true,
    margin: true,
    discountApplied: true,
    voucherApplied: true,
    guestName: true,
    staffName: true,
    location: true,
  });

  const columnModal = useModal();

  const {
    request: searchCategoryReportRequest,
    loading: loadingCategoryReports,
    result: searchCategoryReportResult = { data: [], metadata: { total: 0 } },
    mappedData: categoryReportsData,
    error: errorCategoryReports,
  } = useApi({
    api: searchCategoryReport,
    isArray: true,
    mapper: categoryReportListResponse,
  });

  const { request: searchCategoryReportExportRequest, loading: searchCategoryReportExportLoading } =
    useApi({
      api: searchCategoryReportExport,
    });

  const {
    request: getCategoryDetailReportRequest,
    loading: loadingCategoryDetailReports,
    result: getCategoryDetailReportResult = {
      data: [],
      metadata: { total: 0, totalTransaction: 0 },
    },
    mappedData: categoryDetailReportsData,
    error: errorCategoryDetailReports,
  } = useApi({
    api: getCategoryDetailReport,
    isArray: true,
    mapper: categoryDetailReportListResponse,
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    categoryReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchCategoryReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.categoryReport,
    });
  });

  const showCategoryDetailResultsCb = useCallback((filterState) => {
    if (filterState.category || filterState.category === 0) {
      setShowCategoryDetailResults(true);
    } else {
      setShowCategoryDetailResults(false);
    }
  }, []);

  const fetchCategoryReports = useCallback(
    async (filterState, requestState) => {
      await searchCategoryReportRequest(mapObject({ ...requestState }, categoryReportListRequest));
      showCategoryDetailResultsCb(filterState);
    },
    [searchCategoryReportRequest, showCategoryDetailResultsCb]
  );

  const fetchCategoryDetailReports = useCallback(
    async (filterState, requestState) => {
      await getCategoryDetailReportRequest(mapObject({ ...requestState }, categoryReportListRequest));
      showCategoryDetailResultsCb(filterState);
    },
    [getCategoryDetailReportRequest, showCategoryDetailResultsCb]
  );

  const onClickCategory = useCallback(
    (categoryId) => {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.viewCategoryDetails,
        Page: lang.categoryReport,
      });

      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey: "",
        sort: { key: "dateAndTime", value: "desc" },
        category: categoryId,
      });
      fetchCategoryDetailReports(filterState, requestState);
    },
    [fetchCategoryDetailReports, modifyFilters]
  );

  const renderName = useCallback((name, id) => {
    const linkCategory = !(staff?.roles?.includes("FRONT_OFFICE") && staff?.roles?.length === 1);

    // Uncategorized
    if (id === 0) {
      return (
        <Text className="break-word">
          {name}
        </Text>
      );
    }

    return (
      <a href={ linkCategory ? "/product/category/" + id : null } target="_blank" rel="noreferrer">
        <Text color="text-pelorous cursor-pointer" className="break-word">
          {name}
        </Text>
      </a>
    );
  }, [staff]);

  const prepareCategoryReports = useCallback(() => {
    console.log(categoryReportsData);
    return categoryReportsData
      .sort((a, b) => (a.id === 0 ? -1 : b.id === 0 ? 1 : 0)) // put Uncategorized at the top
      .map((categoryReport) => {
        const { id, name, grossProfit, sale, supplyValue, margin, itemSold } = categoryReport;
        return {
          id,
          name: renderName(name, id),
          grossProfit: grossProfit,
          sale: sale,
          supplyValue: supplyValue,
          margin,
          itemSold,
        };
      });
  }, [categoryReportsData, renderName]);

  const categoryReports = useMemo(() => {
    return prepareCategoryReports();
  }, [prepareCategoryReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchCategoryReports(filterState, requestState);
    },
    [fetchCategoryReports]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      if (filterState.category || filterState.category === 0) {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "dateAndTime", value: "desc" },
        });
        await fetchCategoryDetailReports(filterState, requestState);
      } else {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "sale", value: "desc" },
        });
        await fetchCategoryReports(filterState, requestState);
      }
    },
    [fetchCategoryReports, fetchCategoryDetailReports, modifyFilters, filterState.category]
  );

  const renderDateAndTime = useCallback((dateAndTime) => {
    return (
      <div>
        <Text>{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderTransaction = useCallback((transactionId) => {
    return (
      <a href={"/transaction/" + transactionId} target="_blank" rel="noreferrer">
        <Text color="text-pelorous" className="whitespace-nowrap">{`#${transactionId}`}</Text>
      </a>
    );
  }, []);

  const renderProduct = useCallback((productId, productName) => {
    return (
      <ProductNamePopover productId={productId}>
        <a href={"/product/edit/" + productId} target="_blank" rel="noreferrer">
          <Text color="text-blue" className="whitespace-nowrap">
            {productName}
          </Text>
        </a>{" "}
      </ProductNamePopover>
    );
  }, []);

  const prepareCategoryDetailReports = useCallback(() => {
    return categoryDetailReportsData.map((categoryDetailReport) => {
      const {
        dateAndTime,
        transactionId,
        productId,
        product,
        grossProfit,
        sale,
        supplyValue,
        margin,
        discountApplied,
        voucherApplied,
        guestName,
        staffName,
        location,
      } = categoryDetailReport;

      return {
        dateAndTime: renderDateAndTime(dateAndTime),
        transaction: renderTransaction(transactionId),
        product: renderProduct(productId, product),
        grossProfit: grossProfit,
        sale: sale,
        supplyValue: <span className="whitespace-nowrap text-sm">{supplyValue}</span>,
        margin,
        discountApplied: discountApplied,
        voucherApplied,
        guestName,
        staffName,
        location,
      };
    });
  }, [categoryDetailReportsData, renderDateAndTime, renderTransaction, renderProduct]);

  const categoryDetailReports = useMemo(() => {
    return prepareCategoryDetailReports();
  }, [prepareCategoryDetailReports]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      if (showCategoryDetailResults) {
        fetchCategoryDetailReports(filterState, requestState);
      } else {
        fetchCategoryReports(filterState, requestState);
      }
    },
    [fetchCategoryReports, fetchCategoryDetailReports, showCategoryDetailResults, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchCategoryReportExportRequest(mapObject({ ...requestState }, categoryReportListRequest));
  }, [searchCategoryReportExportRequest, requestState]);

  const categoryDetailTableRightContent = useMemo(() => {
    if (showCategoryDetailResults) {
      return (
        <div>
          <Button
            type={StyleType.Secondary}
            onClick={() => {
              columnModal.show();
            }}
          >
            {lang.showHideColumns}
          </Button>
        </div>
      );
    }
  }, [showCategoryDetailResults, columnModal]);

  const categoryDetailResultContent = useMemo(() => {
    if (showCategoryDetailResults) {
      return `${getCategoryDetailReportResult.metadata.totalTransaction} ${pluralize(
        getCategoryDetailReportResult.metadata.totalTransaction,
        lang.transaction,
        lang.transactions
      )}`;
    }
  }, [getCategoryDetailReportResult.metadata.totalTransaction, showCategoryDetailResults]);

  const prepareColumns = useCallback(() => {
    if (showCategoryDetailResults) {
      return categoryDetailReportColumn(columnValues);
    }
    return [
      {
        key: "name",
        text: lang.categoryName,
        width: "w-96",
      },
      {
        key: "grossProfit",
        text: lang.grossProfit,
        align: "right",
        sort: true,
      },
      {
        key: "sale",
        text: lang.sales,
        align: "right",
        sort: true,
      },
      {
        key: "supplyValue",
        text: lang.supplyValue,
        align: "right",
      },
      {
        key: "margin",
        text: lang.margin,
        align: "right",
      },
      {
        key: "itemSold",
        text: lang.itemsSold,
        align: "right",
      },
      {
        key: "action",
        actions: true,
        align: "right",
        actionOptions: [
          {
            text: lang.viewDetails,
            onClick: (column) => {
              onClickCategory(column.id);
            },
          },
        ],
      },
    ];
  }, [columnValues, showCategoryDetailResults, onClickCategory]);

  const columns = useMemo(() => {
    return prepareColumns();
  }, [prepareColumns]);

  const onChangeColumnCb = useCallback(
    (name, { value }) => {
      setColumnValues({ ...columnValues, [name]: value });
    },
    [columnValues]
  );

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.categoryReport}
        description={lang.viewAndExportSalesReportByCategories}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchCategoryReportExportLoading,
          disabled: searchCategoryReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <CategoryReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {categoryReports.length > 0 || loadingCategoryReports || loadingCategoryDetailReports ? (
        <DataTable
          page={filterState.page}
          resultContent={categoryDetailResultContent}
          renderRightContent={categoryDetailTableRightContent}
          bottomLeftContent={
            <p className="text-sm text-gray-500">{lang.disclaimerCategoryReport}</p>
          }
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={showCategoryDetailResults ? fetchCategoryDetailReports : fetchCategoryReports}
          total={
            showCategoryDetailResults
              ? getCategoryDetailReportResult.metadata.total
              : searchCategoryReportResult.metadata.total
          }
          loading={loadingCategoryReports || loadingCategoryDetailReports}
          columns={columns}
          data={showCategoryDetailResults ? categoryDetailReports : categoryReports}
          error={errorCategoryReports || errorCategoryDetailReports}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}

      <ColumnModal
        {...columnModal}
        columns={[
          [
            { text: lang.grossProfit, name: "grossProfit" },
            { text: "Sales", name: "sale" },
          ],
          [
            { text: lang.supplyValue, name: "supplyValue" },
            { text: lang.margin, name: "margin" },
          ],
          [
            { text: lang.discountApplied, name: "discountApplied" },
            { text: lang.voucherApplied, name: "voucherApplied" },
          ],
          [
            { text: lang.guestName, name: "guestName" },
            { text: lang.staffName, name: "staffName" },
          ],
          [{ text: lang.location, name: "location" }],
        ]}
        columnValues={columnValues}
        onChange={onChangeColumnCb}
      />
    </ModuleWrapper>
  );
};

export default CategoryReport;
