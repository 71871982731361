import React from "react";
import { Title, Text, Button, ButtonLink, Image, Preview } from "components/commons";
import { useRouter } from "hooks";
import classNames from "classnames";
import { isValidURL } from "services/url.service";

const HeaderD = ({
  logoUrl,
  title,
  label,
  description,
  button,
  className,
  returnText,
  returnPath,
  loading,
  background = null,
}) => {
  const { history } = useRouter();
  const {
    path,
    text,
    validate,
    disabled: primaryDisabled = false,
    loading: primaryLoading = false,
    onClick,
    iconPrefix,
  } = button || {};

  const { location } = useRouter();
  const { state } = location;
  const { fromList } = state || {};
  return (
    <div className={classNames(className)}>
      {returnText && (
        <div className="mb-md">
          <ButtonLink
            loading={loading}
            icon="circle-left"
            className="-mx-2"
            path={fromList ? null : returnPath}
            flex={false}
          >
            {returnText}
          </ButtonLink>
        </div>
      )}
      <div className={"md:flex items-center justify-between"}>
        {/* <div className={"flex gap-4"}> */}
        <div className="w-full grid grid-cols-5 gap-2">
          <div className={classNames("col-span-1")}>
            {/* <Image title={title} src={logoUrl} className="max-h-20 max-2-20 rounded-md" /> */}
            {isValidURL(logoUrl) ? (
              <Image title={title} src={logoUrl} className="max-h-20 max-2-20 rounded-md" />
            ) : (
              <Preview
                image={logoUrl}
                color={background}
                title={title}
                useInitials
                className="max-h-20 max-2-20"
              />
            )}
          </div>
          <div className="col-span-4">
            <Title xl className="font-semibold">
              {title}
            </Title>
            {label && <Text label>{label}</Text>}
            {description && <Text>{description}</Text>}
          </div>
        </div>
        <div className="w-1/5">
          {button && (
            <Button
              loading={primaryLoading}
              disabled={primaryDisabled || primaryLoading}
              className="ml-0 mt-sm md:ml-md"
              onClick={
                onClick
                  ? () => {
                      onClick();
                    }
                  : () => {
                      const validated = validate ? validate() : true;
                      if (validated) {
                        history.push(path);
                      }
                    }
              }
              iconPrefix={iconPrefix}
            >
              {text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderD;
