import { searchSupplyItem, searchSupplyItemTransfer } from "apis";
import { Button, DataTable, Field, Input, Modal, Select, Toast } from "components/commons";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi, useFilter, useModal, useSelectItems } from "hooks";
import { supplyItem } from "mappers";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { formatNumberToMoney, mapObjectsToSelect } from "services";
import lang from "translations";
import styles from "./style.scss";
import classNames from "classnames";
import { postSupplyItemTransfer } from "apis/supply-item.api";

const filterKey = "productId";

const Transfer = ({ open, listData, onClose, onSuccess, locationOptions }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const transferModal = useModal();
  const { modifyFilter, filterState, clearFilter } = useFilter(
    {
      source: undefined,
      destination: undefined,
      venueId,
    },
    {
      source: undefined,
      destination: undefined,
      venueId,
    }
  );
  const [listId, setListId] = useState([]);
  const [list, setList] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const {
    request: fetchList,
    loading = true,
    mappedData = [],
  } = useApi({
    api: searchSupplyItemTransfer,
    isArray: true,
    mapper: {
      _keys: ["sourceInStock", "productId", "productName", "measurementUnit", "destinationInStock"],
    },
    params: {
      venueId,
    },
  });
  const [needAll, setNeedAll] = useState(true);
  const {
    selected,
    setSelected,
    isAllSelected,
    setSelectAll,
    selectedCount,
    clearSelected,
    replaceSelected,
  } = useSelectItems({
    items: list,
    indeterminate: true,
    key: filterKey,
  });
  const [actionId, setActionId] = useState({ on: 0, off: 0 });
  const errQuantity = useMemo(() => {
    let err = false;
    let isZero = true;
    const length = Object.values(selected).length;
    for (let i = 0; i < length; i++) {
      const k = Object.keys(selected)[i];
      // eslint-disable-next-line eqeqeq
      const v = list.find((i) => i.productId == k);
      if (v.quantity) {
        isZero = false;
      }
      if (v.sourceInStock < (v.quantity || 0)) {
        err = true;
        break;
      }
    }
    return err || isZero;
  }, [selected, list]);

  const column = useMemo(
    () => [
      {
        key: "name",
        text: lang.supplyItem,
        align: "left",
        custom: true,
      },
      {
        key: "measurement",
        text: lang.measurement,
        // custom: true,
        align: "right",
      },
      {
        key: "sourceLocationOriginalQty",
        text: lang.sourceLocationOriginalQty,
        // custom: true,
        align: "right",
      },
      {
        key: "quantity",
        text: lang.sourceLocationTransferQty,
        custom: true,
        align: "right",
      },
      {
        key: "sourceLocationRemainQty",
        text: lang.sourceLocationRemainQty,
        // custom: true,
        align: "right",
      },
      {
        key: "destinationLocationNewQty",
        text: lang.destinationLocationNewQty,
        // custom: true,
        align: "right",
      },
    ],
    []
  );

  const onChangeTransferQty = useCallback(
    (value, i) => {
      const temp = [...list];
      const row = temp[i];
      row.quantity = Number(value?.replaceAll(",", ""));
      setList(temp);
    },
    [list]
  );

  const onSelectAddItem = useCallback(
    (v) => {
      setListId([...listId, v]);
      setIsAdding(false);
      setActionId({ on: v, off: 0 });
    },
    [listId]
  );

  const onSelectEditItem = useCallback(
    (v, i) => {
      const temp = [...listId];
      setActionId({ on: v, off: temp[i] });
      temp[i] = v;
      setListId(temp);
    },
    [listId]
  );

  const onTransfer = useCallback(async () => {
    try {
      const ids = Object.values(selected).map(({ productId }) => productId);
      const transferData = list
        .filter((i) => ids.includes(i.productId) && !!i.quantity)
        .map(({ productId, quantity }) => ({
          supplyItemId: productId,
          quantity: quantity,
        }));

      await postSupplyItemTransfer({
        venueId,
        sourceLocationId: filterState.source,
        destinationLocationId: filterState.destination,
        transferData,
      });
      transferModal.close();
      onSuccess();
      Toast({
        content: lang.transferSupplyItemsSuccessfully,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      console.log("ERROR TRANSFER", error);
      Toast({
        content: lang.somethingWentWrong,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [
    filterState.destination,
    filterState.source,
    list,
    onSuccess,
    selected,
    transferModal,
    venueId,
  ]);

  const renderData = useMemo(() => {
    const excludeSupplyIds = list.length ? list.map((i) => i.productId) : null;
    const data = list.map(
      (
        { productId, productName, measurementUnit, sourceInStock, destinationInStock, quantity },
        i
      ) => {
        let transfer = quantity || 0;
        return {
          id: productId,
          name: (
            <ProductName
              name={productName}
              excludeSupplyIds={excludeSupplyIds}
              onChange={(v) => {
                onSelectEditItem(v, i);
              }}
            />
          ),
          measurement: measurementUnit,
          sourceLocationOriginalQty: sourceInStock !== null && String(sourceInStock),
          quantity: (
            <InputNum
              i={i}
              value={transfer}
              onBlur={onChangeTransferQty}
              max={Number(sourceInStock || 0)}
            />
          ),
          sourceLocationRemainQty: sourceInStock !== null && String(sourceInStock - transfer),
          destinationLocationNewQty:
            destinationInStock !== null && String(destinationInStock + transfer),
        };
      }
    );
    if (isAdding) {
      const rowAdd = {
        name: <SelectSupplyItem params={{ excludeSupplyIds }} onChange={onSelectAddItem} />,
        measurement: "-",
        sourceLocationOriginalQty: "-",
        quantity: "-",
        sourceLocationRemainQty: "-",
        destinationLocationNewQty: "-",
      };
      data.push(rowAdd);
    }
    return data;
  }, [isAdding, list, onChangeTransferQty, onSelectAddItem, onSelectEditItem]);

  const addItem = useCallback(() => {
    if (!isAdding) setIsAdding(true);
  }, [isAdding]);

  const fetchListTransfer = useCallback(() => {
    if (listId && listId.length) {
      fetchList({
        supplyIds: listId,
        page: 0,
        sourceLocationId: filterState.source,
        destinationLocationId: filterState.destination,
      });
    } else {
      setList([]);
    }
  }, [fetchList, filterState.destination, filterState.source, listId]);

  const handleSetSelectItems = (items = []) => {
    const obj = {};
    items.forEach((item, index) => {
      obj[item[filterKey]] = {
        ...item,
        index,
        checked: true,
      };
    });
    replaceSelected(obj);
  };

  useEffect(() => {
    let temp = [...mappedData];
    temp = temp.map((i) => {
      const v = list.find((e) => e.productId === i.productId);
      return { ...v, ...i };
    });
    setList(temp);

    if (actionId.on || actionId.off) {
      const arrId = Object.keys(selected).map((i) => Number(i));
      handleSetSelectItems(
        temp.filter(({ productId }) => {
          if (productId === actionId.off) return false;
          if (productId === actionId.on || arrId.includes(productId)) return true;
          return false;
        })
      );
    }

    if (!loading && needAll) {
      handleSetSelectItems(temp);
      setNeedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  useEffect(() => {
    if (open) {
      setListId(listData);
    }
    return () => {
      setNeedAll(true);
      clearSelected();
      setIsAdding(false);
      clearFilter();
      setActionId({ on: 0, off: 0 });
      setList([]);
      setListId([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, listData]);

  useEffect(() => {
    fetchListTransfer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, filterState]);

  return (
    <div className={`${styles["container-content"]} pt-4 pb-20 px-3 flex flex-col`}>
      <p className="text-pelorous-darker text-xl font-semibold">{lang.transferSupplyItems}</p>
      <div className="pt-3.5 pb-8 flex gap-16">
        <FieldSelect
          label={lang.sourceLocation}
          name="source"
          value={filterState.source}
          onChange={modifyFilter}
          subtractValue={filterState.destination}
          locationOptions={locationOptions}
        />
        <FieldSelect
          label={lang.destinationLocation}
          name="destination"
          value={filterState.destination}
          onChange={modifyFilter}
          subtractValue={filterState.source}
          locationOptions={locationOptions}
        />
        <div className="col-span-4 flex items-end mb-2.5">
          <Button type={StyleType.Primary} onClick={addItem}>
            {lang.addSupplyItem}
          </Button>
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="flex-1 overflow-auto">
        <DataTable
          loading={listId.length && loading}
          pageable={false}
          total={listId.length}
          columns={column}
          data={renderData}
          selected={selected}
          setSelected={(id) => {
            setActionId({ off: 0, on: 0 });
            setSelected(id);
          }}
          isAllSelected={isAllSelected}
          setSelectAll={setSelectAll}
        />
      </div>
      <div className="mt-10 flex justify-end gap-4">
        <Button type={StyleType.Secondary} onClick={onClose}>
          {lang.discard}
        </Button>
        <Button
          onClick={transferModal.show}
          disabled={
            !filterState.source || !filterState.destination || !selectedCount || errQuantity
          }
        >
          {lang.transfer}
        </Button>
      </div>
      <Modal
        {...transferModal}
        customTitle={
          <p className="text-xl text-pelorous-darker font-semibold">{`${lang.confirmTransfer} ?`}</p>
        }
      >
        <div className="pb-5">
          <p className="text-base mb-7">
            {lang.populate(lang.transferSupplyItemsTxtConfirm, [
              getLocationName(locationOptions, filterState.source),
              getLocationName(locationOptions, filterState.destination),
            ])}
            {"?"}
          </p>

          <div className="flex justify-end gap-2">
            <Button type={StyleType.Secondary} onClick={transferModal.close}>
              {lang.discard}
            </Button>
            <Button type={StyleType.Danger} onClick={onTransfer}>
              {lang.confirmTransfer}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const FieldSelect = memo(
  ({ label, requestState, subtractValue, locationOptions, loading, ...props }) => {
    const options = useMemo(() => {
      return locationOptions.filter((i) => i.value !== subtractValue);
    }, [locationOptions, subtractValue]);

    return (
      <Field className="flex-1" filterLabel={label} required>
        <Select {...props} loading={loading} options={options} placeholder={lang.chooseLocation} />
      </Field>
    );
  }
);

const InputNum = ({ i, value, onBlur, max }) => {
  const [val, setVal] = useState(value);
  const err = max < (val || 0);
  return (
    <div className="flex flex-col items-end">
      <div className="w-28">
        <Input
          right
          value={formatNumberToMoney(val, true, true)}
          onChange={(name, { value }) => {
            setVal(value?.replaceAll(",", ""));
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onBlur={(value) => onBlur(value, i)}
        />
      </div>
      {err && (
        <p className={classNames("whitespace-nowrap", styles["p-err"])}>
          Transfer quantity can not more than original quantity
        </p>
      )}
    </div>
  );
};

const SelectSupplyItem = ({ params, onChange, onBlur }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  // const [searchStr, setSearchStr] = useState("");
  const {
    request,
    loading = true,
    result = { data: [] },
  } = useApi({
    api: searchSupplyItem,
    isArray: true,
    mapper: supplyItem,
    params: {
      venueId,
      pageSize: 9999,
    },
  });
  const options = useMemo(
    () => mapObjectsToSelect(result.data, { textKey: "productName", valueKey: "productId" }),
    [result.data]
  );
  // const debounced = debounce((str) => {
  //   setSearchStr(str);
  // }, 300);

  useEffect(() => {
    request({
      ...params,
      // searchKey: searchStr,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      options={options}
      loading={loading}
      className="w-48"
      searchable
      // onSearch={debounced}
      onChange={(name, { value }) => onChange(value)}
      onBlur={onBlur}
    />
  );
};

export default memo(Transfer);

const getLocationName = (list = [], id) => {
  return list.find((i) => i.value === id)?.text || "";
};

const ProductName = ({ name, excludeSupplyIds, onChange }) => {
  const [editing, setEditing] = useState(false);

  return editing ? (
    <div>
      <SelectSupplyItem
        params={{ excludeSupplyIds }}
        onChange={onChange}
        onBlur={() => setEditing(false)}
      />
    </div>
  ) : (
    <div onDoubleClick={() => setEditing(true)}>{name}</div>
  );
};
