import { ApiPath } from "paths";
import { Post, Get } from "services";

export const createSupplyItem = async (body) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM}`, body);
  return res;
};

export const searchSupplyItem = async (params) => {
  const res = await Post(`${ApiPath.SEARCH_SUPPLY_ITEM}`, params);
  return res;
};

export const searchSupplyItemTransfer = async (params) => {
  const res = await Post(`${ApiPath.SEARCH_SUPPLY_ITEM_TRANSFER}`, params);
  return res;
};

export const postSupplyItemTransfer = async (params) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_TRANSFER}`, params);
  return res;
};

export const getSupplyItems = async (params) => {
  const res = await Get(`${ApiPath.SUPPLY_ITEM_VENUE_ID(params.venueId)}`, params);
  return res;
};

export const getSupplyItem = async ({ id }) => {
  const res = await Get(`${ApiPath.SUPPLY_ITEM_ID(id)}`);
  return res;
};

export const updateSupplyItem = async (body) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_ID(body.productId)}`, body);
  return res;
};

export const exportSupplyItem = async (params) => {
  const res = await Post(`${ApiPath.EXPORT_SUPPLY_ITEM}`, params);
  return res;
};

export const importFileSupplyItem = async (params) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_IMPORT}`, params);
  return res.data;
};

export const importSaveSupplyItem = async (params) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_IMPORT_SAVE}`, params);
  return res.data;
};

export const importFileSupplyItemWholeProduct = async (params) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_IMPORT_WHOLE_PRODUCT}`, params);
  return res.data;
};

export const importSaveSupplyItemWholeProduct = async (params) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_IMPORT_WHOLE_PRODUCT_SAVE}`, params);
  return res.data;
};

export const fetchListStockAdj = async ({ location, ...params }) => {
  const res = await Post(`${ApiPath.FETCH_LIST_STOCK_ADJ_URL(location)}`, params);
  return res;
};

export const postSupplyItemStockAdjustment = async ({ location, ...params }) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_STOCK_ADJ(location)}`, params);
  return res.data;
};
