import { Put, Post, Delete } from "services";
import { ApiPath } from "paths";

export const getTips = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_TIPS}`, body);
  return res;
};

export const createTip = async (body) => {
  const res = await Put(`${ApiPath.TIP}`, body);
  return res;
};

export const deleteTip = async ({ tipId }) => {
  const res = await Delete(`${ApiPath.TIP_ID(tipId)}`);
  return res;
};

export const editTips = async (body) => {
  const res = await Post(`${ApiPath.TIP}`, body);
  return res;
};

export const searchTipReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_TIPS_REPORT}`, body);
  return res;
};

export const searchTipReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_TIPS_REPORT_EXPORT}`, body);
  return res;
};