import { deleteStaffs } from "apis/staff.api";
import { Button, Modal, Toast } from "components";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi } from "hooks";
import React, { useCallback, useContext } from "react";
import lang from "translations";

const DeleteModal = ({ ids, onClose, active, success }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const { request, loading } = useApi({
    api: deleteStaffs,
    params: {
      profileIds: ids,
      venueId,
    },
  });

  const onDelete = useCallback(async () => {
    try {
      await request();
      success();
      Toast({
        content: lang.deleteStaffSuccessfully,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      Toast({
        content: lang.somethingWentWrongPlsTryAgain,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [request, success]);

  return (
    <Modal
      active={active}
      customTitle={<p className="text-xl text-pelorous-darker font-semibold">{lang.removeStaff}</p>}
      width={551}
      close={onClose}
    >
      <div className="my-6">{lang.deleteStaffModalDesc}</div>
      <div className="border border-solid border-yellow-darker bg-yellow-lighter p-2.5 rounded">
        {lang.deleteStaffModalNote}
      </div>
      <div className="flex justify-end gap-4 pt-7 pb-5">
        <Button type={StyleType.Secondary} onClick={onClose}>
          {lang.discard}
        </Button>
        <Button type={StyleType.Danger} onClick={() => onDelete()} disabled={loading}>
          {lang.delete}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
