import { ActionButton, Modal, Text, Title } from "components/commons";
import { StyleType } from "enums";
import React, { useMemo } from "react";
import lang from "translations";

const DeleteModal = ({
  subject,
  subjectName,
  count = 1,
  active,
  close,
  ok,
  deleting,
  customContent,
  primaryText,
  customHeader,
  ...props
}) => {
  const multiple = useMemo(() => {
    return count > 1 || !subjectName;
  }, [count, subjectName]);

  const header = useMemo(() => {
    if (multiple) {
      return lang.populate(lang.deleteThisMultiple, [count, subject]);
    }
    return lang.populate(lang.deleteThis, [subject]);
  }, [multiple, count, subject]);

  const content = useMemo(() => {
    if (multiple) {
      return lang.populate(lang.youAreAboutToDeleteMultiple, [count, subject]);
    }
    return lang.populate(lang.youAreAboutToDelete, [subject, subjectName]);
  }, [multiple, count, subject, subjectName]);

  const deleteText = useMemo(() => {
    if (multiple) {
      return lang.populate(lang.deleteCategories, [count, subject]);
    }
    return lang.delete + " " + (subject ? subject.charAt(0).toUpperCase() + subject.slice(1) : "");
  }, [multiple, count, subject]);

  return (
    <Modal
      {...props}
      noHeader
      active={active}
      actionContent={
        <ActionButton
          loading={deleting}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            text: primaryText || deleteText,
            type: StyleType.Danger,
            onClick: () => {
              ok();
            },
          }}
        />
      }
    >
      {customHeader || (
        <Title lg className="mb-sm pt-md">
          {header}
        </Title>
      )}
      {customContent || <Text>{content}</Text>}
    </Modal>
  );
};

export default DeleteModal;
