import React, { useCallback, useMemo } from "react";
import {
  Panel,
  Field,
  Input,
  Skeleton,
  MultipleSelect,
  FieldWithTitle,
  Switch,
  ButtonLink,
  Checkbox,
  Text,
} from "components/commons";
import { FragmentA } from "components/fragments";
import { StaffRole } from "enums";
import lang from "translations";
import { sortByKeyName } from "services";
import { Path } from "paths";

const StaffFormFields = ({
  fields,
  formTitle,
  modifyField,
  loading,
  requestPaymentMethod,
  requestLocations,
  // requestStaffProfile,
  requestGuestProfiles,
  requestAccessControls,
  modifyForm,
  emailBottomRender,
  basicInfoRender,
  isAwait,
  isEdit,
  noLocationModal,
  setPristine,
  dirty,
  isManager,
}) => {
  const roles = useMemo(() => {
    const role = fields.role.value;
    if (!role) {
      return [];
    }
    if (role && fields.role.isAll) {
      return [
        StaffRole.TopupCredits,
        StaffRole.RegisterGuests,
        StaffRole.SellOnPos,
        StaffRole.StockManager,
        StaffRole.FrontOffice,
        StaffRole.BackOffice,
        StaffRole.AccessControl,
      ];
    }
    return role;
  }, [fields.role]);

  const hasRole = useCallback((roles = [], items = []) => {
    if (!roles.length) return true; // all role
    for (let i = 0; i < items.length; i++) {
      if (roles.includes(items[i])) return true;
    }
    return false;
  }, []);

  return (
    <>
      {!isAwait && isEdit && !loading && (
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <FieldWithTitle {...fields.isActive}>
              <Switch {...fields.isActive} onChange={modifyField} />
            </FieldWithTitle>
            {basicInfoRender}
          </Panel>
        </FragmentA>
      )}
      <FragmentA title={formTitle}>
        {loading ? (
          <Panel>
            <Skeleton />
          </Panel>
        ) : (
          <Panel>
            {((isAwait && isEdit) || !isEdit) && (
              <Field {...fields.email}>
                <Input
                  {...fields.email}
                  onChange={modifyField}
                  disabled={isAwait || requestPaymentMethod.loading || requestLocations.loading}
                />
                {emailBottomRender}
              </Field>
            )}

            <Field {...fields.role} className="mt-sm pl-md pr-md pt-sm pb-sm bg-gray-50 rounded">
              {roles.length === 0 && dirty && (
                <Text className="text-red">Please select atleast one role.</Text>
              )}
              <div className="mb-sm mt-sm">
                <div className="flex items-baseline pr-sm">
                  <Checkbox
                    disabled={isAwait}
                    value={roles.includes(StaffRole.Manager) || fields.role.isAll}
                    onChange={(name, { value }) => {
                      const allList = {
                        paymentMethod: {
                          value: [],
                          disabled: true,
                          isAll: true,
                        },
                        location: {
                          value: [],
                          disabled: true,
                          isAll: true,
                        },
                        guestProfiles: {
                          value: [],
                          disabled: true,
                          isAll: true,
                        },
                        accessControls: {
                          value: [],
                          disabled: true,
                          isAll: true,
                        },
                      };
                      if (value) {
                        modifyForm({
                          role: {
                            value: [StaffRole.Manager],
                            isAll: true,
                          },
                          ...allList,
                        });
                      } else {
                        modifyForm({
                          role: {
                            value: [],
                            isAll: false,
                          },
                          ...allList,
                        });
                      }
                    }}
                  ></Checkbox>
                  <Text className="text-base text-font-bold text-pelorous-darker pl-sm hover-cursor">
                    {lang.manager}
                  </Text>
                </div>
                <Text className="text-sm text-gray">{lang.managerRoleDesc}</Text>
              </div>
              {fields.role.options &&
                fields.role.options.sort(sortByKeyName("text")).map((data) => {
                  return (
                    <div className="mb-sm mt-lg ml-xl">
                      <div className="flex items-baseline pr-sm">
                        <Checkbox
                          name={data.value}
                          disabled={
                            isAwait || roles.includes(StaffRole.Manager) || fields.role.isAll
                          }
                          value={roles.includes(data.value) || roles.includes(StaffRole.Manager)}
                          onChange={(name, { value }) => {
                            if (value) {
                              let merged = [...roles.slice(), ...[name]].filter((role) => {
                                return role !== StaffRole.Manager;
                              });
                              let hasPaymentMethod =
                                merged.includes(StaffRole.TopupCredits) ||
                                merged.includes(StaffRole.RegisterGuests) ||
                                merged.includes(StaffRole.SellOnPos);
                              let hasLocation =
                                merged.includes(StaffRole.TopupCredits) ||
                                merged.includes(StaffRole.RegisterGuests) ||
                                merged.includes(StaffRole.SellOnPos) ||
                                merged.includes(StaffRole.StockManager);
                              let hasGuestProfile = merged.includes(StaffRole.RegisterGuests);
                              let hasAccess = merged.includes(StaffRole.AccessControl);

                              if (
                                merged.includes(StaffRole.BackOffice) &&
                                merged.includes(StaffRole.StockManager)
                              ) {
                                merged.every((role) => {
                                  if (
                                    role === StaffRole.BackOffice ||
                                    role === StaffRole.StockManager
                                  ) {
                                    merged = merged.filter((r) => r !== role);
                                    return false;
                                  }
                                  return true;
                                });
                              }

                              modifyForm({
                                role: {
                                  value: merged,
                                  isAll: false,
                                },
                                paymentMethod: {
                                  value: hasPaymentMethod ? fields.paymentMethod.value : [],
                                  disabled: !hasPaymentMethod,
                                  isAll: true,
                                },
                                location: {
                                  value: hasLocation ? fields.location.value : [],
                                  disabled: !hasLocation,
                                  isAll: true,
                                },
                                guestProfiles: {
                                  value: hasGuestProfile ? fields.guestProfiles.value : [],
                                  disabled: !hasGuestProfile,
                                  isAll: true,
                                },
                                accessControls: {
                                  value: hasAccess ? fields.accessControls.value : [],
                                  disabled: !hasAccess,
                                  isAll: true,
                                },
                              });
                            } else {
                              let newRoles = roles.slice();
                              if (newRoles.includes(StaffRole.Manager) || fields.role.isAll) {
                                newRoles = [
                                  StaffRole.SellOnPos,
                                  StaffRole.TopupCredits,
                                  StaffRole.RegisterGuests,
                                  StaffRole.StockManager,
                                  StaffRole.FrontOffice,
                                  StaffRole.BackOffice,
                                ];
                              }
                              newRoles = newRoles.filter((role) => {
                                return role !== data.value && role !== StaffRole.Manager;
                              });

                              let hasPaymentMethod =
                                newRoles.includes(StaffRole.TopupCredits) ||
                                newRoles.includes(StaffRole.RegisterGuests) ||
                                newRoles.includes(StaffRole.SellOnPos);
                              let hasLocation =
                                newRoles.includes(StaffRole.TopupCredits) ||
                                newRoles.includes(StaffRole.RegisterGuests) ||
                                newRoles.includes(StaffRole.SellOnPos) ||
                                newRoles.includes(StaffRole.StockManager);
                              let hasGuestProfile = newRoles.includes(StaffRole.RegisterGuests);
                              let hasAccess = newRoles.includes(StaffRole.AccessControl);

                              let newPaymentMethod = hasPaymentMethod
                                ? fields.paymentMethod.value.filter((paymentMethod) => {
                                    if (
                                      !newRoles.includes(StaffRole.SellOnPos) &&
                                      paymentMethod.text === "PouchPAY"
                                    ) {
                                      return false;
                                    } else {
                                      return true;
                                    }
                                  })
                                : [];

                              modifyForm({
                                role: {
                                  value: newRoles,
                                  isAll: false,
                                },
                                paymentMethod: {
                                  value: newPaymentMethod,
                                  disabled: !hasPaymentMethod,
                                  isAll: false,
                                },
                                location: {
                                  value: hasLocation ? fields.location.value : [],
                                  disabled: !hasLocation,
                                  isAll: false,
                                },
                                guestProfiles: {
                                  value: hasGuestProfile ? fields.guestProfiles.value : [],
                                  disabled: !hasGuestProfile,
                                  isAll: true,
                                },
                                accessControls: {
                                  value: hasAccess ? fields.accessControls.value : [],
                                  disabled: !hasAccess,
                                  isAll: true,
                                },
                              });
                            }
                          }}
                        ></Checkbox>
                        <Text className="text-base text-font-bold text-pelorous-darker pl-sm">
                          {data.text}
                        </Text>
                      </div>
                      <p className={data.dashboardAccessClass}>{data.dashboardAccess}</p>
                      <p className={data.appAccessClass}>{data.appAccess}</p>
                    </div>
                  );
                })}
            </Field>
            {hasRole(roles, [
              StaffRole.SellOnPos,
              StaffRole.RegisterGuests,
              StaffRole.TopupCredits,
            ]) && (
              <Field
                tooltipLabel="The payment method that can be accepted for Topup Credits, Sell on POS, and Register Guest roles."
                className="mt-sm"
                {...fields.paymentMethod}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.PAYMENT_METHOD_SETTING}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editPaymentMethods}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.paymentMethod}
                  onClick={() => {
                    if (!fields.paymentMethod.disabled) {
                      requestPaymentMethod.request();
                    }
                  }}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                  loading={requestPaymentMethod.loading}
                  options={requestPaymentMethod.mappedData
                    .sort(sortByKeyName("text"))
                    .filter((paymentMethod) => !paymentMethod.deleted)
                    .filter((paymentMethod) => {
                      if (
                        !roles.includes(StaffRole.SellOnPos) &&
                        paymentMethod.text === "PouchPAY"
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    })}
                />
              </Field>
            )}
            {hasRole(roles, [
              StaffRole.StockManager,
              StaffRole.SellOnPos,
              StaffRole.RegisterGuests,
              StaffRole.TopupCredits,
            ]) && (
              <Field
                className="mt-sm"
                {...fields.location}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.LOCATION}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editLocations}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.location}
                  onClick={() => {
                    if (!fields.location.disabled) {
                      requestLocations.request();
                    }
                  }}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                  loading={requestLocations.loading}
                  options={requestLocations.mappedData.sort(sortByKeyName("text"))}
                />
              </Field>
            )}
            {hasRole(roles, [StaffRole.RegisterGuests]) && (
              <Field
                className="mt-sm"
                {...fields.guestProfiles}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.GUEST_PROFILE}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editGuestProfile}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.guestProfiles}
                  loading={requestGuestProfiles.loading}
                  options={requestGuestProfiles.mappedData}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                />
              </Field>
            )}
            {hasRole(roles, [StaffRole.AccessControl]) && (
              <Field
                className="mt-sm"
                {...fields.accessControls}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.SETTING_ACCESS}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editAccessPoint}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.accessControls}
                  loading={requestAccessControls?.loading}
                  options={requestAccessControls?.mappedData}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                />
              </Field>
            )}
          </Panel>
        )}
      </FragmentA>
    </>
  );
};

export default StaffFormFields;
