import { dateTimeRequest, filterRequest } from "mappers";
import { DateTime } from "enums";
import { formatDate } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

export const balanceMovementInListResponse = {
  previousVenueName: { key: "previousVenueName" },
  guestId: { key: "guestId" },
  previousGuestId: { key: "previousGuestId" },
  name: {
    transform: ({ src }) => {
      return src.firstName + " " + src.lastName;
    },
  },
  tagUid: { key: "tagUid" },
  tagUseCount: { key: "tagUseCount" },
  checkInDate: {
    transform: ({ src }) => {
      return formatDate(src.checkInDateTime, DateTime.A);
    },
  },
  checkInTime: {
    transform: ({ src }) => {
      return formatDate(src.checkInDateTime, DateTime.B);
    },
  },
  checkOutDate: {
    transform: ({ src }) => {
      return formatDate(src.checkOutDateTime, DateTime.A);
    },
  },
  checkOutTime: {
    transform: ({ src }) => {
      return formatDate(src.checkOutDateTime, DateTime.B);
    },
  },
  multiPropertyCreditsIn: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.venueGroupCreditsIn);
    },
  },
  multiPropertyFreeCreditsIn: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.venueGroupFreeCreditsIn);
    },
  },
};

export const balanceMovementOutListResponse = {
  guestId: { key: "guestId" },
  name: {
    transform: ({ src }) => {
      return src.firstName + " " + src.lastName;
    },
  },
  tagUid: { key: "tagUid" },
  tagUseCount: { key: "tagUseCount" },
  checkOutDate: {
    transform: ({ src }) => {
      return formatDate(src.checkOutDateTime, DateTime.A);
    },
  },
  checkOutTime: {
    transform: ({ src }) => {
      return formatDate(src.checkOutDateTime, DateTime.B);
    },
  },
  freeCreditsRemoved: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.freeCreditRemoved);
    },
  },
  multiPropertyCreditsOut: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.venueGroupCreditsOut);
    },
  },
  multiPropertyFreeCreditsOut: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.venueGroupFreeCreditsOut);
    },
  },
};

export const balanceMovementInListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  previousVenueIds: { 
    transform: ({ src }) => {
      return src.venueList.map(venue => venue.value);
    },
  },
};

export const balanceMovementOutListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  previousVenueIds: { 
    transform: ({ src }) => {
      return src.venueList.map(venue => venue.value);
    },
  },
};
