import enumeration from "./enumeration";

const PaymentMethod = {
  Credit: "CREDIT",
  Cash: "CASH",
  CreditDebit: "CREDIT_DEBIT",
  PouchPay: "POUCHPAY",
  EWallet: "E_WALLET",
  Others: "OTHER",
};

export default enumeration(PaymentMethod);
