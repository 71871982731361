import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Icon } from "components/commons";
import styles from "./side-nav.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useMount } from "hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
// import lang from "translations";
import { Path } from "paths";

const SideNav = ({ items, className, activeClassName }) => {
  const [hide, setHide] = useState(true);
  // const [mobile, setMobile] = useState(false);
  const flags = useFlags();
  const { 
    bookingPage, 
    stocktake, 
    supplyItems, 
    discounts, 
    endOfDayReports, 
    inventoryReport, 
    categoryReport, 
    discountReport, 
    voucherReport, 
    multiProperty,
  } = flags;

  useMount(() => {
    setHide(window.screen.width < 768);
  });

  return (
    <div
      className={classnames(
        "bg-white p-sm md:p-md overflow-y-auto overflow-x-hidden",
        {
          [`w-14 md:w-sidemenu ${styles.smallView}`]: hide,
          [`w-sidemenu md:w-sidemenu`]: !hide,
        },
        className,
        styles.container
      )}
    >
      <div className={classnames(styles.list)}>
        {items.map(
          ({
            icon,
            to,
            onClick,
            target,
            className: itemClassName,
            exact,
            text,
            items: subItems,
          }) => {
            const hasFlag = {
              [Path.BOOKING]: bookingPage,
              [Path.INVENTORY]: stocktake || supplyItems,
              [Path.INVENTORY_STOCKTAKE]: stocktake,
              [Path.INVENTORY_SUPPLY_ITEM]: supplyItems,
              [Path.DISCOUNT]: discounts,
              [Path.END_OF_DAY_REPORT]: endOfDayReports,
              [Path.REPORT_INVENTORY]: inventoryReport,
              [Path.REPORT_CATEGORY]: categoryReport,
              [Path.REPORT_DISCOUNT]: discountReport,
              [Path.REPORT_VOUCHER]: voucherReport,
              [Path.MULTI_PROPERTY]: multiProperty,
            };
            if (hasFlag.hasOwnProperty(to) && !hasFlag[to]) {
              return null;
            }
            return (
              <span key={uuidv4()}>
                <NavLink
                  activeClassName={classnames(
                    activeClassName,
                    styles.active,
                    subItems ? "font-bold text-gray-600" : "font-bold text-pelorous"
                    // "font-bold text-pelorous-darkest"
                  )}
                  className={classnames(
                    itemClassName,
                    styles.item,
                    "hover:text-pelorous text-gray"
                  )}
                  to={to}
                  target={target}
                  exact={exact}
                  onClick={() => {
                    if (onClick && typeof onClick === "function") {
                      onClick({ to });
                    }
                  }}
                >
                  <span className={classnames("")}>
                    <span className={classnames("flex items-center pl-sm md:pl-md text-sm")}>
                      <Icon name={icon} className={classnames("text-gray-400", styles.icon)} />
                      {text}
                    </span>
                  </span>
                </NavLink>
                {subItems && (
                  <span
                    className={classnames(styles.subItem, {
                      [`${styles.hidden}`]: hide,
                    })}
                  >
                    {subItems
                      .filter((subItem) => {
                        if (hasFlag.hasOwnProperty(subItem.to) && !hasFlag[subItem.to]) {
                          return false;
                        }
                        return true;
                      })
                      .map(({ text: subItemText, to: subItemTo }) => {
                        return (
                          <NavLink
                            exact={true}
                            key={uuidv4()}
                            to={subItemTo}
                            activeClassName={classnames("text-pelorous font-bold")}
                            className={classnames(
                              styles.subItemLink,
                              "hover:text-pelorous text-gray text-sm"
                            )}
                          >
                            {subItemText}
                          </NavLink>
                        );
                      })}
                  </span>
                )}
              </span>
            );
          }
        )}
      </div>

      <div
        id="mobileArrow"
        className={classnames(
          styles.arrow,
          "cursor-pointer flex md:hidden justify-center items-center"
        )}
        onClick={() => {
          setHide(!hide);
        }}
      >
        <Icon
          name={hide ? "arrow-right" : "arrow-left"}
          className={classnames(styles.arrowIcon, "bg-gray-lightest shadow-md text-pelorous")}
        />
      </div>
    </div>
  );
};

export default SideNav;
