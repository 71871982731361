import { closeShift } from "apis/shift.api";
import {
  ActionButton,
  Field,
  Form,
  InputCounter,
  Modal,
  Select,
  Title,
  Toast,
  InfoPanel,
} from "components/commons";
import { StyleType } from "enums";
import { useApi, useForm } from "hooks";
import React, { useCallback, useMemo } from "react";
import lang from "translations";
import initialFormState from "./close-shift-state";
import { mixpanel, TrackEvent } from "mixpanel";

const CloseShiftModal = ({ active, close, ok, initialState = undefined, onShiftClosed, shiftId, ...props }) => {
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    getFormValues,
    clearForm,
    applyFieldErrors,
    validateForm,
  } = useForm({
    initialState: formState,
  });

  const { request: closeShiftRequest, loading: closeShiftLoading } = useApi({
    api: closeShift,
    params: {
      shiftId,
    },
  });

  const handleCloseShiftClicked = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        button: lang.closeShift,
      });
      const params = getFormValues();
      validateForm();
      if (!params.closeType) {
        applyFieldErrors({
          closeType: lang.thisFieldIsRequired,
        });
        return;
      }
      if (params.closeType === "OTHERS" && !params.closeReason) {
        applyFieldErrors({
          banReason: lang.thisFieldIsRequired,
        });
        return;
      }
      await closeShiftRequest(params);
      Toast({
        content: lang.shiftClosed,
        success: true,
        icon: "check",
      }).open();
      clearForm();

      if (onShiftClosed) {
        onShiftClosed();
      }
    } catch ({ code, handleError }) {
      const err = {
        3124: () => {
          applyFieldErrors({
            closeType: lang.shiftAlreadyClosed,
          });
        },
      };

      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    closeShiftRequest,
    getFormValues,
    applyFieldErrors,
    onShiftClosed,
    validateForm,
    clearForm,
  ]);

  return (
    <Modal
      {...props}
      noHeader
      active={active}
      actionContent={
        <ActionButton
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.cancelCloseShift,
              });

              clearForm();
              close();
            },
          }}
          primary={
            {
              loading: closeShiftLoading,
              disabled: closeShiftLoading,
              type: StyleType.Danger,
              text: lang.closeShift,
              onClick: () => {
                handleCloseShiftClicked();
              },
            }
          }
        />
      }
    >
      <Title lg className="mb-sm pt-md">
        {`${lang.closeShift}?`}
      </Title>

      <Form>
        <p className="text-xs text-gray-500 m-0 font-semibold">{lang.reason}</p>
        <Field {...fields.closeType}>
          <Select
            {...fields.closeType}
            placeholder={lang.chooseAReason}
            options={[
              { text: lang.cacheCleared, value: "CACHE_CLEARED" },
              { text: lang.appReinstall, value: "APP_REINSTALL" },
              { text: lang.others, value: "OTHERS" },
            ]}
            onChange={modifyField}
          />
        </Field>
        {fields.closeType.value === "OTHERS" ? (
          <div className="mt-md">
            <InputCounter {...fields.closeReason} textarea onChange={modifyField} />
          </div>
        ) : null}
        <InfoPanel>{lang.closeShiftWarning}</InfoPanel>
      </Form>
    </Modal>
  );
};

export default CloseShiftModal;
