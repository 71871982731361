import {
    ActionButton,
    Field,
    Modal,
    Select,
    Toast,
} from "components/commons";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi, useForm } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import initialFormState from "./assign-revenue-account-state";
import { bulkAssignRevenueAccountCodes } from "apis/product.api";
import { groupAccountOptions } from "services/accounting.service";

const AssignRevenueAccountModal = ({ productIds, accountingOptions, active, close, ok, initialState = undefined, ...props }) => {
    const { venue } = useContext(VenueContext);
    const { venueId } = venue;

    const formState = useMemo(() => {
        return initialFormState(initialState);
    }, [initialState]);

    const {
        fields,
        modifyField,
        submitForm,
        getFormValues,
        clearForm,
        validateField
    } = useForm({
        initialState: formState,
    });

    const { request: bulkAssignRequest, loading: bulkAssignLoading } = useApi({
        api: bulkAssignRevenueAccountCodes,
        params: {
            venueId,
            productIds: productIds ? productIds : []
        },
    });

    const handleSubmit = useCallback(async () => {
        const params = getFormValues();
        try {
            await bulkAssignRequest({ ...params, venueId });
            Toast({
                content: "Revenue Account Assigned",
                success: true,
                icon: "check",
            }).open();
            clearForm();
            close();
            ok();
        } catch ({ code, handleError }) {
            handleError();
        }
    }, [bulkAssignRequest, getFormValues, venueId, clearForm, ok, close]);

    const formField = useMemo(() => {
        const formItems = {};
        [
            "revenueAccountCodeId"
        ].forEach((key) => {
            formItems[key] = {
                ...fields[key],
                onChange: modifyField,
                validateField: () => {
                    validateField(key, fields[key]);
                },
            };
        });
        return formItems;
    }, [fields, modifyField, validateField]);

    const {
        revenueAccountCodeId
    } = formField;

    return (
        <Modal
            title="Set Revenue Account"
            {...props}
            active={active}
            actionContent={
                <ActionButton
                    className="px-md pb-md"
                    secondary={{
                        text: lang.cancel,
                        onClick: () => {
                            clearForm();
                            close();
                        },
                    }}
                    primary={
                        {
                            loading: bulkAssignLoading,
                            disabled: bulkAssignLoading || !fields.revenueAccountCodeId.value,
                            type: StyleType.Danger,
                            text: "Set Revenue Account",
                            onClick: () => {
                                submitForm(handleSubmit);
                            },
                        }
                    }
                />
            }
        >
            <Field {...revenueAccountCodeId}>
                <Select
                    {...revenueAccountCodeId}
                    searchable
                    options={accountingOptions}
                    groupedOptions={groupAccountOptions(
                        accountingOptions,
                        fields.revenueAccountCodeId.suggested,
                        false
                    )}
                    customNotFoundContent={lang.accountNotFound}
                />
            </Field>
        </Modal>
    );
};

export default AssignRevenueAccountModal;
