import { Get } from "services";
import { ApiPath } from "paths";

export const getMewsOutlet = async ({ venueId }) => {
  const res = await Get(`${ApiPath.MEWS_OUTLET(venueId)}`);
  return res;
};

export const getMewsService = async ({ venueId }) => {
  const res = await Get(`${ApiPath.MEWS_SERVICE(venueId)}`);
  return res;
};