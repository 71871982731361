import { Delete, Post, Get } from "services";
import { ApiPath } from "paths";

export const downloadReport = async (reportId) => {
  const res = await Get(
    `${ApiPath.REPORT_DOWNLOAD(reportId)}`,
    {},
    { responseType: "arraybuffer" }
  );
  return res;
};

export const searchReport = async (body) => {
  const res = await Post(`${ApiPath.REPORT_SEARCH}`, body);
  return res;
};

export const deleteReport = async (reportId) => {
  const res = await Delete(`${ApiPath.DELETE_REPORT(reportId)}`, {});
  return res;
};

export const searchImport = async (id) => {
  const res = await Get(ApiPath.IMPORT_SEARCH(id));
  return res;
};

export const deleteImport = async (id, venueId) => {
  const res = await Delete(ApiPath.DELETE_IMPORT({ id, venueId }));
  return res;
};
