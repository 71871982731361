import {
  formatName,
  timeAgo,
} from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import {
  dateTimeRequest,
  filterRequest,
  selectToObjectRequest,
  getCurrentTimeWithTimezone,
} from "./common.mapper";

export const freeCreditsReportRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  locationIds: selectToObjectRequest("locations"),
  requestedAt: getCurrentTimeWithTimezone(),
};

export const freeCreditsReportResponse = {
  guestId: {
    key: "guestId",
  },
  name: {
    transform: ({ src }) => {
      return formatName(src.firstName, src.lastName);
    },
  },
  userTagUid: { key: "userTagUid" },
  type: { key: "type" },
  creditsAmount: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.creditsAmount);
    },
  },
  staffProfileName: { key: "staffProfileName" },
  transactionDate: {
    transform: ({ src }) => {
      return src.transactionDate ? timeAgo(src.transactionDate, true) : "";
    },
  },
  locationId: { key: "locationId" },
  locationName: { key: "locationName" },
};
