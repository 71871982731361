import { PillType, TransactionType } from "enums";

export const getPillTypeOfTransactionType = (type) => {
  return (
    {
      [TransactionType.MIXED]: PillType.Orange,
      [TransactionType.REDEEM]: PillType.Blue,
      [TransactionType.VOUCHER_ISSUE]: PillType.Blue,
      [TransactionType.VOUCHER_REMOVE]: PillType.Red,
      [TransactionType.REMOVE]: PillType.Red,
    }[type] || PillType.Green
  );
};
