import { AccountType, Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
    const { revenueAccountCodeId = "" } = initialState;
    return {
        revenueAccountCodeId: {
            name: "revenueAccountCodeId",
            label: lang.revenueAccount,
            value: revenueAccountCodeId,
            type: Field.ANY,
            placeholder: lang.revenueAccountPlaceholder,
            suggested: AccountType.Asset,
            validations: [Validation.required()],
            ...revenueAccountCodeId,
        }
    };
};

export default initialFormState;
