import { searchFreeCreditsReport, searchFreeCreditsReportExport } from "apis";
import { DataTable, Icon, Text, ButtonLink, Pill } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import { freeCreditsReportResponse, freeCreditsReportRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { Path } from "paths";
import { mapObject } from "services";
import { freeCreditsReportFilterState } from "./filters";
import FreeCreditsReportFilter from "./free-credits-report-filter";
import { freeCreditsReportColumns } from "./columns";
import { PillType, TransactionType } from "enums";
import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { prettifyTransactionType } from "services/pretty.service";

const FreeCreditsReport = () => {
  const { venue } = useContext(VenueContext);

  const {
    request: searchFreeCreditsReportRequest,
    loading: loadingFreeCreditsReports,
    result: searchFreeCreditsReportResult = { data: [], metadata: { total: 0 } },
    mappedData: freeCreditsReportsData,
    error: errorFreeCreditsReports,
  } = useApi({
    api: searchFreeCreditsReport,
    isArray: true,
    mapper: freeCreditsReportResponse,
  });

  const { request: searchFreeCreditsReportExportRequest, loading: searchFreeCreditsReportExportLoading } =
    useApi({
      api: searchFreeCreditsReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    freeCreditsReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchFreeCreditsReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.freeCreditsReport,
    });
  });

  const fetchFreeCreditsReports = useCallback(
    (filterState, requestState) => {
      searchFreeCreditsReportRequest(
        mapObject({ ...requestState }, freeCreditsReportRequest)
      );
    },
    [searchFreeCreditsReportRequest]
  );

  const renderGuest = useCallback(
    (name, tagUid, guestId) => {
      let guestTagDisplay = "";

      guestTagDisplay = (
        <div className="flex items-center">
          <Text className="mr-sm group-hover:text-black-light" tagUid>
            {tagUid}
          </Text>
        </div>
      );

      return (
        <ButtonLink
          className="grid grid-cols-1 cursor-pointer group"
          path={Path.GUEST_DETAILS_ID(guestId)}
        >
          {name ? (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">{name}</Text>
          ) : (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">
              {[lang.guest, " ", guestId]}
            </Text>
          )}
          <div>{guestTagDisplay}</div>
        </ButtonLink>
      );
    },
    []
  );

  const renderTransactionType = useCallback((type) => {
    return (
      <div className="flex items-center">
        <Pill size="text-xs" type={type === TransactionType.ISSUE_FOC ? PillType.Green : PillType.Red}>
          {prettifyTransactionType(type)}
        </Pill>
      </div>
    );
  }, []);

  const renderDateAndTime = useCallback((dateAndTime) => {
    return (
      <div>
        <Text>{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderLocationName = useCallback(
    (locationName, locationId) => (locationId ? (
      <ButtonLink
        className="cursor-pointer"
        // onClick={() => history.push(Path.LOCATION_ID(locationId))}
        path={Path.LOCATION_ID(locationId)}
      >
        <Text className="hover:text-gray">{locationName}</Text>
      </ButtonLink>
    ) : null),
    []
  );

  const prepareReports = useCallback(() => {
    return freeCreditsReportsData.map((reportRow) => {
      const {
        guestId,
        name,
        userTagUid,
        type,
        creditsAmount,
        staffProfileName,
        transactionDate,
        locationId,
        locationName,
      } = reportRow;

      return {
        name: renderGuest(name, userTagUid, guestId),
        type: renderTransactionType(type),
        creditsAmount: creditsAmount,
        staff: staffProfileName,
        transactionDate: renderDateAndTime(transactionDate),
        locationName: renderLocationName(locationName, locationId),
      };
    });
  }, [freeCreditsReportsData, renderGuest, renderTransactionType, renderDateAndTime, renderLocationName]);

  const freeCreditsReports = useMemo(() => {
    return prepareReports();
  }, [prepareReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchFreeCreditsReports(filterState, requestState);
    },
    [fetchFreeCreditsReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchFreeCreditsReports(filterState, requestState);
    },
    [fetchFreeCreditsReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchFreeCreditsReports(filterState, requestState);
    },
    [fetchFreeCreditsReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchFreeCreditsReportExportRequest(
      mapObject({ ...requestState }, freeCreditsReportRequest)
    );
  }, [searchFreeCreditsReportExportRequest, requestState]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.freeCreditsReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchFreeCreditsReportExportLoading,
          disabled: searchFreeCreditsReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <FreeCreditsReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {freeCreditsReports.length > 0 || loadingFreeCreditsReports ? (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchFreeCreditsReports}
          total={searchFreeCreditsReportResult.metadata.total}
          loading={loadingFreeCreditsReports}
          columns={freeCreditsReportColumns}
          data={freeCreditsReports}
          error={errorFreeCreditsReports}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default FreeCreditsReport;
