import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useMount, useApi } from "hooks";
import { Skeleton, Panel, Title, SimpleTable, Icon, Text, Button } from "components/commons";
import { VenueContext } from "contexts";
import {
  searchPaymentMethod,
  createPaymentMethod,
  editPaymentMethod,
} from "apis/payment-method.api";
import { StyleType } from "enums";
import { paymentMethodRequest, createPaymentMethodRequest, paymentMethodList } from "mappers";
import PaymentMethodFormModal from "./payment-method-form/payment-method-form.modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { getAccountCodes, getAccountMappings } from "apis";
import { AccountName } from "enums";
import { accountingResponse } from "mappers/accounting.mapper";
import { mapAccountOptions } from "services/accounting.service";

const PaymentMethodSetting = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const paymentMethodFormModal = useModal();

  const { request, loading, mappedData } = useApi({
    api: searchPaymentMethod,
    params: {
      venueId,
    },
    mapper: paymentMethodList,
    isArray: true,
  });

  useMount(() => {
    request();
    accountRequest();
    accountingCategoriesRequest();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.paymentMethodsSettings,
    });
  });

  const { request: createRequestApi, loading: createLoading } = useApi({
    api: createPaymentMethod,
    params: {
      venueId,
    },
    paramsMapper: createPaymentMethodRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: editRequestApi, loading: editLoading } = useApi({
    api: editPaymentMethod,
    params: {
      venueId,
    },
    paramsMapper: paymentMethodRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const {
    request: accountRequest,
    mappedData: accountListMappedData,
    result: defaultAccountMapping,
    loading: accountLoading,
  } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountingResponse,
  });

  const { request: accountingCategoriesRequest, result: paymentAccountDefault } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue?.venueId,
    },
    handleOwnError: true,
  });

  const defaultPaymentAccountMapping = paymentAccountDefault
    ? paymentAccountDefault?.data?.find((d) => d.itemType === AccountName.CreditsLiabilityAccount)
    : null;

  const defaultPaymentAccountCodeId = defaultPaymentAccountMapping
    ? defaultPaymentAccountMapping?.accountCodeId
    : null;

  const onSave = useCallback(
    async ({ api, message }, fields, success, error) => {
      try {
        const result = await api(fields);
        request();
        return success(result, { message });
      } catch (e) {
        return error(e);
      }
    },
    [request]
  );

  // eslint-disable-next-line
  const onCreateSave = useCallback(
    (fields, success, error) => {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.addPaymentMethod,
        Page: lang.paymentMethodsSettings,
      });

      return onSave(
        {
          api: createRequestApi,
          message: lang.populate(lang.paymentMethodAddedName, [fields.name]),
        },
        fields,
        success,
        error
      );
    },
    [createRequestApi, onSave]
  );

  const onEditSave = useCallback(
    (fields, success, error) => {
      return onSave({ api: editRequestApi, message: lang.changesSaved }, fields, success, error);
    },
    [editRequestApi, onSave]
  );

  const submitting = createLoading || editLoading;

  const accountOptions = useMemo(() => {
    return mapAccountOptions(accountListMappedData);
  }, [accountListMappedData]);

  const data = useMemo(() => {
    // const defaultPayment = mappedData.filter(({ isEditable }) => {
    //   return !isEditable;
    // });

    // const paymentMethods = mappedData
    //   .filter(({ isEditable }) => {
    //     return isEditable;
    //   });

    return mappedData.filter(d => {
      return !d.deleted;
    }).map((d) => {
      const icon = {
        Cash: "cash",
        "Credit / Debit Card": "credit-card",
        Others: "others-payment",
      };
      const accountCode = defaultAccountMapping?.data?.filter(
        (data) => data.id === d.accountCodeId
      )[0]?.code;
      const accountName = defaultAccountMapping?.data?.filter(
        (data) => data.id === d.accountCodeId
      )[0]?.name;
      return {
        ...d,
        nameAndIcon: (
          <div className="flex items-center">
            <Icon name={icon[d.name] || icon.Others} className="text-gray mr-md" fontSize="30px" />
            <Text>{d.name}{d.deleted}</Text>
            {/* {d.refundable ? (
              <Image className="ml-3" src={Union} style={{ width: "20px", height: "20px" }} />
            ) : null} */}
          </div>
        ),
        accountCodeId: d.accountCodeId ? (
          <div className="flex items-center py-2">
            <Text>{`${accountCode ? `${accountCode} -` : ""} ${accountName}`}</Text>
          </div>
        ) : (
          <div className="flex items-center py-2">
            <Text>-</Text>
          </div>
        ),
        refundable: (
          <div className="flex items-center mt-sm px-8">
            {d.refundable ? <Text>{lang.yes}</Text> : <Text>{lang.no}</Text>}
          </div>
        ),
        edit: (
          <div className="display-hover mt-sm">
            <Icon
              name="pencil"
              className="text-blue cursor-pointer px-md"
              onClick={() => {
                paymentMethodFormModal.show({
                  title: lang.editPaymentMethod,
                  primaryText: lang.save,
                  isEdit: true,
                  save: onEditSave,
                  id: d.id,
                  accountOptions,
                  defaultPaymentAccountMapping,
                  defaultPaymentAccountCodeId,
                  initialState: {
                    name: d.name,
                    id: d.id,
                    refundable: d.refundable,
                    accountCodeId: d.accountCodeId,
                  },
                });
              }}
            />
          </div>
        ),
      };
    });
  }, [
    mappedData,
    paymentMethodFormModal,
    onEditSave,
    accountOptions,
    defaultPaymentAccountMapping,
    defaultPaymentAccountCodeId,
    defaultAccountMapping,
  ]);

  return (
    <ModuleWrapper>
      <HeaderB
        returnText={lang.settings}
        title={lang.paymentMethods}
        returnPath={Path.SETTING}
        onClick={() => history.push(Path.SETTING)}
      />
      {loading || accountLoading ? (
        <FragmentA title="">
          <Skeleton />
        </FragmentA>
      ) : (
        <>
          <FragmentA title={""} description={lang.paymentMethodsDescription}>
            <Panel>
              <Title md className="mb-sm">
                {lang.paymentMethods}
              </Title>
              <SimpleTable
                breakAll
                className="-mx-6 mt-6"
                columns={[
                  {
                    text: lang.name,
                    key: "nameAndIcon",
                    className: "py-md px-6",
                    width: "w-50",
                    headerClassName: "text-left px-6",
                  },
                  {
                    text: lang.creditsLiabilityAccount,
                    key: "accountCodeId",
                    className: "py-md px-6",
                    width: "w-50",
                    headerClassName: "text-left px-6",
                  },
                  // : {},
                  // {
                  //   text: (
                  //     <Text className="px-6 ml-7 text-gray text-xs font-medium">
                  //       {lang.nonRefundable}
                  //     </Text>
                  //   ),
                  //   key: "refundable",
                  //   className: "py-md px-6",
                  //   width: "w-30",
                  // },
                  {
                    key: "edit",
                    className: "text-right p-md",
                    width: "w-20",
                  },
                ]}
                data={data}
              />
              <Button
                className="mt-md"
                type={StyleType.Secondary}
                onClick={() => {
                  paymentMethodFormModal.show({
                    title: lang.addNewPaymentMethod,
                    primaryText: lang.add,
                    initialState: undefined,
                    accountOptions,
                    isEdit: false,
                    save: onCreateSave,
                  });
                }}
              >
                {lang.addNewPaymentMethod}
              </Button>
            </Panel>
          </FragmentA>
        </>
      )}
      <PaymentMethodFormModal
        {...paymentMethodFormModal}
        submitting={submitting}
        refreshList={request}
      />
    </ModuleWrapper>
  );
};

export default PaymentMethodSetting;
