const multiPropertyPaths = {
  MULTI_PROPERTY: "",
  SEARCH_BALANCE_MOVEMENTS_IN: "search/balance-movements-in",
  SEARCH_BALANCE_MOVEMENTS_OUT: "search/balance-movements-out",
  SEARCH_BALANCE_MOVEMENTS_VENUES: "search/balance-movements-venues",
  SEARCH_BALANCE_MOVEMENTS_IN_REPORT_EXPORT: "search/balance-movements-in/report/export",
  SEARCH_BALANCE_MOVEMENTS_OUT_REPORT_EXPORT: "search/balance-movements-out/report/export",
};

export default multiPropertyPaths;
