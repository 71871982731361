import React, { useMemo } from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { SimpleTable, Text } from "components/commons";
import { Path } from "paths";

const TopCategoriesBySalesChart = ({ ...props }) => {
  const data = useMemo(() => {
    return props.mappedData.map((p) => {
      return {
        ...p,
        name: (
          <div className="px-md py-sm text-sm">
            <Text>
              {p.id ? (
                <a className="text-sm" href={`/product/category/${p.id}`}>
                  {p.name}
                </a>
              ) : (
                p.name
              )}
            </Text>
          </div>
        ),
        unitSold: (
          <div className="px-md py-sm">
            <Text>{p.unitSold}</Text>
          </div>
        ),
      };
    });
  }, [props.mappedData]);

  return (
    <ChartContainer
      title={{ to: Path.CATEGORY, name: lang.topCategoriesBySales }}
      {...props}
      empty={!props.mappedData.length}
      paddingless
    >
      <SimpleTable
        className="mt-md"
        columns={[
          {
            text: lang.category,
            key: "name",
            className: "py-sm",
            headerClassName: "pl-md text-left capitalize font-bold",
          },
          {
            text: lang.totalSales,
            key: "unitSold",
            className: "text-right py-sm",
            headerClassName: "text-right pr-md capitalize font-bold",
          },
        ]}
        data={data}
      />
    </ChartContainer>
  );
};

export default TopCategoriesBySalesChart;
