import React, { useCallback, useContext, useMemo } from "react";
import { searchDevice } from "apis";
import { Text, DataTable, ButtonLink, TabBar } from "components/commons";
import { VenueContext } from "contexts";
import {
  useApi,
  useFilter,
  useMount,
  // useRouter
} from "hooks";
import { deviceListFilterRequest, deviceListResponse } from "mappers";
import { mapObject } from "services";
import columns from "./columns";
import { Path } from "paths";
import lang from "translations";
import { ArtOctopus } from "images";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const DeviceList = () => {
  const { venue } = useContext(VenueContext);
  // const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchDeviceRequest,
    loading: loadingDevice,
    result: searchDeviceResult = { data: [], metadata: { total: 0 } },
    mappedData,
    error,
  } = useApi({
    api: searchDevice,
    isArray: true,
    mapper: deviceListResponse,
  });

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
    sort: { key: "lastSync", value: "desc" },
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.device === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.device !== getState().name)
      setFilterState({
        name: filterName.device,
        filter: {},
      });
    fetchDevices(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.deviceList,
    });
  });

  const fetchDevices = useCallback(
    (requestState) => {
      searchDeviceRequest(mapObject(requestState, deviceListFilterRequest));
    },
    [searchDeviceRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.device,
        filter: filterState,
      });
      fetchDevices(requestState);
    },
    [fetchDevices, setFilterState]
  );

  const renderLastSync = useCallback(
    (lastSync) => (
      <div>
        <Text>{lastSync?.date}</Text>
        {lastSync?.time && <Text color="text-gray">{lastSync.time}</Text>}
      </div>
    ),
    []
  );

  const prepareDeviceList = useCallback(() => {
    return mappedData.map((device) => {
      const { id, name, imei, lastSync, serialNumber } = device;
      return {
        name: (
          <ButtonLink
            className="text-left"
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.viewDevice,
                Page: lang.deviceList,
              });
              // history.push(Path.DEVICE_ID(id));
            }}
            path={Path.DEVICE_ID(id)}
          >
            {name}
          </ButtonLink>
        ),
        imei: <Text tagUid>{imei || serialNumber}</Text>,
        lastSync: renderLastSync(lastSync),
      };
    });
  }, [mappedData, renderLastSync]);

  const devices = useMemo(() => {
    return prepareDeviceList();
  }, [prepareDeviceList]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState, filterState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState, filterState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  return (
    <ModuleWrapper>
      <HeaderA title={lang.devices} description={lang.manageThePosDevicesVenue} className="mb-sm" />
      <TabBar className="mb-lg" items={[{ to: Path.DEVICE, text: lang.pointOfSale }]} />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchDeviceResult.metadata.total}
        loading={loadingDevice}
        columns={columns}
        sort={filterState.sort}
        setSort={sortCb}
        data={devices}
        error={error}
        minWidth="900px"
        renderEmpty={{
          image: ArtOctopus,
          title: lang.noDevicesFound,
        }}
      />
    </ModuleWrapper>
  );
};

export default DeviceList;
