import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { name = "", description = "", locations = [], productSkuIds = [] } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.printerProfileName,
    },
    description: {
      name: "description",
      value: description,
      type: Field.INPUT,
      validations: [],
      required: false,
      maxLength: 255,
      label: lang.description,
    },
    locations: {
      name: "locations",
      value: locations,
      type: Field.MULTIPLE_DROPDOWN,
      validations: [Validation.requiredMultiDropdown(lang.pleaseSelectLocation, false)],
      required: true,
      selectAllText: lang.allLocations,
    },
    productSkuIds: {
      name: "productSkuIds",
      value: productSkuIds,
      type: Field.ANY,
      required: true,
    },
  };
};

export default initialFormState;
