import React, { useCallback, useState } from "react";
import lang from "translations";
import styles from "./upload-avatar.module.scss";
import { Upload, Toast, Image, Text, Button, Icon, Loader } from "components/commons";
import classnames from "classnames";
import { DefaultImage } from "images";
import { StyleType } from "enums";
import { useApi } from "hooks";
import { uploadFile } from "apis/storage.api";

const UploadImage = ({ name, value: image, onChange, className, loader = true }) => {
  const { request, loading } = useApi({
    api: uploadFile,
  });
  const [loadingImage, setLoading] = useState(false);

  const beforeUpload = useCallback((file) => {
    const fileName = file.name.split(".");
    const fileExt = fileName[fileName.length - 1];
    if (fileName.length === 1) {
      showError();
      return false;
    }
    const isJpgOrPng = ["png", "jpg", "jpeg"].includes(fileExt.toString().toLowerCase());
    const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
    const isFileSizeValid = fileSizeInMB <= 1;

    if (!isJpgOrPng || !isFileSizeValid) {
      Toast({
        content: !isJpgOrPng ? lang.invalidFileType : lang.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
      return false;
    }
    return true;
  }, []);

  const showError = () => {
    Toast({
      content: lang.somethingWentWrong,
      error: true,
      icon: "exclamation-fill",
    }).open();
  };

  const uploadAvatar = useCallback(async (res) => {
    const { onSuccess } = res;
    try {
      if (res && res.fileList) {
        onSuccess(res);
      }
    } catch {
      showError();
    }
  }, []);

  const onChangeImage = useCallback(
    (res) => {
      const reader = new FileReader();
      const file = res.fileList[res.fileList.length - 1].originFileObj;
      reader.readAsDataURL(file);
      let onload = null;
      onload = reader.addEventListener(
        "load",
        async () => {
          const isImageValid = beforeUpload(file);
          if (isImageValid) {
            setLoading(true);
            const imageUrl = await request({
              file: res.file.originFileObj,
            });
            onChange(name, {
              image: imageUrl[0].permanentUrl,
              value: imageUrl[0].permanentUrl,
              file: res.file,
            });
            reader.abort();
            reader.removeEventListener("load", onload);
            setLoading(false);
          }
        },
        false
      );
    },
    [onChange, beforeUpload, name, request, setLoading]
  );

  const uploadRender = useCallback(
    (element) => {
      return (
        <Upload
          className={classnames({
            [`${styles.uploadContainer}`]: !image,
          })}
          beforeUpload={() => {
            return true;
          }}
          customRequest={uploadAvatar}
          onChange={onChangeImage}
        >
          {element}
        </Upload>
      );
    },
    [uploadAvatar, onChangeImage, image]
  );

  const removeImage = useCallback(() => {
    onChange(name, { image: null, value: null, file: "" });
  }, [onChange, name]);

  return (
    <div
      className={classnames(
        styles.container,
        {
          [`${styles.default}`]: !image,
        },
        "flex items-center py-sm"
      )}
    >
      {loading ? (
        <Icon loading className="m-auto" />
      ) : (
        <Image src={image || DefaultImage} className={classnames("m-auto", styles.image)} />
      )}

      {image ? (
        <div className={styles.buttons}>
          <Button className="w-full mb-sm">
            {uploadRender(<Text className={styles.change}>{lang.change}</Text>)}
          </Button>
          <Button type={StyleType.Danger} onClick={removeImage}>
            {lang.remove}
          </Button>
        </div>
      ) : (
        uploadRender(<Text className={styles.uploadPhoto}>{lang.uploadPhoto}</Text>)
      )}
      {loader ? <Loader loading={loadingImage} /> : null}
    </div>
  );
};

export default UploadImage;
