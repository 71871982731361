import { Button, HeaderB, ModuleWrapper } from "components";
import { StyleType } from "enums";
import { useModal, useRouter } from "hooks";
import { Path } from "paths";
import React, { useCallback, useContext, useState } from "react";
import lang from "translations";
import ImportModal from "./import-modal";
import { MB } from "contanst";
import { importFileSupplyItem, importFileSupplyItemWholeProduct } from "apis/supply-item.api";
import LoaderB from "components/commons/loaderB/loaderB";
import { uploadFileV2 } from "apis/storage.api";
import { VenueContext } from "contexts";
import ImportErrorModal from "./import-error";

const convertData = (data = []) => {
  return data.map((item, i) => ({
    ...item,
    supplyItems: item?.supplyItems?.map((sItem, idx) => ({
      ...sItem,
      sku: sItem?.existedProduct?.productSkus[0]?.sku,
      id: i + "-" + idx,
      editAble: !sItem.isExisted,
    })),
  }));
};

const ImportSupplyItems = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history, query } = useRouter();
  const isCreateWhole = !!query?.createWhole;
  const importModal = useModal();
  const importErrModal = useModal();
  const [loading, setLoading] = useState(false);

  const goToList = useCallback(() => {
    history.push(Path.INVENTORY_SUPPLY_ITEM);
  }, [history]);

  const checkFile = (file) => {
    var validExts = [".xlsx", ".xls"];
    var fileExt = file.name;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      return false;
    }
    return true;
  };

  const onUploadFile = useCallback(
    async (e) => {
      try {
        const file = e?.target?.files[0];
        if (file.size > 25 * MB || !checkFile(file)) {
          importErrModal.show({ text: lang.undefinedError });
          return;
        }
        setLoading(true);
        const res = await uploadFileV2({ file });
        const newFileName = res[0].newFilename;
        let data;
        if (isCreateWhole) {
          data = await importFileSupplyItemWholeProduct({
            fileName: newFileName,
            venueId,
            path: "/uploads/",
          });
        } else {
          data = await importFileSupplyItem({
            fileName: newFileName,
            venueId,
            path: "/uploads/",
          });
        }
        importModal.show({
          data: convertData(data),
          fileName: file.name,
          newFileName,
        });
      } catch (error) {
        const code = error.metadata.code;
        let text = lang.undefinedErrorPlsTryAgain;
        const locationName = error.metadata.locationName;
        const measurementStock = error.metadata.measurementStock;
        const supplyItemName = error.metadata.supplyItemName;
        const position = error.metadata.position;
        const sheet = error.metadata.locationName;
        switch (code) {
          case "3131":
          case "3138":
            text = lang.populate(lang.impItemsErr1, [locationName]);
            break;
          case "3132":
          case "3139":
            text = lang.populate(lang.impItemsErr2, [measurementStock]);
            break;
          case "3133":
          case "3136":
            text = lang.populate(lang.impItemsErr3, [sheet, position]);
            break;
          case "3134":
          case "3140":
            text = lang.populate(lang.impItemsErr4, [supplyItemName, measurementStock]);
            break;
          case "3135":
          case "3137":
            text = lang.populate(lang.impItemsErr5, [locationName]);
            break;
          case "3141":
            text = lang.impItemsErr6;
            break;
          case "3142":
            text = lang.populate(lang.impItemsErr7, [supplyItemName, measurementStock]);
            break;
          default:
            break;
        }
        importErrModal.show({ text });
      } finally {
        setLoading(false);
      }
    },
    [importErrModal, importModal, isCreateWhole, venueId]
  );

  const onUpload = () => {
    const a = document.createElement("input");
    a.type = "file";
    a.accept = ".xlsx, .xls";
    a.style.display = "none";
    a.onchange = onUploadFile;
    a.click();
    a.remove();
  };

  return (
    <ModuleWrapper>
      <HeaderB
        title={isCreateWhole ? lang.importSupplyItemsCreateWholeProducts : lang.importSupplyItems}
        returnText={lang.supplyItems}
        onClick={goToList}
      />
      <div className="flex justify-end gap-4 mt-2.5 mb-5">
        <Button type={StyleType.Secondary} onClick={goToList}>
          {lang.discard}
        </Button>
        <Button onClick={onUpload}>{lang.upload}</Button>
      </div>
      <div className="flex flex-col pt-24 pb-32 items-center bg-white">
        <img src="/excel.png" alt="" className="w-44" />
        <div className="mt-7 mb-8 text-lg">{lang.uploadExcelFileToImport}</div>
        <a
          href={
            isCreateWhole
              ? "/Template-Import-Supply-Items-Create-Whole-Product.xlsx"
              : "/Template-Import-Supply-Items.xlsx"
          }
          download
          className="bg-pelorous py-1 px-4 text-white rounded hover:text-white"
          rel="noreferrer"
        >
          {lang.downloadTemplate}
        </a>
      </div>
      <ImportModal {...importModal} isCreateWhole={isCreateWhole} />
      <ImportErrorModal {...importErrModal} />
      <LoaderB loading={loading} />
    </ModuleWrapper>
  );
};

export default ImportSupplyItems;
