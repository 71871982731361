import { BookingStatus } from "enums";
import moment from "moment";
import lang from "translations";

export const statusFilter = [
  { text: lang.confirmed, value: BookingStatus.CONFIRMED },
  { text: lang.toBeCheckedIn, value: BookingStatus.TO_BE_CHECKED_IN },
  { text: lang.missedCheckIn, value: BookingStatus.MISSED_CHECK_IN },
  { text: lang.toBeCheckedOut, value: BookingStatus.TO_BE_CHECKED_OUT },
  { text: lang.missedCheckOut, value: BookingStatus.MISSED_CHECK_OUT },
  { text: lang.checkedIn, value: BookingStatus.CHECKED_IN },
  { text: lang.checkedOut, value: BookingStatus.CHECKED_OUT },
  { text: lang.cancelled, value: BookingStatus.CANCELLED },
];

export const bookingListFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    statusList: [],
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    startDateTime: null,
    endDateTime: null,
  };
};
