import React, { useCallback, useMemo, useRef, useState } from "react";
import { Menu, Dropdown } from "antd";
import { InputPill, Text, Checkbox, Icon } from "components/commons";
import classnames from "classnames";
import styles from "./multiple-select.module.scss";
import useMultipleSelect from "./useMultipleSelect";
import { DownOutlined } from "@ant-design/icons";
import lang from "translations";

const MultipleSelect = ({
  name,
  value,
  selectAllText,
  options = [],
  onChange,
  allowAddOption,
  handleSubmitKey,
  optionsLoading,
  defaultAll = true,
  total,
  isAll,
  onClick,
  emptyPlaceHolder,
  required,
  error,
  className,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const inputEl = useRef(null);
  const containerEl = useRef(null);
  // const [mounted, setMounted] = useState(false);

  const { isAllSelected, setSelectAll, setSelected, selected, normalizeItems } = useMultipleSelect({
    items: options,
    key: "value",
    allowAddOption: true,
    reverse: true,
    totalItems: total || options.length,
    setValue: useCallback(
      (v) => {
        // setMounted(true);
        onChange(name, {
          ...v,
          // dirty: mounted,
        });
      },
      [onChange, name]
    ),
    value,
    defaultAll,
    isAll,
  });

  const focusOnInput = useCallback(
    (e) => {
      if (inputEl?.current) {
        inputEl.current.focus();
      }

      if (e) {
        e.preventDefault();
      }
    },
    [inputEl]
  );

  const filteredOptions = useMemo(() => {
    let hasMatch = false;
    const sk = searchKey.trim().toLowerCase();
    const op = options.filter(({ text }) => {
      const label = text.toString().toLowerCase();
      if (sk === label) {
        hasMatch = true;
      }
      if (!sk) {
        return true;
      }
      return label.includes(sk);
    });

    if (!hasMatch && sk && allowAddOption) {
      op.push({
        value: sk,
        text: `Add "${sk}"`,
        added: true,
      });
    }
    return op;
  }, [searchKey, options, allowAddOption]);

  const menu = useMemo(() => {
    return (
      <Menu>
        {!searchKey.trim() && (
          <Menu.Item key="all" onClick={() => setSelectAll()}>
            <Text className={"text-blue-dark"} strong>
              {selectAllText}
            </Text>
          </Menu.Item>
        )}
        {optionsLoading && (
          <Menu.Item key={"loading"}>
            <Icon loading />
          </Menu.Item>
        )}

        {filteredOptions.length ? (
          filteredOptions.map((item, index) => {
            return (
              <Menu.Item
                key={index}
                onClick={() => {
                  setSelected(item.value, item.added);
                  setSearchKey("");
                  focusOnInput();
                }}
                style={{
                  maxWidth: containerEl.current?.offsetWidth
                    ? containerEl.current?.offsetWidth
                    : "",
                }}
              >
                <Text className="flex items-center max-w-full">
                  {!item.added ? (
                    <span className={classnames("w-7 text-center flex items-center")}>
                      <Checkbox
                        className={classnames("m-1")}
                        value={selected[item.value]}
                        onChange={() => {
                          setSelected(item.value);
                          setSearchKey("");
                          focusOnInput();
                        }}
                      />
                    </span>
                  ) : (
                    <Icon name="plus" />
                  )}

                  <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {item.text}
                  </span>
                </Text>
              </Menu.Item>
            );
          })
        ) : (
          <Menu.Item key={"no-results"}>{lang.noResultsFound}</Menu.Item>
        )}
      </Menu>
    );
  }, [
    filteredOptions,
    selected,
    setSelected,
    selectAllText,
    setSelectAll,
    optionsLoading,
    searchKey,
    focusOnInput,
  ]);

  const valueItems = useMemo(() => {
    if (value) {
      return value.map((vv) => {
        let text = vv.text;
        if (!text) {
          text = normalizeItems?.[vv.value]?.text || `#${vv.value}`;
        }
        return {
          ...vv,
          text,
        };
      });
    }
    return [];
  }, [value, normalizeItems]);

  const renderPlaceholder = useMemo(() => {
    if (props.loading) {
      return "Loading...";
    }
    if (isAllSelected || isAll) {
      return selectAllText;
    }
    if (!options.length) {
      return emptyPlaceHolder;
    }
    return props.placeholder;
  }, [
    isAllSelected,
    isAll,
    options,
    emptyPlaceHolder,
    props.loading,
    props.placeholder,
    selectAllText,
  ]);

  return (
    <div
      className={classnames({
        "cursor-pointer": !props.disabled,
        "cursor-wait": props.loading,
        "cursor-not-allowed": props.disabled,
      })}
    >
      <Dropdown
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        overlay={menu}
        trigger={["click"]}
        onVisibleChange={(v) => {
          setVisible(v);
        }}
        visible={visible}
        disabled={!options.length || props.disabled}
      >
        <div ref={containerEl}>
          <InputPill
            error={error}
            required={required}
            disabled={!options.length}
            value={isAllSelected ? [] : valueItems}
            setValue={(pills) => {
              if (pills.length) {
                onChange(name, {
                  value: pills,
                  isAll: false,
                });
              } else {
                setSelectAll();
              }
            }}
            classNameInput={classnames({
              "placeholder-black": isAllSelected || isAll,
            })}
            {...props}
            placeholder={renderPlaceholder}
            suffix={
              optionsLoading ? (
                <Icon loading />
              ) : (
                <div
                  className={classnames("flex", styles.icon, {
                    [`${styles.open}`]: visible,
                  })}
                >
                  <DownOutlined />
                </div>
              )
            }
            handleSubmitKey={() => {
              return false;
            }}
            input={[searchKey, setSearchKey]}
            onSearchChanged={() => {
              if (!visible) {
                setVisible(true);
              }
            }}
            ref={inputEl}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default MultipleSelect;
