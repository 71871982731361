import lang from "translations";

const columns = [
  {
    key: "status",
    text: lang.status,
    width: "w-1/12",
    align: "center",
    custom: true,
  },
  {
    key: "name",
    text: lang.guest,
    width: "w-3/12",
    custom: true,
  },
  {
    key: "currentGuestProfiles",
    text: lang.currentGuestProfiles,
    width: "w-auto",
    custom: true,
  },
  {
    key: "checkInDateTime",
    text: lang.checkedInDate,
    custom: true,
    width: "w-auto",
  },
  {
    key: "checkOutDateTime",
    text: lang.checkedOutDate,
    width: "w-auto",
    custom: true,
  },
  {
    key: "totalBalance",
    text: lang.balance,
    width: "w-auto",
    align: "right",
  },
  {
    key: "transactionCount",
    text: lang.transactions,
    width: "w-auto",
    align: "right",
  },
];

export default columns;
