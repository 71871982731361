import { searchCategory, searchLocation } from "apis";
import { Field, Filter, MultipleSelect, Select } from "components/commons";
import { useApi, useMount } from "hooks";
import { allCategoryRequest, allLocationRequest, location, categoryResponse } from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";

const ProductFilter = ({ filterState, requestState, modifyFilter, clearFilter, applyFilter }) => {
  const {
    request: searchCategoryRequest,
    loading: loadingCategories,
    mappedData: categories,
  } = useApi({
    api: searchCategory,
    isArray: true,
    mapper: categoryResponse,
    handleOwnError: true,
  });

  const {
    request: searchLocationRequest,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });
  useMount(() => {
    fetchCategoryRequest(requestState);
    fetchLocationRequest(requestState);
  });

  const fetchCategoryRequest = useCallback(
    (requestState) => {
      searchCategoryRequest(mapObject({ ...requestState }, allCategoryRequest));
    },
    [searchCategoryRequest]
  );

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, allLocationRequest));
    },
    [searchLocationRequest]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const categoryOptions = useMemo(() => {
    return mapObjectsToSelect(categories, { textKey: "name" });
  }, [categories]);

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return mapObjectsToSelect(sorted, { textKey: "name" });
  }, [locations]);

  const posOptions = useMemo(() => {
    return [
      { text: lang.allProducts, value: null },
      { text: lang.sellingOnPos, value: true },
      { text: lang.notSellingOnPos, value: false },
    ];
  }, []);

  // const productStockLevelOptions = useMemo(() => {
  //   const productStockLevels = [
  //     ProductStockLevel.InStock,
  //     ProductStockLevel.CheckStock,
  //     ProductStockLevel.ReorderStock,
  //   ];
  //   return productStockLevels.map((type) => {
  //     return { text: prettifyProductStockLevel(type), value: type };
  //   });
  // }, []);

  return (
    <Filter
      placeholder={lang.searchProductByKeyOrSku}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      filterState={filterState}
      actionsSpan={3}
    >
      <Field className="col-span-3" filterLabel={lang.productCategory}>
        <MultipleSelect
          name="categories"
          selectAllText={lang.allCategories}
          loading={loadingCategories}
          options={categoryOptions}
          value={filterState.categories}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!categoryOptions.length ? lang.noAvailableCategories : lang.selectCategory}
          emptyPlaceHolder={lang.noAvailableCategories}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocations}
          options={locationOptions}
          value={filterState.locations}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
          emptyPlaceHolder={lang.noLocationAvailable}
        />
      </Field>
      {/* <Field className="col-span-3" filterLabel={lang.stockLevels}>
        <MultipleSelect
          name="productStockLevels"
          defaultAll={true}
          selectAllText={lang.allStockLevels}
          isAll={filterState.productStockLevels && filterState.productStockLevels.length === 0}
          options={productStockLevelOptions}
          value={filterState.productStockLevels}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.selectStockLevel}
        />
      </Field> */}
      <Field className="col-span-3" filterLabel={lang.availabilityOnPos}>
        <Select
          name="posStatus"
          options={posOptions}
          value={filterState.posStatus}
          onChange={modifyFilter}
        />
      </Field>
    </Filter>
  );
};

export default ProductFilter;
