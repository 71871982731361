import classNames from "classnames";
import { Field, Icon, Input, Select, Text, Tooltip } from "components/commons";
import lang from "translations";
import React, { useContext } from "react";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber, toAmount } from "services";
import { VenueContext } from "contexts";

const SupplyItemField = ({
  supplyItem,
  supplyUnit,
  supplyQuantity,
  supplyCost,
  sellingQuantity,
  modifyForm,
  requestMeasurement,
  isEdit,
  options,
}) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;
  const isProductPerPc = supplyUnit.unit === "pc";
  const measurementOptions = requestMeasurement.mappedData;

  return (
    <div
      className={classNames("grid mt-sm", {
        "grid-cols-5": isEdit,
        "grid-cols-6": !isEdit && isProductPerPc,
        "grid-cols-4": !isEdit && !isProductPerPc,
      })}
    >
      {isEdit && (
        <div className="col-span-2">
          <Field {...supplyItem}>
            <Select
              {...supplyItem}
              options={options}
              onChange={(name, { value, option }) => {
                const item = options[option.key];

                modifyForm({
                  [name]: {
                    value: value,
                  },
                  supplyUnit: {
                    unit: item?.measurement?.unit || "pc",
                  },
                  supplyCost: {
                    value: (item.supplyCost * sellingQuantity.value).toFixed(2),
                  },
                });
              }}
            />
          </Field>
        </div>
      )}

      {!isEdit && (
        <div className={"col-span-2 pr-xs"}>
          <Field {...supplyUnit}>
            <Select
              {...supplyUnit}
              onChange={(name, { value, option }) => {
                const item = measurementOptions[option.key];
                const sellingQty = (parseAmountToNumber(sellingQuantity.value) || 1)
                  .toString()
                  .substring(0, item.text === "pc" ? 8 : 11);

                modifyForm({
                  [name]: {
                    ...supplyUnit,
                    value,
                    unit: measurementOptions[option.key].text,
                  },
                  [sellingQuantity.name]: {
                    ...sellingQuantity,
                    value: toAmount(sellingQty, item.text === "pc" ? "0,0" : "0,0.000"),
                    disabled: false,
                  },
                  [supplyQuantity.name]: {
                    ...supplyQuantity,
                    required: item.text === "pc",
                    value: "0.000",
                    disabled: false,
                  },
                });
              }}
              options={measurementOptions}
            />
          </Field>
        </div>
      )}

      {isProductPerPc && !isEdit && (
        <div className="col-span-2">
          <Field {...supplyQuantity}>
            <Input
              {...supplyQuantity}
              required={false}
              iconSuffix={<Text color="text-gray">g</Text>}
              onChange={(name, { value }) => {
                if (!isNumberValid(value) || !isValidDecimalPlaces(value, 3)) {
                  return;
                }

                const max = 9999999.999;
                if (parseAmountToNumber(value) <= max) {
                  supplyQuantity.onChange(name, {
                    value: value.trim(),
                  });
                }
              }}
              onFocus={() => {
                const value = parseAmountToNumber(supplyQuantity.value);
                supplyQuantity.onChange(supplyQuantity.name, {
                  value: !value ? "" : value,
                });
              }}
              onBlur={() => {
                supplyQuantity.onChange(supplyQuantity.name, {
                  // value: toAmount(parseAmountToNumber(supplyQuantity.value), "0,0.000"),
                  value: supplyQuantity.value,
                });
              }}
            />
          </Field>
        </div>
      )}

      {(supplyUnit.value || isEdit) && (
        <div
          className={classNames("pl-xs", {
            "col-span-2": !isEdit,
            "col-span-1": isEdit,
          })}
        >
          <Field
            {...sellingQuantity}
            customLabel={
              <div className="flex items-center h-6">
                <Text label>{sellingQuantity.label}</Text>
                <Tooltip title={<Text color="text-white">{lang.quantitySupplyItems}</Text>}>
                  <span className="h-min mt-0 flex items-center">
                    <Icon name="info" color="text-gray" className="text-xxs" />
                  </span>
                </Tooltip>
              </div>
            }
          >
            <Input
              {...sellingQuantity}
              onChange={(name, { value }) => {
                if (!isNumberValid(value) || !isValidDecimalPlaces(value, isProductPerPc ? 0 : 3)) {
                  return;
                }
                const max = isProductPerPc ? 99999999 : 9999999.999;
                if (parseAmountToNumber(value) <= max) {
                  let items = options?.filter((option) => {
                    return option.value === supplyItem.value;
                  });
                  if (items?.[0]) {
                    let item = items[0];
                    modifyForm({
                      sellingQuantity: {
                        value: value.trim(),
                      },
                      supplyCost: {
                        value: (item.supplyCost * value.trim()).toFixed(2),
                      },
                    });
                  } else {
                    sellingQuantity.onChange(name, {
                      value: value.trim(),
                    });
                  }
                }
              }}
              onFocus={() => {
                const value = parseAmountToNumber(sellingQuantity.value);
                sellingQuantity.onChange(sellingQuantity.name, {
                  value: !value ? "" : value,
                });
              }}
              onBlur={() => {
                sellingQuantity.onChange(sellingQuantity.name, {
                  // value: toAmount(
                  //   parseAmountToNumber(sellingQuantity.value),
                  //   isProductPerPc ? "0,0" : "0,0.000"
                  // ),
                  value: sellingQuantity.value,
                });
              }}
              iconSuffix={<Text color="text-gray">{supplyUnit.unit}</Text>}
            />
          </Field>
        </div>
      )}

      {isEdit && (
        <div className={"col-span-2 pl-xs"}>
          <Field {...supplyCost}>
            <Input
              {...supplyCost}
              disabled={true}
              iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
            />
          </Field>
        </div>
      )}
    </div>
  );
};

export default SupplyItemField;
