import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import { useApi, useMount, useRouter, useFilter, useModal, useSelectItems } from "hooks";
import {
  DataTable,
  Icon,
  Text,
  Tooltip,
  ButtonLink,
  Modal,
  SimpleDropdown,
} from "components/commons";
import { location, supplyItem } from "mappers";
import { searchSupplyItem, exportSupplyItem } from "apis/supply-item.api";
import classNames from "classnames";
import { ModuleWrapper } from "components/fragments";
import SupplyItemFilter from "./supply-item-filter";
import { supplyItemFilter } from "./filters";
import { mapObject, mapObjectsToSelect, toAmount } from "services";
import { supplyItemListFilterRequest } from "mappers/supply-item.mapper";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import Transfer from "./transfer";
import styles from "./style.scss";
import useSelectID from "../../../hooks/useSelectID";
import StockAdjModal from "./stock-adj";
import { searchLocation } from "apis";

const SupplyItemList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const deleteModal = useModal();
  const transferModal = useModal();
  const stockAdjModal = useModal();
  const { setState: setFilterState, getState } = useFilterStore();
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchSupplyItem,
    isArray: true,
    mapper: supplyItem,
  });

  const locationRequest = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });

  const { selected, setSelected, isAllSelected, setSelectAll, clearSelected, replaceSelected } =
    useSelectItems({
      items: mappedData,
      indeterminate: true,
    });
  const selectID = useSelectID("supply-item");

  const onSelect = (id) => {
    setSelected(id);
    selectID.onSel(id);
  };
  const onSelectAll = () => {
    setSelectAll();
    const ids = mappedData?.map(({ id }) => id);
    if (!isAllSelected) selectID.onAdd(ids);
    else selectID.onSub(ids);
  };

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter, isFilterDirty } =
    useFilter(
      filterName.supplyItem === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : supplyItemFilter(venueId),
      supplyItemFilter(venueId)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.supplyItem !== getState().name)
      setFilterState({
        name: filterName.supplyItem,
        filter: {},
      });
    fetchSupplyItems(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.supplyItems,
    });
  });

  const fetchSupplyItems = useCallback(
    async (requestState) => {
      clearSelected();
      await request(mapObject(requestState, supplyItemListFilterRequest));
    },
    [request, clearSelected]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.supplyItem,
        filter: filterState,
      });
      fetchSupplyItems(requestState);
    },
    [fetchSupplyItems, setFilterState]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [fetchUpdateStore, clearFilter]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
        sort: { key: "p.productName", value: "asc" },
      });
      await fetchUpdateStore(requestState, filterState);
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const renderData = useMemo(() => {
    return mappedData.map(
      ({ id, name, inStock, parLevel, reorderPoint, inventoryValue, measurement = {} }) => {
        return {
          id,
          name: (
            <Tooltip
              title={
                inStock < parLevel
                  ? lang.stockBelowParLevel
                  : inStock <= reorderPoint && reorderPoint > 0
                  ? lang.stockReachedReorderPoint
                  : null
              }
            >
              <div className="flex align-center items-center ">
                <ButtonLink
                  // onClick={() => {
                  //   history.push(Path.SUPPLY_ITEM_ID(id));
                  // }}
                  path={Path.SUPPLY_ITEM_ID(id)}
                >
                  <Text color="text-sm text-black font-semibold text-left hover:text-gray">
                    {name}
                  </Text>
                </ButtonLink>

                {inStock < parLevel ? (
                  <Icon paddingless name="warning" className="ml-sm text-red-500" />
                ) : inStock <= reorderPoint && reorderPoint > 0 ? (
                  <Icon paddingless name="warning" className="ml-sm text-yellow-400" />
                ) : null}
              </div>
            </Tooltip>
          ),
          inStock: (
            <Text
              className={classNames({
                "text-red-500": inStock < parLevel,
              })}
            >
              {toAmount(inStock, measurement?.unit === "pc" ? "0,0" : "0,0.00")} {measurement?.unit}
            </Text>
          ),
          parLevel: (
            <Text>
              {toAmount(parLevel, measurement?.unit === "pc" ? "0,0" : "0,0.00")}{" "}
              {measurement?.unit}
            </Text>
          ),
          reorderPoint: (
            <Text>
              {toAmount(reorderPoint, measurement?.unit === "pc" ? "0,0" : "0,0.00")}{" "}
              {measurement?.unit}
            </Text>
          ),
          inventoryValue,
        };
      }
    );
  }, [mappedData]);

  const { request: exportSupplyItemsRequest } = useApi({
    api: exportSupplyItem,
  });

  const exportCsv = useCallback(() => {
    exportSupplyItemsRequest(mapObject(requestState, supplyItemListFilterRequest));
  }, [exportSupplyItemsRequest, requestState]);

  const actionSelectOption = useMemo(() => {
    const arr = [
      {
        text: lang.importSupplyItems,
        onClick: () => {
          history.push(Path.IMPORT_SUPPLY_ITEM);
        },
      },
      {
        text: lang.importSupplyItemsCreateWholeProducts,
        onClick: () => {
          history.push(Path.IMPORT_SUPPLY_ITEM_CREATE_WHOLE);
        },
      },
      {
        text: lang.export,
        onClick: () => {
          exportCsv();
        },
      },
      {
        text: lang.transfer,
        onClick: transferModal.show,
      },
      {
        text: lang.stockAdjustment,
        onClick: stockAdjModal.show,
      },
    ];
    // hide export when no data
    if (!result.metadata?.total) {
      arr.splice(2, 1);
    }
    return arr.map((e, i) => ({ ...e, value: i }));
  }, [exportCsv, history, result.metadata?.total, stockAdjModal.show, transferModal.show]);

  const locationOptions = useMemo(() => {
    let sorted = locationRequest.mappedData
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let mapped = mapObjectsToSelect(sorted, { textKey: "name", valueKey: "id" });
    return mapped;
  }, [locationRequest.mappedData]);

  useEffect(() => {
    const obj = {};
    mappedData
      .filter(({ id }) => selectID.arr.includes(id))
      .forEach((item, index) => {
        obj[item["id"]] = {
          ...item,
          index,
          checked: true,
        };
      });
    replaceSelected(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.supplyItems}
        description={lang.createAndManageRawMaterials}
        button={{
          path: Path.CREATE_SUPPLY_ITEM,
          text: lang.createNewSupplyItem,
        }}
        className="mb-md"
      />
      <SupplyItemFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
        locationRequest={locationRequest}
        locationOptions={locationOptions}
      />

      <div className="flex justify-end gap-4 mb-sm">
        <SimpleDropdown
          text={<div className="text-pelorous text-base mr-1">{lang.actions}</div>}
          options={actionSelectOption}
          classname="cursor-pointer border rounded border-solid border-white-darker flex align-center bg-white justify-center p-sm"
          iconName="sort-descending1"
          iconStyle="text-pelorous text-xxs"
        />
      </div>

      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={result.metadata?.total}
        loading={loading}
        hasAppliedFilter={hasAppliedFilter}
        columns={[
          {
            key: "name",
            text: lang.supplyItem,
            custom: true,
            align: "left",
          },
          {
            key: "inStock",
            text: lang.inStock,
            custom: true,
            align: "right",
          },
          {
            key: "parLevel",
            text: lang.parLevel,
            custom: true,
            align: "right",
          },
          {
            key: "reorderPoint",
            text: lang.reorderPoint,
            custom: true,
            align: "right",
          },
          {
            key: "inventoryValue",
            text: lang.inventoryValue,
            align: "right",
          },
          {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.editSupplyItem,
                onClick: (column) => {
                  history.push(Path.EDIT_SUPPLY_ITEM(column.id));
                },
              },
              {
                text: lang.deleteSupplyItem,
                className: "text-red-500",
                onClick: (column) => {
                  deleteModal.show({
                    id: column.id,
                  });
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
        selected={selected}
        setSelected={onSelect}
        isAllSelected={isAllSelected}
        setSelectAll={onSelectAll}
      />
      <DeleteSupplyItem
        onList={true}
        {...deleteModal}
        refreshList={() => fetchSupplyItems(requestState)}
      ></DeleteSupplyItem>

      <Modal {...transferModal} noCloseButton closable className={styles.modal} width={1191}>
        <Transfer
          open={transferModal.active}
          locationOptions={locationOptions}
          listData={selectID.arr}
          onClose={transferModal.close}
          onSuccess={() => {
            transferModal.close();
            selectID.onDel();
            fetchSupplyItems(requestState);
          }}
        />
      </Modal>
      <StockAdjModal
        {...stockAdjModal}
        locationOptions={locationOptions}
        defaultIds={selectID.arr}
        onSuccess={() => {
          stockAdjModal.close();
          selectID.onDel();
          fetchSupplyItems(requestState);
        }}
      ></StockAdjModal>
    </ModuleWrapper>
  );
};

export default SupplyItemList;
