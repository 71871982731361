import React, { useCallback, useContext, useMemo } from "react";
import { searchPrinter } from "apis";
import { DataTable, ButtonLink, Button, Text, TabBar } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { printerListFilterRequest, printerListResponse } from "mappers";
import { mapObject } from "services";
import columns from "./columns";
import { Path } from "paths";
import lang from "translations";
import { NoVendors } from "../../../images";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const PrinterList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchPrinterRequest,
    loading: loadingPrinters,
    result: searchPrinterResult = { data: [], metadata: { total: 0 } },
    mappedData,
    error,
  } = useApi({
    api: searchPrinter,
    isArray: true,
    mapper: printerListResponse,
  });

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.printer === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.printer !== getState().name)
      setFilterState({
        name: filterName.printer,
        filter: {},
      });
    fetchPrinters(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.printers,
    });
  });

  const fetchPrinters = useCallback(
    (requestState) => {
      searchPrinterRequest(mapObject(requestState, printerListFilterRequest));
    },
    [searchPrinterRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.printer,
        filter: filterState,
      });
      fetchPrinters(requestState);
    },
    [fetchPrinters, setFilterState]
  );

  const preparePrinterList = useCallback(() => {
    return mappedData.map((printer) => {
      const { id, name, locations } = printer;
      return {
        name: (
          <ButtonLink
            className="text-left"
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.viewPrinter,
                Page: lang.printers,
              });
              // history.push(Path.PRINTER_ID(id));
            }}
            path={Path.PRINTER_ID(id)}
          >
            {name}
          </ButtonLink>
        ),
        locations: (
          <div className="flex flex-wrap">
            {locations.map(({ deleted, locationId, locationName, status }, index) => (
              <div className="mr-xs flex">
                {deleted ? (
                  <Text>{locationName}</Text>
                ) : (
                  <ButtonLink
                    className="cursor-pointer"
                    // onClick={() => history.push(Path.LOCATION_ID(locationId))}
                    path={Path.LOCATION_ID(locationId)}
                  >
                    <Text className="hover:text-gray">{locationName}</Text>
                  </ButtonLink>
                )}
                {locations.length - 1 === index ? null : <Text>,</Text>}
              </div>
            ))}
          </div>
        ),
      };
    });
  }, [mappedData]);

  const printers = useMemo(() => {
    return preparePrinterList();
  }, [preparePrinterList]);

  return (
    <ModuleWrapper>
      <HeaderA title={lang.printers} className="mb-sm" />

      <TabBar className="mb-lg" items={[{ to: Path.PRINTER, text: lang.printerProfiles }]} />
      <Text color="text-rgba(0,0,0,.65)">{lang.byCreatingPrinterProfiles}</Text>
      <Button
        className="mt-md mb-lg"
        onClick={() => {
          mixpanel.track(TrackEvent.ClickedButton, {
            Button: lang.createPrinterProfile,
            Page: lang.printers,
          });
          history.push(Path.PRINTER_CREATE);
        }}
      >
        {lang.createPrinterProfile}
      </Button>
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchPrinterResult.metadata.total}
        loading={loadingPrinters}
        columns={columns}
        data={printers}
        error={error}
        renderEmpty={{
          image: NoVendors,
          title: lang.createPrinterProfile,
        }}
      />
    </ModuleWrapper>
  );
};

export default PrinterList;
