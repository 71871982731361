import React from "react";
import { Path } from "paths";
import {
  SupplyItemList,
  CreateSupplyItem,
  SupplyItemSummary,
  SupplyItemMovement,
  EditSupplyItem,
  StocktakeList,
  CreateStocktake,
  StocktakeDetails,
} from "modules";
import { RouteRender } from "components/commons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { setPageTitle } from "services";
import { useMount } from "hooks";
import ImportSupplyItems from "modules/inventory/import-supply-item";

const InventoryPage = () => {
  const flags = useFlags();
  const { stocktake, supplyItems } = flags;

  useMount(() => {
    setPageTitle("inventory");
  });

  return (
    <RouteRender
      defaultRedirect={Path.INVENTORY_SUPPLY_ITEM}
      renders={{
        [Path.CREATE_STOCKTAKE]: {
          exact: true,
          component: CreateStocktake,
          featureFlag: stocktake,
        },
        [Path.CREATE_SUPPLY_ITEM]: {
          exact: true,
          component: CreateSupplyItem,
          featureFlag: supplyItems,
        },
        [Path.IMPORT_SUPPLY_ITEM]: {
          exact: true,
          component: ImportSupplyItems,
          featureFlag: supplyItems,
        },
        [Path.EDIT_SUPPLY_ITEM_PAGE]: {
          exact: true,
          component: EditSupplyItem,
          featureFlag: supplyItems,
        },
        [Path.SUPPLY_ITEM_MOVEMENT]: {
          exact: true,
          component: SupplyItemMovement,
          featureFlag: supplyItems,
        },
        [Path.SUPPLY_ITEM_SUMMARY]: {
          exact: true,
          component: SupplyItemSummary,
          featureFlag: supplyItems,
        },
        [Path.INVENTORY_SUPPLY_ITEM]: {
          exact: true,
          component: SupplyItemList,
          featureFlag: supplyItems,
        },
        [Path.INVENTORY_STOCKTAKE]: {
          exact: true,
          component: StocktakeList,
          featureFlag: stocktake,
        },
        [Path.STOCKTAKE_PAGE]: {
          exact: true,
          component: StocktakeDetails,
          featureFlag: stocktake,
        },
      }}
    />
  );
};

export default InventoryPage;
