import {
  Button,
  DataTable,
  HeaderB,
  Modal,
  ModuleWrapper,
  Panel,
  Switch,
  Text,
  Toast,
} from "components";
import { useModal, useRouter } from "hooks";
import { Path } from "paths";
import React, { useMemo, useState } from "react";
import lang from "translations";
import { useList } from "./useList";
import FormModal from "./form-modal";
import { StyleType } from "enums";
import { deleteAccessPoint } from "apis/accessPoint";
import useToggle from "./useToggle";

const AccessSetting = () => {
  const { history } = useRouter();
  const { error, loading, mappedData, request } = useList();
  const formModal = useModal();
  const deleteModal = useModal();
  const [delId, setDelId] = useState();
  const { checked, loading: loadingToggle, onToggle } = useToggle();

  const renderData = useMemo(() => {
    return mappedData.map((data) => {
      const { name, id, guestProfiles } = data;
      return {
        data,
        name: (
          <div
            className={"text-pelorous text-base cursor-pointer max-w-xs"}
            onClick={() => {
              formModal.show({
                isEdit: true,
                data: data,
                onRefresh: request,
              });
            }}
          >
            {name}
          </div>
        ),
        id: id,
        guestProfiles: guestProfiles?.length ? (
          <div className="max-w-xs">
            {guestProfiles.map((profile) => (
              <Text className={"text-black text-base"}>{profile?.guestProfileName}</Text>
            ))}
          </div>
        ) : (
          "-"
        ),
      };
    });
  }, [formModal, mappedData, request]);

  const onDel = () => {
    deleteModal.onClose();
    if (!delId) return;
    deleteAccessPoint(delId)
      .then(() => {
        setDelId(0);
        request();
        Toast({
          content: lang.deletedSuccessfully,
          success: true,
          icon: "check",
        }).open();
      })
      .catch(() => {
        Toast({
          content: lang.somethingWentWrongPlsTryAgain,
          error: true,
          icon: "exclamation-fill",
        }).open();
      });
  };

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.accessPoint}
          returnPath={Path.SETTING}
          onClick={() => {
            history.push(Path.SETTING);
          }}
        />
      }
    >
      <Panel>
        <Text className="text-pelorous-darker text-base font-semibold">
          {lang.regulateEntryExit}
        </Text>
        <Text className={"whitespace-pre text-gray text-sm"}>{lang.regulateEntryExitDesc}</Text>
        <div className="inline-flex items-center mt-2">
          <Switch className="mr-2" disabled={loadingToggle} value={checked} onChange={onToggle} />
          <div className="text-xs text-black-light pt-1.5">{lang.enableDifferentiateEntryExit}</div>
        </div>
      </Panel>
      <div className="bg-white rounded">
        <div className="flex justify-end pt-5 pr-10">
          <Button onClick={() => formModal.show()}>{lang.createAccessPoint}</Button>
        </div>

        <DataTable
          total={0}
          error={error}
          loading={loading}
          data={renderData}
          columns={[
            {
              key: "name",
              text: lang.accessPoint,
            },
            {
              key: "guestProfiles",
              text: lang.allowedGuestProfile,
            },
            {
              key: "action",
              actions: true,
              align: "right",
              actionOptions: [
                {
                  text: lang.edit,
                  className: "hover:bg-pelorous-lighter",
                  onClick: (column) => {
                    formModal.show({
                      isEdit: true,
                      data: column.data,
                      onRefresh: request,
                    });
                  },
                },
                {
                  text: lang.delete,
                  onClick: (column) => {
                    setDelId(column.data.id);
                    deleteModal.show();
                  },
                },
              ],
            },
          ]}
        />
      </div>

      <FormModal {...formModal} onRefresh={request}></FormModal>
      <Modal
        customTitle={
          <div className="px-1 text-2xl text-pelorous-darker font-semibold">
            {lang.deleteAccessPoint} ?
          </div>
        }
        {...deleteModal}
      >
        <div className="px-1 hover:bg-pelorous-lighter">{lang.deleteAccessPointDesc}</div>
        <div className="flex justify-end gap-4 py-5 px-1">
          <Button type={StyleType.Secondary} onClick={deleteModal.onClose}>
            {lang.discard}
          </Button>
          <Button type={StyleType.Danger} onClick={onDel}>
            {lang.deleteAccessPoint}
          </Button>
        </div>
      </Modal>
    </ModuleWrapper>
  );
};

export default AccessSetting;
