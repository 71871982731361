import { searchDiscount, updateDiscountPos } from "apis";
import { Pill, Switch, Text, DataTable, Toast, ButtonLink, HeaderA } from "components";
import { VenueContext } from "contexts";
import { DiscountStatus, PillType } from "enums";
import {
  useApi,
  useFilter,
  useMount,
  // useRouter
} from "hooks";
import { discountListResponse, discountListFilterRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject, prettifyDiscountStatus } from "services";
import columns from "./columns";
import lang from "translations";
import { Path } from "paths";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const DiscountList = () => {
  const { venue } = useContext(VenueContext);
  // const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    error,
    request: searchDiscountRequest,
    loading: loadingDiscount,
    result: searchDiscountResult = { data: [], metadata: { total: 0 } },
    mappedData,
  } = useApi({
    api: searchDiscount,
    isArray: true,
    mapper: discountListResponse,
  });

  const { request: updateDiscountPosRequest, loading: updatingPos } = useApi({
    api: updateDiscountPos,
  });

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.discount === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.discount !== getState().name)
      setFilterState({
        name: filterName.discount,
        filter: {},
      });
    fetchDiscounts(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.discountsPage,
    });
  });

  const fetchDiscounts = useCallback(
    (requestState) => {
      searchDiscountRequest(mapObject(requestState, discountListFilterRequest));
    },
    [searchDiscountRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.discount,
        filter: requestState,
      });
      fetchDiscounts(requestState);
    },
    [fetchDiscounts, setFilterState]
  );

  const renderDiscount = useCallback(
    (name, id) => (
      <ButtonLink
        className="text-left"
        flex={false}
        // onClick={() => history.push(Path.DISCOUNT_ID(id))}
        path={Path.DISCOUNT_ID(id)}
      >
        {name}
      </ButtonLink>
    ),
    []
  );

  const renderStatus = useCallback((status) => {
    if (status === DiscountStatus.Active) {
      return (
        <Pill type={PillType.Green} size="text-xs">
          {prettifyDiscountStatus(status)}
        </Pill>
      );
    }
    if (status === DiscountStatus.Inactive) {
      return (
        <Pill type={PillType.Opaque} size="text-xs">
          {prettifyDiscountStatus(status)}
        </Pill>
      );
    }
    return (
      <Pill type={PillType.Gray} size="text-xs">
        {prettifyDiscountStatus(status)}
      </Pill>
    );
  }, []);

  const renderAvailability = useCallback((startDate, startTime, endDate, endTime) => {
    if (startDate && startTime && endDate && endTime) {
      return (
        <div>
          <div className="flex">
            <Text className="whitespace-nowrap">{startDate}</Text>
            <Text className="whitespace-nowrap mx-xs" color="text-gray">
              {startTime}
            </Text>
            <Text>{lang.to}</Text>
          </div>
          <div className="flex">
            <Text className="whitespace-nowrap">{endDate}</Text>
            <Text className="whitespace-nowrap mx-xs" color="text-gray">
              {endTime}
            </Text>
          </div>
        </div>
      );
    }

    if (startDate && startTime && endTime) {
      return (
        <div>
          <div className="flex">
            <Text className="whitespace-nowrap">From {startDate}</Text>
          </div>
          <div className="flex">
            <Text color="text-gray whitespace-nowrap">{startTime}</Text>
            <Text className="whitespace-nowrap mx-xs">{lang.to}</Text>
            <Text color="text-gray whitespace-nowrap">{endTime}</Text>
          </div>
        </div>
      );
    }

    if (startDate && endDate) {
      return (
        <div>
          <div className="flex">
            <Text className="whitespace-nowrap">{startDate}</Text>
            <Text className="whitespace-nowrap ml-xs">{lang.to}</Text>
          </div>
          <div>
            <Text className="whitespace-nowrap">{endDate}</Text>
          </div>
        </div>
      );
    }

    if (startDate) {
      return (
        <div>
          <Text className="whitespace-nowrap">From {startDate}</Text>
        </div>
      );
    }

    return "";
  }, []);

  const renderDateCreated = useCallback((dateCreated) => {
    return (
      <div>
        <Text className="whitespace-nowrap">{dateCreated?.date}</Text>
        {dateCreated?.time && <Text color="text-gray whitespace-nowrap">{dateCreated.time}</Text>}
      </div>
    );
  }, []);

  const updateDiscountPosCb = useCallback(
    async (discountId, sellableOnPos) => {
      await updateDiscountPosRequest({ sellableOnPos, discountId });
      fetchUpdateStore(requestState, filterState);
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    },
    [fetchUpdateStore, filterState, requestState, updateDiscountPosRequest]
  );

  const renderOnPos = useCallback(
    (discountId, onPos) => {
      return (
        <Switch
          value={onPos}
          onChange={() => {
            updateDiscountPosCb(discountId, !onPos);
          }}
        />
      );
    },
    [updateDiscountPosCb]
  );

  const prepareDiscountList = useCallback(() => {
    return mappedData.map((discount) => {
      const {
        discountId,
        name,
        value,
        status,
        startDate,
        startTime,
        endDate,
        endTime,
        dateCreated,
        onPos,
      } = discount;

      return {
        discountId,
        discount: renderDiscount(name, discountId),
        value,
        status: renderStatus(status),
        availability: renderAvailability(startDate, startTime, endDate, endTime),
        dateCreated: renderDateCreated(dateCreated),
        onPos: renderOnPos(discountId, onPos),
      };
    });
  }, [
    mappedData,
    renderDiscount,
    renderStatus,
    renderAvailability,
    renderDateCreated,
    renderOnPos,
  ]);

  const discounts = useMemo(() => {
    return prepareDiscountList();
  }, [prepareDiscountList]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.discounts}
        description={lang.createAndManagerDiscounts}
        button={{
          path: Path.DISCOUNT_CREATE,
          text: lang.createNewDiscount,
        }}
        className="mb-md"
      />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchDiscountResult.metadata.total}
        loading={loadingDiscount || updatingPos}
        columns={columns}
        data={discounts}
        error={error}
      />
    </ModuleWrapper>
  );
};

export default DiscountList;
