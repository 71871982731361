import { Post, Get } from "services";
import { ApiPath } from "paths";

export const searchTransaction = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_TRANSACTION}`, body);
  return res;
};

export const getGraphReportOfTotalSalesOfLocation = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_SALES_LOCATION(body.venueId)}`, body);
  return res;
};

export const getGraphReportOfTotalSales = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_SALES_TOTAL(body.venueId)}`, body);
  return res;
};

export const getTopLocationsBySales = async (body) => {
  const res = await Post(`${ApiPath.TOP_LOCATION_SALES(body.venueId)}`, body);
  return res;
};

export const getGraphOfTopUpSales = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_TOP_UP(body.venueId)}`, body);
  return res;
};

export const getGraphOfReturnCredits = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_RETURN_CREDITS(body.venueId)}`, body);
  return res;
};

export const getTopProductsBySales = async (body) => {
  const res = await Post(`${ApiPath.TOP_PRODUCTS_BY_SALES(body.venueId)}`, body);
  return res;
};

export const getAverageOrderValue = async (body) => {
  const res = await Post(`${ApiPath.AVERANGE_ORDER_VALUE(body.venueId)}`, body);
  return res;
};

export const getTotalTransactionsValue = async (body) => {
  const res = await Post(`${ApiPath.TOTAL_TRANSACTIONS_VALUE(body.venueId)}`, body);
  return res;
};

export const getTopSpendingGuest = async (body) => {
  const res = await Post(`${ApiPath.TOP_SPENDING_GUESTS(body.venueId)}`, body);
  return res;
};

export const searchProductsThatIsOutOfStock = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_OUT_OF_STOCK}`, body);
  return res;
};

export const getTopCategoriesBySales = async (body) => {
  const res = await Post(`${ApiPath.TOP_CATEGORIES_BY_SALES(body.venueId)}`, body);
  return res;
};

export const getTransactionById = async ({ transactionId }) => {
  const res = await Get(`${ApiPath.TRANSACTION_ID(transactionId)}`, {});
  return res;
};

export const exportTransaction = async ({ ...body }) => {
  const res = await Post(`${ApiPath.TRANSACTION_EXPORT()}`, body);
  return res;
};

export const exportTransactionTax = async ({ ...body }) => {
  const res = await Post(`${ApiPath.TRANSACTION_TAX_EXPORT()}`, body);
  return res;
};

export const searchSalesReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SALES_REPORT_EXPORT}`, body);
  return res;
};

export const searchSalesReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SALES_REPORT}`, body);
  return res;
};

export const getGraphSalesReport = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_SALES_REPORT(body.venueId)}`, body);
  return res;
};

export const getTotalUnspentCredits = async (body) => {
  const res = await Post(`${ApiPath.TOTAL_UNSPENT_CREDITS(body.venueId)}`, body);
  return res;
};

export const getTotalCreditsReceived = async (body) => {
  const res = await Post(`${ApiPath.TOTAL_CREDITS_RECEIVED(body.venueId)}`, body);
  return res;
};

export const getTotalCreditsKept = async (body) => {
  const res = await Post(`${ApiPath.TOTAL_CREDITS_KEPT(body.venueId)}`, body);
  return res;
};