import { Field, StaffRole } from "enums";
import { prettifyStaffRole } from "services";
import {
  describeDashboardAccess,
  describeAppAccess,
  classAppAccess,
  classDashboardAccess,
} from "services/pretty.service";
import Validation from "services/validation.service";
import lang from "translations";

export const roleOptions = (hasManager) => {
  const arr = [
    StaffRole.SellOnPos,
    StaffRole.TopupCredits,
    // StaffRole.ReturnCredits,
    StaffRole.RegisterGuests,
    StaffRole.StockManager,
    StaffRole.FrontOffice,
    StaffRole.BackOffice,
    StaffRole.AccessControl,
  ];
  if (hasManager) {
    arr.push(StaffRole.Manager);
  }
  return arr.map((role) => {
    return {
      text: prettifyStaffRole(role),
      value: role,
      dashboardAccess: describeDashboardAccess(role),
      dashboardAccessClass: classDashboardAccess(role),
      appAccess: describeAppAccess(role),
      appAccessClass: classAppAccess(role),
    };
  });
};

const initialFormState = (initialState = {}) => {
  const {
    email,
    staffProfile,
    role = { value: [], isAll: false },
    paymentMethod = { disabled: true, value: [] },
    location = { disabled: true, value: [] },
    guestProfiles = { disabled: true, value: [] },
    accessControls = { disabled: true, value: [] },
    isActive,
  } = initialState;
  let isManager = true;
  let hasPM = false;
  let hasLocation = false;
  let hasGuestProfile = false;
  let hasAccess = false;
  const v = role.value.map((r) => {
    return r;
  });
  isManager = !v.length;

  if (
    isManager ||
    v.includes(StaffRole.TopupCredits) ||
    v.includes(StaffRole.SellOnPos) ||
    v.includes(StaffRole.RegisterGuests)
  ) {
    hasPM = true;
  }
  if (
    isManager ||
    v.includes(StaffRole.SellOnPos) ||
    v.includes(StaffRole.StockManager) ||
    v.includes(StaffRole.TopupCredits) ||
    v.includes(StaffRole.RegisterGuests)
  ) {
    hasLocation = true;
  }
  if (isManager || v.includes(StaffRole.RegisterGuests)) {
    hasGuestProfile = true;
  }
  if (isManager || v.includes(StaffRole.AccessControl)) {
    hasAccess = true;
  }

  return {
    email: {
      name: "email",
      value: "",
      type: Field.INPUT,
      validations: [Validation.emailAddress(), Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.emailAddress,
      placeholder: "john@example.com",
      ...email,
    },
    staffProfile: {
      name: "staffProfile",
      value: null,
      type: Field.INPUT,
      label: lang.staffProfile,
      options: [],
      ...staffProfile,
    },
    role: {
      name: "role",
      type: Field.ANY,
      validations: [Validation.requiredMultiDropdown()],
      required: true,
      label: lang.role,
      placeholder: lang.chooseRole,
      options: roleOptions(),
      selectAllText: lang.manager,
      value: role.value,
      isAll: role.isAll,
    },
    paymentMethod: {
      name: "paymentMethod",
      value: paymentMethod.value,
      type: Field.ANY,
      validations: [],
      required: true,
      label: lang.allowedPaymentMethod,
      placeholder: lang.choosePaymentMethod,
      selectAllText: lang.allPaymentMethods,
      options: [],
      disabled: paymentMethod?.disabled || !hasPM || paymentMethod.disabled,
      emptyPlaceHolder: lang.noAvailablePaymentMethodYet,
    },
    guestProfiles: {
      name: "guestProfiles",
      value: guestProfiles.value,
      type: Field.ANY,
      options: [],
      validations: [],
      required: true,
      label: lang.allowGuestProfile,
      placeholder: lang.selectGuestProfile,
      selectAllText: lang.allGuestProfiles,
      emptyPlaceHolder: lang.noAvailableGuestProfile,
      disabled: guestProfiles?.disabled || !hasGuestProfile || guestProfiles.disabled,
    },
    location: {
      name: "location",
      value: location.value,
      type: Field.ANY,
      validations: [],
      required: true,
      label: lang.locations,
      placeholder: lang.chooseLocationForThisStaff,
      selectAllText: lang.allLocations,
      options: [],
      disabled: location?.disabled || !hasLocation || location.disabled,
      emptyPlaceHolder: lang.noAvailableLocationsYet,
    },
    accessControls: {
      name: "accessControls",
      value: accessControls.value,
      type: Field.ANY,
      options: [],
      validations: [],
      required: true,
      label: lang.allowedAccessPoint,
      placeholder: lang.selectAccessPoints,
      selectAllText: lang.allAccessPoints,
      emptyPlaceHolder: lang.noAvailableAccessPoint,
      disabled: accessControls?.disabled || !hasAccess || accessControls.disabled,
    },
    isActive: {
      name: "isActive",
      value: false,
      type: Field.CHECKBOX,
      title: lang.accountIsActive,
      description: lang.staffCanAccess,
      ...isActive,
    },
  };
};

export default initialFormState;
