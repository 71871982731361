import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { batchDeleteCategory, searchCategory } from "apis";
import { ButtonLink, DataTable, SimpleDropdown, Text, Toast } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter, useSelectItems } from "hooks";
import { categoryListFilterRequest, categoryListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { DeleteModal } from "components/modals";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import useSelectID from "hooks/useSelectID";

const CategoryList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();

  const deleteModal = useModal();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchCategoryRequest,
    loading: loadingCategory,
    result: searchCategoryResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchCategory,
    mapper: categoryListResponse,
    isArray: true,
    handleOwnError: true,
  });

  const { request: batchDeleteCategoryRequest, loading: deletingCategory } = useApi({
    api: batchDeleteCategory,
  });

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.category === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.category !== getState().name)
      setFilterState({
        name: filterName.category,
        filter: {},
      });
    fetchCategories(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.categoryList,
    });
  });

  const renderCategoryName = useCallback(
    (name, id) =>
      id === 0 ? (
        <Text italic>{lang.uncategorized}</Text>
      ) : (
        // <ButtonLink className="text-left" onClick={() => history.push(Path.CATEGORY_ID(id))}>
        <ButtonLink className="text-left" path={Path.CATEGORY_ID(id)}>
          {name}
        </ButtonLink>
      ),
    []
  );

  const renderDescription = useCallback(
    (description, id) => (
      <Text italic={id === 0}>{id === 0 ? lang.productsWithNoCategory : description}</Text>
    ),
    []
  );

  const renderProductCount = useCallback(
    (productCount, id) =>
      productCount > 0 ? (
        <ButtonLink
          className="text-left"
          onClick={() => {
            history.push(Path.PRODUCT, { categories: [{ value: id }] });
          }}
          // path={(Path.PRODUCT, { categories: [{ value: id }] })}
        >
          {productCount}
        </ButtonLink>
      ) : (
        <Text>{productCount}</Text>
      ),
    [history]
  );

  const prepareCategoryList = useCallback(() => {
    return mappedData.map((category) => {
      const { id, name, description, productCount } = category;
      return {
        id,
        name: renderCategoryName(name, id),
        description: renderDescription(description, id),
        productCount: renderProductCount(productCount, id),
      };
    });
  }, [mappedData, renderCategoryName, renderProductCount, renderDescription]);

  const categories = useMemo(() => {
    return prepareCategoryList();
  }, [prepareCategoryList]);

  const {
    selected,
    selectedCount,
    setSelected,
    isAllSelected,
    setSelectAll,
    clearSelected,
    replaceSelected,
  } = useSelectItems({
    items: categories,
    indeterminate: true,
  });

  const selectID = useSelectID("product-categories");

  const onSelect = (id) => {
    setSelected(id);
    selectID.onSel(id);
  };
  const onSelectAll = () => {
    setSelectAll();
    const ids = mappedData?.map(({ id }) => id);
    if (!isAllSelected) selectID.onAdd(ids);
    else selectID.onSub(ids);
  };

  useEffect(() => {
    const obj = {};
    mappedData
      .filter(({ id }) => selectID.arr.includes(id))
      .forEach((item, index) => {
        obj[item["id"]] = {
          ...item,
          index,
          checked: true,
        };
      });
    replaceSelected(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  const fetchCategories = useCallback(
    (requestState) => {
      searchCategoryRequest(mapObject(requestState, categoryListFilterRequest));
    },
    [searchCategoryRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.category,
        filter: filterState,
      });
      fetchCategories(requestState);
    },
    [fetchCategories, setFilterState]
  );

  const toBeDeletedCategory = useMemo(() => {
    if (selectedCount === 1) {
      const category = mappedData.find((d) => d.id === parseInt(Object.keys(selected)[0]));
      return category.name;
    }
    return null;
  }, [selectedCount, mappedData, selected]);

  const batchDeleteCategoryCb = useCallback(async () => {
    mixpanel.track(TrackEvent.ClickedButton, {
      Button: lang.deleteCategory,
      Page: lang.categoryList,
    });

    await batchDeleteCategoryRequest({
      categoryIds: selectID.arr,
    });
    Toast({
      content: toBeDeletedCategory
        ? lang.populate(lang.categoriesDeleted, [selectID.arr.length])
        : lang.populate(lang.categoryDeleted, [toBeDeletedCategory]),
      success: true,
      icon: "check",
    }).open();
    deleteModal.close();
    const { requestState } = modifyFilters({ page: 1, pageSize: filterState.pageSize });
    clearSelected();
    selectID.onDel();
    fetchCategories(requestState);
  }, [
    batchDeleteCategoryRequest,
    deleteModal,
    selectID,
    fetchCategories,
    toBeDeletedCategory,
    filterState.pageSize,
    modifyFilters,
    clearSelected,
  ]);

  const changePageConfigCb = useCallback(
    (pageProps) => {
      clearSelected();
      return modifyFilters(pageProps);
    },
    [modifyFilters, clearSelected]
  );

  const actionSelectContent = useMemo(() => {
    if (selectID.arr.length > 0) {
      return {
        text: `${selectID.arr.length} ${pluralize(selectID.arr.length, "item", "items")}`,
        options: [
          {
            text:
              selectID.arr.length > 1 ? lang.deleteSelectedCategories : lang.deleteSelectedCategory,
            value: "0",
            onClick: () => {
              deleteModal.show({
                ok: batchDeleteCategoryCb,
              });
            },
          },
        ],
      };
    }
  }, [batchDeleteCategoryCb, deleteModal, selectID.arr.length]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.categories}
        description={lang.createCategoriesToLink}
        button={{
          path: Path.CATEGORY_CREATE,
          text: lang.createNewCategory,
        }}
        className="mb-md"
      />
      <div className="md:flex justify-between items-center">
        {actionSelectContent && (
          <div>
            <SimpleDropdown
              className="mb-sm"
              text={actionSelectContent.text}
              options={actionSelectContent.options}
            />
          </div>
        )}
      </div>
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={changePageConfigCb}
        fetchList={fetchUpdateStore}
        total={searchCategoryResult.metadata.total}
        loading={loadingCategory}
        columns={columns}
        data={categories}
        error={error}
        selected={selected}
        setSelected={onSelect}
        isAllSelected={isAllSelected}
        setSelectAll={onSelectAll}
        disabled={[0]}
        minWidth="200px"
      />
      <DeleteModal
        deleting={deletingCategory}
        count={selectID.arr.length}
        subject={selectID.arr.length > 1 ? lang.categories.toLowerCase() : lang.category}
        subjectName={toBeDeletedCategory}
        {...deleteModal}
      />
    </ModuleWrapper>
  );
};

export default CategoryList;
