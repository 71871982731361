import { searchStocktake } from "apis";
import { ModuleWrapper, HeaderA, DataTable, Pill, Text, ButtonLink } from "components";
import { StaffContext, VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useState, useContext, useCallback, useMemo } from "react";
import lang from "translations";
import { stocktakeFilter } from "./filters";
import StocktakeFilter from "./stocktake-filter";
import { formatId, mapObject, prettifyStocktakeStatus } from "services";
import { stocktakeFilterRequest, stocktakeListResponse } from "mappers";
import columns from "./columns";
import { PillType, StocktakeStatus } from "enums";
import { Popover } from "antd";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { VoidStocktakeModal } from "..";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const StocktakeList = () => {
  const { hasAccessOnRoute } = useContext(StaffContext);
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const { staff } = useContext(StaffContext);
  const { profileId } = staff;
  const voidStocktakeModal = useModal();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchStocktakeRequest,
    result: searchStocktakeResult = { data: [], metadata: { total: 0 } },
    mappedData,
    loading,
    error,
  } = useApi({
    api: searchStocktake,
    isArray: true,
    mapper: stocktakeListResponse,
    handleOwnError: true,
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter, isFilterDirty } =
    useFilter(
      filterName.stocktake === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : stocktakeFilter(venueId),
      stocktakeFilter(venueId)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.stocktake !== getState().name)
      setFilterState({
        name: filterName.stocktake,
        filter: {},
      });
    fetchStocktake(requestState);
  });

  const fetchStocktake = useCallback(
    async (requestState) => {
      await searchStocktakeRequest(mapObject(requestState, stocktakeFilterRequest));
    },
    [searchStocktakeRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.stocktake,
        filter: requestState,
      });
      fetchStocktake(requestState);
    },
    [fetchStocktake, setFilterState]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [fetchUpdateStore, clearFilter]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
      });
      await fetchUpdateStore(requestState, filterState);
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const renderStatus = (status) => {
    const pillType =
      {
        [StocktakeStatus.InProgress]: PillType.Blue,
        [StocktakeStatus.ForApproval]: PillType.Orange,
        [StocktakeStatus.Void]: PillType.Red,
        [StocktakeStatus.Completed]: PillType.Green,
      }[status] || PillType.Gray;

    return (
      <Pill type={pillType} size="text-xs">
        {prettifyStocktakeStatus(status)}
      </Pill>
    );
  };

  const renderActionOptions = useCallback(
    (status, stocktakeId) => {
      const actionOptions = [];
      if (status === StocktakeStatus.InProgress || status === StocktakeStatus.ForApproval) {
        actionOptions.push({
          text: lang.view,
          onClick: () => {
            history.push(Path.STOCKTAKE_ID(stocktakeId));
          },
        });
        if (hasAccessOnRoute(Path.VOID_STOCKTAKE)) {
          actionOptions.push({
            text: lang.voidStocktake,
            className: "text-red-500",
            onClick: () => {
              voidStocktakeModal.show({
                stocktakeId,
                venueId,
                profileId,
              });
            },
          });
        }
      } else {
        actionOptions.push({
          text: lang.view,
          onClick: () => {
            history.push(Path.STOCKTAKE_ID(stocktakeId));
          },
        });
      }

      return (
        <Popover
          overlayClassName="options-popover"
          placement="bottomRight"
          content={actionOptions?.map((option) => {
            return (
              <button
                key={uuidv4()}
                className={classnames(
                  `border-none outline-none w-full text-left`,
                  option?.className
                )}
                onClick={() => {
                  option.onClick();
                }}
              >
                {option.text}
              </button>
            );
          })}
          trigger="click"
          arrow={false}
        >
          <div className="text-right">
            <i
              className={classnames(`icon-options align-middle`)}
              style={{
                fontSize: 3,
              }}
            />
          </div>
        </Popover>
      );
    },
    // eslint-disable-next-line
    [history]
  );

  const prepareStocktakes = useCallback(() => {
    return mappedData.map((stocktake) => {
      const { stocktakeId, locationName, status, dateModified, timeModified, amount } = stocktake;
      return {
        stocktakeId: (
          <ButtonLink
            className="text-sm text-black font-semibold text-left"
            // onClick={() => history.push(Path.STOCKTAKE_ID(stocktakeId))}
            path={Path.STOCKTAKE_ID(stocktakeId)}
          >
            <Text className="font-semibold">{`ST-${formatId(stocktakeId)}`}</Text>
          </ButtonLink>
        ),
        dateModified: (
          <div>
            <Text>{dateModified}</Text>
            <Text color="text-gray">{timeModified}</Text>
          </div>
        ),
        location: locationName,
        status: renderStatus(status),
        amount: amount,
        action: renderActionOptions(status, stocktakeId),
      };
    });
  }, [mappedData, renderActionOptions]);

  const stocktakes = useMemo(() => {
    return prepareStocktakes();
  }, [prepareStocktakes]);

  return (
    <ModuleWrapper
      header={
        <HeaderA
          title={lang.stocktake}
          description={lang.trackAndManage}
          button={
            hasAccessOnRoute(Path.CREATE_STOCKTAKE)
              ? {
                  path: Path.CREATE_STOCKTAKE,
                  text: lang.createNewStocktake,
                }
              : null
          }
          className="mb-md"
        />
      }
    >
      <StocktakeFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        onApply={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        columns={columns}
        loading={loading}
        data={stocktakes}
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
        error={error}
        fetchList={fetchUpdateStore}
        total={searchStocktakeResult.metadata.total}
      />
      <VoidStocktakeModal
        {...voidStocktakeModal}
        refreshList={() => fetchUpdateStore(requestState)}
      />
    </ModuleWrapper>
  );
};

export default StocktakeList;
