import React from "react";
import { Path } from "paths";
import {
  GuestList,
  GuestProfileList,
  CreateGuestProfile,
  EditGuestProfile,
  GuestDetails,
  GuestDetailsProfile,
} from "modules";
import { RouteRender } from "components/commons";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const GuestPage = () => {
  useMount(() => {
    setPageTitle("guests");
  });
  
  return (
    <RouteRender
      defaultRedirect={Path.GUESTS}
      renders={{
        [Path.GUEST_PROFILE_CREATE]: {
          exact: true,
          component: CreateGuestProfile,
        },
        [Path.GUEST_PROFILE_EDIT]: {
          exact: true,
          component: EditGuestProfile,
        },
        [Path.GUEST_PROFILE]: {
          exact: true,
          component: GuestProfileList,
        },
        [Path.GUEST_DETAILS]: {
          exact: true,
          component: GuestDetails,
        },
        [Path.GUEST_DETAILS_PROFILE]: {
          exact: true,
          component: GuestDetailsProfile,
        },
        [Path.GUESTS]: {
          exact: true,
          component: GuestList,
        },
      }}
    />
  );
};

export default GuestPage;
