import { STACK_BAR_ACCESS_COLOR, STACK_BAR_GUEST_COLOR } from "contanst/graph-color";
import { TimeUnit, ViewReportAccessControl } from "enums";
import * as moment from "moment";
import lang from "translations";

// prepareSeries = (data, dateTimeType) => {
//   const sort = data.sort((a, b) => {
//     const first = Object.keys(a)[0];
//     const second = Object.keys(b)[0];
//     const format = this.format(dateTimeType);
//     return moment(first, format).toDate() > moment(second, format).toDate()
//       ? 1
//       : moment(first, format).toDate() < moment(second, format).toDate()
//       ? -1
//       : 0;
//   });
//   const d = sort.map((plot) => {
//     const date = Object.keys(plot)[0];
//     const value = plot[date];
//     return {
//       name: this.formatLineChartDateDisplay(date, dateTimeType),
//       value,
//     };
//   });

//   return d;
// };

export const prepareChart = (series) => {
  if (series.length) {
    return {
      labels: series.map((s) => {
        return s.name;
      }),
      data: series.map((s) => {
        return s.value;
      }),
      items: series.map((s) => {
        return s.items;
      }),
    };
  }
  return {
    labels: [],
    data: [],
  };
};

export const getDateTimeFormatByTimeUnit = (type) => {
  const dateType = {
    [TimeUnit.Hour]: "ha",
    [TimeUnit.Day]: "MMM D",
    [TimeUnit.Month]: "MMM YYYY",
  };
  return dateType[type];
};

export const getApiDateTimeFormatByTimeUnit = (type) => {
  const dateType = {
    [TimeUnit.Hour]: "YYYY-MM-DD HH:mm:ss",
    [TimeUnit.Day]: "YYYY-MM-DD",
    [TimeUnit.Month]: "YYYY-MM",
  };
  return dateType[type];
};

export const denormalizePlotDateTimeFromApiResponse = (plot, timeUnit) => {
  if (timeUnit === TimeUnit.WeekS || timeUnit === TimeUnit.Week) {
    return denormalizeCalcWeekDataGraph(plot, timeUnit === TimeUnit.WeekS);
  } else if (timeUnit === TimeUnit.Quarter) {
    return denormalizeCalcQuarterDataGraph(plot);
  }
  return plot.map((p) => {
    const name = Object.keys(p)[0];
    return {
      name: moment(name, getApiDateTimeFormatByTimeUnit(timeUnit)).format(
        getDateTimeFormatByTimeUnit(timeUnit)
      ),
      value: p[name],
    };
  });
};

/**
 * @param {[*]} plot : plot value
 * @param {boolean} s : start with Sunday
 */
const denormalizeCalcWeekDataGraph = (plot, s = false) => {
  let ret = [];
  let sum = 0;
  let title = "";
  const wStart = s ? 0 : 1;
  const wEnd = s ? 6 : 0;

  plot.forEach((p, idx) => {
    Object.entries(p).forEach(([k, v]) => {
      const m = moment(k, getApiDateTimeFormatByTimeUnit(TimeUnit.Day));
      const d = m.day();

      if (d === wStart || !idx) {
        title = m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day));
        sum = 0;
      }
      sum += v;
      if (d === wEnd || idx === plot.length - 1) {
        ret.push({
          name: title.concat(" - ").concat(m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day))),
          value: sum,
        });
      }
    });
  });
  return ret;
};

const denormalizeCalcQuarterDataGraph = (plot) => {
  let ret = [];
  let temp = {
    name: "",
    value: 0,
  };

  plot.forEach((p, idx) => {
    Object.entries(p).forEach(([k, v]) => {
      const m = moment(k, getApiDateTimeFormatByTimeUnit(TimeUnit.Month));
      const q = m.quarter();
      const curQuarter = `${lang.quarter} ${q} - ${m.year()}`;

      if (temp.name !== curQuarter) {
        if (temp.name) ret.push(temp);
        temp = {
          name: curQuarter,
          value: 0,
        };
      }
      temp.value += v;
      if (idx === plot.length - 1) {
        ret.push(temp);
      }
    });
  });
  return ret;
};

export const calcWeekDataGraph = (plot, s = false) => {
  let ret = [];
  let sum = 0;
  let title = "";
  let arr = [];
  const wStart = s ? 0 : 1;
  const wEnd = s ? 6 : 0;

  plot.forEach(({ timestamp, value, items }, idx) => {
    const m = moment(timestamp, getApiDateTimeFormatByTimeUnit(TimeUnit.Day));
    const d = m.day();

    if (d === wStart || !idx) {
      title = m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day));
      sum = 0;
      arr = [];
    }
    sum += value;
    arr.push(...items);
    if (d === wEnd || idx === plot.length - 1) {
      ret.push({
        name: title.concat(" - ").concat(m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day))),
        value: sum,
        items: arr,
      });
    }
  });
  return ret;
};

export const calcQuarterDataGraph = (plot, s = false) => {
  let ret = [];
  let temp = {
    name: "",
    value: 0,
    items: [],
  };

  plot.forEach(({ timestamp, value, items }, idx) => {
    const m = moment(timestamp, getApiDateTimeFormatByTimeUnit(TimeUnit.Month));
    const q = m.quarter();
    const curQuarter = `${lang.quarter} ${q}  - ${m.year()}`;

    if (temp.name !== curQuarter) {
      if (temp.name) ret.push(temp);
      temp = {
        name: curQuarter,
        value: 0,
        items: [],
      };
    }
    temp.value += value;
    temp.items.push(...items);
    if (idx === plot.length - 1) {
      ret.push(temp);
    }
  });

  return ret;
};

export const toStackBarChartAccessPointData = (
  { viewBy, entities = [], data = [] },
  intervalType
) => {
  const obj = {};
  const labels = [];

  entities.forEach(({ id, name }, i) => {
    obj[id] = {
      id,
      name,
      data: [],
      backgroundColor: (viewBy = ViewReportAccessControl.Access
        ? STACK_BAR_ACCESS_COLOR[i % STACK_BAR_ACCESS_COLOR.length]
        : STACK_BAR_GUEST_COLOR[i % STACK_BAR_GUEST_COLOR.length]),
    };
  });

  data.forEach(({ dateTime, details = [] }) => {
    const label = moment(dateTime).format(getDateTimeFormatByTimeUnit(intervalType));
    labels.push(label);
    Object.keys(obj).forEach((k) => {
      let temp = details.find((i) => String(i.entityId) === k);
      if (temp) {
        obj[k].data.push(temp?.total || 0);
      } else obj[k].data.push(0);
    });
  });

  const datasets = Object.values(obj).map(({ name, data, backgroundColor }) => {
    return {
      label: name,
      data,
      backgroundColor,
    };
  });

  if (intervalType === TimeUnit.WeekS || intervalType === TimeUnit.Week) {
    let newDatasets = [...datasets];
    let newLabels = [];

    datasets.forEach(({ data }, idx) => {
      newDatasets[idx].data = timeData2ChartWeek(labels, data, intervalType === TimeUnit.WeekS);
    });

    const isWeeks = intervalType === TimeUnit.WeekS;

    let title = "";
    labels.forEach((label, idx) => {
      const m = moment(label, getApiDateTimeFormatByTimeUnit(TimeUnit.Day));
      const d = m.day();
      const wStart = isWeeks ? 0 : 1;
      const wEnd = isWeeks ? 6 : 0;
      if (d === wStart || !idx) {
        title = m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day));
      }
      if (d === wEnd || idx === labels.length - 1) {
        title = title.concat(" - ").concat(m.format(getDateTimeFormatByTimeUnit(TimeUnit.Day)));
        newLabels.push(title);
      }
    });
    return { labels: newLabels, datasets: newDatasets };
  } else if (intervalType === TimeUnit.Quarter) {
    let newDatasets = [...datasets];
    let newLabels = [];
    datasets.forEach(({ data }, idx) => {
      newDatasets[idx].data = timeData2ChartQuarter(labels, data, intervalType === TimeUnit.WeekS);
    });

    let title = "";
    labels.forEach((label, idx) => {
      const m = moment(label, getApiDateTimeFormatByTimeUnit(TimeUnit.Month));
      const q = m.quarter();
      const curQuarter = `${lang.quarter} ${q}  - ${m.year()}`;

      if (title !== curQuarter) {
        if (title) newLabels.push(title);
        title = curQuarter;
      }
      if (idx === labels.length - 1) {
        newLabels.push(title);
      }
    });
    return { labels: newLabels, datasets: newDatasets };
  }
  return { labels, datasets };
};

const timeData2ChartWeek = (times, data, s = false) => {
  let ret = [];
  let sum = 0;
  const wStart = s ? 0 : 1;
  const wEnd = s ? 6 : 0;

  times.forEach((t, idx) => {
    const m = moment(t, getApiDateTimeFormatByTimeUnit(TimeUnit.Day));
    const d = m.day();

    if (d === wStart || !idx) {
      sum = 0;
    }
    sum += data[idx];
    if (d === wEnd || idx === times.length - 1) {
      ret.push(sum);
    }
  });

  return ret;
};

const timeData2ChartQuarter = (times, data) => {
  let ret = [];
  let sum = 0;
  let quarter = "";

  times.forEach((t, idx) => {
    const m = moment(t, getApiDateTimeFormatByTimeUnit(TimeUnit.Month));
    const q = m.quarter();
    const curQuarter = `${lang.quarter} ${q}  - ${m.year()}`;

    if (quarter !== curQuarter) {
      if (quarter) ret.push(sum);
      sum = 0;
      quarter = curQuarter;
    }
    sum += data[idx];
    if (idx === times.length - 1) {
      ret.push(sum);
    }
  });
  return ret;
};
