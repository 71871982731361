import {
    ActionButton,
    Modal,
} from "components/commons";
import { StyleType } from "enums";
import React from "react";
const MewsTroubleshootingModal = ({ ...props }) => {

    return (
        <Modal
            title="MEWS Troubleshooting Steps"
            {...props}
            actionContent={
                <ActionButton
                    className="px-md pb-md"
                    secondary={null}
                    primary={
                        {
                            type: StyleType.Secondary,
                            text: "Okay",
                            onClick: () => {
                                props.close();
                            },
                        }
                    }
                />
            }
        >
            <p className="text-sm mb-xs font-bold">Step #1: Configure Your Outlets and Services</p>
            <p className="text-sm mb-sm">
                Make sure that your MEWS Accounting integration status is not "Partially Connected". If you have this status in your integrations page, this means that you have not yet configured your Outlets and Services.
                Click the "Finish Integration" button to complete the integration process.
            </p>
            <p className="text-sm mb-xs font-bold mt-md">Step #2: Edit Accounting Editable History Window</p>
            <p className="text-sm mb-sm">
                Since the app works on a hybrid manner (offline and online), we need to make sure that your transactions can still be synced to MEWS
                even if there is a long network disruption. Therefore, we recommend you to set your "Accounting editable history window" to 7 days from
                your MEWS settings. <a href="https://help.mews.com/s/article/set-up-your-editable-history-windows?language=en_US#link2a" target="_blank" rel="noreferrer" className="text-pelorous text-sm">This article</a> shows you
                the steps on how to do this.
            </p>
            <p className="text-sm mb-xs font-bold mt-md">Step #3: Check Your Currency Settings</p>
            <p className="text-sm mb-sm">
                Make sure that the currency set in your Venue Dashboard <span className="text-gray text-sm">(you can find it by navigating through Settings > General)</span> is the same currency as to what is configured in your MEWS account <span className="text-gray text-sm">(Settings > Property > Globalization > Default Currency)</span>.
            </p>
            <p className="text-sm mb-xs font-bold mt-md">Step #4: Make Sure You Run Your End-of-Day</p>
            <p className="text-sm mb-sm">
                Transactions that are not linked to a Guest from MEWS will sync after you have done an End-of-Day Procedure. We recommend that you run your End of Day at-least once a day. <a href="https://help.pouchnation.com/support/solutions/articles/67000696705-end-of-day-reports" target="_blank" rel="noreferrer" className="text-sm text-pelorous">You can find more information about this here.</a>
            </p>

        </Modal>
    );
};

export default MewsTroubleshootingModal;
