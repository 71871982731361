import { Button, Checkbox, Field, Input, Modal, Toast } from "components";
import React, { useContext, useEffect, useMemo } from "react";
import lang from "translations";
import styles from "./access.module.scss";
import classNames from "classnames";
import { Field as FieldType, StyleType } from "enums";
import { useApi, useForm, useMount, useSelectItems } from "hooks";
import { searchGuestProfile } from "apis/guest-profile.api";
import { VenueContext } from "contexts";
import Validation from "services/validation.service";
import { createAccessPoint, updateAccessPoint } from "apis/accessPoint";

const FormModal = (props) => {
  const { isEdit, data, onClose, active, onRefresh } = props;
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const { selectedCount, selected, setSelected, clearSelected, replaceSelected } = useSelectItems({
    key: "value",
  });

  const requestGuestProfiles = useApi({
    api: searchGuestProfile,
    isArray: true,
    params: {
      venueId,
      orders: { "gp.guestProfileDefault": "desc", "gp.createdDate": "desc" },
    },
    mapper: {
      value: { key: "guestProfileId" },
      text: { key: "guestProfileName" },
      deleted: { key: "deleted" },
    },
  });

  const formState = useMemo(() => {
    return {
      name: {
        name: "name",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required()],
        maxLength: 255,
        label: lang.accessPointName,
      },
    };
  }, []);

  const { fields, submitForm, getFormValues, clearForm, modifyField, applyFieldErrors } = useForm({
    initialState: formState,
  });

  const onSubmit = async () => {
    const { name } = getFormValues();
    const params = { venueId, accessPointName: name };
    params.guestProfileIds = Object.keys(selected).map((i) => Number(i));

    try {
      if (isEdit) {
        await updateAccessPoint(data.id, params);
      } else {
        await createAccessPoint(params);
      }
      onClose();
      onRefresh();
      Toast({
        content: isEdit ? lang.changesSavedSuccessfully : lang.accessPointCreateSuccess,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      if (error?.metadata?.code === "I_ACCESS_POINT_NAME_ALREADY_EXIST") {
        applyFieldErrors({ name: lang.accessPointExisted });
      } else {
        Toast({
          content: lang.somethingWentWrongPlsTryAgain,
          error: true,
          icon: "exclamation-fill",
        }).open();
      }
    }
  };

  useEffect(() => {
    if (isEdit && active) {
      const { name, guestProfiles = [] } = data;
      modifyField("name", { value: name });

      const selectedIds = guestProfiles.map((i) => i.guestProfileId);
      const obj = {};
      requestGuestProfiles.mappedData
        .filter((item) => selectedIds?.includes(item["value"]))
        .forEach((item, index) => {
          obj[item["value"]] = {
            ...item,
            index,
            checked: true,
          };
        });
      replaceSelected(obj);
    }
    return () => {
      if (active) {
        clearForm();
        clearSelected();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, requestGuestProfiles.mappedData]);

  useMount(() => {
    requestGuestProfiles.request();
  });

  return (
    <Modal
      customTitle={
        <div className="px-5 text-2xl text-pelorous-darker font-semibold">
          {isEdit ? lang.editAccessPoint : lang.createAccessPoint}
        </div>
      }
      {...props}
      width={1226}
    >
      <div className="px-5 pb-16">
        <div className={classNames(styles.row)}>
          <div className={classNames(styles.label, "text-pelorous-darker text-md")}>
            {lang.accessPoint}
          </div>
          <div className="bg-white p-5 flex-1">
            <Field {...fields.name}>
              <Input {...fields.name} required onChange={modifyField} />
            </Field>
          </div>
        </div>
        <div className={classNames(styles.row)}>
          <div className={classNames(styles.label, "text-pelorous-darker text-md")}>
            {lang.guestProfile}
          </div>
          <div className="bg-white p-5 flex-1">
            {!selectedCount && (
              <div className="text-red text-base mb-4">{lang.atLeast1GuestProfile}</div>
            )}
            <div className={classNames(styles["guest-container"], "flex flex-col gap-4")}>
              {requestGuestProfiles.mappedData.map((item) => (
                <div>
                  <Checkbox
                    value={selected[item.value]}
                    onChange={() => setSelected(item.value)}
                    className="mr-3"
                  />
                  {item.text}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button type={StyleType.Secondary} onClick={onClose}>
            {lang.discard}
          </Button>
          <Button onClick={() => submitForm(onSubmit)} disabled={!selectedCount}>
            {lang.save}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
