import React from "react";
import { Bar } from "react-chartjs-2";

/** ex: 
    <StackBarGraph
      labels={["1st total: ???", "2nd", "3rd", "4th"]}
      datasets={[
        { label: "data 1", data: [100, 100, 100, 100], backgroundColor: "red" },
        { label: "data 2", data: [50, 50, 50, 50], backgroundColor: "blue" },
        { label: "data 3", data: [70, 70, 70, 70], backgroundColor: "green" },
      ]}
    />
 */
const StackBarGraph = ({ title = "", height = 500, labels = [], datasets = [], ...rest }) => {
  return (
    <div className="f-full">
      <Bar
        height={height}
        data={{
          labels,
          datasets,
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title,
            },
            tooltip: {
              bodySpacing: 3,
              bodyFontSize: 15,
              titleFontSize: 12,
              xPadding: 12,
              yPadding: 12,
              callbacks: {
                title: (items) => {
                  let total = 0;
                  items.forEach(({ dataIndex, dataset }) => {
                    total += dataset.data[dataIndex];
                  });
                  return `Total: ${total}`;
                },
              },
            },
          },
          scales: {
            x: { stacked: true },
            y: { stacked: true, ticks: { beginAtZero: true } },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default StackBarGraph;
