import { dateTimeRequest, filterRequest, selectToObjectRequest, getCurrentTimeWithTimezone } from "./common.mapper";
import { timeAgo, formatName } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

export const tipResponseMapper = {
  venueId: {
    key: "venueId",
  },
  allowTip: {
    key: "allowTip",
  },
  allowGuestsCustomTip: {
    key: "allowGuestsCustomTip",
  },
  tips: {
    key: "tips",
  },
};

export const tipReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  requestedAt: getCurrentTimeWithTimezone(),
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  orders: {
    transform: ({ src }) => {
      console.log(src)
      if (src.sort.key === "transactionDate") {
        return { [`t.device_updated_date`]: src.sort.value };
      }
      if (src.sort.key === "locationName") {
        return { [`l.location_name`]: src.sort.value };
      }
      return { [`${src.sort.key}`]: src.sort.value };
    },
  },
};

export const tipReportResponseMapper = {
  transactionId: {
    key: "transactionId",
  },
  guestId: {
    key: "guestId",
  },
  name: {
    transform: ({ src }) => {
      return formatName(src.userFirstName, src.userLastName);
    },
  },
  userTagUid: { key: "userTagUid" },
  transactionDate: {
    transform: ({ src }) => {
      console.log(src)
      return timeAgo(src.deviceUpdatedDate, true);
    },
  },
  locationName: {
    key: "locationName",
  },
  type: {
    key: "type",
  },
  staffName: {
    transform: ({ src }) => {
      return src.staffFirstName + " " + src.staffLastName;
    }
  },
  tipAmount: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.tipAmount);
    }
  },
  paymentMethod: {
    key: "paymentMethod",
  },
};