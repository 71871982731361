import { Panel, Text, Title, Icon, ButtonLink, Skeleton } from "components";
import React from "react";
import lang from "translations";
import classnames from "classnames";
import { formatNumberToMoneyWithCurrencySymbol } from "services";

const Row = ({ label, children, custom, style = {}, isValueBreakdown = false }) => {
  const { fontSize, danger } = style;
  return (
    <div className={classnames("flex justify-between align-center items-center mb-xs")}>
      <Text className={isValueBreakdown ? "text-gray" : "text-black"} size="text-sm">
        {label}
      </Text>
      <div>
        {custom ? (
          children
        ) : (
          <Text
            className={classnames({
              "text-washed-dark": isValueBreakdown,
              "text-black-light": !isValueBreakdown,
            })}
            fontWeight={!isValueBreakdown ? "font-bold" : ""}
            size={fontSize}
            danger={danger}
          >
            {children}
          </Text>
        )}
      </div>
    </div>
  );
};

const EodSummary = ({ data = {}, loading }) => {
  const {
    totalSales,
    totalFreeCreditsUsed,
    totalCreditsUsed,
    totalPostpaidUsed,
    totalSaleCash,
    totalSaleCard,
    totalSaleOthers,
    totalTax,
    totalTopupCash,
    totalShifts,
    totalShiftsIgnore,
    totalTopup,
    totalTopupCredit,
    totalTopupOther,
    totalRefunded,
    totalTransactions,
    totalGuestsCheckedIn,
    totalGuestsCheckedOut,
    totalVouchersIssued,
    totalVouchersRedeemed,
    totalDiscount,
    totalFreeCreditsIssued,
    totalFreeCreditsRemoved,
    totalIssuedTag,
    totalReturnedTag,
    totalClearedTag,
    totalVouchersRemoved,
    totalVouchersRedeemedRetailValue,
    totalVouchersRedeemedSupplyValue,
    expectedCloseBalance,
    openBalance,
    hasTransactions,
    hasDiscrepancy,
    discrepancy,
    closeBalance,
    totalVoidedItems,
    totalVoidValue,
    totalVoidValuePouchPay,
    totalVoidValueCash,
    totalVoidValueCard,
    totalVoidValueOthers,
    additionalCharges,
    totalCreditsKept,
    totalCreditsFromVenues,
  } = data?.summary || {};

  const totalShiftsActive = totalShifts - totalShiftsIgnore;

  const divider = (
    <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
  );

  if (loading) {
    return (
      <Panel>
        <Skeleton />
      </Panel>
    );
  }

  return (
    <Panel>
      <div>
        <Title className="mb-md flex items-center" fontWeight="font-bold">
          <Icon name="calendar" className="text-pelorous mr-xs" fontSize="25px" />
          {data?.date || "-"}
        </Title>

        <>
          <p className="font-bold mb-sm">
            <Title>{lang.salesSummary}</Title>
          </p>

          <Row label={lang.totalProductSales} style={{ fontSize: "font-md" }}>
            {totalSales}
          </Row>
          {data?.salesBreakdown ? (
            <div className="pl-lg">
              {data?.salesBreakdown.map((sb) => {
                return (
                  <Row label={sb.name} isValueBreakdown={true}>
                    {formatNumberToMoneyWithCurrencySymbol(sb.amount)}
                  </Row>
                );
              })}
            </div>
          ) : (
            <div className="pl-lg">
              <Row label={lang.credits} isValueBreakdown={true}>
                {totalCreditsUsed}
              </Row>
              <Row label={lang.freeCredits} isValueBreakdown={true}>
                {totalFreeCreditsUsed}
              </Row>
              <Row label={lang.postPaidCreditsUsed} isValueBreakdown={true}>
                {totalPostpaidUsed}
              </Row>
              <Row label={lang.cash} isValueBreakdown={true}>
                {totalSaleCash}
              </Row>
              <Row label={lang.creditDebit} isValueBreakdown={true}>
                {totalSaleCard}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {totalSaleOthers}
              </Row>
            </div>
          )}

          {divider}

          <p className="font-bold mt-md mb-sm">{lang.taxes}</p>
          <Row label={lang.totalTax} style={{ fontSize: "font-md" }}>
            {totalTax}
          </Row>

          {divider}

          <p className="font-bold mt-md mb-sm">{lang.tipsAndCharges}</p>

          <Row label={lang.tips} style={{ fontSize: "font-md" }}>
            {formatNumberToMoneyWithCurrencySymbol(data.totalTips)}
          </Row>

          <Row label={lang.additionalCharges} style={{ fontSize: "font-md" }}></Row>

          <div className="pl-lg">
            {additionalCharges?.map((item) => {
              return (
                <Row label={item.name} isValueBreakdown={true}>
                  {item.value
                    ? formatNumberToMoneyWithCurrencySymbol(item.value)
                    : formatNumberToMoneyWithCurrencySymbol(0)}
                </Row>
              );
            })}
          </div>

          {divider}

          <p className="font-bold mt-md mb-sm">{lang.credits}</p>

          <Row label={lang.totalTopupCredits} style={{ fontSize: "font-md" }}>
            {totalTopup}
          </Row>

          {data?.topUpBreakdown ? (
            <div className="pl-lg">
              {data?.topUpBreakdown.map((sb) => {
                return (
                  <Row label={sb.name} isValueBreakdown={true}>
                    {formatNumberToMoneyWithCurrencySymbol(sb.amount)}
                  </Row>
                );
              })}
            </div>
          ) : (
            <div className="pl-lg">
              <Row label={lang.cash} isValueBreakdown={true}>
                {totalTopupCash}
              </Row>
              <Row label={lang.debitCredit} isValueBreakdown={true}>
                {totalTopupCredit}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {totalTopupOther}
              </Row>
            </div>
          )}

          <Row label={lang.totalRefundedCredits} style={{ fontSize: "font-md" }}>
            {totalRefunded}
          </Row>

          <Row label={lang.totalCreditsKeptByGuests} style={{ fontSize: "font-md" }}>
            {totalCreditsKept}
          </Row>

          <Row label={lang.totalCreditsReceivedFromVenues} style={{ fontSize: "font-md" }}>
            {totalCreditsFromVenues}
          </Row>

          {divider}

          <p className="font-bold mt-md mb-sm">{lang.shiftsAndTransactions}</p>
          <Row label={lang.shifts} custom={true}>
            {totalShiftsActive}
          </Row>
          <Row label={lang.transactions} custom={true}>
            {hasTransactions ? (
              <ButtonLink
                className="md:text-right"
                prefix={false}
                suffix
                flex={false}
                newTabPath={data.redirectUrl}
              >
                {totalTransactions}
                <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
              </ButtonLink>
            ) : (
              totalTransactions
            )}
          </Row>
          <Row label={lang.voidedItems}>{totalVoidedItems}</Row>
          <Row label={lang.voidedValue}>{totalVoidValue}</Row>
          {data?.voidSalesBreakdown ? (
            <div className="pl-lg">
              {data?.voidSalesBreakdown.map((sb) => {
                return (
                  <Row label={sb.name} isValueBreakdown={true}>
                    {formatNumberToMoneyWithCurrencySymbol(sb.amount)}
                  </Row>
                );
              })}
            </div>
          ) : (
            <div className="pl-md">
              <Row label={lang.pouchPay} isValueBreakdown={true}>
                {totalVoidValuePouchPay}
              </Row>
              <Row label={lang.cash} isValueBreakdown={true}>
                {totalVoidValueCash}
              </Row>
              <Row label={lang.creditDebit} isValueBreakdown={true}>
                {totalVoidValueCard}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {totalVoidValueOthers}
              </Row>
            </div>
          )}
          <Row label={lang.discounts}>{totalDiscount}</Row>
          <Row label={lang.freeCreditsIssued}>{totalFreeCreditsIssued}</Row>
          <Row label={lang.freeCreditsRemoved}>{totalFreeCreditsRemoved}</Row>
          <Row label={lang.vouchersIssued}>{totalVouchersIssued}</Row>
          <Row label={lang.vouchersRemoved}>{totalVouchersRemoved}</Row>
          <Row label={lang.vouchersUsage}>{totalVouchersRedeemed}</Row>
          <div className="pl-md">
            <Row label={lang.voucherSupplyValue} isValueBreakdown={true}>
              {totalVouchersRedeemedSupplyValue}
            </Row>
            <Row label={lang.voucherRetailValue} isValueBreakdown={true}>
              {totalVouchersRedeemedRetailValue}
            </Row>
          </div>

          {divider}

          <p className="font-bold mt-md mb-sm">{lang.guests}</p>
          <Row label={lang.checkIns}>{totalGuestsCheckedIn}</Row>
          <Row label={lang.checkOuts}>{totalGuestsCheckedOut}</Row>

          {divider}
          <p className="font-bold mt-md mb-sm">{lang.tags}</p>
          <Row label={lang.tagsIssued}>{totalIssuedTag}</Row>
          <Row label={lang.returnedTags}>{totalReturnedTag}</Row>
          <Row label={lang.clearedTags}>{totalClearedTag}</Row>

          {divider}
          <p className="font-bold mt-md mb-sm">{lang.daySummary}</p>
          <Row label={lang.reportingDay}>{data.reportingDay}</Row>
          <Row label={lang.dayStart}>{data.dayStart}</Row>
          {!data.isOpen && <Row label={lang.dayEnd}>{data.dayEnd}</Row>}
          {!data.isOpen && <Row label={lang.endedBy}>{data.endedBy}</Row>}

          <Row label={lang.totalOpeningBalance}>{openBalance}</Row>
          <Row label={lang.totalExpectedEndingBalance}>{expectedCloseBalance}</Row>
          <Row label={lang.actualClosingBalance}>{closeBalance}</Row>

          <Row
            label={lang.discrepancy}
            style={{
              danger: hasDiscrepancy,
            }}
          >
            {discrepancy}
          </Row>

          {data?.notes && (
            <div className="mt-lg">
              <div className="p-md border border-gray-disabled rounded">
                <Text className="text-gray mb-md" uppercase strong size="text-xs">
                  {lang.notes}
                </Text>
                <Text>{data?.notes}</Text>
              </div>
            </div>
          )}
        </>
      </div>
    </Panel>
  );
};

export default EodSummary;
