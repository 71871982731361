import {
  Modal,
  Title,
  Text,
  ActionButton,
  TextArea,
  Icon,
  Button,
  Toast,
  Skeleton,
  Pill,
  ButtonLink,
  Tooltip,
} from "components";
import lang from "translations";
import "./run-end-of-day-modal.scss";
import React, { useEffect, useMemo, useCallback, useState, useContext } from "react";
import EndOfDayChecklistModal from "../end-of-day-checklist-modal/end-of-day-checklist-modal";
import { useApi, useModal, useForm, useRouter } from "hooks";
import { deviceColumns, shiftColumns } from "../end-of-day-checklist-modal/columns";
import { getEndOfDayReport, runEndOfDay, updateIncludedShift } from "apis";
import { endOfDayResponse } from "mappers";
import { formatDate, formatNumberToMoneyWithCurrencySymbol, pluralize } from "services";
import { DateTime, StyleType, Field as FieldType, PillType } from "enums";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";
import { VenueContext } from "contexts";
import { updateAllIgnoredShifts } from "apis/end-of-day.api";
import { useFlags } from "launchdarkly-react-client-sdk";

const RunEndOfDayModal = ({ id, active, close, title, dateRange, refreshList, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { eodUnsyncedTransactionWarningFlag } = useFlags();
  const { history } = useRouter();
  const [eodId, setEodId] = useState(id);
  const endOfDayChecklistModal = useModal();
  const { request: updateIncludedShiftRequest, loading: updatingShift } = useApi({
    api: updateIncludedShift,
  });

  const {
    request: getEndOfDayRequest,
    loading,
    mappedData,
    error,
  } = useApi({
    api: getEndOfDayReport,
    mapper: endOfDayResponse,
  });

  const { request: endDayRequest, loading: endingDayReport } = useApi({
    api: runEndOfDay,
  });

  useEffect(() => {
    if (id) {
      fetchEndOfDayReports();
      setEodId(id);
      mixpanel.track(TrackEvent.VisitedPage, {
        Page: lang.runEndOfDay,
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const fetchEndOfDayReports = useCallback(async () => {
    await getEndOfDayRequest({ eodId: id });
  }, [getEndOfDayRequest, id]);

  const { request: requestIgnoreAllShifts, loading: loadingIgnoredShifts } = useApi({
    api: updateAllIgnoredShifts,
  });

  const {
    totalSales,
    totalTransactions,
    totalLocations,
    totalShifts,
    shifts = [],
    devices = [],
    notes,
    eodReportId,
    totalDevices,
    totalReturnCredits,
    totalTopUp,
    totalIgnoredShifts,
    totalOpenAndNotIgnoredShifts,
    totalOpenShifts,
    totalCreditsKept,
    totalCreditsFromVenues,
    allTransactionSynced,
  } = mappedData || {};

  const initialState = useMemo(() => {
    return {
      notes: {
        name: "notes",
        value: notes,
        type: FieldType.INPUT,
      },
    };
  }, [notes]);

  const { fields, modifyField, submitForm, getFormValues } = useForm({
    initialState,
  });

  const renderNumberOfShifts = useMemo(() => {
    let totalShift = `${totalShifts} ${pluralize(totalShifts, lang.shift, lang.shifts)}`;
    let ignoredShifts = `${totalIgnoredShifts} Ignored ${pluralize(
      totalIgnoredShifts,
      lang.shift,
      lang.shifts
    )}`;
    let openAndNotIgnoredShifts = `${totalOpenAndNotIgnoredShifts} Open ${pluralize(
      totalOpenAndNotIgnoredShifts,
      lang.shift,
      lang.shifts
    )}`;

    return `${totalShift} ${
      totalIgnoredShifts >= 1 || totalOpenAndNotIgnoredShifts >= 1
        ? `(${totalIgnoredShifts >= 1 ? ignoredShifts : ""}${
            totalOpenAndNotIgnoredShifts >= 1 && totalIgnoredShifts >= 1
              ? ` and ${openAndNotIgnoredShifts}`
              : totalOpenAndNotIgnoredShifts >= 1
              ? `${openAndNotIgnoredShifts}`
              : ""
          })`
        : ""
    } 
    
    `;
  }, [totalShifts, totalIgnoredShifts, totalOpenAndNotIgnoredShifts]);

  const renderShiftsDateRange = useMemo(() => {
    const unignoredShifts = shifts.filter((shift) => !shift.ignoredInEod) || [];
    const closedUnignoredShifts =
      shifts.filter((shift) => shift.closeDate && !shift.ignoredInEod) || [];

    return `${formatDate(unignoredShifts[unignoredShifts?.length - 1]?.openDate, DateTime.C)}
    ${
      unignoredShifts[unignoredShifts?.length - 1]?.openDate && closedUnignoredShifts[0]
        ? " - "
        : ""
    }
    ${closedUnignoredShifts[0] ? `${formatDate(closedUnignoredShifts[0], DateTime.C)}` : ""}`;
  }, [shifts]);

  const prepareDeviceList = useCallback(() => {
    return devices.map((device) => {
      const { deviceName, deviceSerialNumber, deviceLogUuid, transactionLastSynced } = device;
      let past = new Date(transactionLastSynced).getTime();
      let twoMins = 1000 * 60 * 2;
      let isPast = new Date().getTime() - past < twoMins ? false : true;
      return {
        name: (
          <div className="py-sm">
            <Text>{deviceName}</Text>
            <Text tagUid fontMono color="text-gray-light">
              {deviceSerialNumber || deviceLogUuid || "-"}
            </Text>
          </div>
        ),
        syncDate: isPast ? (
          <Text color="text-gray">{`Last synced ${formatDate(
            transactionLastSynced,
            DateTime.C
          )} at ${
            transactionLastSynced ? formatDate(transactionLastSynced, DateTime.B) : "-"
          }`}</Text>
        ) : (
          <div className="flex items-center gap-2 text-green-darker">
            <Icon name="check" paddingless />
            <Text color="text-green-darker mt-xxs">{lang.upToDate}</Text>
          </div>
        ),
      };
    });
  }, [devices]);

  const deviceList = useMemo(() => {
    return prepareDeviceList();
  }, [prepareDeviceList]);

  const prepareShiftList = useCallback(() => {
    return shifts.map((shift) => {
      const {
        location,
        shiftId,
        staffProfile,
        device,
        openDate,
        closeDate,
        ignoredInEod,
        deviceLogUuid,
        transactionSynced,
      } = shift;
      const { deviceName, deviceSerialNumber } = device || {};
      const { fullName } = staffProfile || {};
      const { locationName } = location || {};
      return {
        header: formatDate(openDate, DateTime.C),
        name: (
          <div className={ignoredInEod ? "opacity-50" : null}>
            <Text color="text-gray" className="mt-sm">
              {locationName ?? "-"}
            </Text>
            <div className="flex items-center">
              <ButtonLink
                color="text-pelorous"
                onClick={() => {
                  history.push(Path.REGISTER_REPORT_ID(shiftId));
                }}
              >{`#${shiftId}`}</ButtonLink>
              {eodUnsyncedTransactionWarningFlag ? (
                !transactionSynced ? (
                  <Tooltip
                    title={
                      <Text className="text-center text-white">
                        {lang.thisShiftHasUnsyncedTransactions}
                      </Text>
                    }
                    placement="top"
                    overlayStyle={{ maxWidth: "156px" }}
                  >
                    <div className="p-0 m-0 h-min flex items-start -mt-2">
                      <Icon className="text-red text-md" name="warning" />
                    </div>
                  </Tooltip>
                ) : null
              ) : null}
            </div>
            <Text>{fullName ?? "-"}</Text>
          </div>
        ),
        deviceName: (
          <div className={ignoredInEod ? "opacity-50 self-center" : "self-center"}>
            <Text>{deviceName}</Text>
            <Text tagUid fontMono color="text-gray-light">
              {deviceSerialNumber || deviceLogUuid || "-"}
            </Text>
          </div>
        ),
        dateTimeStarted: (
          <div className={ignoredInEod ? "opacity-50" : null}>
            <Text>{formatDate(openDate, DateTime.A)}</Text>
            <Text>{formatDate(openDate, DateTime.B)}</Text>
          </div>
        ),
        dateTimeEnded: closeDate ? (
          <div className={ignoredInEod ? "opacity-50" : null}>
            <Text>{formatDate(closeDate, DateTime.A)}</Text>
            <Text>{formatDate(closeDate, DateTime.B)}</Text>
          </div>
        ) : (
          <Pill className={ignoredInEod ? "opacity-50" : null} type={PillType.Green}>
            {lang.open}
          </Pill>
        ),
        shiftIgnoreStatus: (
          <Button
            className="disabledButton"
            type={StyleType.Secondary}
            loading={updatingShift || loading}
            disabled={ignoredInEod && !closeDate}
            onClick={async () => {
              const res = await updateIncludedShiftRequest({
                eodId: eodId,
                shiftId: shiftId,
                body: { ignored: !ignoredInEod },
              });
              const { data } = res;
              await getEndOfDayRequest({ eodId: data?.eodReportId });
              refreshList();
            }}
          >
            <Text
              fontWeight="font-semibold"
              color={ignoredInEod ? "text-gray-darker" : "text-blue"}
            >
              {ignoredInEod ? lang.unignore : lang.ignore}
            </Text>
          </Button>
        ),
      };
    });
  }, [
    updateIncludedShiftRequest,
    getEndOfDayRequest,
    refreshList,
    eodId,
    history,
    shifts,
    updatingShift,
    loading,
    eodUnsyncedTransactionWarningFlag,
  ]);

  const isAllShiftsIgnored = useMemo(() => {
    if (totalIgnoredShifts === totalShifts) {
      return true;
    } else {
      return false;
    }
  }, [totalIgnoredShifts, totalShifts]);

  const isAllOpenShiftsIgnored = useMemo(() => {
    if (totalOpenShifts !== totalOpenAndNotIgnoredShifts) {
      return true;
    } else {
      return false;
    }
  }, [totalOpenAndNotIgnoredShifts, totalOpenShifts]);

  const allIgnoredShiftsCB = useCallback(async () => {
    const res = await requestIgnoreAllShifts({
      eodReportId,
      venueId,
      body: {
        ignored: !isAllShiftsIgnored,
        onlyOpenShifts: false,
      },
    });
    const { data } = res;
    await getEndOfDayRequest({ eodId: data?.eodReportId });
    refreshList();
  }, [
    eodReportId,
    venueId,
    getEndOfDayRequest,
    isAllShiftsIgnored,
    refreshList,
    requestIgnoreAllShifts,
  ]);

  const allIgnoredOpenShiftsCB = useCallback(async () => {
    if (isAllOpenShiftsIgnored) {
      const res = await requestIgnoreAllShifts({
        eodReportId,
        venueId,
        body: {
          ignored: false,
          onlyOpenShifts: true,
        },
      });
      const { data } = res;
      await getEndOfDayRequest({ eodId: data?.eodReportId });
    } else {
      const res = await requestIgnoreAllShifts({
        eodReportId,
        venueId,
        body: {
          ignored: true,
          onlyOpenShifts: true,
        },
      });
      const { data } = res;
      await getEndOfDayRequest({ eodId: data?.eodReportId });
    }
    refreshList();
  }, [
    eodReportId,
    venueId,
    getEndOfDayRequest,
    isAllOpenShiftsIgnored,
    refreshList,
    requestIgnoreAllShifts,
  ]);

  const prepareIgnoreAllShifts = useMemo(() => {
    return (
      <div>
        <div className="border-b"></div>
        <div className="flex justify-end p-4">
          <Button
            className="mr-md"
            type={StyleType.Secondary}
            loading={loadingIgnoredShifts}
            onClick={() => {
              allIgnoredShiftsCB();
              endOfDayChecklistModal.close();
              props.show({ id });
            }}
          >
            {isAllShiftsIgnored ? lang.unignoreAllShifts : lang.ignoreAllShifts}
          </Button>
          <Button
            className="mr-md"
            type={StyleType.Primary}
            loading={loadingIgnoredShifts}
            onClick={() => {
              allIgnoredOpenShiftsCB();
              endOfDayChecklistModal.close();
              props.show({ id });
            }}
          >
            {isAllOpenShiftsIgnored ? lang.unignoreAllOpenShifts : lang.ignoreAllOpenShifts}
          </Button>
        </div>
      </div>
    );
  }, [
    loadingIgnoredShifts,
    allIgnoredOpenShiftsCB,
    allIgnoredShiftsCB,
    isAllShiftsIgnored,
    isAllOpenShiftsIgnored,
    endOfDayChecklistModal,
    id,
    props,
  ]);

  const shiftsList = useMemo(() => {
    return prepareShiftList();
  }, [prepareShiftList]);

  const submitFormValue = useCallback(async () => {
    try {
      const notes = getFormValues();
      await endDayRequest({ eodId: eodReportId, body: notes });
      refreshList();
      Toast({
        content: lang.endOfDayCompleted,
        success: true,
        icon: "check",
      }).open();
      close();
    } catch ({ code, handleError, error }) {
      Toast({
        content: lang.endOfDayFailed,
        success: false,
      }).open();
    }
  }, [getFormValues, endDayRequest, eodReportId, close, refreshList]);

  return (
    <>
      <Modal
        width={1200}
        active={active}
        close={close}
        customTitle={
          <div className="text-center w-full">
            <Title className="self-center">{lang.endOfDay}</Title>
            <Text label>{renderShiftsDateRange}</Text>
          </div>
        }
        paddingless
        actionContent={
          <div
            className={
              eodUnsyncedTransactionWarningFlag
                ? !allTransactionSynced
                  ? "flex justify-between"
                  : "flex justify-end"
                : "flex justify-end"
            }
          >
            {eodUnsyncedTransactionWarningFlag ? (
              !allTransactionSynced ? (
                <div className="flex justify-start ml-2 mt-1">
                  <Icon className="text-red mx-2" name="warning" />
                  <Text className="text-red mt-1">{lang.runningTheEodWithUnsynced}</Text>
                </div>
              ) : null
            ) : null}
            <ActionButton
              className="px-md pb-md"
              secondary={{
                text: lang.cancel,
                onClick: () => {
                  close();
                  refreshList();
                },
              }}
              loading={endingDayReport}
              primary={{
                text: lang.endDay,
                onClick: () => {
                  submitForm(submitFormValue);
                },
                disabled: totalOpenAndNotIgnoredShifts >= 1,
              }}
            />
          </div>
        }
        {...props}
      >
        <div
          className={
            !loading
              ? "flex items-center justify-between p-md mx-md"
              : "grid grid-cols-5 gap-4 p-md"
          }
        >
          <div className="text-center">
            <Text label>{lang.totalSales}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalSales}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div className="text-center">
            <Text label>{lang.totalReturnCredits}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalReturnCredits >= 0
                  ? formatNumberToMoneyWithCurrencySymbol(totalReturnCredits)
                  : "-"}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div className="text-center">
            <Text label>{lang.totalCreditsKeptByGuests}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalCreditsKept >= 0
                  ? formatNumberToMoneyWithCurrencySymbol(totalCreditsKept)
                  : "-"}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div className="text-center">
            <Text label>{lang.totalCreditsReceivedFromVenues}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalCreditsFromVenues >= 0
                  ? formatNumberToMoneyWithCurrencySymbol(totalCreditsFromVenues)
                  : "-"}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div className="text-center">
            <Text label className="break-all">
              {lang.transactions}
            </Text>
            {!loading ? <Title lg>{totalTransactions ?? "-"}</Title> : <Skeleton single />}
          </div>
          <div className="text-center">
            <Text label>{lang.totalTopUps}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalTopUp >= 0 ? formatNumberToMoneyWithCurrencySymbol(totalTopUp) : "-"}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div className="text-center">
            <Text label>{lang.locations}</Text>
            {!loading ? (
              <Title lg className="break-all">
                {totalLocations ?? "-"}
              </Title>
            ) : (
              <Skeleton single />
            )}
          </div>
        </div>
        <div className="p-md border-t border-b hover:bg-gray-lightest">
          <Text className="text-gray-darker font-semibold">{lang.endOfDayChecklist}</Text>
          <div
            className="flex justify-between items-center mt-sm"
            onClick={() => {
              close();
              endOfDayChecklistModal.show({
                ignoreAllShifts: prepareIgnoreAllShifts,
                allTransactionSynced: allTransactionSynced,
                unsyncedTransactionWarningFlag: eodUnsyncedTransactionWarningFlag,
                title: lang.shifts,
                error,
                shifts: true,
                request: prepareShiftList,
                columns: shiftColumns,
                loading,
                close: () => {
                  endOfDayChecklistModal.close();
                  refreshList();
                },
                back: () => {
                  endOfDayChecklistModal.close();
                  props.show({ id });
                },
              });
            }}
          >
            <div>
              <Text size="text-md font-semibold tracking-wide">{lang.allShiftsClosed}</Text>
              {!loading ? (
                <Text color={totalOpenAndNotIgnoredShifts >= 1 ? "text-red-light" : "text-gray"}>
                  {renderNumberOfShifts}
                </Text>
              ) : (
                <Skeleton single />
              )}
            </div>
            <div className="flex items-center">
              {totalOpenAndNotIgnoredShifts >= 1 && <Icon name="warning" color="text-red-light" />}
              <Icon
                name="arrow-right"
                fontSize="27px"
                color="text-gray-light"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div
          className="flex justify-between items-center p-md border-b hover:bg-gray-lightest"
          onClick={() => {
            close();
            endOfDayChecklistModal.show({
              title: lang.devices,
              error,
              devices: true,
              loading,
              columns: deviceColumns,
              close: () => {
                endOfDayChecklistModal.close();
                refreshList();
              },
              back: () => {
                endOfDayChecklistModal.close();
                props.show({ id });
              },
            });
          }}
        >
          <div>
            <Text size="text-md font-semibold tracking-wide">{lang.allDevicesUsed}</Text>
            {!loading ? (
              <Text color="text-gray">{`${totalDevices} ${pluralize(
                totalDevices,
                lang.device,
                lang.devices
              )}`}</Text>
            ) : (
              <Skeleton single />
            )}
          </div>
          <div>
            <Icon
              name="arrow-right"
              fontSize="27px"
              color="text-gray-light"
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="p-md border-b">
          <Text className="text-gray-darker font-semibold">{lang.notes}</Text>
          <TextArea
            {...fields.notes}
            maxLength={255}
            placeholder={lang.addANote}
            onChange={modifyField}
          ></TextArea>
        </div>
      </Modal>
      <EndOfDayChecklistModal
        {...endOfDayChecklistModal}
        shiftsData={shiftsList}
        devicesData={deviceList}
      />
    </>
  );
};

export default RunEndOfDayModal;
