import {
  searchLocationWithTransaction,
  searchStaffWithTransaction,
} from "apis";
import {
  Field,
  MultipleSelect,
  RangePicker,
  ReportFilter,
  Text,
  TimePicker,
} from "components/commons";
import { useApi, useMount } from "hooks";
import {
  locationWithTransactionFilterResponse,
  locationWithTransactionFilterRequest,
  staffWithTransactionFilterRequest,
  staffWithTransactionFilterResponse,
} from "mappers";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";
import moment from "moment";

const TipReportFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  onClear,
  onApply,
}) => {
  const [filterDisabled, setFilterDisabled] = useState(false);

  const {
    request: searchLocationRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: searchLocationWithTransaction,
    isArray: true,
    mapper: locationWithTransactionFilterResponse,
  });

  const {
    request: searchStaffRequest,
    loading: loadingStaff,
    mappedData: staffs,
  } = useApi({
    api: searchStaffWithTransaction,
    isArray: true,
    mapper: staffWithTransactionFilterResponse,
  });

  useMount(() => {
    fetchLocations(requestState);
    fetchStaffs(requestState);
  });

  const fetchLocations = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, locationWithTransactionFilterRequest));
    },
    [searchLocationRequest]
  );

  const fetchStaffs = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject({ ...requestState }, staffWithTransactionFilterRequest));
    },
    [searchStaffRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      onApply(searchKey);
    },
    [onApply]
  );

  const clearFilterCb = useCallback(() => {
    const { filterState, requestState } = clearFilter();
    onClear(filterState, requestState);
    fetchLocations(requestState);
    fetchStaffs(requestState);
  }, [clearFilter, onClear, fetchLocations, fetchStaffs]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocations(requestState);
      fetchStaffs(requestState);
    },
    [fetchLocations, fetchStaffs, modifyFilter]
  );

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  useEffect(() => {
    if (!locationOptions.length && filterState.locations.length) {
      modifyFilter("locations", { value: [] });
    }
  }, [locationOptions, filterState.locations, modifyFilter]);

  const staffOptions = useMemo(() => {
    let sorted = staffs
      .map((staff) => {
        staff.name = staff.name.trim();
        return staff;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return mapObjectsToSelect(sorted, { textKey: "name" });
  }, [staffs]);

  useEffect(() => {
    const startDate = moment(filterState.dateRange[0].format("MM-DD-YYYY HH:mm:ss"));
    const endDate = moment(filterState.dateRange[1].format("MM-DD-YYYY HH:mm:ss"));
    if (startDate.isAfter(endDate)) {
      setFilterDisabled(true);
    } else {
      setFilterDisabled(false);
    }
  }, [filterState.dateRange]);

  return (
    <div>
      <ReportFilter
        disabled={filterDisabled}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
        dateRange={filterState.dateRange}
      >
        <Field filterLabel={lang.dateRange} className="col-span-4">
          <RangePicker
            name="dateRange"
            onChange={changeDateRangeCb}
            value={filterState.dateRange}
          />
        </Field>
        <Field
          className="col-span-4"
          filterLabel={lang.timeRange}
          customMessage={filterDisabled ? lang.invalidTimeRange : ""}
          errorClassName="text-sm text-red-400"
        >
          <div className="flex items-center">
            <TimePicker
              error={filterDisabled}
              value={filterState.dateRange[0]}
              onChange={(startTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0].set({
                      hour: startTime.get("hour"),
                      minute: startTime.get("minute"),
                    }),
                    filterState.dateRange[1],
                  ],
                });
              }}
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              error={filterDisabled}
              value={filterState.dateRange[1]}
              onChange={(endTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0],
                    filterState.dateRange[1].set({
                      hour: endTime.get("hour"),
                      minute: endTime.get("minute"),
                    }),
                  ],
                });
              }}
            />
          </div>
        </Field>
        <Field className="col-span-4" filterLabel={lang.location}>
          <MultipleSelect
            name="locations"
            selectAllText={lang.allLocations}
            loading={loadingLocation}
            options={locationOptions}
            value={filterState.locations}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={lang.selectLocation}
            emptyPlaceHolder={lang.noLocationAvailable}
            isAll
            defaultAll
          />
        </Field>
        <Field filterLabel={lang.staff} className="col-span-4">
          <MultipleSelect
            name="staffs"
            selectAllText={lang.allStaff}
            loading={loadingStaff}
            options={staffOptions}
            value={filterState.staffs}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={lang.selectStaff}
            emptyPlaceHolder={lang.noAvailableStaff}
            isAll
            defaultAll
          />
        </Field>
        <Field className="col-span-4">
        </Field>
      </ReportFilter>
    </div>
  );
};

export default TipReportFilter;
