import { deleteAdditionalCharge, queryAdditionalCharge } from "apis/additional-charge.api";
import { ActionButton, Modal, Text, Title, Toast, Icon } from "components/commons";
import { DeleteModal } from "components/modals";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import { additionalChargeResponseMapper } from "mappers";
import React, { useCallback, useEffect, useMemo } from "react";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteAdditionalCharge = ({ refreshList, id = 0, ...props }) => {
  const { history } = useRouter();

  const { request } = useApi({
    api: deleteAdditionalCharge,
    params: {
      id,
    },
  });

  const {
    request: requestAdditionalCharge,
    loading = true,
    mappedData,
  } = useApi({
    api: queryAdditionalCharge,
    params: {
      id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: additionalChargeResponseMapper,
  });

  useMount(() => {
    fetchAddCharge();
  });

  const isPartOfCompositions = useMemo(() => {
    return Boolean(mappedData?.partOfCompositions);
  }, [mappedData]);

  const fetchAddCharge = useCallback(async () => {
    await requestAdditionalCharge();
  }, [requestAdditionalCharge]);

  useEffect(() => {
    fetchAddCharge();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {!isPartOfCompositions && !loading ? (
        <DeleteModal
          primaryText={lang.deleteCharge}
          customHeader={
            <div className="flex items-center justify-between">
              <Title lg className="mb-sm pt-md">
                {lang.deleteCharge + "?"}
              </Title>
              <Icon
                className="outline-none text-xl text-gray hover:text-gray cursor-pointer"
                name="remove"
                onClick={() => {
                  props.close();
                }}
              />
            </div>
          }
          customContent={
            <Text>{lang.populate(lang.areYouSureYouWantToDeleteCharge, [mappedData.name])}</Text>
          }
          ok={async () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.deleteCharge,
              Page: lang.supplyItems,
            });

            await request();
            Toast({
              content: lang.populate(lang.chargeDeleted, [mappedData.name]),
              success: true,
              icon: "check",
            }).open();
            props.close();
            history.push(Path.SETTING_ADDITIONAL_CHARGE);
            if (refreshList) {
              refreshList();
            }
          }}
          {...props}
        />
      ) : null}
      {isPartOfCompositions & !loading ? (
        <Modal
          title={<strong>{lang.unableToDelete}</strong>}
          actionContent={
            <ActionButton
              className="px-md pb-md"
              secondary={{
                text: lang.okay,
                onClick: () => {
                  props.close();
                },
              }}
              primary={false}
            />
          }
          {...props}
        >
          <Text>{lang.deleteChargeFailed}</Text>
        </Modal>
      ) : null}
    </>
  );
};

export default DeleteAdditionalCharge;
