export const environment = {
  id: "staging",
  production: false,
  API_V3: "https://api.staging.services.pouchnation.com",
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "d8dce737577e847b5008f7996ae12f0d",
  FEATURE_FLAG: {
    clientSideID: "614ed91f06177525d49e24d3",
  },
  SENTRY_DSN: "https://136c174163aa48e5865678da5ada8253@o431184.ingest.sentry.io/6147379",
  ACTIVE_MQ_HOST:
    "wss://b-daa044d3-40d2-40ab-b74a-8d0973987485-1.mq.ap-southeast-1.amazonaws.com:61619",
  ACTIVE_MQ_USER: "pnstaging",
  ACTIVE_MQ_PW: "pouchnationstaging123",
  POUCH_VENUES: "https://staging.venue.pouchnation.com/",
  POUCH_ACCOUNTS: "https://staging.accounts.pouchnation.com/",
  PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL:
    "https://payments.pabbly.com/checkout/620da53ff1b5b726d87eaa0a",
  PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL:
    "https://payments.pabbly.com/checkout/620da53ff1b5b726d87eaa0a",
  MEWS_URL: "https://demo.mews.li",
  CONNECTOR_URL: "https://staging.connector.pouchnation.com",
  CLOUDBEDS_CONNECT_URL:
    "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=pouchnation2_dev_jnaYOAwPizVHC4bky0WxE7G2&redirect_uri=https%3A%2F%2Fstaging.venue.pouchnation.com%2Fsetting%2Fintegration&response_type=code&scope=read%3Aadjustment+write%3Aadjustment+delete%3Aadjustment+read%3Aitem+write%3Aitem+delete%3Aitem+read%3Aguest+write%3Aguest+read%3Ahotel+read%3AhouseAccount+write%3AhouseAccount+read%3Apayment+write%3Apayment+read%3Areservation+write%3Areservation+read%3AtaxesAndFees+read%3Auser+read%3Acurrency",
  FORGOT_PASSWORD_URL: "https://staging.accounts.pouchnation.com/forgot"
};
