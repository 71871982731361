import { Post, Delete, Get } from "services";
import { ApiPath } from "paths";

export const searchProduct = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT}`, body);
  return res;
};

export const searchProductMovement = async ({ id, ...body }) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_MOVEMENT(id)}`, body);
  return res;
};

export const searchProductsOutOfStock = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_STOCK_LOW_QUANTITY}`, body);
  return res;
};

export const searchProductReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_REPORT}`, body);
  return res;
};

export const searchProductReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_REPORT_EXPORT}`, body);
  return res;
};

export const searchProductExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_EXPORT}`, body);
  return res;
};

export const searchProductSku = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_SKU}`, body);
  return res;
};

export const editPos = async (body) => {
  const res = await Post(`${ApiPath.PRODUCT_POS}`, body);
  return res;
};

export const bulkProductPos = async (body) => {
  const res = await Post(`${ApiPath.BULK_PRODUCT_POS}`, body);
  return res;
};

export const batchProduct = async (body) => {
  const res = await Post(`${ApiPath.BATCH_PRODUCT}`, body);
  return res;
};

export const bulkProduct = async (body) => {
  const res = await Post(`${ApiPath.BULK_PRODUCT}`, body);
  return res;
};

export const deleteProduct = async ({ id }) => {
  const res = await Delete(`${ApiPath.PRODUCT_ID(id)}`);
  return res;
};

export const getProduct = async ({ productId }) => {
  const res = await Get(`${ApiPath.PRODUCT_ID(productId)}`);
  return res;
};

export const createProduct = async (body) => {
  const res = await Post(`${ApiPath.PRODUCT}`, body);
  return res;
};

export const updateProduct = async ({ productId, ...body }) => {
  const res = await Post(`${ApiPath.PRODUCT_ID(productId)}`, body);
  return res;
};

export const editProductLocationStock = async ({ id, locationId, body }) => {
  const res = await Post(`${ApiPath.EDIT_PRODUCT_LOCATION_STOCK({ id, locationId })}`, body);
  return res;
};

export const editOnlineOrder = async (body) => {
  const res = await Post(`${ApiPath.ONLINE_ORDER}`, body);
  return res;
};

export const bulkAssignRevenueAccountCodes = async (body) => {
  const res = await Post(`/inventory/product/revenue-account-codes`, body);
  return res;
};