import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { VenueContext } from "contexts";
import { createAdditionalCharge } from "apis/additional-charge.api";
import { useApi, useModal, useMount, useRouter } from "hooks";
import initialFormState from "../form/additional-charge.form-state";
import { Text, Toast, Switch } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";
import { Form, Field, RadioGroup, Checkbox, InputMoney } from "components/commons/index";
import { ActionButton, MultipleSelect, Panel } from "components/index";
import useForm from "hooks/useForm";
import Input from "components/commons/input/input";
import { getLocations } from "apis/location.api";
import { location } from "mappers";
import { mapObjectsToSelect } from "services";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber } from "services";

const AdditionalChargeSetting = () => {
  const { history } = useRouter();

  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const unsaveChangesModal = useModal();
  useMount(() => {
    requestLocations({ venueId });
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.addCharge,
    });
  });

  const {
    request: requestLocations,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  const initialState = useMemo(() => {
    return {
      name: null,
      locationIds: [],
      isActiveAllLocations: true,
      calculateMethod: "WITHOUT_TAX",
      type: null,
      showInPos: false,
      addToCart: false,
      value: null,
      venueId: venueId,
    };
  }, [venueId]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, validateForm, modifyField, getFormValues, dirty } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.SETTING_ADDITIONAL_CHARGE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const calculateMethodOptions = useMemo(() => {
    return [
      {
        text: "Calculate charge before tax",
        value: "WITHOUT_TAX",
      },
      {
        text: "Calculate charge after tax",
        value: "WITH_TAX",
      },
    ];
  }, []);

  const typeOptions = useMemo(() => {
    return [
      {
        text: "Percentage",
        value: "PERCENTAGE",
      },
      {
        text: "Fixed",
        value: "FIXED",
      },
    ];
  }, []);

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let mapped = mapObjectsToSelect(sorted, { textKey: "name", valueKey: "id" });
    return mapped;
  }, [locations]);

  //submit api

  const { request: createAdditionalChargeRequest, loading: submitting } = useApi({
    api: createAdditionalCharge,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitAdd = useCallback(async () => {
    try {
      await createAdditionalChargeRequest({
        ...getFormValues(),
        locationIds: fields.locationIds.value.filter((x) => !!x).map((x) => x.value),
        isActiveAllLocations: fields.locationIds.value.length === 0,
        venueId: venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch (error) {
      console.log(error);
    }
  }, [createAdditionalChargeRequest, fields.locationIds, venueId, getFormValues, goToList]);

  const isMoneyValid = useCallback((value) => {
    value = parseAmountToNumber(value) || 0;
    return (
      ((Number(value) || value === "0" || !value) && Number(value) < 99999999) ||
      (value && value.toString().indexOf(".") === value.length - 1)
    );
  }, []);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.additionalCharges}
          title={lang.addCharge}
          returnPath={Path.SETTING_ADDITIONAL_CHARGE}
          onClick={() => {}}
        />
      }
    >
      <Form
        unsaveChangesModal={unsaveChangesModal}
        submitForm={() => {
          submitAdd();
        }}
      >
        <FragmentA>
          <Panel>
            <div>
              <div className="flex flex-row items-center mb-5 ">
                <Switch {...fields.showInPos} onChange={modifyField} />

                <Text className="ml-sm" size="text-sm" color="text-gray" fontWeight="font-semibold">
                  {lang.activateOnPOS}
                </Text>
              </div>
              <Field label={lang.chargeName} {...fields.name}>
                <Input required {...fields.name} onChange={modifyField} />
              </Field>

              <Field {...fields.type} label={lang.type}>
                <RadioGroup
                  {...fields.type}
                  options={typeOptions}
                  onChange={(value) => {
                    modifyField("type", { value: value });
                  }}
                />
              </Field>

              <div className="grid grid-cols-5 my-md">
                <Field label={lang.value} {...fields.value}>
                  {fields.type.value === "FIXED" ? (
                    <InputMoney
                      placeholder="0.00"
                      {...fields.value}
                      onChange={(obj, value) => {
                        let val = (value.value + "").trim();
                        if (isMoneyValid(val)) {
                          modifyField(obj, { value: val });
                        }
                      }}
                    />
                  ) : (
                    <Input
                      required
                      {...fields.value}
                      onChange={(name, obj) => {
                        console.log("fields", fields);
                        console.log("value onchange", name, obj);
                        const { value } = obj;
                        if (!isNumberValid(value) || !isValidDecimalPlaces(value, 3)) {
                          return;
                        }

                        const max = 9999999.999;
                        if (parseAmountToNumber(value) <= max) {
                          // supplyQuantity.onChange(name, {
                          //   value: value.trim(),
                          // });
                          modifyField("value", { value: obj.value });
                        }
                      }}
                      iconSuffix={<span className="text-sm text-gray-400 pl-sm">%</span>}
                    />
                  )}
                </Field>
              </div>
              <Field {...fields.calculateMethod} label={lang.chargeCalculation}>
                <RadioGroup
                  {...fields.calculateMethod}
                  options={calculateMethodOptions}
                  onChange={(value) => {
                    modifyField("calculateMethod", { value: value });
                  }}
                />
              </Field>

              <Field label={lang.location} {...fields.locationId}>
                <MultipleSelect
                  {...fields.locationIds}
                  options={locationOptions}
                  optionsLoading={loadingLocations}
                  isAll={fields.isActiveAllLocations}
                  onChange={(name, obj) => {
                    modifyField("locationIds", { value: obj.value });
                  }}
                />
              </Field>

              <div className="flex flex-row">
                <Checkbox
                  {...fields.addToCart}
                  // className={classnames("m-auto")}
                  onChange={(name, obj) => {
                    modifyField("addToCart", { value: obj.value });
                  }}
                />

                <div>
                  <Text className="ml-sm">{lang.automaticallyAddThisChargeToTheCart}</Text>
                  <Text className="ml-sm" size="text-xs" italic={true}>
                    {lang.automaticallyAddThisChargeToTheCartDesc}
                  </Text>
                </div>
              </div>
            </div>
          </Panel>
        </FragmentA>

        <ActionButton
          showLine
          loading={submitting}
          primary={{
            disabled: fields.locationIds?.length === 0 && !fields.isActiveAllLocations,
            onClick: () => {
              if (!validateForm().error) {
                submitAdd();
              }
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default AdditionalChargeSetting;
