import timeUnit from "enums/time-unit";
import moment from "moment";

export const dashboardFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    status: "",
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    locationIds: [],
    interval: timeUnit.Hour,
  };
};
