const supplyItemPaths = {
  SUPPLY_ITEM: "",
  SEARCH_SUPPLY_ITEM: "search",
  SUPPLY_ITEM_ID: (id) => id,
  EXPORT_SUPPLY_ITEM: "search/export",
  SEARCH_SUPPLY_ITEM_TRANSFER: "transfer/search",
  SUPPLY_ITEM_TRANSFER: "transfer",
  SUPPLY_ITEM_IMPORT: "import-file",
  SUPPLY_ITEM_IMPORT_SAVE: "import-file/save",
  SUPPLY_ITEM_IMPORT_WHOLE_PRODUCT: "import-file/whole-product",
  SUPPLY_ITEM_IMPORT_WHOLE_PRODUCT_SAVE: "import-file/whole-product/save",
  FETCH_LIST_STOCK_ADJ_URL: (locationId) => `search/location/${locationId}`,
  SUPPLY_ITEM_STOCK_ADJ: (locationId) => `location/${locationId}`,
};

export default supplyItemPaths;
