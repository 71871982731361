import React from "react";
import { Dashboard } from "modules";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const DashboardPage = () => {
  useMount(() => {
    setPageTitle();
  });
  return <Dashboard />;
};

export default DashboardPage;
