import { Button, Form, Input, Text, Toast } from "components/commons";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useApi, useForm } from "hooks";
import { Field, ProductType, StyleType } from "enums";
import lang from "translations";
import { Popover } from "antd";
import {
  computeMarkUp,
  computeRetailPrice,
  formatNumberToMoney,
  isNumberValid,
  isValidDecimalPlaces,
  parseAmountToNumber,
  parseMoneyToNumber,
  sortByKeyName,
} from "services";
import { isDecimalLastCharacter, isZeroLastCharacter, toAmount } from "services/number.service";
import { VenueContext } from "contexts";
import initialFormState, {
  CreateProductField,
} from "modules/product/product-set-form/product-set-form.state";
import { getProduct } from "apis";
import { productSetRequest } from "mappers/product.mapper";
import Validation from "services/validation.service";
import { updateProduct } from "apis/product.api";
import { mixpanel } from "mixpanel";
import { isEmpty } from "lodash";

const EditPriceProductSetPopover = ({
  id,
  supplyPriceRange,
  trigger = "click",
  placement = "top",
  onRefresh,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const { venue } = useContext(VenueContext);
  const { currencySymbol, venueId } = venue;

  const { request, mappedData } = useApi({
    api: getProduct,
    params: {
      venueId,
      productId: id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: productSetRequest,
  });

  const { request: saveProduct, loading: submitting } = useApi({
    api: updateProduct,
    params: {
      productId: id,
      venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const initialState = useMemo(() => {
    const {
      productLogo,
      productName,
      activePos,
      description,
      category = [],
      hasTax,
      tax,
      location = [],
      isActiveAllLocations,
      isActiveAllCategories,
      activeOnlineMenu = false,
      sku,
      products = [],
      retailPrice,
      productSkuId,
      revenueAccountCodeId,
      cogsAccountCodeId,
      bargainAllowed,
      bargainRequiresApproval,
    } = mappedData;

    let supplyPrice = 0;
    products.forEach((product) => {
      let qty = product.quantity;
      let price = product.price;
      supplyPrice += qty * price;
    });

    let obj = {
      productName,
      productLogo,
      description,
      category: {
        isAll: isActiveAllCategories,
        value: category,
      },
      activePos,
      activeOnlineMenu,
      hasTax,
      tax,
      generatedSku: {
        value: false,
      },
      location: {
        isAll: isActiveAllLocations,
        value: location,
      },
      sku: {
        value: sku,
        required: true,
        validations: [Validation.required(), Validation.minlength(4)],
      },
      retailPrice,
      bargainAllowed,
      bargainRequiresApproval,
      products: {
        isFormArray: true,
        type: Field.ANY,
        value: products.sort(sortByKeyName("productName")).map((product) => {
          return CreateProductField({
            productSkuId: {
              value: product.productSkuId,
            },
            itemName: {
              value: product.productName,
              error: false,
            },
            quantity: {
              value: product.quantity,
              symbol: product.type === ProductType.SupplyItem ? product.measurement?.unit : "pc",
            },
            supplyPrice: {
              value: formatNumberToMoney((product.price || 0) * product.quantity),
            },
            price: {
              value: product.price,
            },
            type: {
              value: product.type,
            },
          });
        }),
      },
      supplyCost: {
        value: formatNumberToMoney(supplyPrice),
      },
      markUp: {
        value:
          toAmount(supplyPrice) === toAmount(retailPrice)
            ? "0.00"
            : toAmount(computeMarkUp(supplyPrice, retailPrice)),
      },
      productSkuId,
      revenueAccountCodeId: {
        value: revenueAccountCodeId,
      },
      cogsAccountCodeId: { value: cogsAccountCodeId },
    };

    return obj;
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const form = useForm({
    initialState: formState,
  });

  const { modifyField, fields, modifyForm, getFormValues, applyFieldErrors, clearForm } = form;

  const formField = useMemo(() => {
    const formItems = {};
    [
      "productLogo",
      "productName",
      "description",
      "category",
      "revenueAccountCodeId",
      "cogsAccountCodeId",
      "activePos",
      "activeOnlineMenu",
      "location",
      "inventoryType",
      "sku",
      "generatedSku",
      "supplyUnit",
      "supplyQuantity",
      "sellingQuantity",
      "hasVariants",
      "hasTax",
      "supplyCost",
      "markUp",
      "retailPrice",
      "variants",
      "attributeAndOptions",
      "tax",
      "products",
      "productSkuId",
      "bargainAllowed",
      "bargainRequiresApproval",
    ].forEach((key) => {
      formItems[key] = {
        ...fields[key],
        onChange: modifyField,
      };
    });
    return formItems;
  }, [fields, modifyField]);
  const { supplyCost, markUp, retailPrice } = formField;

  const submitForm = useCallback(async () => {
    const formValues = getFormValues();

    let params = {
      imageLink: formValues.productLogo,
      productName: formValues.productName,
      description: formValues.description,
      revenueAccountCodeId: formValues.revenueAccountCodeId,
      cogsAccountCodeId: formValues.cogsAccountCodeId,
      inventoryTracked: false,
      categories: formValues.category?.map(({ text, value }) => {
        return { categoryName: text, categoryId: value };
      }),
      locationIds: formValues.location?.length
        ? formValues.location?.map(({ value }) => {
            return value;
          })
        : [],
      isActiveAllLocations: fields.location.isAll,
      isActiveAllCategories: fields.category.isAll,
      taxId: formValues.hasTax ? Number(formValues.tax) : null,
      sellableInPos: formValues.activePos,
      isActiveMobileOrdering: formValues.activeOnlineMenu,
      creationType: formValues.inventoryType,
      sku: formValues.sku,
      compositions: formValues.products.map((product) => {
        return {
          productSkuId: product.productSkuId,
          quantity: parseAmountToNumber(product.quantity),
        };
      }),
      supplyPrice: parseMoneyToNumber(formValues.supplyCost).value,
      retailPrice: parseMoneyToNumber(formValues.retailPrice).value,
      bargainAllowed: formValues.bargainAllowed,
      bargainRequiresApproval: formValues.bargainRequiresApproval,
    };

    try {
      mixpanel.track(TrackEvent.Clickedbutton, {
        Button: lang.saveProductSet,
      });
      if (!params.isActiveAllLocations && params.locationIds.length === 0) {
        applyFieldErrors({
          location: lang.pleaseSelectLocation,
        });
        return;
      }
      await saveProduct({
        ...params,
        venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      setVisible(false);
      onRefresh();
    } catch (e) {
      console.log("error", e);
      Toast({
        content: lang.somethingWentWrong,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [
    applyFieldErrors,
    fields.category.isAll,
    fields.location.isAll,
    getFormValues,
    onRefresh,
    saveProduct,
    venueId,
  ]);

  useEffect(() => {
    if (visible) {
      if (isEmpty(mappedData)) request();
    } else {
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, mappedData]);

  const content = useMemo(() => {
    return (
      <Form className="p-1 w-56">
        <div className="grid grid-cols-2 gap-y-5">
          <div className="text-base font-semibold">{lang.supplyCost}</div>
          <div className="text-base text-disabled text-right mr-7">{supplyPriceRange}</div>
          <div className="text-base font-semibold my-2">{lang.markUp}</div>
          <div>
            <Input
              {...markUp}
              iconSuffix={<Text color="text-gray">%</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || value.trim() === "-") {
                  modifyForm({
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value) || !isValidDecimalPlaces(value, 2)) {
                  return false;
                }
                const max = 99999999.99;
                if (parseAmountToNumber(value) <= max) {
                  modifyForm({
                    supplyCost,
                    retailPrice,
                    markUp: {
                      ...markUp,
                      value: value,
                    },
                  });
                }
              }}
              onFocus={() => {
                modifyForm({
                  supplyCost,
                  retailPrice,
                  markUp: {
                    ...markUp,
                    value: parseAmountToNumber(markUp.value) || "",
                  },
                });
              }}
              onBlur={() => {
                const v = parseAmountToNumber(markUp.value);
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const rp = computeRetailPrice(sc, v);
                modifyForm({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    value: formatNumberToMoney(rp),
                  },
                  markUp: {
                    ...markUp,
                    value: markUp.value || 0,
                  },
                });
              }}
            />
          </div>
          <div className="text-base font-semibold my-2">{lang.retailPrice}</div>
          <div>
            <Input
              right
              {...retailPrice}
              iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
              onChange={(name, { value }) => {
                if (isDecimalLastCharacter(value) || isZeroLastCharacter(value)) {
                  modifyForm({
                    retailPrice: {
                      ...retailPrice,
                      value: value,
                    },
                  });
                  return false;
                } else if (!isNumberValid(value)) {
                  return false;
                }
                const v = parseMoneyToNumber(value).value;
                const max = 99999999.99;
                if (v <= max) {
                  modifyForm({
                    supplyCost,
                    retailPrice: {
                      ...retailPrice,
                      value: v,
                    },
                    markUp,
                  });
                }
              }}
              onBlur={() => {
                const rp = parseMoneyToNumber(retailPrice.value).value;
                const sc = parseMoneyToNumber(supplyCost.value).value;
                const mu = computeMarkUp(sc, rp);
                modifyForm({
                  supplyCost,
                  retailPrice: {
                    ...retailPrice,
                    value: retailPrice.value,
                  },
                  markUp: {
                    ...markUp,
                    value: toAmount(mu),
                  },
                });
              }}
              onFocus={() => {
                modifyForm({
                  retailPrice: {
                    ...retailPrice,
                    value: parseMoneyToNumber(retailPrice.value).value || "",
                  },
                  supplyCost,
                  markUp,
                });
              }}
            />
          </div>
        </div>

        <div className="flex gap-5 mx-5 mt-5">
          <Button
            type={StyleType.Secondary}
            onClick={() => setVisible(false)}
            className="flex-1"
            disabled={submitting}
          >
            {lang.cancel}
          </Button>
          <Button onClick={submitForm} className="flex-1" disabled={submitting}>
            {lang.save}
          </Button>
        </div>
      </Form>
    );
  }, [
    currencySymbol,
    markUp,
    modifyForm,
    retailPrice,
    submitForm,
    submitting,
    supplyCost,
    supplyPriceRange,
  ]);

  return (
    <Popover
      content={content}
      trigger={trigger}
      visible={visible}
      placement={placement}
      onVisibleChange={setVisible}
    >
      {children}
    </Popover>
  );
};

export default EditPriceProductSetPopover;
