import enumeration from "./enumeration";

const ReceiptNameOrder = {
  header: {
    VENUE_LOGO: 1,
    VENUE_NAME: 2,
    BUSINESS_NAME: 3,
    BUSINESS_ADDRESS: 4,
    BUSINESS_CONTACT_NUMBER: 5,
    TAX_NUMBER: 6,
    DEVICE_ID: 7,
    SERIAL_NUMBER: 8,
    RECEIPT_NUMBER: 9,
    DATE_AND_TIME_OF_TRANSACTION: 10,
    VENUE_LOCATION: 11,
    STAFF_NAME_AND_OR_TAG_ID: 12,
    TAX_INVOICE_NUMBER: 13,
    CUSTOM_HEADER: 14
  },
  footer: {
    CUSTOM_FOOTER: 1,
    POS_PROVIDER: 2,
    GUEST_NAME_AND_OR_TAG_ID: 3,
    TRANSACTION_ID: 4
  },
  sales: {
    ITEM_TAX: 1,
    GUESTS_PREVIOUS_BALANCE_TOTAL_PAID_AND_NEW_BALANCE: 2
  }
};

export default enumeration(ReceiptNameOrder);
