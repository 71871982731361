import React, { useCallback, useContext, useMemo } from "react";
import StaffForm from "../staff-form/staff-form";
import lang from "translations";
import { editStaff, getStaffById, resendInvite, deleteStaff } from "apis/staff.api";
import { editStaffRequest, staffResponse } from "mappers";
import { useApi, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import { Field, Icon, Pill, Text, Toast, Hightlight, Title } from "components/commons";
import { PillType, StaffRole } from "enums";
import { useModal } from "hooks";
import { DeleteModal } from "components/modals";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const EditStaff = () => {
  const { venue } = useContext(VenueContext);
  const { query, history } = useRouter();
  const { id } = query;
  const { venueId, venueName } = venue || {};

  const {
    request: getStaffRequest,
    loading,
    mappedData,
    error,
  } = useApi({
    api: getStaffById,
    params: {
      staffId: id,
      venueId,
    },
    mapper: staffResponse,
  });

  const deleteStaffRequest = useApi({
    api: deleteStaff,
    params: {
      staffId: id,
      venueId,
    },
  });

  const {
    name,
    isAwait,
    status,
    isManager,
    roles = [],
    paymentMethod,
    location = [],
    guestProfiles = [],
    accessControls = [],
    isActive,
    email,
    tagUids,
  } = mappedData;

  const deleteStaffModal = useModal();

  const { request: requestResendInvite, loading: resending } = useApi({
    api: resendInvite,
    params: {
      venueId,
      locationIds: location.map((l) => l.value),
      roles: roles.map((r) => r.value),
      email,
    },
  });

  const { request, loading: submitting } = useApi({
    api: editStaff,
    params: {
      id,
      venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: editStaffRequest,
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  const renderTitle = useMemo(() => {
    if (isAwait) {
      return (
        <Pill className="mt-sm" type={PillType.Italic} size="text-xs">
          {status}
        </Pill>
      );
    }
    return name;
  }, [isAwait, status, name]);

  const renderDeleteModalContent = useMemo(() => {
    if (isAwait) {
      return (
        <div className="text-sm">{lang.populate(lang.thisStaffHasNpLongerAccess, [venueName])}</div>
      );
    }
    return (
      <div className="text-sm">
        {lang.populate(lang.youAreAboutToDeleteStaffName, [name, venueName])}
      </div>
    );
  }, [venueName, name, isAwait]);

  useMount(() => {
    getStaffRequest();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editStaffPage,
    });
  });

  const initialState = useMemo(() => {
    return {
      email: {
        value: email,
        disabled: true,
      },
      role: {
        disabled: isAwait,
        value: isManager ? [StaffRole.Manager] : roles,
        defaultAll: true,
        isAll: isManager,
      },
      paymentMethod: {
        disabled: isAwait || isManager,
        value: isManager ? [] : paymentMethod,
        defaultAll: isManager,
        isAll: isManager,
      },
      location: {
        disabled: isAwait || isManager,
        value: isManager ? [] : location,
        defaultAll: isManager,
        isAll: isManager,
      },
      guestProfiles: {
        disabled: isAwait || isManager,
        value: isManager ? [] : guestProfiles,
        defaultAll: isManager,
        isAll: isManager,
      },
      accessControls: {
        disabled: isAwait || isManager,
        value: isManager ? [] : accessControls,
        defaultAll: isManager,
        isAll: isManager,
      },
      isActive: {
        value: isActive,
      },
    };
  }, [
    email,
    isAwait,
    isManager,
    roles,
    paymentMethod,
    location,
    guestProfiles,
    accessControls,
    isActive,
  ]);

  return (
    <>
      <DeleteModal
        {...deleteStaffModal}
        deleting={deleteStaffRequest.loading}
        primaryText={isAwait ? lang.revokeInvite : lang.deleteStaff}
        ok={async () => {
          mixpanel.track(TrackEvent.ClickedButton, {
            Button: lang.deleteStaff,
            Page: lang.editStaffPage,
          });
          await deleteStaffRequest.request();
          Toast({
            content: lang.staffRemoved,
            success: true,
            icon: "check",
          }).open();
          history.push(Path.STAFF);
        }}
        customContent={
          <div>
            {renderDeleteModalContent}
            <Hightlight>{lang.noteTransactionStaff}</Hightlight>
          </div>
        }
        customHeader={
          <Title lg className="mb-md pt-md">
            {isAwait ? lang.revokeThisInvite : lang.populate(lang.removeNameAsYourStaff, [name])}
          </Title>
        }
      />

      <StaffForm
        title={renderTitle}
        error={error}
        formTitle={isAwait ? lang.basicInfo : lang.roleAndDesignation}
        submitting={submitting}
        submit={submitForm}
        loading={loading}
        isAwait={isAwait}
        isEdit
        danger={{
          text: isAwait ? lang.revokeInvite : lang.deleteStaff,
          onClick: () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.revokeInvite,
              Page: lang.editStaffPage,
            });
            deleteStaffModal.show();
          },
        }}
        basicInfoRender={
          <>
            <Field label={lang.name} className="mt-md">
              <Text lg>{name}</Text>
            </Field>
            <Field label={lang.email} className="mt-md">
              <Text lg>{email}</Text>
            </Field>
            {tagUids && tagUids.length > 0 ? (
              <Field label={lang.tagIds} className="mt-md">
                {tagUids?.map((t) => {
                  return <Text fontMono>{t}</Text>;
                })}
              </Field>
            ) : null}
          </>
        }
        emailBottomRender={
          isAwait ? (
            <div
              className="mt-xs flex cursor-pointer"
              onClick={async () => {
                await requestResendInvite();
                Toast({
                  content: lang.populate(lang.newInviteLink, [email]),
                  success: true,
                  icon: "check",
                }).open();
              }}
            >
              <Text color="text-gray" size="text-xs" italic className="mr-xs">
                {lang.awaitingInviteResponse}
              </Text>
              <Text color="text-blue" strong size="text-xs">
                {lang.resendInvite} {resending && <Icon loading />}
              </Text>
            </div>
          ) : null
        }
        initialState={initialState}
      />
    </>
  );
};

export default EditStaff;
