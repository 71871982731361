import { searchSupplyItem } from "apis";
import { Button, DataTable, Modal, Select, Toast } from "components";
import { StyleType } from "enums";
import { useApi, useModal, useMount, useSelectItems } from "hooks";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import lang from "translations";
import { VenueContext } from "contexts";
// eslint-disable-next-line no-unused-vars
import styles from "./import-supply-item.module.scss";
import useSelectID from "hooks/useSelectID";
import { importSaveSupplyItem, importSaveSupplyItemWholeProduct } from "apis/supply-item.api";

const ImportModal = (props) => {
  const { active, onClose, data = [], fileName, newFileName, isCreateWhole } = props;
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [location, setLocation] = useState();
  const [list, setList] = useState([]);
  const modal = useModal();
  const dataRef = useRef([]);

  const reqItems = useApi({
    api: searchSupplyItem,
    isArray: true,
    params: { venueId },
    mapper: {
      _keys: ["productId", "productSkuId"],
      text: {
        transform: ({ src }) => {
          const { sku, productName } = src;
          return `using "${productName || ""}" (${sku || ""})`;
        },
      },
      value: { key: "productId" },
      subvalue: {
        transform: ({ src: { sku, productSkuId, productName, measurement, productId } }) => ({
          productId,
          productSkuId,
          sku,
          name: productName,
          measurement: measurement,
        }),
      },
    },
  });

  const { request, loading } = useApi({
    api: isCreateWhole ? importSaveSupplyItemWholeProduct : importSaveSupplyItem,
    isArray: true,
    params: { venueId, fileName, newFileName },
  });

  const { selected, setSelected, isAllSelected, setSelectAll, clearSelected, replaceSelected } =
    useSelectItems({
      items: list,
      indeterminate: true,
    });
  const selectedId = useSelectID("import-modal");

  const onSelectAll = () => {
    setSelectAll();
    const ids = list?.map(({ id }) => id);
    if (!isAllSelected) selectedId.onAdd(ids);
    else selectedId.onSub(ids);
  };

  const editList = useCallback(
    (v, i) => {
      // change list
      const temp = [...list];
      temp[i].isExisted = !!v;
      if (v) {
        temp[i].subvalue = v.subvalue;
      } else {
        temp[i].subvalue = undefined;
      }
      setList(temp);

      // change data ref
      const idx = dataRef.current.findIndex((e) => e.location.locationId === location);
      dataRef.current[idx].supplyItems = temp;
    },
    [list, location]
  );

  const removeSku = useCallback(
    (i) => {
      editList(undefined, i);
    },
    [editList]
  );
  const selectSku = useCallback(
    (v, i) => {
      editList(v, i);
    },
    [editList]
  );

  const onConfirm = useCallback(async () => {
    const items = [];
    dataRef.current.forEach(({ location, supplyItems }) => {
      const { locationId } = location;
      supplyItems.forEach((item) => {
        if (!selectedId.arr.includes(item.id)) return;
        let subvalue = item.subvalue;
        items.push({
          locationId,
          supplyName: item.name,
          measurementId: item.measurement.measurementId,
          quantity: item.quantity,
          price: item.price,
          ...(!subvalue &&
            item.isExisted && {
              productId: item?.existedProduct?.productId,
              productSkuId:
                item?.productSkuId || item?.existedProduct?.productSkus[0]?.productSkuId,
            }),
          ...(!!subvalue && {
            productId: subvalue.productId,
            productSkuId: subvalue.productSkuId,
            supplyName: subvalue.name,
            measurementId: subvalue.measurement.measurementId,
          }),
          ...(isCreateWhole && {
            categoryIds: item?.categoryIds,
            displayedIn: item?.displayedIn,
            retailPrice: item?.retailPrice,
          }),
        });
      });
    });

    try {
      await request({ items });
      modal.close();
      onClose();
    } catch (error) {
      Toast({
        content: lang.somethingWentWrongPlsTryAgain,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [isCreateWhole, modal, onClose, request, selectedId.arr]);

  const getList = useCallback(
    (id) => {
      if (!id) return;
      const temp = dataRef.current.find(({ location }) => location?.locationId === id)?.supplyItems;
      setList(temp || []);
      const obj = {};
      temp
        ?.filter((item) => selectedId.arr.includes(item.id))
        ?.forEach((item, index) => {
          obj[item.id] = {
            ...item,
            index,
            checked: true,
          };
        });
      replaceSelected(obj);
    },
    [replaceSelected, selectedId.arr]
  );

  const locations = useMemo(
    () => data.map(({ location }) => ({ text: location.locationName, value: location.locationId })),
    [data]
  );

  useMount(() => {
    reqItems.request();
  });

  useEffect(() => {
    getList(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (active) {
      const ids = [];
      data.forEach(({ supplyItems }) => {
        ids.push(...supplyItems.map((i) => i.id));
      });
      selectedId.onAdd(ids);
      dataRef.current = data;
      setLocation(data[0]?.location.locationId || 0);
    }
    return () => {
      clearSelected();
      selectedId.onDel();
      setLocation(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const columns = useMemo(
    () => [
      { key: "name", text: lang.supplyItem, align: "left", custom: true },
      {
        key: "measurement",
        text: lang.measurement,
        custom: true,
        align: "right",
      },
      {
        key: "quantity",
        text: lang.quantity,
        custom: true,
        align: "right",
      },
      {
        key: "inventoryExisted",
        text: lang.inventoryExisted,
        custom: true,
        align: "right",
      },
      {
        key: "sku",
        text: lang.sku,
        custom: true,
        align: "right",
      },
      {
        key: "handle",
        text: lang.actions,
        custom: true,
        align: "right",
      },
    ],
    []
  );

  const renderData = useMemo(() => {
    return list.map((item, i) => {
      const { id, name, measurement, quantity, sku, isExisted, editAble, subvalue } = item;
      return {
        id,
        name: subvalue?.name || name,
        measurement: subvalue?.measurement?.unit || measurement?.unit || "",
        quantity: subvalue?.quantity || quantity,
        inventoryExisted: (
          <div className={!!subvalue || isExisted ? "text-green" : "text-red"}>
            {isExisted ? lang.yes : lang.no}
          </div>
        ),
        sku: subvalue?.sku || sku || lang.newSku,
        handle: !editAble ? (
          "-"
        ) : isExisted ? (
          <div className="text-red cursor-pointer" onClick={() => removeSku(i)}>
            {lang.removeSku}
          </div>
        ) : (
          <FindSimilar
            onChange={(v) => {
              selectSku(v, i);
            }}
            loading={reqItems.loading}
            options={reqItems.mappedData}
          />
        ),
      };
    });
  }, [list, removeSku, reqItems.loading, reqItems.mappedData, selectSku]);

  return (
    <Modal
      {...props}
      width={1337}
      customTitle={
        <p className="text-xl text-pelorous-darker font-semibold">{`${lang.impItem}`}</p>
      }
      noCloseButton
    >
      <div>
        <Select
          className="w-48"
          options={locations}
          placeholder={lang.pleaseChooseLocation}
          value={location}
          onChange={(name, { value }) => setLocation(value)}
        />
      </div>

      <div className="mt-4 mb-6">
        <DataTable
          total={list.length}
          columns={columns}
          data={renderData}
          selected={selected}
          setSelected={(id) => {
            setSelected(id);
            selectedId.onSel(id);
          }}
          isAllSelected={isAllSelected}
          setSelectAll={onSelectAll}
          pageable={false}
        />
      </div>

      <div className="flex justify-between pb-10">
        <ul className="">
          <li className="text-xs">{lang.impItemModalNote1}</li>
          <li className="text-xs">{lang.impItemModalNote2}</li>
        </ul>
        <div className="flex gap-2 h-fit">
          <Button type={StyleType.Secondary} onClick={onClose}>
            {lang.discard}
          </Button>
          <Button onClick={modal.show} disabled={!location}>
            {lang.confirm}
          </Button>
        </div>
      </div>
      <Modal
        {...modal}
        customTitle={
          <p className="text-xl text-pelorous-darker font-semibold">{`${lang.confirmImport} ?`}</p>
        }
      >
        <div className="pb-5">
          <p className="text-base mb-7">{lang.confirmImportDesc}</p>

          <div className="flex justify-end gap-2">
            <Button type={StyleType.Secondary} onClick={modal.close}>
              {lang.discard}
            </Button>
            <Button type={StyleType.Danger} onClick={onConfirm} loading={loading}>
              {lang.confirmImport}
            </Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

const FindSimilar = ({ onChange, options = [], loading }) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <div>
        <Select
          options={options}
          loading={loading}
          className="w-48"
          searchable
          onChange={(name, { option }) => {
            setEditing(true);
            onChange(option);
          }}
          onBlur={() => setEditing(false)}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="text-green cursor-pointer" onClick={() => setEditing(true)}>
        {lang.findSimilar}
      </div>
    </div>
  );
};

export default ImportModal;
