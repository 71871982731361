import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";
import { AccountName, AccountType } from "enums";

const initialFormState = (initialState = {}) => {
  const {
    name = "",
    description = "",
    supplyAccountCodeId = null,
    sku = null,
    automaticallyGenerateSku = true,
    measurementId = null,
    weightInGrams = 0,
    orderQty = null,
    pricePerOrderQty = null,
    productSku = {
      retailPrice: 0,
      supplyPrice: 0,
      parLevel: 0,
      reorderPoint: 0,
      locationStocks: [],
    },
  } = initialState;
  return {
    name: {
      label: lang.itemName,
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    description: {
      name: "description",
      label: lang.description,
      value: description,
      type: Field.INPUT,
      maxLength: 255,
      placeholder: lang.typeHere,
    },
    supplyAccountCodeId: {
      name: "supplyAccountCodeId",
      type: Field.DROPDOWN,
      value: supplyAccountCodeId,
      options: [],
      emptyPlaceHolder: lang.noAccountsAvailable,
      placeholder: lang.selectAccount,
      accountType: AccountName.InventoryAccount,
      suggested: AccountType.Asset,
    },
    sku: {
      name: "sku",
      value: sku,
      type: Field.INPUT,
      maxLength: 16,
      disabled: true,
      validations: [Validation.required(), Validation.minlength(4)],
    },
    automaticallyGenerateSku: {
      name: "automaticallyGenerateSku",
      label: lang.automaticallyGenerateSku,
      value: automaticallyGenerateSku,
      type: Field.CHECKBOX,
    },
    measurementId: {
      name: "measurementId",
      label: lang.stockUnit,
      validations: [Validation.required()],
      required: true,
      value: measurementId,
      type: Field.DROPDOWN,
      placeholder: lang.pleaseSelectStockUnit,
    },
    weightInGrams: {
      name: "weightInGrams",
      label: lang.weightInGrams,
      validations: [Validation.required()],
      value: weightInGrams,
      type: Field.INPUT,
    },
    productSku: {
      name: "productSku",
      type: Field.ANY,
      value: productSku,
    },
    orderQuantity: {
      name: "orderQuantity",
      label: lang.orderQty,
      value: orderQty,
      type: Field.INPUT,
      maxLength: 255,
    },
    pricePerOrderQuantity: {
      name: "pricePerOrderQuantity",
      label: lang.pricePerOrderQty,
      validations: [Validation.maxValue(20000000, lang.maximumValueAllowed)],
      type: Field.INPUT,
      value: pricePerOrderQty,
      maxLength: 255,
    },
  };
};

export default initialFormState;
