import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { BookingAccommodation, BookingGuests, BookingSummary, BookingDetailsHeader } from "modules";
import { useApi, useMount, useRouter } from "hooks";
import { ModuleWrapper } from "components/fragments";
import { getBookingDetails } from "apis/booking.api";
import { bookingDetailsResponse } from "mappers/booking.mapper";
import { Toast } from "components";
import lang from "translations";

const BookingDetailsPage = () => {
  const { query } = useRouter();
  const { id } = query;

  const {
    request,
    mappedData: data,
    loading,
    error,
  } = useApi({
    api: getBookingDetails,
    params: { bookingId: id },
    mapper: bookingDetailsResponse,
    handleOwnError: true,
  });

  useMount(() => {
    request();
  });

  useEffect(() => {
    if (data && data.isWarningUpdate) {
      Toast({
        content: lang.theGuestHasNoAssignedRoom,
        error: true,
        icon: "warning",
      }).open();
    }
  }, [data]);

  return (
    <ModuleWrapper loading={loading} error={error}>
      <BookingDetailsHeader data={data} id={id} loading={loading} />
      <Switch>
        <Route path={Path.BOOKING_SUMMARY}>
          <BookingSummary data={data} loading={loading} error={error}></BookingSummary>
        </Route>
        <Route path={Path.BOOKING_GUESTS}>
          <BookingGuests data={data} loading={loading} error={error}></BookingGuests>
        </Route>
        <Route path={Path.BOOKING_ACCOMMODATION}>
          <BookingAccommodation data={data} loading={loading} error={error}></BookingAccommodation>
        </Route>
        <Redirect to={Path.BOOKING_SUMMARY} />
      </Switch>
    </ModuleWrapper>
  );
};

export default BookingDetailsPage;
