import React, { useCallback, useMemo } from "react";
import ChartContainer from "../chart-container";
import { Text, ButtonLink, Icon, Pill, Tooltip, DataTable } from "components/commons";
import lang from "translations";
import { Path } from "paths";
import { useRouter } from "hooks";
import { prettifyTransactionType } from "services";
import { PillType, TapStatus, TransactionStatus, TransactionType } from "enums";
import columns from "./columns";

const GuestRecentTransaction = ({ mappedData, loading, guestCheckinId }) => {
  const { history } = useRouter();

  const renderTransactionType = useCallback((type, status) => {
    if (status === TransactionStatus.VOID) {
      return (
        <Pill size="text-xs" type={PillType.Gray}>
          {`${prettifyTransactionType(type)} ${lang.voided}`}
        </Pill>
      );
    }
    if (
      type === TransactionType.TOPUP ||
      type === TransactionType.SALE ||
      type === TransactionType.RETURN ||
      type === TransactionType.ISSUE_FOC ||
      type === TransactionType.REMOVE_FOC
    ) {
      return (
        <Pill size="text-xs" type={PillType.Green}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.MIXED) {
      return (
        <Pill size="text-xs" type={PillType.Orange}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.REDEEM || type === TransactionType.VOUCHER_ISSUE) {
      return (
        <Pill size="text-xs" type={PillType.Blue}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.REMOVE || type === TransactionType.VOUCHER_REMOVE) {
      return (
        <Pill size="text-xs" type={PillType.Red}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    return null;
  }, []);

  const renderItems = useCallback((items) => {
    const list = items.slice(0, 4);
    if (items.length > 4) {
      list.push(`${items.length - 4} more.`);
    }
    if (items.length) {
      return (
        <Tooltip
          title={
            <Text color="text-white" fontWeight="font-semibold">
              {items.join(", ")}
            </Text>
          }
        >
          <div>
            <Text>{list.join(", ")}</Text>
          </div>
        </Tooltip>
      );
    }
  }, []);

  const prepareData = useCallback(() => {
    const transactionList = [];
    let transactionCount = 0;
    mappedData.forEach((trans) => {
      if (trans.tapStatus === TapStatus.Failed) {
        return;
      }
      if (transactionCount < 5) {
        transactionList.push(trans);
        transactionCount += 1;
      }
    });
    return transactionList;
  }, [mappedData]);

  const renderDateAndTime = useCallback((date) => {
    let dateWithNoTime = null;
    let time = null;

    const dateAndTime = date.split(" ");
    dateWithNoTime = `${dateAndTime[0]} ${dateAndTime[1]} ${dateAndTime[2]}`;
    time = `${dateAndTime[3]} ${dateAndTime[4]}`;

    return (
      <div>
        <Text>{dateWithNoTime ?? "-"}</Text>
        <Text color="text-gray">{time ?? "-"}</Text>
      </div>
    );
  }, []);

  const data = useMemo(() => {
    const transactionList = prepareData();
    return transactionList.map((transaction) => {
      const {
        transactionId = 0,
        deviceCreatedDate: date,
        locationName,
        paidAmount,
        type,
        transactionItems,
        items,
        transactionStatus,
      } = transaction || {};

      const transactionItemsCount = items.length;

      let totalQuantity = 0;
      transactionItems.map((item) => {
        return (totalQuantity += item.quantity);
      });

      return {
        recentTransactionId: (
          <ButtonLink className="text-blue" newTabPath={Path.TRANSACTION_ID(transactionId)}>
            #{transactionId}
            <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
          </ButtonLink>
        ),
        location: locationName,
        type: renderTransactionType(type, transactionStatus),
        date: renderDateAndTime(date),
        items: renderItems(items),
        qty:
          type === TransactionType.TOPUP ||
          type === TransactionType.ISSUE_FOC ||
          type === TransactionType.RETURN ||
          type === TransactionType.REMOVE ||
          type === TransactionType.REMOVE_FOC
            ? null
            : transactionItemsCount >= 1
            ? totalQuantity
            : 0,
        amount:
          type === TransactionType.RETURN || transactionStatus === TransactionStatus.VOID ? (
            <Text breakAll color="text-red">
              {paidAmount}
            </Text>
          ) : (
            <Text breakAll>{paidAmount}</Text>
          ),
      };
    });
  }, [renderTransactionType, renderItems, prepareData, renderDateAndTime]);

  return (
    <ChartContainer
      title={lang.recentTransactions}
      empty={mappedData?.length === 0}
      emptyText={""}
      paddingless
      loading={loading}
    >
      <div classnames="grid grid-cols-1">
        <DataTable
          addBorder={data}
          addBorderContent
          className="mt-md"
          columns={columns}
          data={data || []}
          total={5}
        />
        <div>
          <ButtonLink
            className="p-md text-blue-winter"
            onClick={() => history.push(`${Path.TRANSACTIONS}?guestCheckinId=${guestCheckinId}`)}
          >
            {lang.viewAllTransactions}
          </ButtonLink>
        </div>
      </div>
    </ChartContainer>
  );
};

export default GuestRecentTransaction;
