export const items = [
  {
    id: 1,
    name: "Patch Iced Tea",
    description: { quantity: 1, price: 100.0, sugar: null, size: null, isVoucherPresent: true },
    tax: "Sales Tax (3% / 3.00)",
  },
  {
    id: 2,
    name: "Brewed Coffee",
    description: {
      quantity: 1,
      price: 30.0,
      sugar: "Less Sugar",
      size: null,
      isVoucherPresent: false,
    },
    tax: "Sales Tax (3% / 0.90)",
  },
  {
    id: 3,
    name: "Iced Green Tea Matcha Latte",
    description: {
      quantity: 1,
      price: 120.0,
      sugar: null,
      size: "Grande",
      isVoucherPresent: false,
    },
    tax: "Drinks Tax (10% / 12.00)",
  },
];

export const transactionInfo = {
  taxNumber: "000-000-000-000",
  deviceId: "00:00:00:00:00:00",
  serialNumber: "YT9116HN8L",
  receiptNumber: "00000000000001",
  dateAndTime: "04/06/2021 04:30 PM",
  venueLocation: "Lounge",
  venueLocationId: 19235,
  staffTag: "1234 5678 1234 5678",
  posProvider: "PouchNATION Inc.",
  posProviderAddress: "7610 Guijo St. San Antonio Village Makati City, Philippines",
  accrNumber: "0000000000000000000",
  dateIssued: "01-01-2021",
  validUntil: "01-01-2025",
  permitNumber: "00000000000000000",
  guestName: "Juan Dela Cruz",
  guestTagId: "1234 5678 9012 123",
  transactionId: "4019",
  companyName: "Easy Touch Pte. Ltd.",
  venueStreetAddressBuildingNumber: "77 Robinson Road, #16-00 Robinson 77",
  countryAndPostalCode: "Singapore 068896",
  customerServiceEmail: "clientsupport@pouchnation.com",
  companyWebsite: "pouchnation.com",
  companyRegistrationNumber: "201212095K",
};

export const taxList = [
  { id: 1, name: "Sales Tax (3%)", amount: 15.9 },
  { id: 2, name: "Drinks Tax (10%)", amount: 3.9 },
];

export const discountList = [
  { id: 1, name: "Loyalty Discount (%4)", amount: '1.20' }
];
