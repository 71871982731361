import { DataTable, Icon, Text, Tooltip } from "components/commons";
import { React } from "react";
import columns from "./columns";
import lang from "translations";
import { useMount } from "hooks";
import { formatDate } from "services";
import { DateTime } from "enums";
import { mixpanel } from "mixpanel";

const BookingGuests = ({ data: bookingDetailsData, loading: loadingBookingDetails, error }) => {
  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingGuests,
    });
  });

  const renderData = () => {
    if (!bookingDetailsData || !bookingDetailsData.customers) return [];
    return bookingDetailsData.customers.map((customer) => {
      const {
        name,
        checkedInAt,
        isOwner,
        email,
        mobileNumber,
        gender,
        birthdate,
        nationality,
        guestProfile,
        ticketNo,
        preloadedCredit,
      } = customer;
      return {
        name: (
          <div className="flex items-center mt-sm mb-sm">
            <Text>{name}</Text>
            {isOwner && (
              <Tooltip title={lang.headOfBooking} placement="top">
                <div className="p-0 m-0 h-min flex items-start -mt-1">
                  <Icon name="star" color="text-pelorous" className="text-xxs" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
        email: <Text>{email}</Text>,
        mobileNumber: <Text>{mobileNumber}</Text>,
        gender: <Text>{gender}</Text>,
        birthdate: <Text>{formatDate(birthdate, DateTime.V)}</Text>,
        nationality: <Text>{nationality}</Text>,
        guestProfile: <Text>{guestProfile}</Text>,
        ticketNo: <Text>{ticketNo}</Text>,
        preloadedCredit: <Text>{preloadedCredit}</Text>,
        checkinDate: checkedInAt ? (
          formatDate(checkedInAt, DateTime.I)
        ) : (
          <Text color="text-gray">{lang.notCheckedIn}</Text>
        ),
      };
    });
  };

  return (
    <DataTable
      loading={loadingBookingDetails}
      error={error}
      total={bookingDetailsData?.customers?.length}
      title={lang.guestList}
      columns={columns}
      data={renderData()}
      minWidth="800px"
    />
  );
};

export default BookingGuests;
