import { Post } from "services";
import { ApiPath } from "paths";

export const searchBalanceMovementsIn = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BALANCE_MOVEMENTS_IN}`, body);
  return res;
};

export const searchBalanceMovementsOut = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BALANCE_MOVEMENTS_OUT}`, body);
  return res;
};

export const searchBalanceMovementsVenues = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BALANCE_MOVEMENTS_VENUES}`, body);
  return res;
};

export const searchBalanceMovementsInReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BALANCE_MOVEMENTS_IN_REPORT_EXPORT}`, body);
  return res;
};

export const searchBalanceMovementsOutReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BALANCE_MOVEMENTS_OUT_REPORT_EXPORT}`, body);
  return res;
};