import enumeration from "./enumeration";

const BookingStatus = {
  TO_BE_CHECKED_IN: "TO_BE_CHECKED_IN",
  TO_BE_CHECKED_OUT: "TO_BE_CHECKED_OUT",
  CHECKED_IN: "CHECKED_IN",
  MISSED_CHECK_IN: "MISSED_CHECK_IN",
  CHECKED_OUT: "CHECKED_OUT",
  MISSED_CHECK_OUT: "MISSED_CHECK_OUT",
  CANCELLED: "CANCELLED",
  CANCELED: "CANCELED",
  CONFIRMED: "CONFIRMED",
  OTHERS: "OTHERS",
  IN_HOUSE: "IN_HOUSE",
  NO_SHOW: "NO_SHOW",
  NOT_CONFIRMED: "NOT_CONFIRMED",
  FUTURE: "FUTURE",
  CONFIRMATION_PENDING: "CONFIRMATION_PENDING",
  DELETED: "DELETED",
};

export default enumeration(BookingStatus);
