import enumeration from "./enumeration";

const ExternalPaymentStatus = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Pending: "PENDING",
  Timeout: "TIMEOUT",
};

export default enumeration(ExternalPaymentStatus);
