const additionalChargePaths = {
  ADDITIONAL_CHARGE: "",
  SEARCH_ADDITIONAL_CHARGE: "search",
  SEARCH_ADDITIONAL_CHARGE_REPORT: "search/report",
  SEARCH_ADDITIONAL_CHARGE_REPORT_EXPORT: "search/report/export",
  ADD_ADDITIONAL_CHARGE: "add",
  EDIT_ADDITIONAL_CHARGE: "edit",
  ADDITIONAL_CHARGE_ID: (id) => id,
};

export default additionalChargePaths;
