import React, { useCallback, useMemo } from "react";
import StaffForm from "../staff-form/staff-form";
import lang from "translations";
import { inviteStaff } from "apis/staff.api";
import { staffInviteRequest } from "mappers";
import { useApi, useMount } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateStaff = () => {
  const { request, loading: submitting } = useApi({
    api: inviteStaff,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: staffInviteRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.inviteNewStaff,
    });
  });

  const initialState = useMemo(() => {
    return {
      role: {
        value: [],
        defaultAll: false,
        isAll: false,
      },
    }
  }, []);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.populate(lang.invitationHasBeenSentTo, [params.email]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  return (
    <StaffForm
      title={lang.inviteNewStaff}
      formTitle={lang.basicInfo}
      submitting={submitting}
      submit={submitForm}
      initialState={initialState}
    />
  );
};

export default CreateStaff;
