import { ExternalPaymentStatus, TapStatus } from "enums";

export const isFailedTransaction = (externalPaymentStatus, nfcStatus) => {
  if (externalPaymentStatus) {
    if (
      externalPaymentStatus === ExternalPaymentStatus.Success &&
      nfcStatus === TapStatus.Success
    ) {
      return false;
    } else return true;
  }

  return nfcStatus !== TapStatus.Success;
};
