import { Form, Modal, Field, Input, Toast, ActionButton, Select, Text } from "components/commons";
import { useForm, useModal } from "hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import lang from "translations";
import initialFormState from "./payment-method.form-state";
import DeletePaymentMethodModal from "../delete-payment-method/delete-payment-method.modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { groupAccountOptions } from "services/accounting.service";

const PaymentMethodFormModal = ({
  title,
  active,
  close,
  primaryText,
  initialState = undefined,
  save,
  submitting,
  id,
  refreshList,
  isEdit,
  accountOptions,
  defaultPaymentAccountMapping,
  defaultPaymentAccountCodeId,
}) => {
  const unsaveChangesModal = useModal();
  const deletePaymentMethodModal = useModal();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { dirty, fields, modifyField, clearForm, submitForm, getFormValues, applyFieldErrors } =
    useForm({
      initialState: formState,
    });

  const exitForm = () => {
    clearForm();
    unsaveChangesModal.close();
  };

  const onClose = () => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          exitForm();
          setTimeout(() => {
            close();
          });
        },
      });
      return;
    }
    close();
    exitForm();
  };

  useEffect(() => {
    if (active) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const onSave = useCallback(() => {
    save(
      getFormValues(),
      (res, { message }) => {
        Toast({
          content: message,
          success: true,
          icon: "check",
        }).open();
        close();
        clearForm();
      },
      ({ code, handleError }) => {
        const err = {
          3068: () => {
            applyFieldErrors({
              name: lang.paymentMethodAlreadyExist,
            });
          },
        };
        if (err[code]) {
          err[code]();
        } else {
          handleError();
        }
      }
    );
  }, [save, clearForm, close, getFormValues, applyFieldErrors]);

  const selectMessage = (description) => {
    return (
      <Text color="text-gray-light" size="text-xs" className="mt-sm">
        {description}
      </Text>
    );
  };

  return (
    <div>
      <Modal
        active={active}
        hidden={unsaveChangesModal.active || deletePaymentMethodModal.active}
        onClose={() => onClose()}
        primaryText={primaryText}
        loading={submitting}
        noCloseButton
        title={title}
        actionContent={
          <ActionButton
            loading={submitting}
            className="m-md pb-md"
            mb="md"
            primary={{
              text: primaryText,
              onClick: () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.savePaymentMethod,
                  Page: lang.paymentMethodsSettings,
                });
                submitForm(onSave);
              },
              disabled: !dirty,
            }}
            secondary={{
              onClick: () => {
                onClose();
              },
              text: lang.cancel,
            }}
            danger={
              initialState?.name !== lang.cash &&
                initialState?.name !== lang.others &&
                initialState?.name !== "Credit / Debit Card" &&
                initialState?.name !== "PouchPAY" &&
                initialState?.name !== "E-wallet" &&
                isEdit
                ? {
                  text: lang.deletePaymentMethod,
                  onClick: () => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      Button: lang.deletePaymentMethod,
                      Page: lang.paymentMethodsSettings,
                    });
                    deletePaymentMethodModal.show();
                  },
                }
                : null
            }
          />
        }
      >
        <div className="px-sm">
          <Form unsaveChangesModal={unsaveChangesModal}>
            <Field {...fields.name} className="mt-sm">
              <Input required {...fields.name} onChange={modifyField} />
            </Field>

            <Field
              {...fields.accountCodeId}
              customMessage={selectMessage(lang.holdingAccoutForToppedUpItems)}
              className="mt-sm"
            >
              <Select
                {...fields.accountCodeId}
                options={accountOptions}
                searchable
                value={
                  isEdit && !fields.accountCodeId.value && defaultPaymentAccountCodeId
                    ? defaultPaymentAccountCodeId
                    : fields.accountCodeId.value
                }
                onChange={modifyField}
                groupedOptions={groupAccountOptions(accountOptions, fields.accountCodeId.suggested)}
                customNotFoundContent={lang.accountNotFound}
              />
            </Field>

            {/* <CheckboxField
                {...fields.refundable}
                name="refundable"
                value={fields.refundable.value}
                className="py-sm"
                onChange={modifyField}        
                textSize="text-sm"
              >
                {lang.setAsNonRefundable}
              </CheckboxField> */}
          </Form>
        </div>
      </Modal>
      <DeletePaymentMethodModal
        {...deletePaymentMethodModal}
        name={initialState?.name}
        id={id}
        goToList={() => {
          close();
          refreshList();
        }}
      />
    </div>
  );
};

export default PaymentMethodFormModal;
