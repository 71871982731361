import classNames from "classnames";
import {
  ActionButton,
  Field,
  Input,
  Modal,
  Panel,
  PrevNext,
  Text,
  Title,
  UploadImage,
} from "components/commons";
import { InventoryType } from "enums";
import { useForm, useModal } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import lang from "translations";
import styles from "./manage-variant-modal.module.scss";
import PriceField from "./price-field";
import { CreateVariantForm, validateSkus } from "./product-form.state";
import SkuField from "./sku-field";
import SupplyItemField from "./supply-item-field";
import SupplyItemsField from "./supply-items-field";
import { v4 as uuidv4 } from "uuid";
import Validation from "services/validation.service";
import { sortByKeyName } from "services";
import { UnsaveChangesModal } from "components/modals";
import FieldType from "enums/field";

const ManageVariantModal = ({
  form,
  title,
  requestMeasurement,
  requestTax,
  requestSupplyItems,
  createVariant,
  isEdit,
  submit,
  ...modal
}) => {
  const { productName, tax, hasTax, generatedSku, inventoryType, attributes, variants } = {
    ...form,
  };
  const unsaveChangesModal = useModal();
  const [active, setActive] = useState(0);
  const [hasCreate, setHasCreate] = useState(false);

  const formState = useMemo(() => {
    const obj = {
      variants: { ...form.variants, onChange: () => {} },
      createVariant: CreateVariantField(attributes.value, inventoryType),
      modal: {
        value: modal.active,
        type: FieldType.ANY,
      },
    };
    return obj;
  }, [form.variants, attributes.value, modal.active, inventoryType]);

  const variantForm = useForm({
    initialState: formState,
  });

  const { fields, modifyForm, dirty, clearFormV2, clearForm } = {
    ...variantForm,
  };
  // const { variants } = fields;

  const selectedVariant = useMemo(() => {
    if (active === "create") {
      return fields.createVariant?.value;
    }
    if (variants.value[active]) {
      return variants.value[active].value;
    }
    return {};
  }, [active, variants, fields]);

  useEffect(() => {
    if (modal.active) {
      setActive(createVariant ? "create" : 0);
      setHasCreate(createVariant);
      if (createVariant) {
        clearForm();
      } else {
        clearFormV2();
      }
    }
    // eslint-disable-next-line
  }, [modal.active]);

  const onChange = useCallback(
    (value) => {
      if (active === "create") {
        modifyForm({
          createVariant: {
            ...fields.createVariant,
            value: {
              ...fields.createVariant.value,
              ...value,
            },
          },
        });
        return;
      }
      const variant = variants.value;
      variant[active] = {
        ...variant[active],
        value: {
          ...variant[active].value,
          ...value,
        },
      };
      // modifyForm({
      //   variants: {
      //     value: variant,
      //   },
      // });
      submit({
        value: variant,
      });
    },
    [modifyForm, variants, active, fields.createVariant, submit]
  );

  const destoryModal = useCallback(() => {
    setActive(0);
    modal.close();
    setHasCreate(false);
  }, [modal, setActive]);

  const closeModal = useCallback(() => {
    let isValid = validateFields(variants.value);
    if (!isValid) {
      return;
    }
    if (dirty) {
      unsaveChangesModal.show();
      return;
    }
    destoryModal();
    // eslint-disable-next-line
  }, [destoryModal, unsaveChangesModal, dirty, variants.value]);

  const onChangeLogo = useCallback(
    (name, { value, image, file }) => {
      onChange({
        logo: {
          ...selectedVariant.logo,
          value,
          image,
          file,
        },
      });
    },
    [onChange, selectedVariant]
  );

  const variantListValue = useMemo(() => {
    const variantValue = variants.value
      .map((variant, index) => {
        return {
          text: variant.value.key?.value.split(",").join(", "),
          index,
        };
      })
      .sort(sortByKeyName("text"));
    return variantValue.map((variant) => {
      const index = variant.index;
      return (
        <div
          key={uuidv4()}
          className={classNames(
            styles.variant,
            {
              [`${styles.active}`]: active === index,
            },
            "py-sm"
          )}
          onClick={() => {
            setActive(index);
          }}
        >
          <Text
            strong
            size="text-lg"
            className={classNames({
              [`${styles.active}`]: active === index,
            })}
            color=""
          >
            {variant.text}
          </Text>
        </div>
      );
    });
  }, [variants.value, active]);

  const isFormIsValid = useMemo(() => {
    let isError = false;
    if (!dirty) {
      return false;
    }

    if (hasCreate && fields.createVariant.dirty) {
      const createVariantField = fields.createVariant.value;
      if (
        [
          createVariantField.attribute.error,
          !createVariantField.sku.value.trim() && !generatedSku.value,
          createVariantField.sku.error,
        ].includes(true) ||
        (inventoryType.value === InventoryType.WholeProduct && !createVariantField.supplyUnit.value)
      ) {
        isError = true;
      }
      if (inventoryType.value === InventoryType.AssembleProduct) {
        fields.createVariant.value.supplyItems.value.forEach((supplyItem) => {
          if (!supplyItem.value.item.value) {
            isError = true;
          }
        });
      }
      return !isError;
    }
    variants.value.forEach((variant) => {
      const skuStates = [
        variant.value.sku.error,
        !variant.value.sku.value.trim() && !generatedSku.value,
      ];
      if (skuStates.includes(true)) {
        isError = true;
      }
    });
    return !isError;
  }, [variants, generatedSku, hasCreate, fields, dirty, inventoryType]);

  const validateFields = (fields) => {
    let isValid = true;
    if (isEdit) {
      fields?.forEach((field) => {
        let f = field.value["supplyItems"]?.value[0]?.value["quantity"] || 0;
        if (parseFloat(f.value) <= 0) {
          isValid = false;
          return isValid;
        }
      });
    } else {
      fields?.forEach((field) => {
        let f = field.value?.sellingQuantity || 0;
        let quantity = field.value["supplyItems"]?.value[0]?.value["quantity"] || 0;
        if ((parseFloat(f.value) <= 0 && f.error) || parseFloat(quantity.value) <= 0) {
          isValid = false;
          return isValid;
        }
      });
    }
    return isValid;
  };

  return (
    <>
      <Modal
        fullModal
        {...modal}
        active={unsaveChangesModal.active ? false : modal.active}
        width="100%"
        close={() => {
          closeModal();
        }}
      >
        <div className={classNames("overflow-y-auto m-auto bg-white", styles.modalContainer)}>
          <div className={classNames("grid grid-cols-3 m-auto", styles.container)}>
            {!createVariant && (
              <div className={classNames("col-span-1 pr-xl")}>
                <div className={styles.variantsContainer}>
                  <Text label uppercase>
                    {lang.editVariants}
                  </Text>
                  <Title xl>{productName.value || "-"}</Title>
                  <div className="flex justify-between">
                    <Title sm fontWeight="font-bold">
                      {title}
                    </Title>
                  </div>
                  <div className={classNames(styles.variants, "overflow-y-auto")}>
                    {variantListValue}
                  </div>
                </div>
              </div>
            )}
            <div
              className={classNames(
                {
                  "col-span-2": !createVariant,
                  "col-span-3": createVariant,
                },
                styles.variantForm
              )}
            >
              <div className="flex justify-between items-center">
                <div>
                  <Title xl className="break-all mr-md">
                    {createVariant
                      ? lang.addVariant
                      : selectedVariant.key?.value.split(",").join(", ")}
                  </Title>
                </div>
                <div>
                  {!createVariant && (
                    <PrevNext setPage={setActive} page={active} total={variants.value.length} />
                  )}
                </div>
              </div>
              <div className="mt-lg">
                <Panel bordered>
                  <Title>{lang.options}</Title>
                  <div className="flex mt-md">
                    <div>
                      <UploadImage {...selectedVariant.logo} onChange={onChangeLogo} />
                    </div>
                    <div className={classNames("px-md", styles.attribute)}>
                      {selectedVariant.attribute?.value
                        .sort(sortByKeyName("attribute"))
                        .map((option, index) => {
                          return (
                            <Field label={option.attribute} key={index}>
                              <Input
                                value={option.value}
                                disabled={!createVariant}
                                error={
                                  (selectedVariant.attribute?.fieldDirty?.[index] &&
                                    selectedVariant.attribute?.requiredError?.[index]) ||
                                  selectedVariant.attribute.error
                                }
                                required={createVariant}
                                maxLength={75}
                                onChange={(name, { value }) => {
                                  const attributeValue = selectedVariant.attribute.value;
                                  attributeValue[index] = {
                                    ...attributeValue[index],
                                    value,
                                  };
                                  const options = attributeValue.map((attr) => {
                                    return attr.value;
                                  });
                                  onChange({
                                    attribute: {
                                      ...selectedVariant.attribute,
                                      value: attributeValue,
                                    },
                                    key: {
                                      value: options.join(","),
                                    },
                                  });
                                }}
                              />
                            </Field>
                          );
                        })}
                      {selectedVariant?.attribute?.message && (
                        <Text error>{selectedVariant.attribute.message}</Text>
                      )}
                    </div>
                  </div>
                </Panel>
                <Panel bordered>
                  <Title>{lang.skuStockKeepingUnit}</Title>
                  <SkuField
                    generatedSku={generatedSku}
                    sku={{
                      ...selectedVariant.sku,
                      error: selectedVariant.sku.dirty && selectedVariant.sku.error,
                      message: selectedVariant.sku.dirty ? selectedVariant.sku.message : "",
                      onChange: (name, { value }) => {
                        onChange({
                          [name]: {
                            ...selectedVariant.sku,
                            value,
                            invalid: false,
                            dirty: true,
                          },
                        });
                      },
                      disabled: generatedSku.value,
                      required: selectedVariant.added || selectedVariant.sku.required,
                    }}
                  />
                </Panel>
                {(!isEdit || createVariant || selectedVariant.added.value) &&
                inventoryType.value === InventoryType.WholeProduct ? (
                  <Panel bordered>
                    <Title>{lang.supplyItem}</Title>
                    <SupplyItemField
                      requestMeasurement={requestMeasurement}
                      supplyUnit={{
                        ...selectedVariant.supplyUnit,
                        onChange: (name, { value }) => {
                          onChange({
                            [name]: {
                              ...selectedVariant.supplyUnit,
                              value,
                            },
                          });
                        },
                      }}
                      supplyQuantity={{
                        ...selectedVariant.supplyQuantity,

                        onChange: (name, { value }) => {
                          onChange({
                            [name]: {
                              ...selectedVariant.supplyQuantity,
                              value,
                            },
                          });
                        },
                      }}
                      sellingQuantity={{
                        ...selectedVariant.sellingQuantity,
                        onChange: (name, { value }) => {
                          onChange({
                            [name]: {
                              ...selectedVariant.sellingQuantity,
                              value,
                            },
                          });
                        },
                      }}
                      modifyForm={(value) => {
                        onChange(value);
                      }}
                    />
                  </Panel>
                ) : (
                  <Panel bordered>
                    {(isEdit || createVariant || selectedVariant.added.value) &&
                    InventoryType.WholeProduct ? (
                      <Title>{lang.supplyItem}</Title>
                    ) : (
                      <>
                        <Title>{lang.supplyItems}</Title>
                        <Text className="mb-md">{lang.adjustTheSupplyItemsVariant}</Text>
                      </>
                    )}

                    <SupplyItemsField
                      removeAddButton={isEdit && inventoryType.value === InventoryType.WholeProduct}
                      form={{
                        ...selectedVariant,
                        supplyItems: {
                          ...selectedVariant.supplyItems,
                          onChange: (name, value) => {
                            onChange({
                              [name]: {
                                ...selectedVariant[name],
                                ...value,
                              },
                            });
                          },
                        },
                      }}
                      modifyForm={(value) => {
                        onChange({
                          supplyItems: {
                            ...selectedVariant.supplyItems,
                            ...value.supplyItems,
                          },
                          supplyCost: {
                            ...selectedVariant.supplyCost,
                            ...value.supplyCost,
                          },
                          retailPrice: {
                            ...selectedVariant.retailPrice,
                            ...value.retailPrice,
                          },
                        });
                      }}
                      options={requestSupplyItems.mappedData}
                      loadingOptions={requestSupplyItems.loading}
                    />
                  </Panel>
                )}

                <Panel bordered>
                  <Title>{lang.price}</Title>
                  <PriceField
                    tax={tax}
                    hasTax={hasTax}
                    taxLoading={requestTax.loading}
                    taxOptions={requestTax.mappedData}
                    supplyCost={selectedVariant.supplyCost}
                    markUp={{
                      ...selectedVariant.markUp,
                      disabled: inventoryType.value === InventoryType.WholeProduct,
                    }}
                    retailPrice={selectedVariant.retailPrice}
                    bargainAllowed={selectedVariant.bargainAllowed}
                    bargainRequiresApproval={selectedVariant.bargainRequiresApproval}
                    onChange={onChange}
                  />
                </Panel>

                <ActionButton
                  className="mb-lg"
                  disabled={!isFormIsValid}
                  primary={{
                    disabled: createVariant ? !isFormIsValid : false,
                    text: createVariant ? lang.save : lang.ok,
                    onClick: () => {
                      let isValid = validateFields(variants.value);
                      if (!isValid) {
                        return;
                      }
                      if (!createVariant) {
                        modal.close();
                        return;
                      }
                      // submitForm(() => {
                      const value = [...variants.value];
                      if (hasCreate) {
                        value.push({
                          ...fields.createVariant,
                          value: {
                            ...fields.createVariant.value,
                            attribute: {
                              ...fields.createVariant.value.attribute,
                              validations: [],
                            },
                            sku: {
                              ...fields.createVariant.value.sku,
                              validations: [
                                Validation.minlength(4),
                                Validation.maxlength(16),
                                Validation.required(),
                                validateSkus,
                              ],
                            },
                          },
                        });
                      }
                      const variantValue = value
                        .map((variant, index) => {
                          return {
                            text: variant.value.key?.value.split(",").join(", "),
                            index,
                          };
                        })
                        .sort(sortByKeyName("text"));

                      submit({
                        ...variants,
                        value: variantValue.map(({ index }) => {
                          return value[index];
                        }),
                      });
                      // });
                      modal.close();
                    },
                  }}
                  secondary={
                    !createVariant
                      ? null
                      : {
                          onClick: () => {
                            closeModal();
                          },
                          text: lang.cancel,
                        }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <UnsaveChangesModal
        {...unsaveChangesModal}
        ok={() => {
          destoryModal();
          unsaveChangesModal.close();
        }}
      />
    </>
  );
};

export default ManageVariantModal;

const CreateVariantField = (attributes, inventoryType) => {
  const skuValidation = [
    Validation.minlength(4),
    Validation.maxlength(16),
    Validation.required(),
    (field, { form }) => {
      const { variants: variantsField } = form;
      const skus = variantsField.value.map((f) => {
        return f.value.sku.value.trim();
      });
      const error = skus.includes(field.value.trim());
      return {
        error,
        message: error ? lang.thisSkuExists : "",
      };
    },
  ];
  return {
    ...CreateVariantForm({
      added: { value: true },
      key: { value: lang.addVariant },
      sku: {
        value: "",
        validations: skuValidation,
        required: true,
      },
      supplyItems: {
        disabled: inventoryType.value === InventoryType.WholeProduct,
      },
      supplyUnit: {
        disabled: inventoryType.value === InventoryType.AssembleProduct,
      },
      attribute: {
        value: attributes.map((attr) => {
          return {
            attribute: attr,
            value: "",
          };
        }),
        validations: [
          (field, { form }) => {
            const { variants: variantsField } = form;
            const combinations = variantsField.value.map((f) => {
              const name = [];
              f.value.attribute.value.sort(sortByKeyName("attribute")).forEach((v) => {
                name.push(`${v.attribute}-${v.value}`);
              });
              return name.join("");
            });
            const values = [];
            let error = "";
            let errorMessage = "";
            const fieldDirty = field.fieldDirty || {};
            field.value.sort(sortByKeyName("attribute")).forEach((v, index) => {
              if (!fieldDirty[index] && v.value) {
                fieldDirty[index] = true;
              }
              if (v.value.trim()) {
                const val = v.value.trim().toLowerCase();

                if (!values.includes(val)) {
                  values.push(val);
                } else if (!error && values.includes(val)) {
                  error = true;
                  errorMessage = lang.invalidOptions;
                }
              }
            });

            const attributeNameAndOptions = [];
            field.value.sort(sortByKeyName("attribute")).forEach((v) => {
              attributeNameAndOptions.push(`${v.attribute}-${v.value}`);
            });
            if (!error && combinations.includes(attributeNameAndOptions.join(""))) {
              error = true;
              errorMessage = lang.variantAlreadyExist;
            }

            const requiredError = field.value.sort(sortByKeyName("attribute")).map((attr) => {
              if (!attr.value.trim()) {
                error = true;
                errorMessage = "";
                return true;
              }
              return false;
            });

            return {
              error: error,
              message: errorMessage,
              requiredError,
              fieldDirty,
            };
          },
        ],
      },
    }),
  };
};
