import { searchDiscountWithTransaction, searchLocationWithTransaction } from "apis";
import {
  Field,
  MultipleSelect,
  RangePicker,
  ReportFilter,
  Select,
  Text,
  TimePicker,
} from "components/commons";
import { useApi, useMount } from "hooks";
import {
  locationWithTransactionFilterResponse,
  locationWithTransactionFilterRequest,
  discountWithTransactionFilterRequest,
  discountWithTransactionFilterResponse,
} from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";

const DiscountReportFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  onClear,
  onApply,
}) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: searchLocationWithTransaction,
    isArray: true,
    mapper: locationWithTransactionFilterResponse,
  });

  const {
    request: searchDiscountRequest,
    loading: loadingDiscount,
    mappedData: discounts,
  } = useApi({
    api: searchDiscountWithTransaction,
    isArray: true,
    mapper: discountWithTransactionFilterResponse,
  });

  useMount(() => {
    fetchLocations(requestState);
    fetchDiscounts(requestState);
  });

  const fetchLocations = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, locationWithTransactionFilterRequest));
    },
    [searchLocationRequest]
  );

  const fetchDiscounts = useCallback(
    (requestState) => {
      searchDiscountRequest(mapObject({ ...requestState }, discountWithTransactionFilterRequest));
    },
    [searchDiscountRequest]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
     await onApply(searchKey);
    },
    [onApply]
  );

  const clearFilterCb = useCallback(async () => {
    const { filterState, requestState } = await clearFilter();
    onClear(filterState, requestState);
    fetchLocations(requestState);
    fetchDiscounts(requestState);
  }, [clearFilter, onClear, fetchLocations, fetchDiscounts]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocations(requestState);
      fetchDiscounts(requestState);
    },
    [fetchLocations, fetchDiscounts, modifyFilter]
  );

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const discountOptions = useMemo(() => {
    let sorted = discounts
      .map((discount) => {
        discount.name = discount.name.trim();
        return discount;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return [
      { text: lang.allDiscounts, value: null },
      ...mapObjectsToSelect(sorted, { textKey: "name" }),
    ];
  }, [discounts]);

  return (
    <div>
      <ReportFilter
        onClear={clearFilterCb}
        onApply={applyFilterCb}
        dateRange={filterState.dateRange}
        span={8}
      >
        <Field filterLabel={lang.dateRange} className="col-span-4">
          <RangePicker
            name="dateRange"
            onChange={changeDateRangeCb}
            value={filterState.dateRange}
          />
        </Field>
        <Field className="col-span-4" filterLabel={lang.timeRange}>
          <div className="flex items-center">
            <TimePicker
              value={filterState.dateRange[0]}
              onChange={(startTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0].set({
                      hour: startTime.get("hour"),
                      minute: startTime.get("minute"),
                    }),
                    filterState.dateRange[1],
                  ],
                });
              }}
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              value={filterState.dateRange[1]}
              onChange={(endTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0],
                    filterState.dateRange[1].set({
                      hour: endTime.get("hour"),
                      minute: endTime.get("minute"),
                    }),
                  ],
                });
              }}
            />
          </div>
        </Field>
        <Field className="col-span-4" filterLabel={lang.location}>
          <MultipleSelect
            name="locations"
            selectAllText={lang.allLocations}
            loading={loadingLocation}
            options={locationOptions}
            value={filterState.locations}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={lang.selectLocation}
            emptyPlaceHolder={lang.noLocationAvailable}
            defaultAll
            isAll
          />
        </Field>
        <Field className="col-span-4" filterLabel={lang.discountName}>
          <Select
            name="discount"
            loading={loadingDiscount}
            options={discountOptions}
            value={filterState.discount}
            onChange={modifyFilter}
          />
        </Field>
      </ReportFilter>
    </div>
  );
};

export default DiscountReportFilter;
