const productPaths = {
  PRODUCT: "",
  SEARCH_PRODUCT: "search/v2",
  PRODUCT_ID: (locationId) => locationId,
  SEARCH_PRODUCT_REPORT: "search/report",
  SEARCH_PRODUCT_REPORT_EXPORT: "search/report/export",
  SEARCH_PRODUCT_EXPORT: "search/v2/export",
  PRODUCT_POS: "pos",
  BULK_PRODUCT_POS: "bulk-pos",
  BATCH_PRODUCT: "batch",
  BULK_PRODUCT: "bulk-product",
  SEARCH_PRODUCT_MOVEMENT: (id) => `${id}/movement`,
  EDIT_PRODUCT_LOCATION_STOCK: ({ id, locationId }) => `product-sku/${id}/location/${locationId}`,
  ONLINE_ORDER: "online-order",
};

export default productPaths;
