import React from "react";
import { Icon } from "..";
import styles from "./loaderB.module.scss";

const LoaderB = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className={styles.container}>
      <Icon loading className="m-auto text-white" fontSize="40px" />
    </div>
  );
};

export default LoaderB;
