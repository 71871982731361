import { Field, Filter, MultipleSelect, RangePicker, Select, Text, TimePicker } from "components";
import { ViewReportAccessControl } from "enums";
import React, { useEffect, useMemo } from "react";
import { measureTime, modifyFromTimeRange, modifyToTimeRange } from "services";
import { prettifyIntervalTime } from "services/pretty.service";
import lang from "translations";

const AccessControlReportFilter = ({
  venueId,
  filterState,
  modifyFilter,
  applyFilterCb,
  clearFilterCb,
  accessPointData,
  accessPointLoading,
  guestProfileData,
  guestProfileLoading,
}) => {
  const viewOptions = useMemo(
    () => [
      { value: ViewReportAccessControl.Access, text: lang.accessPoint },
      { value: ViewReportAccessControl.Guest, text: lang.guestProfile },
    ],
    []
  );

  const intervalOptions = useMemo(() => {
    const dateRange = filterState.dateRange;
    let ret = measureTime([dateRange[0], dateRange[1]]).getOptType();
    ret = ret.map((i) => ({
      value: i,
      text: prettifyIntervalTime(i),
    }));
    return ret;
  }, [filterState.dateRange]);

  useEffect(() => {
    modifyFilter("intervalType", { ...intervalOptions[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalOptions]);

  return (
    <Filter
      className="my-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      searchBox={false}
      filterState={filterState}
      actionsSpan={3}
    >
      <Field className="col-span-3" filterLabel={lang.dateRange}>
        <RangePicker
          value={filterState.dateRange}
          name="dateRange"
          onChange={(name, value) => {
            if (value) {
              modifyFilter(name, {
                value,
              });
            }
          }}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(val) => {
              modifyFromTimeRange(val, filterState, modifyFilter);
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            to
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(val) => {
              modifyToTimeRange(val, filterState, modifyFilter);
            }}
          />
        </div>
      </Field>
      <Field className="col-span-3" filterLabel={lang.viewBy}>
        <Select
          name="viewBy"
          options={viewOptions}
          value={filterState.viewBy}
          onChange={modifyFilter}
          sortOptions={false}
        />
      </Field>
      {filterState.viewBy === ViewReportAccessControl.Access && (
        <Field className="col-span-3" filterLabel={lang.accessPoint}>
          <MultipleSelect
            name="accessPointIds"
            loading={accessPointLoading}
            options={accessPointData}
            value={filterState.accessPointIds}
            onChange={modifyFilter}
            sortOptions={false}
            selectAllText={lang.allAccessPoints}
            defaultAll
          />
        </Field>
      )}
      {filterState.viewBy === ViewReportAccessControl.Guest && (
        <Field className="col-span-3" filterLabel={lang.guestProfile}>
          <MultipleSelect
            name="guestProfileIds"
            loading={guestProfileLoading}
            options={guestProfileData}
            value={filterState.guestProfileIds}
            onChange={modifyFilter}
            sortOptions={false}
            selectAllText={lang.allGuestProfiles}
            defaultAll
          />
        </Field>
      )}
      <Field className="col-span-3" filterLabel={lang.interval}>
        <Select
          name="intervalType"
          options={intervalOptions}
          value={filterState.intervalType}
          onChange={modifyFilter}
          sortOptions={false}
        />
      </Field>
      <div className="col-span-6"></div>
    </Filter>
  );
};

export default AccessControlReportFilter;
